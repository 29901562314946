import React, { useEffect, useState } from "react";
import Logo from "../../assets/ypp-logo.svg";
import * as utils from "../../utils";
import { useMsal } from "@azure/msal-react";
import Helper from "../../utils/config/helper";
import { loginRequest } from "../../config/authConfig";
import Dashboard from "../Dashboard/dashboard";
import APIHelper from "../../utils/config/apiHelper";
import APIURLConstant from "../../utils/config/apiURLConstant";
import {
  InteractionStatus,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";

const LoginView = (props) => {
  const [accessToken, setAccessToken] = useState(
    Helper.getStorage("accessToken") ?? null
  );
  useEffect(() => {
    setAccessToken(Helper.getStorage("accessToken"));
  }, []);
  useEffect(() => {
    if (accessToken === null) {
      handleLogin();
      console.log("logout");
    } else {
      console.log("login");
    }
  }, [accessToken]);

  const { instance, inProgress } = useMsal();

  // useEffect(() => {
  //   if (inProgress === InteractionStatus.None) {
  //     instance
  //       .handleRedirectPromise()
  //       .then(async (tokenResponse) => {
  //         if (!tokenResponse) {
  //           const allAccounts = instance.getAllAccounts();
  //           if (allAccounts.length === 0) {
  //             console.log("allAccounts.length === 0");
  //             let check = await instance.loginPopup(loginRequest);

  //             if (check.accessToken) {
  //               // APIHelper.get(APIURLConstant.GET_USER_DETAILS(check.accessToken)).then((data) => {
  //               //   if (data) {
  //               //     console.log(data)
  //               //   } else {
  //               //     console.log(data)
  //               //   }
  //               // }).catch((err) => { console.log(err); });
  //               // console.log(check.accessToken);
  //               Helper.setStorage("accessToken", check.accessToken);
  //               Helper.setStorage("chk", "aad");
  //               // props.setLogin(false);
  //             }
  //           }
  //           else {
  //             if (instance && allAccounts[0]) {
  //               const accessTokenRequest = {
  //                 ...loginRequest,
  //                 account: allAccounts[0],
  //               };
  //               if (inProgress === InteractionStatus.None) {
  //                 instance
  //                   .acquireTokenSilent(accessTokenRequest)
  //                   .then(async (response) => {
  //                     Helper.setStorage("accessToken", response.accessToken);
  //                     Helper.setStorage("chk", "aad");
  //                     window.location.reload();
  //                     // eslint-disable-next-line no-debugger
  //                   })
  //                   .catch((error) => {
  //                     if (error instanceof InteractionRequiredAuthError) {
  //                       instance.acquireTokenRedirect(accessTokenRequest);
  //                     }
  //                   });
  //               }
  //             }
  //             // console.log(accounts);
  //             // Helper.setStorage('accessToken', check.accessToken);
  //             // Helper.setStorage('chk', 'aad');
  //           }
  //         } else {
  //           Helper.setStorage("accessToken", tokenResponse.accessToken);
  //           Helper.setStorage("chk", "aad");
  //           // props.setLogin(false);
  //           setAccessToken(Helper.getStorage("accessToken"));
  //           window.location.reload();
  //           console.log("!tokenResponse");
  //         }
  //       })
  //       .catch((err) => {
  //         // Handle error
  //         console.error(err);
  //       });
  //   }
  // }, [inProgress, instance, props]);

  const handleLogin = () => {
    instance
      .handleRedirectPromise()
      .then(async (tokenResponse) => {
        if (!tokenResponse) {
          const accounts = instance.getAllAccounts();
          if (accounts.length === 0) {
            // No user signed in
            // let check = await instance.loginPopup(loginRequest);
            let check = await instance.loginRedirect(loginRequest);

            if (check.accessToken) {
              // APIHelper.get(APIURLConstant.GET_USER_DETAILS(check.accessToken)).then((data) => {
              //   if (data) {
              //     console.log(data)
              //   } else {
              //     console.log(data)
              //   }
              // }).catch((err) => { console.log(err); });
              // console.log(check.accessToken);
              Helper.setStorage("accessToken", check.accessToken);
              Helper.setStorage("chk", "aad");
              // props.setLogin(false);
            }
          } else {
            if (instance && accounts[0]) {
              const accessTokenRequest = {
                ...loginRequest,
                account: accounts[0],
              };
              if (inProgress === InteractionStatus.None) {
                instance
                  .acquireTokenSilent(accessTokenRequest)
                  .then(async (response) => {
                    //const payLoad = {emailId : response.account.username}
                    APIHelper.post(APIURLConstant.UPDATE_USER_LAST_LOGIN, {email : response.account.username})
                    .then((res) => {
                      Helper.setStorage("accessToken", response.accessToken);
                      Helper.setStorage("chk", "aad");
                      window.location.reload();
                      // eslint-disable-next-line no-debugger
                    })
                  })
                  .catch((error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                      instance.acquireTokenRedirect(accessTokenRequest);
                    }
                  });
              }
            }
            // console.log(accounts);
            // Helper.setStorage('accessToken', check.accessToken);
            // Helper.setStorage('chk', 'aad');
          }
        } else {
          // Do something with the tokenResponse
          //const payLoad = {emailId : tokenResponse.account.username}
          APIHelper.post(APIURLConstant.UPDATE_USER_LAST_LOGIN, {email :tokenResponse.account.username})
          .then((res) => {
            Helper.setStorage("accessToken", tokenResponse.accessToken);
            Helper.setStorage("chk", "aad");
            window.location.reload();
            // props.setLogin(false);
          })
        }
      })
      .catch((err) => {
        // Handle error
        console.error(err);
      });
    // eslint-disable-next-line no-debugger
    // debugger
    // location.href = "/sso";
    //props.history.replace("/sso");
  };

  return (
    <div className="mx-auto">
      {accessToken ? (
        <div>
          <Dashboard />
        </div>
      ) : (
        <div className="h-screen grid place-items-center">
          {/* <div className=" max-w-sm rounded-2xl overflow-hidden shadow-lg w-full pb-4">
            <div className="flex justify-center text-center py-1 bg-[#1D428A]">
              <img src={Logo} className="h-15 w-5" alt="logo" />
              <h3 className="text-2xl text-white font-semibold mt-1 px-2">
                Huddle
              </h3>
            </div> */}
          {/* <form onSubmit={props.handleSubmit()}>
          <div className="px-6 py-2 mt-4">
            <input
              type="text"
              id="Email"
              value={props.data.email}
              className={`h-10 w-full border focus:outline-none p-2 text-gray-900 rounded ${
                props.errorMessage && props.errorMessage === "mailEmtyError"
                  ? "border-red-400"
                  : props.errorMessage === "mailFormatError"
                  ? "border-red-400"
                  : "border-grey-300"
              }`}
              placeholder="Email"
              onChange={(e) => props.handleEmailChange(e.target.value)}
            />
            {props.errorMessage === "mailEmtyError" && (
              <div className="text-start pl-1">
                <span className="text-red-500 text-sm">
                  Please enter the mail
                </span>
              </div>
            )}
            {props.errorMessage === "mailFormatError" && (
              <div className="text-start pl-1">
                <span className="text-red-500 text-sm">
                  Please enter the valid mail
                </span>
              </div>
            )}
          </div>
          <div className="px-6 py-2">
            <input
              type="password"
              id="password"
              value={props.data.password}
              className={`h-10 w-full border focus:outline-none p-2 text-gray-900 rounded ${
                props.errorMessage && props.errorMessage === "passwordError"
                  ? "border-red-400"
                  : "border-grey-300"
              }`}
              placeholder="Password"
              onChange={(e) => props.handlePasswordChange(e.target.value)}
            />
            {props.errorMessage === "passwordError" && (
              <div className="text-start pl-1">
                <span className="text-red-500 text-sm">
                  Please enter the password
                </span>
              </div>
            )}
          </div>
          <div className="px-6 py-2">
            <button
              type="submit"
              className="focus:outline-none w-full text-white bg-red-700 hover:bg-red-800 font-medium rounded px-5 py-2.5 mr-2 mb-2 "
            >
              {utils.string.t("login.loginLabel")}
            </button>
          </div>
        </form> */}
          {/* <div className="text-center mb-4 px-6">
              <p className="text-base text-gray-900 dark:text-white">
                {utils.string.t("login.loginWarningLabel")}
              </p>
            </div> */}
          {/* <div className="px-6 py-2">
              <button
                type="button"
                className="focus:outline-none w-full text-white bg-[#1D428A] hover:bg-blue-800 font-medium rounded px-5 py-2.5 mr-2 mb-2"
                onClick={() => handleLogin()}
              >
                {utils.string.t("login.sso")}
              </button>
            </div> */}
          {/* <div className="flex justify-between px-6 py-2">
          <Link to="/login" className="font-sm text-blue-900 underline ">
            {utils.string.t("login.forgotPasswordLabel")}
          </Link>
          <div className="flex">
            <input
              id="default-checkbox"
              type="checkbox"
              value=""
              className="w-4 h-6 text-blue-600 bg-gray-100 rounded"
            />
            <label className="ml-2 text-sm font-medium text-gray-700 dark:text-gray-300">
              {utils.string.t("login.rememberMeLabel")}
            </label>
          </div>
        </div> */}
          {/* </div> */}
        </div>
      )}
    </div>
  );
};

export default LoginView;
