import React, { useState, useContext, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import Loader from "../../sharedComponent/Loader/Loader";
import APIHelper from "../../utils/config/apiHelper";
import APIURLConstant from "../../utils/config/apiURLConstant";
import { AppContext } from "../../store/context";
import { Multiselect } from "multiselect-react-dropdown";
import Select from "react-select";
import Chart from "../../sharedComponent/Chart/Chart";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ExportExcel from "./exportData";
import DownloadExcel from "../../assets/download_excel.svg";
import DatePicker from "react-datepicker";
import FilterIcon from "../../assets/filter.svg";
import ProgramCountBar from "../../sharedComponent/Chart/ProgramCountBar";
import ParticipantReachBar from "../../sharedComponent/Chart/ParticipantReachBar";
import ProgramListForCalendar from "./ProgramListForCalendar";

const Calender = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [programList, setProgramList] = useState([]);
  const [upcomingProgram, setUpcomingPrograms] = useState([]);
  const [needWorkProgram, setNeedsWorkPrograms] = useState([]);
  const [overAllProgramList, setOverAllProgramList] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    userDetails,
    userRegions,
    userCategories,
    dispatchRegions,
    dispatchCategories,
  } = useContext(AppContext);
  const [eventDetails, setEventDetails] = useState({});
  const [programCategory, setProgramCategory] = useState([]);
  const [regions, setRegions] = useState([]);
  const [programCategoryFilters, setProgramCategoryFilters] = useState("");
  const [regionFilters, setRegionFilters] = useState([]);
  const [programTypes, setProgramTypes] = useState([]);
  const [programTypesFilters, setProgramTypesFilters] = useState([]);
  const [programChartData, setprogramChartData] = useState([]);
  const [participantReachCount, setParticipantReactCount] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [programCountStartDate, setProgramCountStartDate] = useState(
    moment().startOf("week").toDate()
  );
  const [programCountEndDate, setProgramCountEndtDate] = useState(
    moment().endOf("week").toDate()
  );
  const [participantCountStartDate, setParticipantCountStartDate] = useState(
    moment().startOf("week").toDate()
  );
  const [participantCountEndDate, setParticipantCountEndtDate] = useState(
    moment().endOf("week").toDate()
  );
  const [programType, setProgramType] = useState([]);
  const [programTypeList, setProgramTypeList] = useState([]);
  const [particiapantRegion, setParticipantRegion] = useState([]);
  const navigate = useNavigate();
  const [programListToDownload, setProgramListToDownload] = useState([]);
  const [showCreateProgram, setShowCreateProgram] = useState(false);
  const [isActual, setIsActual] = useState(true);
  const colorCode = {
    1: {
      color: "#00D18C",
    },
    2: {
      color: "#27A2CB",
    },
    3: {
      color: "#F0D900",
    },
    4: {
      color: "#7000FF",
    },
    5: {
      color: "#D129BB",
    },
    6: {
      color: "#FE4917",
    },
    primary: {
      color: "#C8102E",
    },
  };

  const filterOptions = [
    {
      name: "BWB programs",
      color: "bwb-programs",
    },
    {
      name: "Jr. NBA US programs",
      color: "jr-nba-us-programs",
    },
    {
      name: "NBA Basketball School programs",
      color: "nba-basketball-school-programs",
    },
    {
      name: "NBA Academy programs",
      color: "nba-academy-programs",
    },
    {
      name: "Jr. NBA International programs",
      color: "jr-nba-international-programs",
    },
    {
      name: "Other",
      color: "other",
    },
  ];

  useEffect(() => {
    if (
      (userDetails.roleIDList !== "" && userDetails.roleIDList === "7") ||
      userDetails.roleIDList === "8" ||
      userDetails.roleIDList === "9" ||
      userDetails.roleIDList === "10"
    ) {
      setShowCreateProgram(false);
    } else {
      setShowCreateProgram(true);
    }
  }, [userDetails]);

  useEffect(() => {
    if (userDetails.email !== "") {
      let region = regionFilters
        .map((item) => {
          return item.value;
        })
        .join(",");
      let types = programTypesFilters
        .map((item) => {
          return item.value;
        })
        .join(",");
      if (startDate !== null && endDate !== null) {
        getProgramList(
          programCategoryFilters !== "" ? programCategoryFilters?.value : null,
          types?.length > 0 ? types : null,
          region?.length > 0 ? region : null
        );
      }
    }
  }, [userDetails.email, endDate]);

  useEffect(() => {
    if (userDetails?.email !== "") {
      getProgramCategory();
      getProgramRegions();
      getProgramCounts(programCountStartDate, programCountEndDate);
    }
  }, [userDetails.email]);

  useEffect(() => {
    if (programTypeList?.length > 0 && regions?.length > 0) {
      getParticipantReachCount(
        participantCountStartDate,
        participantCountEndDate,
        JSON.stringify(regions[0]?.value),
        programTypeList[0]?.value
      );
    }
  }, [programTypeList, regions]);

  useEffect(() => {
    if (programCategory !== null && programCategory?.length > 0) {
      getAllProgramTypes();
    }
  }, [programCategory]);

  const getAllProgramTypes = () => {
    setLoading(true);
    APIHelper.get(APIURLConstant.GET_PROGRAMTYPESALL).then((res) => {
      setLoading(false);

      if (res && res?.length > 0) {
        const updatedData = [];
        res?.forEach((item) => {
          if (item?.isDeleted === false) {
            updatedData.push({
              label: item?.type,
              value: item?.type,
              ...item,
            });
          }
        });
        setProgramTypeList(updatedData);

        setProgramType(updatedData[0]);
      } else {
        setProgramTypeList([]);
      }
    });
  };

  const getProgramCounts = (start, end) => {
    const payLoad = {
      startDate: start,
      endDate: end,
    };
    setLoading(true);
    APIHelper.post(APIURLConstant.GET_PROGRAME_COUNT_BY_CATEGORY, payLoad)
      .then((res) => {
        setLoading(false);

        const data = [
          {
            name: "Jr. NBA International Programs",
            "Jr. NBA International Programs":
              res?.programs_By_Jr_NBA_International_Programs_Category,
            label: "Jr.NBA Intern",
          },
          {
            name: "Jr. NBA US Programs",
            "Jr. NBA US Programs": res?.programs_By_Jr_NBA_US_Programs_Category,
            label: "Jr.NBA US",
          },
          {
            name: "BWB Programs",
            "BWB Programs": res?.programs_By_BWB_Programs_Category,
            label: "BWB",
          },
          {
            name: "NBA Basketball School Programs",
            "NBA Basketball School Programs":
              res?.programs_By_NBA_Basketball_School_Programs_Category,
            label: "NBA Bas schl",
          },
          {
            name: "NBA Academy Programs",
            "NBA Academy Programs":
              res?.programs_By_NBA_Academy_Programs_Category,
            label: "NBA Acad",
          },
          {
            name: "Other Programs",
            "Other Programs": res?.programs_By_Other_Programs_Category,
            label: "other",
          },
        ];
        setprogramChartData(data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const getParticipantReachCount = (
    start,
    end,
    region,
    programTypeSelected
  ) => {
    const payLoad = {
      startDate: start,
      endDate: end,
      userRegionIDs: region,
      programType: programTypeSelected,
    };
    setLoading(true);
    APIHelper.post(
      APIURLConstant.GET_PARTICIPANTS_ACTUAL_AND_REACH_COUNT,
      payLoad
    )
      .then((res) => {
        setLoading(false);

        const dataUpdated = [
          {
            name: "Coaches",
            actual: res?.actualCoaches !== null ? res?.actualCoaches : 0,
            reached: res?.reachedCoaches !== null ? res?.reachedCoaches : 0,
            totalDirect: res?.totalDirect,
            totalReached: res?.totalReached,
          },
          {
            name: "Youths",
            actual: res?.actualYouths !== null ? res?.actualYouths : 0,
            reached: res?.reachedYouths !== null ? res?.reachedYouths : 0,
          },
          {
            name: "Referees",
            actual: res?.actualReferees !== null ? res?.actualReferees : 0,
            reached: res?.reachedReferees !== null ? res?.reachedReferees : 0,
          },
          {
            name: "Teams",
            actual: res?.actualTeams !== null ? res?.actualTeams : 0,
            reached: res?.reachedTeams !== null ? res?.reachedTeams : 0,
          },
        ];
        setParticipantReactCount(dataUpdated);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const Image = () => {
    return <image src={DownloadExcel} />;
  };

  const getProgramList = (
    categoryIDs,
    typeIDs,
    regionsIDs,
    userEmail = userDetails.email
  ) => {
    setLoading(true);

    const payload = {
      StartDate: new Date(startDate),
      EndDate: new Date(endDate),
      UserEmail: userEmail,
      CategoryIDs: categoryIDs === null ? "null" : categoryIDs.toString(),
      TypeIDs: typeIDs === null ? "null" : typeIDs.toString(),
      RegionIDs: regionsIDs
        ? regionsIDs
        : userDetails.regionIDs
        ? userDetails.regionIDs
        : "null",
    };
    APIHelper.post(APIURLConstant.GET_PROGRAM_LIST_FOR_CALENDER, payload)
      .then((res) => {
        if (res && res.length > 0) {
          //save detailed list
          let data;
          const filteredData = [];
          res?.forEach((item) => {
            if (item?.programsFor === "CalendarPrograms") {
              filteredData.push(item);
            }
          });

          data = filteredData.map((item) => {
            let isConfirmedValue = item?.isConfirmed ? " - *C" : " - *T";
            let now = moment(userDetails?.lastLoginDate); //todays date
            let end = moment(item?.createdDate); // another date
            let duration = moment.duration(now.diff(end));
            let days = duration.days();
            let hours = duration.hours();
            let minutes = duration.minutes();

            const isNewProgram = days === 0 && hours <= 1 ? true : false;

            return {
              title: item?.localProgramName + isConfirmedValue,
              start:
                `${moment(item?.overallStartDate).format(
                  "YYYY-MM-DD"
                )}`.toString() || "",
              // ${new Date(item?.overallStartDate)?.getDate().toString()
              //   .length === 1
              //   ? +"0" + new Date(item?.overallStartDate)?.getDate()
              //   : new Date(item?.overallStartDate)?.getDate()
              // }

              end:
                `${moment(item?.overallEndDate)
                  .add(1, "days")
                  .format("YYYY-MM-DD")}`.toString() || "",
              // ${new Date(item?.overallEndDate)?.getDate().toString()
              //   .length === 1
              //   ? +"0" +
              //   new Date(item?.overallEndDate)?.getDate() +
              //   (dateDifferenceInDays(
              //     item?.overallStartDate,
              //     item?.overallEndDate
              //   ) > 0
              //     ? +1
              //     : +0)
              //   : new Date(item?.overallEndDate)?.getDate() +
              //   (dateDifferenceInDays(
              //     item?.overallStartDate,
              //     item?.overallEndDate
              //   ) > 0
              //     ? +1
              //     : +0)
              // }`.toString() || "",

              extendedProps: {
                description: item?.programDescription ?? "",
                endDate: item?.overallEndDate ?? "",
                startDate: item?.overallStartDate ?? "",
                region: item?.regionName ?? "",
                programType: item?.programType ?? "",
                duration: item?.duration ?? "",
                localProgramName: item?.localProgramName ?? "",
                agency: item?.agency ?? "",
                agencyList: item?.agencyList ?? "",
                sponsors: item?.sponsors ?? "",
                sponsorsList: item?.sponsorsList ?? "",
                country: item?.countryName ?? "",
                state: item?.stateName ?? "",
                category: item?.programCategory ?? " ",
                programID: item?.programID,
                isEditable: item?.isEditable,
                userName: item?.userName,
                city: item?.city,
                programReviewerName: item.programReviewerName,

                programReviewerID: item.programReviewerID,
              },
              color:
                colorCode[item?.programCategoryID]?.color ||
                colorCode["primary"].color,
              classNames: `font-bold ${
                isNewProgram === true ? "isNewProgram" : ""
              }`,
              textColor: "#ffff",
            };
          });
          let dataForUpComingPrograms = [];
          let dataForNeedsWorkPrograms = [];
          res?.forEach((item) => {
            if (item?.programsFor === "UpcomingPrograms") {
              dataForUpComingPrograms.push({
                programID: {
                  value: item.programID,
                },
                localProgramName: {
                  value: `${item.localProgramName} ^ ${item.overallStartDate} ^ ${item.overallEndDate}`,
                },

                isDeleted: {
                  value: item.IsDeleted,
                },
                programStatus: {
                  value: item.programStatus,
                },
                tagList: {
                  value: item.programCategory,
                },
                programCategoryID: {
                  value: item.programCategoryID,
                },
                programDescription: {
                  value: item.programDescription,
                },
                region: {
                  value: item.regionName,
                },
                isSelected: {
                  value: false,
                },
                startDate: {
                  value: item.overallStartDate,
                },
                endDate: {
                  value: item.overallEndDate,
                },
                programType: {
                  value: item.programType,
                },
                programTypeID: {
                  value: item.programTypeID,
                },
                totalCount: {
                  value: item.totalCount,
                },
                programStage: {
                  value: item.programStage,
                },
                isEditable: {
                  value: false,
                },
                isComment: {
                  value: item.isComment,
                },
                userName: {
                  value: item.userName,
                },
                programReviewerName: {
                  value: item.programReviewerName,
                },
                programReviewerID: {
                  value: item.programReviewerID,
                },
              });
            } else if (item?.programsFor === "NeedsWorkPrograms") {
              dataForNeedsWorkPrograms.push({
                programID: {
                  value: item.programID,
                },
                localProgramName: {
                  value: `${item.localProgramName} ^ ${item.overallStartDate} ^ ${item.overallEndDate}`,
                },

                isDeleted: {
                  value: item.IsDeleted,
                },
                programStatus: {
                  value: item.programStatus,
                },
                tagList: {
                  value: item.programCategory,
                },
                programCategoryID: {
                  value: item.programCategoryID,
                },
                programDescription: {
                  value: item.programDescription,
                },
                region: {
                  value: item.regionName,
                },
                isSelected: {
                  value: false,
                },
                startDate: {
                  value: item.overallStartDate,
                },
                endDate: {
                  value: item.overallEndDate,
                },
                programType: {
                  value: item.programType,
                },
                programTypeID: {
                  value: item.programTypeID,
                },
                totalCount: {
                  value: item.totalCount,
                },
                programStage: {
                  value: item.programStage,
                },
                isEditable: {
                  value: false,
                },
                isComment: {
                  value: item.isComment,
                },
                userName: {
                  value: item.userName,
                },
                programReviewerName: {
                  value: item.programReviewerName,
                },
                programReviewerID: {
                  value: item.programReviewerID,
                },
              });
            }
          });

          setProgramList(data);
          setOverAllProgramList(res);
          setUpcomingPrograms(dataForUpComingPrograms);
          setNeedsWorkPrograms(dataForNeedsWorkPrograms);
          setProgramListToDownload(res);
        } else {
          setProgramList([]);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // const dateDifferenceInDays = (startDate, endDate) => {
  //   const date1 = new Date(startDate);
  //   const date2 = new Date(endDate);
  //   const diffTime = Math.abs(date2 - date1);
  //   return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  // };

  const handleChangeProgramCategory = (value) => {
    setProgramCategoryFilters(value);
    getProgramTypes(value?.value);
  };

  const handleChangeRegion = (value) => {
    setRegionFilters(value);
  };

  const handleChangeProgramTypes = (value) => {
    setProgramTypesFilters(value);
  };

  const getProgramCategory = () => {
    if (userCategories === undefined || userCategories?.length === 0) {
      setLoading(true);
      const payload = {
        RoleId: userDetails.roleIDList,
        UserID: 0,
      };
      APIHelper.post(APIURLConstant.GET_PROGRAMCATEGORY_BY_USER_ROLE, payload)
        .then((res) => {
          if (res) {
            const programCategory = res.map((item) => {
              return {
                value: item.programCategoryID,
                label: item.category,
              };
            });
            if (programCategory && programCategory.length > 0) {
              setProgramCategory([...programCategory]);
              const category = [...programCategory];
              dispatchCategories("ADD_CATEGORY", category);
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      const categoriesUpdated = userCategories ?? [];
      setProgramCategory(categoriesUpdated);
    }
  };

  const getProgramRegions = () => {
    if (userRegions === undefined || userRegions?.length === 0) {
      setLoading(true);
      APIHelper.post(APIURLConstant.GET_PROGRAMREGIONS, {
        RegionIDs: userDetails?.regionIDs ? userDetails?.regionIDs : "null",
      })
        .then((res) => {
          // const regionIDs = userDetails?.regionIDs?.split(',');
          // let newRes = [];
          // const escapeRegExpMatch = function (s) {
          //   return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
          // };

          // const isExactMatch = (str, match) => {
          //   return new RegExp(`\\b${escapeRegExpMatch(match)}\\b`).test(str)
          // }

          // if (userDetails?.regionIDs) {
          //   for (let i = 0; i < regionIDs.length; i++) {
          //     for (let j = 0; i < res.length; j++) {
          //       newRes.push(isExactMatch(res[j].regionID, regionIDs[i]));
          //     }
          //   }
          // }
          // else {
          //   newRes = res
          // }
          // const newRes = userDetails.regionIDs ? res.filter((item) => userDetails.regionIDs.includes(item.regionID)) : res
          if (res) {
            const programRegions = res.map((item) => {
              return {
                value: item.regionID,
                label: item.regionName,
              };
            });
            if (programRegions && programRegions.length > 0) {
              setRegions([...programRegions]);
              const region = [...programRegions];
              dispatchRegions("ADD_REGIONS", region);
              setParticipantRegion(programRegions[0]);
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      const regionsUpdated = userRegions ?? [];
      setRegions([...regionsUpdated]);
      setParticipantRegion(regionsUpdated[0]);
    }
  };

  const getProgramTypes = (categoryIds) => {
    setLoading(true);
    APIHelper.post(APIURLConstant.GET_PROGRAMTYPE, {
      ProgramCategoryID: categoryIds,
    })
      .then((res) => {
        if (res) {
          const programTypes = res.map((item) => {
            return {
              value: item.programTypeID,
              label: item.type,
            };
          });
          if (programTypes && programTypes.length > 0) {
            setProgramTypes([...programTypes]);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleDateClick = (arg) => {
    // bind with an arrow function
    console.log(arg.event);
    //alert(arg.dateStr);
    setEventDetails(arg.event);
    setShowDetails(true);
  };

  const handleSearch = () => {
    let region = regionFilters
      .map((item) => {
        return item.value;
      })
      .join(",");
    let types = programTypesFilters
      .map((item) => {
        return item.value;
      })
      .join(",");
    getProgramList(
      programCategoryFilters !== "" ? programCategoryFilters.value : null,
      types?.length > 0 ? types : null,
      region?.length > 0 ? region : null
    );
    //update participant count
  };

  const resetFilter = () => {
    setProgramCategoryFilters("");
    setProgramTypesFilters([]);
    setProgramTypes([]);
    setRegionFilters([]);
    getProgramList(null, null, null);
  };

  const handleClickChart = (value) => {
    // if (value === "Operating") {
    //   navigate("/program-list", {
    //     state: {
    //       stage: "Operating",
    //       regions: regions,
    //       programCategory: programCategory,
    //     },
    //   });
    // }
    // if (value === "Completed") {
    //   navigate("/program-list", {
    //     state: {
    //       stage: "Completed",
    //       regions: regions,
    //       programCategory: programCategory,
    //     },
    //   });
    // }
    // if (value === "Planning") {
    //   navigate("/program-list", {
    //     state: {
    //       stage: "Planning",
    //       regions: regions,
    //       programCategory: programCategory,
    //     },
    //   });
    // }
  };

  const redirectToProgramPage = (programID) => {
    setShowDetails(false);

    navigate(`/program-details/${programID}`, {
      state: {
        selectedProgram: programListToDownload.filter(
          (program) => program.programID === programID
        ),
        tabTwoActive: false,
      },
    });
  };

  const RenderEventDetails = () => {
    return (
      <div class="fixed flex justify-center inset-0 z-[10000] items-center  overflow-y-auto overflow-x-hidden outline-none">
        <div class="pointer-events-none relative h-4/6 mx-auto max-w-3xl translate-y-[-50px]  transition-all duration-300 ease-in-out ">
          <div class="pointer-events-auto relative flex max-h-[100%] w-full flex-col overflow-hidden rounded-md border-none shadow-[0px_5px_10px_#333] bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
            {/*header*/}
            <div className="flex items-start justify-between px-3 py-3 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-2xl font-semibold  m_title">
                {eventDetails?._def.title}
              </h3>
              <div className="flex gap-1 mt-2">
                <h4 class="text-blue-900 text-sm ml-2 font-semibold">
                  Created By:
                </h4>
                <h4 className="text-sm font-semibold">
                  {eventDetails?._def.extendedProps.userName}
                </h4>
              </div>
              <button
                className="p-1 ml-auto outline-none focus:outline-none text-red-700"
                onClick={() => setShowDetails(false)}
                data-category="view-program"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>

            <div class="relative overflow-y-auto ">
              <div className="overflow-auto relative ">
                <div className="text-blue-900 flex flex-wrap justify-between p-4">
                  <span>
                    <strong className="text-xl">Program Details</strong>
                  </span>
                  <button
                    className="px-3 flex gap-1"
                    onClick={() => {
                      redirectToProgramPage(
                        eventDetails?._def.extendedProps.programID
                      );
                    }}
                    data-galabel="Program Details Dashboard"
                  >
                    View Details
                  </button>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4 border-b border-solid border-slate-200 p-4">
                <div>
                  <p className="text-nd font-bold">Program Category</p>
                  <p className=" text-xl text-blue-900">
                    {eventDetails?._def.extendedProps.category ?? ""}
                  </p>
                </div>
                <div>
                  <p className="text-nd font-bold">Program Type</p>
                  <p className="  text-xl text-blue-900">
                    {eventDetails?._def.extendedProps.programType ?? ""}
                  </p>
                </div>
                <div>
                  <p className="text-nd font-bold">Program Duration</p>
                  <p className="  text-xl text-blue-900">
                    {eventDetails?._def.extendedProps.duration ?? ""}
                  </p>
                </div>
                <div>
                  <p className="text-nd font-bold">Local Program Name</p>
                  <p className="  text-xl text-blue-900">
                    {eventDetails?._def.extendedProps.localProgramName ?? ""}
                  </p>
                </div>
                <div>
                  <p className="text-nd font-bold">Region</p>
                  <p className="  text-xl text-blue-900">
                    {eventDetails?._def.extendedProps.region ?? ""}
                  </p>
                </div>
                <div>
                  <p className="text-nd font-bold">City</p>
                  <p className="  text-xl text-blue-900">
                    {eventDetails?._def.extendedProps.city ?? ""}
                  </p>
                </div>
                <div>
                  <p className="text-nd font-bold">Start Date</p>
                  <p className="  text-xl text-blue-900">
                    {eventDetails?._def.extendedProps.startDate
                      ? eventDetails?._def.extendedProps.startDate.toString()
                      : ""}
                  </p>
                </div>
                <div>
                  <p className="text-nd font-bold">End Date</p>
                  <p className="  text-xl text-blue-900">
                    {eventDetails?._def.extendedProps.endDate
                      ? `${eventDetails?._def.extendedProps.endDate.toString()}`
                      : ""}
                  </p>
                </div>
                <div>
                  <p className="text-nd font-bold">Country Of Program</p>
                  <p className="  text-xl text-blue-900">
                    {eventDetails?._def.extendedProps.country ?? ""}
                  </p>
                </div>
                {eventDetails?._def.extendedProps.state && (
                  <div>
                    <p className="text-nd font-bold">State Of Program</p>
                    <p className="  text-xl text-blue-900">
                      {eventDetails?._def.extendedProps.state ?? ""}
                    </p>
                  </div>
                )}
                <div>
                  <p className="text-nd font-bold">Agency</p>
                  <p className="  text-xl text-blue-900">
                    {eventDetails?._def.extendedProps.agencyList ?? ""}
                  </p>
                </div>
                <div>
                  <p className="text-nd font-bold">Sponsors</p>
                  <p className="  text-xl text-blue-900">
                    {eventDetails?._def.extendedProps.sponsorsList ?? ""}
                  </p>
                </div>
                <div>
                  <p className="text-nd font-bold">Reviewer</p>
                  <p className="  text-xl text-blue-900">
                    {eventDetails?._def.extendedProps.programReviewerName ?? ""}
                  </p>
                </div>
                <div className="col-span-1 lg:col-span-2">
                  <p className="text-nd font-bold">Program Description</p>
                  <p className="  text-xl text-blue-900">
                    {eventDetails?._def.extendedProps.description ??
                      "Program Description"}
                  </p>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
      // </div><div className="flex justify-center inset-0 items-center overflow-x-hidden overflow-y-auto fixed z-[10000]">
      //   {" "}
      //   <div className="relative mx-auto max-w-3xl overflow-hidden h-4/6  my-5">
      //     {/*content*/}
      //
      //       <div className="overflow-auto relative ">
      //         <div className="text-blue-900 flex justify-between p-4">
      //           <span>
      //             <strong className="text-xl">Program Details</strong>
      //           </span>
      //           {eventDetails?._def.extendedProps.isEditable ? (
      //             <button
      //               className="px-3 flex gap-1"
      //               onClick={() => {
      //                 redirectToProgramPage(
      //                   eventDetails?._def.extendedProps.programID
      //                 );
      //               }}
      //             >
      //               View Details
      //             </button>
      //           ) : null}
      //         </div>

      //         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4 border-b border-solid border-slate-200">
      //           <div>
      //             <p className="text-nd font-bold">Program Category</p>
      //             <p className=" text-xl text-blue-900">
      //               {eventDetails?._def.extendedProps.category ?? ""}
      //             </p>
      //           </div>
      //           <div>
      //             <p className="text-nd font-bold">Program Type</p>
      //             <p className="  text-xl text-blue-900">
      //               {eventDetails?._def.extendedProps.programType ?? ""}
      //             </p>
      //           </div>
      //           <div>
      //             <p className="text-nd font-bold">Program Duration</p>
      //             <p className="  text-xl text-blue-900">
      //               {eventDetails?._def.extendedProps.duration ?? ""}
      //             </p>
      //           </div>
      //           <div>
      //             <p className="text-nd font-bold">Local Program Name</p>
      //             <p className="  text-xl text-blue-900">
      //               {eventDetails?._def.extendedProps.localProgramName ?? ""}
      //             </p>
      //           </div>
      //           <div>
      //             <p className="text-nd font-bold">Region</p>
      //             <p className="  text-xl text-blue-900">
      //               {eventDetails?._def.extendedProps.region ?? ""}
      //             </p>
      //           </div>
      //           <div>
      //             <p className="text-nd font-bold">Start Date</p>
      //             <p className="  text-xl text-blue-900">
      //               {eventDetails?._def.extendedProps.startDate
      //                 ? eventDetails?._def.extendedProps.startDate.toString()
      //                 : ""}
      //             </p>
      //           </div>
      //           <div>
      //             <p className="text-nd font-bold">End Date</p>
      //             <p className="  text-xl text-blue-900">
      //               {eventDetails?._def.extendedProps.endDate
      //                 ? `${eventDetails?._def.extendedProps.endDate.toString()}`
      //                 : ""}
      //             </p>
      //           </div>
      //           <div>
      //             <p className="text-nd font-bold">Country Of Program</p>
      //             <p className="  text-xl text-blue-900">
      //               {eventDetails?._def.extendedProps.country ?? ""}
      //             </p>
      //           </div>
      //           {eventDetails?._def.extendedProps.state && (
      //             <div>
      //               <p className="text-nd font-bold">State Of Program</p>
      //               <p className="  text-xl text-blue-900">
      //                 {eventDetails?._def.extendedProps.state ?? ""}
      //               </p>
      //             </div>
      //           )}
      //           <div>
      //             <p className="text-nd font-bold">Agency</p>
      //             <p className="  text-xl text-blue-900">
      //               {eventDetails?._def.extendedProps.agencyList ?? ""}
      //             </p>
      //           </div>
      //           <div>
      //             <p className="text-nd font-bold">Sponsors</p>
      //             <p className="  text-xl text-blue-900">
      //               {eventDetails?._def.extendedProps.sponsorsList ?? ""}
      //             </p>
      //           </div>
      //           <div>
      //             <p className="text-nd font-bold">Program Description</p>
      //             <p className="  text-xl text-blue-900">
      //               {eventDetails?._def.extendedProps.description ??
      //                 "Program Description"}
      //             </p>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  };

  const filterProgramCount = () => {
    getProgramCounts(programCountStartDate, programCountEndDate);
  };
  const filterParticipantCount = () => {
    getParticipantReachCount(
      participantCountStartDate,
      participantCountEndDate,
      JSON.stringify(particiapantRegion?.value),
      programType?.value
    );
  };
  const resetParticipantCount = () => {
    const start = moment().startOf("week").toDate();
    const end = moment().endOf("week").toDate();
    setParticipantCountStartDate(start);
    setParticipantCountEndtDate(end);
    setParticipantRegion(regions[0]);
    setProgramType(programTypeList[0]);
    getParticipantReachCount(
      start,
      end,
      JSON.stringify(regions[0]?.value),
      "Jr. NBA League"
    );
  };

  const resetProgramCount = () => {
    const start = moment().startOf("week").toDate();
    const end = moment().endOf("week").toDate();
    setProgramCountStartDate(start);
    setProgramCountEndtDate(end);
    getProgramCounts(start, end);
  };

  return (
    <div className="p-2 mt-16">
      {/* {showCreateProgram === true ? (
        <div className="flex w-full justify-end mb-2">
          <button
            className="bg-[#1D428A] p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white text-sm"
            onClick={() => navigate("/create-program")}
          >
            Create a Program
          </button>
        </div>
      ) : null} */}
      <Loader showLoading={loading} />
      <div className="w-full flex-none lg:flex gap-2">
        <div className="w-full h-full lg:w-5/12">
          <div className="shadow-lg h-full h-[40rem] rounded-lg overflow-hidden mb-3">
            <p className="py-3 px-5 bg-gray-200 text-[#323232]  font-semibold">
              Upcoming Programs
            </p>
            <div className="overflow-y-auto h-full pb-[3rem]">
              <ProgramListForCalendar
                allData={overAllProgramList}
                data={upcomingProgram}
                userDetails={userDetails}
                getProgramList={() => getProgramList(null, null, null)}
                setLoading={setLoading}
              />
            </div>
          </div>
          <div className="shadow-lg h-full h-[40rem] rounded-lg overflow-hidden ">
            <p className="py-3 px-5 bg-gray-200 text-[#323232]  font-semibold">
              Needs Work
            </p>
            <div className="overflow-y-auto h-full pb-[3rem]">
              <ProgramListForCalendar
                allData={overAllProgramList}
                data={needWorkProgram}
                userDetails={userDetails}
                getProgramList={() => getProgramList(null, null, null)}
                setLoading={setLoading}
              />
            </div>
          </div>
        </div>
        <div className="w-full lg:w-7/12">
          <div className="w-full flex-none lg:flex gap-2  mb-4">
            {/* <PieChart /> */}
            {/* <div className="shadow-lg w-full h-full lg:h-3/6 rounded-lg overflow-hidden">
            <div className="py-3 px-5 bg-gray-200 flex-none md:flex justify-between align-center">
              <p className=" text-[#323232]  font-semibold">Program Stages</p>
              <span className="text-[12px] font-normal mt-1">
                {" "}
                {startDate && moment(startDate).format("MM/DD/YYYY")} -{" "}
                {endDate &&
                  moment(endDate).subtract(1, "days").format("MM/DD/YYYY")}
              </span>
            </div>
            <div className="flex justify-center items-center h-full">
              <Chart
                type="pie"
                data={programChartData}
                colors={["#808080", "#FFA500", "#006400"]}
                handleClickChart={handleClickChart}
              />
            </div>
          </div> */}
            <div className="shadow-lg w-full h-full lg:h-3/6 rounded-lg overflow-hidden">
              <p className="py-3 px-5 bg-gray-200 text-[#323232]  font-semibold">
                Program Count
              </p>
              <div className="p-2">
                <div className="h-full flex gap-2 flex-wrap">
                  <DatePicker
                    showIcon
                    className="h-9 w-[10rem] pl-1 border focus:outline-none border border-grey-300 focus:shadow-outline border-grey-300 text-gray-900 rounded"
                    placeholderText="Start Date"
                    dateFormat="MM/dd/yyyy"
                    selected={programCountStartDate}
                    onChange={(date) => {
                      setProgramCountStartDate(date);
                    }}
                    icon="fa fa-calendar"
                  />
                  <DatePicker
                    showIcon
                    className="h-9 w-[10rem] pl-1 border focus:outline-none border border-grey-300 focus:shadow-outline border-grey-300 text-gray-900 rounded"
                    placeholderText="End Date"
                    selected={programCountEndDate}
                    dateFormat="MM/dd/yyyy"
                    icon="fa fa-calendar"
                    onChange={(date) => {
                      setProgramCountEndtDate(date);
                    }}
                  />

                  <button className="" onClick={() => filterProgramCount()}>
                    <img src={FilterIcon} className="h-5 w-5" />
                  </button>
                  <button className="" onClick={() => resetProgramCount()}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-5 text-blue-900 h-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.755 10.059a7.5 7.5 0 0 1 12.548-3.364l1.903 1.903h-3.183a.75.75 0 1 0 0 1.5h4.992a.75.75 0 0 0 .75-.75V4.356a.75.75 0 0 0-1.5 0v3.18l-1.9-1.9A9 9 0 0 0 3.306 9.67a.75.75 0 1 0 1.45.388Zm15.408 3.352a.75.75 0 0 0-.919.53 7.5 7.5 0 0 1-12.548 3.364l-1.902-1.903h3.183a.75.75 0 0 0 0-1.5H2.984a.75.75 0 0 0-.75.75v4.992a.75.75 0 0 0 1.5 0v-3.18l1.9 1.9a9 9 0 0 0 15.059-4.035.75.75 0 0 0-.53-.918Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className=" h-full ">
                {/* <Chart type="ProgramStageBar" data={programChartData} /> */}
                <ProgramCountBar data={programChartData} />
              </div>
            </div>
            <div className="shadow-lg w-full h-full lg:h-3/6 rounded-lg overflow-hidden ">
              <p className="py-3 px-5 bg-gray-200 text-[#323232]  font-semibold">
                Count
              </p>
              <div className="p-2">
                <div className="h-full grid grid-cols-1 md:grid-cols-2 gap-2">
                  <div className="w-full date-picker">
                    <DatePicker
                      className="h-9  w-full  pl-1 border focus:outline-none border border-grey-300 focus:shadow-outline border-grey-300 text-gray-900 rounded"
                      placeholderText="Start Date"
                      dateFormat="MM/dd/yyyy"
                      selected={participantCountStartDate}
                      onChange={(date) => {
                        setParticipantCountStartDate(date);
                      }}
                    />
                  </div>
                  <div className="w-full date-picker">
                    <DatePicker
                      className="h-9 w-full  pl-1 border focus:outline-none border border-grey-300 focus:shadow-outline border-grey-300 text-gray-900 rounded"
                      placeholderText="End Date"
                      selected={participantCountEndDate}
                      dateFormat="MM/dd/yyyy"
                      showIcon={true}
                      icon="fa fa-calendar"
                      onChange={(date) => {
                        setParticipantCountEndtDate(date);
                      }}
                    />
                  </div>
                  <div>
                    <Select
                      className="h-9 w-full   focus:outline-none focus:shadow-outline text-gray-900 rounded"
                      value={programType}
                      onChange={(value) => {
                        setProgramType(value);
                      }}
                      placeholder="Program Type"
                      options={programTypeList}
                    />
                  </div>
                  <div>
                    <Select
                      className="h-9 w-full   focus:outline-none focus:shadow-outline text-gray-900 rounded"
                      value={particiapantRegion}
                      onChange={(value) => setParticipantRegion(value)}
                      placeholder=" Region"
                      options={regions}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap justify-between">
                  <div className="inline-flex items-start lg:items-center">
                    <span
                      className={`${
                        isActual ? "text-[#00b050]" : "text-[#4472c4]"
                      } text-sm pb-4 lg:p-0 md:p-0 lg:text-end text-center mx-2 font-bold`}
                    >
                      {isActual ? "Actual" : "Reach"}
                    </span>
                    <label className="inline-flex relative items-center  w-11">
                      <input
                        type="checkbox"
                        checked={isActual}
                        value={isActual}
                        className="sr-only peer cursor-pointer"
                        onChange={(e) => setIsActual(e.target.checked)}
                      />
                      <div className="w-11 h-6 bg-[#4472c4] rounded-full dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#00b050]"></div>
                    </label>
                  </div>{" "}
                  <div className="flex gap-2 flex-wrap justify-end my-2">
                    <button
                      className=""
                      onClick={() => filterParticipantCount()}
                    >
                      <img src={FilterIcon} className="h-5 w-5" />
                    </button>
                    <button
                      className=""
                      onClick={() => resetParticipantCount()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 text-blue-900 h-5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.755 10.059a7.5 7.5 0 0 1 12.548-3.364l1.903 1.903h-3.183a.75.75 0 1 0 0 1.5h4.992a.75.75 0 0 0 .75-.75V4.356a.75.75 0 0 0-1.5 0v3.18l-1.9-1.9A9 9 0 0 0 3.306 9.67a.75.75 0 1 0 1.45.388Zm15.408 3.352a.75.75 0 0 0-.919.53 7.5 7.5 0 0 1-12.548 3.364l-1.902-1.903h3.183a.75.75 0 0 0 0-1.5H2.984a.75.75 0 0 0-.75.75v4.992a.75.75 0 0 0 1.5 0v-3.18l1.9 1.9a9 9 0 0 0 15.059-4.035.75.75 0 0 0-.53-.918Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex justify-center h-full overflow-auto">
                <ParticipantReachBar
                  isActual={isActual}
                  data={participantReachCount}
                />
              </div>
            </div>
          </div>
          <div className=" w-full  ">
            <div className="shadow-lg h-full rounded-lg overflow-hidden">
              <p className="py-3 px-5 bg-gray-200 text-[#323232]  font-semibold">
                Calendar
              </p>
              <div className="w-full flex-none md:flex gap-2 py-4 px-2">
                <div className="w-full md:w-3/12">
                  <Select
                    value={programCategoryFilters}
                    options={programCategory}
                    className="my-1 w-full focus:outline-none dropdown-selct-overlap focus:shadow-outline  text-gray-900 rounded"
                    placeholder="Program Category"
                    onChange={(e) => handleChangeProgramCategory(e)}
                  />
                </div>
                <div className="w-full md:w-3/12">
                  <Multiselect
                    displayValue="label"
                    showCheckbox={true}
                    onSelect={handleChangeProgramTypes}
                    options={programTypes}
                    placeholder={"Program Type"}
                    selectedValues={programTypesFilters}
                    className="multi-select-class my-1"
                    onRemove={(event) => {
                      setProgramTypesFilters(event);
                    }}
                  />
                </div>
                {userDetails.regionID === 0 && (
                  <div className="w-full md:w-3/12">
                    <Multiselect
                      displayValue="label"
                      showCheckbox={true}
                      onSelect={handleChangeRegion}
                      options={regions}
                      selectedValues={regionFilters}
                      placeholder={"Region"}
                      className="my-1"
                      onRemove={(event) => {
                        setRegionFilters(event);
                      }}
                    />
                  </div>
                )}
                <div className="flex md:flex-none w-full md:w-3/12 gap-2 my-1">
                  <div className="flex justify-center">
                    <div className="relative" data-te-dropdown-ref>
                      <button
                        className="flex items-center mt-0 md:mt-2 transition duration-150 ease-in-out focus:outline-none focus:ring-0 motion-reduce:transition-none"
                        type="button"
                        id="dropdownMenuButton1"
                        data-te-dropdown-toggle-ref
                        aria-expanded="false"
                        data-te-ripple-init
                        data-te-ripple-color="light"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6 because "
                        >
                          <path
                            fillRule="evenodd"
                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                      <ul
                        className="absolute z-[1000] float-left m-0 p-2 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
                        aria-labelledby="dropdownMenuButton1"
                        data-te-dropdown-menu-ref
                      >
                        {filterOptions.map((item, index) => {
                          return (
                            <li
                              key={item.color + index}
                              className={`block w-full text-white whitespace-nowrap ${item.color} py-1 my-1 px-4 md:px-8 font-bold text-sm`}
                            >
                              {item.name}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>

                  <button
                    disabled={
                      !(
                        programCategoryFilters !== "" ||
                        programTypesFilters.length > 0 ||
                        regionFilters.length > 0
                      )
                    }
                    className={
                      programCategoryFilters !== "" ||
                      programTypesFilters.length > 0 ||
                      regionFilters.length > 0
                        ? "bg-blue-900 p-1 w-14 h-10 rounded-md text-white"
                        : "bg-gray-400 p-1 w-14 h-10 rounded-md text-white"
                    }
                    onClick={() => {
                      handleSearch();
                    }}
                  >
                    Filter
                  </button>
                  <button
                    disabled={!(programListToDownload.length > 0)}
                    className={
                      programListToDownload.length > 0
                        ? "bg-blue-900 p-1  h-10 rounded-md text-white"
                        : "bg-gray-400 p-1 h-10 rounded-md text-white"
                    }
                  >
                    {programListToDownload.length > 0 ? (
                      <ExportExcel data={programListToDownload} />
                    ) : (
                      <img
                        src={DownloadExcel}
                        className="h-8 w-8"
                        alt="downloadExcel"
                      />
                    )}
                  </button>

                  <button
                    className={
                      programCategoryFilters !== "" ||
                      programCategoryFilters.length > 0 ||
                      programTypesFilters.length > 0 ||
                      regionFilters.length > 0
                        ? "bg-blue-900 p-1  h-10 rounded-md text-white"
                        : "bg-gray-400 p-1 h-10 rounded-md text-white"
                    }
                    onClick={() => {
                      if (
                        programCategoryFilters !== "" ||
                        programCategoryFilters.length > 0 ||
                        programTypesFilters.length > 0 ||
                        regionFilters.length > 0
                      ) {
                        resetFilter();
                      }
                    }}
                  >
                    Reset
                  </button>
                </div>
              </div>
              <div className="w-full flex flex-wrap px-2 py-2">
                <div className="pr-2 border-r-2">
                  <span className="font-normal text-sm text-blue-900 italic">
                    *T : Tentative
                  </span>
                </div>
                <div className="pl-2">
                  <span className="font-normal text-sm text-blue-900 italic">
                    *C : Confirmed
                  </span>
                </div>
              </div>
              <div
                className={`px-2 h-[40rem] lg:h-full overflow-y-auto pb-4 ${
                  showDetails ? "pointer-events-none" : ""
                }`}
              >
                <FullCalendar
                  plugins={[dayGridPlugin]}
                  initialView="dayGridWeek"
                  dayMaxEvents={true}
                  events={programList}
                  //   eventColor={"#01579B"}

                  eventClick={handleDateClick}
                  datesSet={(arg) => {
                    setStartDate(moment(arg.start).format("MM/DD/YYYY"));
                    setEndDate(moment(arg.end).format("MM/DD/YYYY"));
                  }}
                  buttonText={{
                    today: "Today",
                    month: "Month",
                    week: "Week",
                    day: "Day",
                  }}
                  headerToolbar={{
                    left: "today prev,next",
                    center: "title",
                    right: "dayGridMonth,dayGridWeek,dayGridDay", // user can switch between the two
                  }}
                  // height="100%"
                  viewClassNames="view-class-name"
                  dayCellClassNames="day-cell-view"
                  views={{
                    Months: {
                      type: "dayGrid",
                      duration: { Weeks: 4 },
                    },
                  }}
                  fixedWeekCount={false}
                />
              </div>
            </div>
            {showDetails && <RenderEventDetails />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calender;
