import React, { useEffect, useState, useContext } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import APIHelper from "../../../utils/config/apiHelper";
import APIURLConstant from "../../../utils/config/apiURLConstant";
import { AppContext } from "../../../store/context";
import LoadSurveyForm from "./LoadSurveyForm";
import Loader from "../../../sharedComponent/Loader/Loader";

const ProgramQuestions = (props) => {
  const [model, setModel] = useState({});
  const [answerJSON, setAnswerJSON] = useState(null);
  const [canStoreResult, setCanStoreResult] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const { userDetails } = useContext(AppContext);
  //const survey = new Model(props.modalJson);
  //survey.showNavigationButtons = false;
  //survey.goNextPageAutomatic = true;
  //survey.showCompletedPage = false;
  // const surveyDataElm =
  //   {
  //     "test qus": "Test5566",
  //     "test question test4": "Test78988",
  //     "Jr NBA Clinic question": "yes",
  //     "QA-Ques": "QA test1"
  //  };

  // survey.data = surveyDataElm;

  // survey.onComplete.add((sender, options) => {
  //   if (canStoreResult) {
  //     //sender.clear(false, true);
  //     console.log(JSON.stringify(sender.data, null, 3));
  //     storeResult(JSON.stringify(sender.data, null, 3));
  //     sender.clear(false, true);
  //   }
  //   survey.data = sender.data;
  //   //setCanStoreResult(true);
  // });

  //store answers JSON
  function storeResult(answersJSON, isAllQuestionsAnswered) {
    let requestObj = {
      answers: [],
    };
    setLoading(true);
    let config = {
      answerJSID:
        answerJSON !== null && answerJSON.length > 0
          ? answerJSON[0].answerJSID
          : 0,
      programID: props.programId,
      componentID: props.componentId,
      answerJSON: answersJSON,
      isDeleted: false,
      isActive: true,
      createdBy: userDetails.userID,
      createdDate: null,
      modifiedBy: userDetails.userID,
      modifiedDate: null,
      sequence: props.sequence,
    };
    requestObj.answers.push({ ...config });
    if (requestObj.answers && requestObj.answers.length > 0) {
      //call API here...
      APIHelper.post(
        APIURLConstant.CREATE_ANSWERS_JSON_FOR_PROGRAM_CATEGORY_TYPE,
        requestObj
      )
        .then((data) => {
          props.setToasterMessage({
            showToaster: true,
            errorType: "success",
            message: `Answers Stored Successfully With Program Details`,
          });
          setLoading(false);
        })
        .catch((err) => {
          props.setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: `Failed to Store Answers`,
          });
          console.log(err);
          setLoading(false);
        });
    }
  }

  //Load answers in Survey
  useEffect(() => {
    if (
      props.programId &&
      props.modalJson !== null &&
      Object.keys(props.modalJson).length !== 0
    ) {
      if (answerJSON === null) {
        loadAnswers();
      }
    }
  }, [props]);

  useEffect(() => {
    if (userDetails?.roleNameList) {
      const roleName = userDetails?.roleNameList;
      if (roleName) {
        setIsAdmin(
          roleName === "Youth Safety Admin" ||
            roleName === "Intl Bbops User" ||
            roleName === "Regional Program User" ||
            roleName === "US Progam User" ||
            roleName === "Jr. NBA International Admin" ||
            roleName === "Intl Bbops Admin"
        );
      }
    }
  }, [userDetails]);

  //Load answers if any
  useEffect(() => {
    if (answerJSON !== null && Object.keys(answerJSON).length !== 0) {
      const getAnswers = JSON.parse(answerJSON[0].answerJSON);
      if (getAnswers !== null) {
        // for (let x in getAnswers) {
        //   survey.setValue(x, getAnswers[x]);
        // }
        // survey.data = getAnswers;
        setModel(getAnswers);
        //setCanStoreResult(false);
      }
    }
  }, [answerJSON]);

  function loadAnswers() {
    try {
      if (props.componentId === null) {
        setLoading(true);
        APIHelper.post(APIURLConstant.GET_PROGRAM_ANSWER_JSON, {
          ProgramId: props.programId,
        })
          .then((res) => {
            setAnswerJSON(res);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      } else {
        setLoading(true);
        APIHelper.post(APIURLConstant.GET_COMPONENT_ANSWER_JSON, {
          ProgramId: props.programId,
          ComponentId: props.componentId,
          Sequence: props.sequence,
        })
          .then((res) => {
            setAnswerJSON(res);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <Loader showLoading={loading} />
      {/* <Survey model={survey}/> */}

      {model ? (
        <LoadSurveyForm
          surveyDataElements={model}
          modalJson={props.modalJson}
          storeResult={storeResult}
          isAdmin={isAdmin}
          isModuleData={false}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ProgramQuestions;
