import React, { useState, useContext, useEffect } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { Modal, Table } from "../../sharedComponent";
import ButtonContainer from "../../sharedComponent/ButtonContainer/ButtonContainer";
import Loader from "../../sharedComponent/Loader/Loader";
import Toaster from "../../sharedComponent/Toaster/Toaster";
import { AppContext } from "../../store/context";
import APIHelper from "../../utils/config/apiHelper";
import APIURLConstant from "../../utils/config/apiURLConstant";
import { useLocation } from "react-router-dom";

const CreateResource = () => {
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [fileSrc, setFileSrc] = useState([]);
  const [tempFiles, setTempFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userDetails } = useContext(AppContext);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [category, setCategory] = useState(null);
  const [subCategoryL1, setSubCategoryL1] = useState(null);
  const [subCategoryL2, setSubCategoryL2] = useState(null);
  const [categoryList, setCategoryList] = useState(null);
  const [subCategoryL1List, setSubCategoryL1List] = useState(null);
  const [subCategoryL2List, setSubCategoryL2List] = useState(null);
  const [title, setTitle] = useState("");
  const [documentId, setDocumentId] = useState(0);
  const [showFileUpload, setShowFileUpload] = useState(true);
  const [description, setDescription] = useState("");
  const fileInputRef = useRef();
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [toasterMessage, setToasterMessage] = useState({
    showToaster: false,
    errorType: "",
    message: "",
  });

  const [errorMessage, setErrorMessage] = useState({
    documentTitle: false,
    description: false,
    category: false,
    fileSrc: false,
  });

  const location = useLocation();

  useEffect(() => {
    //set details in Edit Mode
    if (location.state) {
      setSelectedDocument(location.state.selectedDocument);
    } else {
      setSelectedDocument(null);
    }
  }, [location.state]);

  useEffect(() => {
    openDocumentInEditMode();
  }, [selectedDocument]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (category !== null) {
      setSubCategoryL1(null);
      setSubCategoryL2(null);

      setSubCategoryL2List(null);
      getSubCategories();
    }
  }, [category]);
  useEffect(() => {
    if (subCategoryL1 !== null) {
      setSubCategoryL2(null);

      getSubCategoriesBySubsectionId();
    } else {
      setSubCategoryL2(null);
      setSubCategoryL2List(null);
    }
  }, [subCategoryL1]);

  useEffect(() => {
    if (userDetails.email !== "") {
      getCategoryList();
    }
  }, [userDetails]);

  function FileListItems(files) {
    var b = new ClipboardEvent("").clipboardData || new DataTransfer();
    for (var i = 0, len = files.length; i < len; i++) b.items.add(files[i]);
    return b.files;
  }
  const getSubCategories = () => {
    setLoading(true);

    const payLoad = {
      subSectionID: null,
      sectionID: category?.sectionID,
    };
    APIHelper.post(APIURLConstant.GET_SUBCATEGORIES_BY_CATEGORY_ID, payLoad)
      .then((res) => {
        setLoading(false);
        if (res && res.length > 0) {
          let dataList = [];
          if (res?.length > 0) {
            res.forEach((item) => {
              if (item.isDeleted === false && item.parentSubSectionID === 0) {
                dataList.push({
                  label: item.subSectionName,
                  value: item.subSectionID,
                  ...item,
                });
              }
            });
          }
          if (selectedDocument !== null) {
            if (selectedDocument?.parentSubSectionID !== 0)
              dataList?.forEach((filters) => {
                if (filters.value == selectedDocument?.parentSubSectionID) {
                  setSubCategoryL1({
                    label: filters.subSectionName,
                    value: filters.subSectionID,
                    ...filters,
                  });
                }
              });
          }
          setSubCategoryL1List(dataList);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const getSubCategoriesBySubsectionId = () => {
    setLoading(true);
    const payLoad = {
      subSectionID: subCategoryL1?.value,
      sectionID: null,
    };
    APIHelper.post(APIURLConstant.GET_SUBCATEGORIES_BY_SUBSECTION_ID, payLoad)
      .then((res) => {
        setLoading(false);
        if (res && res.length > 0) {
          let dataList = [];
          if (res?.length > 0) {
            res.forEach((item) => {
              if (item.isDeleted === false && item.parentSubSectionID !== 0) {
                dataList.push({
                  label: item.subSectionName,
                  value: item.subSectionID,
                  ...item,
                });
              }
            });
          }
          if (selectedDocument?.subSectionID !== 0)
            dataList?.forEach((filters) => {
              if (filters.value == selectedDocument?.subSectionID) {
                setSubCategoryL2({
                  label: filters.subSectionName,
                  value: filters.subSectionID,
                  ...filters,
                });
              }
            });
          setSubCategoryL2List(dataList);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const openDocumentInEditMode = () => {
    if (selectedDocument !== null) {
      setTitle(selectedDocument.documentTitle);
      setDescription(selectedDocument.description);
      setCategory({
        sectionName: selectedDocument.sectionName,
        label: selectedDocument.sectionName,
        value: selectedDocument.sectionName,
        sectionID: selectedDocument.sectionID,
      });
      setUploadedFileName(selectedDocument.documentName);
      setShowFileUpload(false);
      setDocumentId(selectedDocument.documentID);
      const input = document.getElementById("uploadFile");
      var files = [new File(["content"], selectedDocument.documentName)];
      input.files = new FileListItems(files);
    }
  };

  const resetState = () => {
    setTitle("");
    setSelectedDocument(null);
    setDescription("");
    setCategory(null);
    setFileSrc([]);
    setFiles([]);
    setErrorMessage({
      documentTitle: false,
      description: false,
      role: false,
      category: false,

      fileSrc: false,
    });
    if (fileInputRef && fileInputRef.current !== null) {
      fileInputRef.current.value = null;
    }
  };

  const getCategoryList = () => {
    setLoading(true);
    APIHelper.get(
      APIURLConstant.GET_RESOURCE_CATEGORY_BY_USER_ROLE_ID(
        parseInt(userDetails?.roleIDList)
      )
    )
      .then((res) => {
        const updatedTags = res.map((item) => {
          return {
            label: item.sectionName,
            value: item.sectionName,
            ...item,
          };
        });
        if (updatedTags && updatedTags.length > 0) {
          setCategoryList([...updatedTags]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleChangeTitle = (value) => {
    if (value.length > 0) {
      setTitle(value);
      errorMessage.documentTitle = false;
    } else {
      setTitle("");
      errorMessage.documentTitle = true;
    }
  };
  const handleChangeDescription = (value) => {
    if (value.length > 0) {
      setDescription(value);
      errorMessage.description = false;
    } else {
      setDescription("");
      errorMessage.description = true;
    }
  };

  const handleChangeCategory = (value) => {
    setCategory(value);

    errorMessage.category = false;
  };
  const handleChangeSubCategory = (value) => {
    setSubCategoryL1(value);
  };
  const handleChangeSubCategoryL2 = (value) => {
    setSubCategoryL2(value);
  };

  const handleChangeFile = (event) => {
    let file = event.currentTarget.files;
    let files = [];
    let fileSrcs = [];
    errorMessage.fileSrc = false;
    if (file.length > 0) {
      if (
        file[0].type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file[0].type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file[0].type === "application/pdf" ||
        file[0].type === "application/msword" ||
        file[0].type === "application/vnd.ms-excel" ||
        file[0].type === "text/plain" ||
        file[0].type ===
          "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
        file[0].type === "application/vnd.ms-powerpoint"
      ) {
        let blob = file[0].slice(0, file[0].size, file[0].type);
        let extension = file[0].name.substring(
          file[0].name.lastIndexOf(".") + 1
        );
        let fileName = file[0].name.substring(0, file[0].name.lastIndexOf("."));
        let newFileName =
          fileName + "_" + new Date().getTime() + "." + extension;
        setUploadedFileName(newFileName);
        const newFile = new File([blob], newFileName, { type: file[0].type });
        files.push({
          fileName: newFileName,
          createdDate: new Date(),
          file: newFile,
        });
        fileSrcs.push({
          fileName: newFileName,
        });
        setFiles(files);
        setFileSrc(fileSrcs);
      } else {
        fileInputRef.current.value = null;
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message:
            "Please Upload only .doc, .docx, .pdf, .xlsx, .xls, .ppt, .pptx, .txt",
        });
      }
    } else {
      if (
        selectedDocument &&
        selectedDocument.documentName &&
        selectedDocument.documentName !== ""
      ) {
        const input = document.getElementById("uploadFile");
        var filesNew = [new File(["content"], selectedDocument.documentName)];
        input.files = new FileListItems(filesNew);
      } else {
        setFileSrc([]);
        setFiles([]);
        setShowFileUpload(true);
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: "Please select a file.",
        });
      }
    }
  };

  const validateForm = () => {
    let isFormValid = true;
    if (title === "") {
      errorMessage.documentTitle = true;
      isFormValid = false;
    }

    if (category === null) {
      errorMessage.category = true;
      isFormValid = false;
    } else {
      errorMessage.category = false;
    }
    if (showFileUpload && fileSrc.length === 0) {
      errorMessage.fileSrc = true;
      isFormValid = false;
    }
    return isFormValid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setLoading(true);
      let resourceForm = new FormData();
      resourceForm.append("DocumentID", documentId);
      resourceForm.append("DocumentName", uploadedFileName);
      resourceForm.append("DocumentTitle", title);
      resourceForm.append("SectionID", category.sectionID);
      resourceForm.append("SectionName", category.sectionName);
      resourceForm.append("MainPath", "");
      resourceForm.append("CreatedBy", userDetails.userID);
      resourceForm.append("ModifiedBy", userDetails.userID);
      resourceForm.append("IsActive", true);
      resourceForm.append("IsDeleted", false);
      resourceForm.append(
        "ParentSubSectionID",
        subCategoryL1 !== null ? subCategoryL1?.value : 0
      );
      resourceForm.append(
        "SubSectionID",
        subCategoryL2 !== null ? subCategoryL2?.value : 0
      );

      //resourceForm.append('FileSource', fileSrc);
      if (files.length > 0) {
        files.forEach((file) => {
          resourceForm.append("FileSource", file.file);
        });
      } else {
        resourceForm.append("FileSource", null);
      }

      resourceForm.append("Description", description);
      // resourceForm.append('FileSource', state.src.attach(Downgraded).get());
      APIHelper.post(APIURLConstant.CREATE_DOCUMENT, resourceForm)
        .then((data) => {
          setToasterMessage({
            showToaster: true,
            errorType: "success",
            message: `Document uploaded Successfully`,
          });
          setLoading(false);
          resetState();
          if (documentId === 0) {
            updateNotification(data, category.sectionID, userDetails.userID);
          }
          navigate("/resource-list");
        })

        .catch((err) => {
          setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: `Failed to upload Document`,
          });
          console.log(err);
          setLoading(false);
        });
    } else {
      setToasterMessage({
        showToaster: true,
        errorType: "error",
        message: "Please enter the required fields",
      });
    }
  };

  const updateNotification = (resourceID, sectionID, createdBy) => {
    setLoading(true);
    const notificationObject = {
      NotificationID: 0,
      ProgramID: null,
      CreatedBy: createdBy,
      IsDelete: false,
      IsRead: false,
      IsNotificationDelete: false,
      ToUserID: 0,
      NotificationIDs: null,
      NotificationType: "Resource Creation Notification",
      SendEmail: true,
      CommentText: null,
      ResourceID: resourceID,
      SectionID: sectionID,
    };
    APIHelper.post(APIURLConstant.ADD_UPDATE_NOTIFICATIONS, notificationObject)
      .then((data) => {
        setLoading(false);
      })

      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div className=" mt-9">
      <Loader showLoading={loading} />
      <div className="mx-auto  md:p-7">
        <p className="text-[#c8102e] font-semibold text-[19px] py-3">
          {selectedDocument === null ? "Create a Resource" : "Edit Resource"}
        </p>

        <div className="bg-white border border-gray-200 rounded-lg shadow-md w-full p-4">
          <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
            <div className="md:w-2/12 lg:2/12 w-full">
              <p className="text-sm pb-4 lg:p-0 md:p-0 lg:text-end lg:pt-2 text-center required text-[#394150] font-bold">
                Title :
              </p>
            </div>
            <div className="w-full lg:w-8/12 md:w-7/12">
              <input
                value={title}
                onChange={(e) => handleChangeTitle(e.target.value)}
                placeholder="Please enter title"
                className={`question-input resize-none border textAreaHeight py-1 w-full border focus:outline-none focus:shadow-outline
                    ${
                      errorMessage && errorMessage.documentTitle === true
                        ? "border-red-400"
                        : "border-grey-400"
                    } text-gray-900 rounded pl-2`}
              />
              {errorMessage && errorMessage.documentTitle === true && (
                <div className="text-start">
                  <span className="text-red-500 text-sm">
                    Please enter the title{" "}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
            <div className="md:w-2/12 lg:2/12 w-full">
              <p className="text-sm pb-4 lg:p-0 md:p-0 lg:text-end lg:pt-2 text-center text-[#394150] font-bold">
                Description :
              </p>
            </div>
            <div className="w-full lg:w-8/12 md:w-7/12">
              <textarea
                value={description}
                onChange={(e) => handleChangeDescription(e.target.value)}
                placeholder="Please enter title"
                className={`border textAreaHeight py-1 h-[5rem] w-full border focus:outline-none focus:shadow-outline border-grey-400
                     text-gray-900 rounded pl-2`}
              />
            </div>
          </div>
          <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
            <div className="md:w-2/12 lg:2/12 w-full">
              <p className="text-sm pb-4 lg:p-0 md:p-0 lg:text-end lg:pt-2 required text-center text-[#394150] font-bold">
                Category :
              </p>
            </div>
            <div className="w-full lg:w-10/12 md:w-10/12 ">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 w-full">
                <div className="w-full">
                  {" "}
                  <Select
                    value={category}
                    options={categoryList}
                    className={`w-full focus:outline-none ${
                      errorMessage.category === true
                        ? "border border-red-400"
                        : ""
                    } focus:shadow-outline text-gray-900 rounded`}
                    placeholder="Select Category"
                    onChange={(e) => handleChangeCategory(e)}
                  />
                  {errorMessage.category === true && (
                    <div className="text-start">
                      <span className="text-red-500 text-sm">
                        Please select the category{" "}
                      </span>
                    </div>
                  )}
                </div>
                {subCategoryL1List !== null && subCategoryL1List.length > 0 && (
                  <Select
                    value={subCategoryL1}
                    options={subCategoryL1List}
                    className={`w-full focus:outline-none focus:shadow-outline text-gray-900 rounded`}
                    placeholder="Select Sub Category"
                    onChange={(e) => handleChangeSubCategory(e)}
                    isClearable
                  />
                )}
                {subCategoryL2List !== null && subCategoryL2List.length > 0 && (
                  <Select
                    value={subCategoryL2}
                    options={subCategoryL2List}
                    className={`w-full focus:outline-none focus:shadow-outline text-gray-900 rounded`}
                    placeholder="Select Child Sub Category"
                    onChange={(e) => handleChangeSubCategoryL2(e)}
                    isClearable
                  />
                )}
              </div>
            </div>
          </div>

          <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
            <div className="md:w-2/12 lg:2/12 w-full">
              <p className="text-sm pb-4 lg:p-0 md:p-0 lg:text-end lg:pt-2 text-center required text-[#394150] font-bold">
                Uploade file :
              </p>
            </div>
            <div className="w-full lg:w-4/12 md:w-7/12">
              <input
                type="file"
                id="uploadFile"
                ref={fileInputRef}
                accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.presentationml.presentation,text/plain, application/vnd.ms-powerpoint"
                onChange={handleChangeFile}
                className={`relative m-0 block ${
                  errorMessage && errorMessage.fileSrc === true
                    ? "border-red-400"
                    : "border-grey-300"
                } w-full min-w-0 flex-auto rounded border  bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:shadow-te-primary focus:outline-none`}
              />
              {errorMessage && errorMessage.fileSrc === true && (
                <div className="text-start">
                  <span className="text-red-500 text-sm">
                    Please select a file
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
            <div className=" md:w-2/12 w-full" />
            <ButtonContainer
              handleSubmit={() => handleSubmit()}
              handleCancel={() => {
                resetState();
                navigate("/resource-list");
              }}
              sourceName="Create-Resource"
            />{" "}
          </div>
        </div>
      </div>

      {/* Toaster */}

      <Toaster
        toasterMessage={toasterMessage}
        setToasterMessage={setToasterMessage}
      />
    </div>
  );
};

export default CreateResource;
