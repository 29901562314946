import React from "react";
import LoginView from "./Login.view";
// import Validator from "validator";

const Login = (props) => {

  return (
    <LoginView
      showHeader={props.showHeader}
    />
  );
};

export default Login;
