import React, { useEffect, useState } from "react";
let viz;

const Reports = (props) => {
  const [reportURL, setReportURL] = useState("");
  useEffect(() => {
    if (props && props?.url !== "") {
      showReport();
    }
  }, [props]);

  const showReport = () => {
    var dvReport = document.getElementById("rptContainer");
    var options = {
      width: "100%",
      height: "72vh",
    };
    var url = props.url;
    if (viz) {
      viz.dispose();
    }
    viz = new window.tableau.Viz(dvReport, url, options);
  };

  return <div id="rptContainer"></div>;
};
export default Reports;
