export default class Validations {
  //...Required Field validation
  static Required(value) {
    //..Returns true when the value is empty.
    let strRegex = new RegExp(/^(?=.*[a-zA-Z0-9])/);
    let result = strRegex.test(value);
    return !result;
    // return /^\s*$/.test(value) || /null/.test(value);
  }

  static Validate(value, rules) {
    let isValid = true;
    let validatioResult = { isValid: true, validationMessage: null };

    if (!rules) return validatioResult;

    //..Required Field Validation
    if (rules.Required && isValid) {
      isValid = !Validations.Required(value);
      validatioResult.isValid = isValid;
      validatioResult.validationMessage = isValid ? null : "Value is required";
    }

    return validatioResult;
  }
}
