export default class JSONUtils {
    static getQuestionsJSON(questionObj) {
        let questionsJSON = [];
        try {
            for (let i = 0; i < questionObj.length; i++) {
                if (questionObj[i].answerType === "Start- End Dates") {
                    questionsJSON.push({
                        answerTypeID: questionObj[i].answerTypeID,
                        type: 'multipletext',//this.getType(questionObj[i].answerType),
                        details: questionObj[i].details,
                        isOptionsRequired: questionObj[i].isOptionsRequired,
                        isRequired: (questionObj[i].isMandatory) ? true : false,
                        choices: this.getOptions(questionObj[i].answerOptions),
                        name : questionObj[i].questionText,
                        //inputType: "date"
                        items: [
                            {
                             "name": "Start Date",
                             "inputType": "date"
                            },
                            {
                             "name": "End Date",
                             "inputType": "date"
                            }
                           ]
                      });
                } else {
                    questionsJSON.push({
                        answerTypeID: questionObj[i].answerTypeID,
                        type: this.getType(questionObj[i].answerType),
                        details: questionObj[i].details,
                        isOptionsRequired: questionObj[i].isOptionsRequired,
                        isRequired: (questionObj[i].isMandatory) ? true : false,
                        choices: this.getOptions(questionObj[i].answerOptions),
                        name : questionObj[i].questionText,
                        inputType: "" //(questionObj[i].answerType === "Start- End Dates")? "date" : ""
                      });
                }               
              }
        } catch (e) {
            return {};
        }
        return {
            "name": "",
            "elements": questionsJSON};
    }

    static getOptions(optionDetails) {
        try {
            return [...optionDetails.split(';')]
        } catch (e) {
            return [];
        }
    }

    static getType(type) {
        try {
            switch (type) {
                case "Dropdown List":
                  return "dropdown";
                case "Text Field Long":
                  return "comment";
                case "Text Field Short":
                    return "text";
                case "Start- End Dates":
                    return "text";
                case "Multiple Add Field":
                    return "tagbox";
                case "Radio Buttons":
                    return "radiogroup";
                case "Checkbox(es)":
                    return "checkbox";
                case "File Upload":
                    return "file";
                default:
                  break;
            }
        } catch (e) {
            return "";
        }
    }
}