import React, { useState, useEffect, useRef, useContext } from "react";
import CreateRecapReport from "./CreateRecapReport";
import APIHelper from "../../../utils/config/apiHelper";
import APIURLConstant from "../../../utils/config/apiURLConstant";
import EditIcon from "../../../assets/edit.svg"; //"../../assets/edit.svg";
import { Modal } from "../../../sharedComponent";
import Loader from "../../../sharedComponent/Loader/Loader";
import moment from "moment";
import Logo from "../../../assets/ypp-logo.svg";
import CalendarPdf from "../../../assets/calendar_pdf.svg";
import LocationPdf from "../../../assets/location_pdf.svg";
import StudentPdf from "../../../assets/student_pdf.svg";
import TeacherPdf from "../../../assets/teacher_pdf.svg";
import LinkPdf from "../../../assets/link_pdf.svg";
import { AppContext } from "../../../store/context";
import { useReactToPrint } from "react-to-print";

const RecapReport = (props) => {
  const { userDetails } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [showCreateRecapReport, setShowCreateRecapReport] = useState(false);
  const [recapReportList, setRecapReportList] = useState([]);
  const [programDetails, setProgramDetails] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({
    title: "",
    message: "",
  });
  const componentRef = useRef();
  const [headerBgColor, setHeaderBgColor] = useState(null);
  const [footerBgColor, setFooterBgColor] = useState(null);
  const [headerTextColor, setHeaderTextColor] = useState(null);
  const [headerText, setHeaderText] = useState();
  const [ckEditorData, setCkEditorData] = useState("");
  const [programDetailsCount, setprogramDetailsCount] = useState({
    youthsCount: 0,
    teacherCount: 0,
  });
  const [socialMediaLinks, setSocialMediaLinks] = useState([]);
  const [allFileSrc, setAllFileSrc] = useState([]);
  const [allImageUrl, setAllImageUrl] = useState([]);
  const [toasterMessage, setToasterMessage] = useState({
    showToaster: false,
    errorType: "",
    message: "",
  });

  useEffect(() => {
    if (selectedItem !== null && selectedItem !== undefined) {
      setHeaderText(selectedItem.headerText ?? programDetails.localProgramName);
      setHeaderBgColor({
        hex: selectedItem?.headerColor ?? "#C8102E",
        rgb: null,
      });
      setHeaderTextColor({
        hex: selectedItem?.headerTextColor ?? "#FFFFFF",
        rgb: null,
      });
      setFooterBgColor({
        hex: selectedItem?.footerColor ?? "#C8102E",
        rgb: null,
      });
      console.log(selectedItem);
      setCkEditorData(selectedItem?.eventRecap);
    }
  }, [selectedItem]);

  // useEffect(() => {
  //   if (programDetails !== null && programDetails.programID) {
  //     getRecapReportList();
  //   }
  // }, [programDetails]);
  useEffect(() => {
    if (props && props.programDetails) {
      setProgramDetails(props.programDetails);
    }
    if (props && props.recapReportList) {
      setRecapReportList(props.recapReportList);
    }
  }, [props]);

  const handleEdit = () => {
    setShowModal(false);
    setModalMessage({
      title: "",
      message: "",
    });
    if (selectedItem !== null) {
      setShowCreateRecapReport(true);
    }
  };
  const handleDownload = () => {
    getSocialMediaImages();
    setShowModal(false);
  };
  const getRecapReportCount = () => {
    if (
      programDetailsCount.youthsCount === 0 &&
      programDetailsCount.teacherCount === 0
    ) {
      setLoading(true);
      let data = props.programDetails;
      const payLoad = {
        programID: data?.programID,
        UserEmail: userDetails.email,
      };
      APIHelper.post(APIURLConstant.GET_PARTICIPANTS_COUNT, payLoad)
        .then((res) => {
          if (res) {
            setprogramDetailsCount({
              youthsCount: res.youthsCount,
              teacherCount: res.coachesCount,
            });
          }
          //saveToDatabase()
          setLoading(false);
          handlePrint();
        })
        .catch((err) => {
          console.log(err);
          setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: "Error while getting program Youths & Teachers count",
          });
          setLoading(false);
        });
    } else {
      //saveToDatabase()
      handlePrint();
    }
  };

  const getSocialMediaImages = () => {
    setLoading(true);
    APIHelper.post(
      APIURLConstant.GET_RECAP_REPORT_SOCIALMEDIA_IMAGES_BY_REPORTID,
      { RecapReportID: selectedItem?.recapReportID }
    )
      .then((res) => {
        console.log(res);
        if (res && res.length > 0) {
          //
          const imageFileData = res.filter(
            (image) => !image.isSocialMediaResource
          );
          let imageFiles = [];
          let imageFilesDetails = [];
          imageFileData.forEach((item) => {
            imageFiles.push({
              mediaLinkID: item.mediaLinkID,
              id: imageFiles ? imageFiles.length + 1 : 1,
              name: item.title,
              imageOrder: item.imageOrder,
              src: item.mainPath ? item.mainPath : null, //item.mainPath ? item.mainPath : null ,
            });
            imageFilesDetails.push({
              FileName: item.title,
              IsSocialMediaResource: false,
              MediaOrder: 0,
              ImageOrder: item.imageOrder,
              IsDeleted: false,
            });
          });

          setAllFileSrc((prevState) => [...prevState, ...imageFiles]);

          const socialMediaLinksData = res.filter(
            (image) => image.isSocialMediaResource
          );
          let socialMediaFiles = [];
          let socialMediaLinksDetails = [];
          socialMediaLinksData.forEach((item) => {
            socialMediaFiles.push({
              mediaLinkID: item.mediaLinkID,
              id: socialMediaFiles ? socialMediaFiles.length + 1 : 1,
              title: item.mTitle,
              mediaOrder: item.MediaOrder,
              url: item.url, //item.mainPath ? item.mainPath : item.url,
              src: item.mainPath ? item.mainPath : null, //item.mainPath ? item.mainPath : null ,
            });
            socialMediaLinksDetails.push({
              FileName: item.title,
              IsSocialMediaResource: true,
              MediaOrder: item.mediaOrder,
              ImageOrder: 0,
              IsDeleted: false,
            });
          });

          setSocialMediaLinks((prevState) => [
            ...prevState,
            ...socialMediaFiles,
          ]);
        }
        setLoading(false);
        getRecapReportCount();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const handlePrint = useReactToPrint({
    onPrintError: (error) => console.log(error),
    content: () => componentRef.current,
    onAfterPrint: () => handleResetDownloadState(),
    documentTitle: "Recap_Report",
    removeAfterPrint: true,
  });
  const handleResetDownloadState = () => {
    setSelectedItem(null);
    setAllFileSrc([]);
    setSocialMediaLinks([]);
    setHeaderText();
    setHeaderBgColor(null);
    setHeaderTextColor(null);
    setFooterBgColor(null);
    setCkEditorData("");
  };

  //TODO: Need to implement another solution for this. Here we are checking whether 'Title' is having any number
  //OR not, if having number means Image is uploaded in Azure. If not having number means no image is uploaded while
  //creating this Social Media Link and if there is no image than no need to load blank image.
  function hasNumber(myString) {
    return /\d/.test(myString);
  }

  return (
    <div className="mt-2 p-2 ">
      <Loader showLoading={loading} />
      {showCreateRecapReport ? (
        <div className="flex justify-between items-center">
          <p className="text-[#c8102e] font-semibold text-[19px] py-3">
            Create Recap Report
          </p>
          <button
            className="border-[#1D428A] border p-1 px-[1rem] h-[2.2rem] rounded-[3px] text-[#1D428A] text-sm"
            onClick={() => {
              setShowCreateRecapReport(false);
              props.getRecapReportList();
            }}
          >
            Back
          </button>{" "}
        </div>
      ) : (
        <div className="flex justify-between items-center">
          <p className="text-[#c8102e] font-semibold text-[19px] py-3">
            Recap Report List
          </p>
          <button
            className="bg-[#1D428A] p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white text-sm"
            onClick={() => {
              setSelectedItem(null);
              setTimeout(() => setShowCreateRecapReport(true), 1000);
            }}
          >
            Create
          </button>
        </div>
      )}

      {showCreateRecapReport ? (
        <CreateRecapReport
          {...props}
          recapReport={selectedItem}
          setShowCreateRecapReport={setShowCreateRecapReport}
          getRecapReportList={() => props.getRecapReportList()}
        />
      ) : (recapReportList && recapReportList.length) > 0 ? (
        <div className="mb-[5rem]">
          {recapReportList.map((recapReport) => {
            return (
              <div className="w-full mt-3 overflow-x-hidden">
                <div className="border-[#ccc] border bg-white py-4 md:px-4 mb-1 gap-2 rounded w-full grid grid-cols-1 items-center md:grid-cols-2 ">
                  <div className="w-full flex gap-2 justify-center md:justify-start">
                    <span className="text-blue-900">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5H5.625zM7.5 15a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 017.5 15zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H8.25z"
                          clipRule="evenodd"
                        />
                        <path d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z" />
                      </svg>
                    </span>
                    <div>
                      <p>{recapReport.headerText}</p>
                      <p className=" text-[12px]">
                        Last updated by - {recapReport.userName}
                      </p>
                      {recapReport.modifiedDate !== undefined &&
                      recapReport.modifiedDate !== null ? (
                        <p className=" text-[12px]">
                          Last updated date -{" "}
                          {moment(recapReport.modifiedDate).format(
                            "MM/DD/YYYY"
                          )}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="w-full flex flex-wrap gap-4 justify-center md:justify-end">
                    <button
                      onClick={() => {
                        setSelectedItem(recapReport);
                        setShowModal(true);
                        setModalMessage({
                          title: "Edit",
                          message: "Do you want to Edit this Recap Report?",
                        });
                      }}
                      data-te-toggle="tooltip"
                      title="Edit"
                    >
                      <img src={EditIcon} className="h-5 w-5 mr-1" alt="edit" />
                    </button>
                    <button
                      className="text-blue-900"
                      onClick={() => {
                        setSelectedItem(recapReport);
                        setShowModal(true);
                        setModalMessage({
                          title: "Download",
                          message: "Do you want to Download this Recap Report?",
                        });
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12 2.25a.75.75 0 01.75.75v11.69l3.22-3.22a.75.75 0 111.06 1.06l-4.5 4.5a.75.75 0 01-1.06 0l-4.5-4.5a.75.75 0 111.06-1.06l3.22 3.22V3a.75.75 0 01.75-.75zm-9 13.5a.75.75 0 01.75.75v2.25a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V16.5a.75.75 0 011.5 0v2.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V16.5a.75.75 0 01.75-.75z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="border-[#ccc] flex justify-center border bg-white py-2 rounded w-full ">
          No Data
        </div>
      )}
      <Modal
        showModal={showModal}
        handleClose={() => {
          setShowModal(false);
          setSelectedItem(null);
        }}
        modalMessage={modalMessage}
        handleClick={
          modalMessage.title === "Edit" ? handleEdit : handleDownload
        } //{handleDelete}
      />
      {/* Download report */}
      <div className="hidden">
        <div ref={componentRef}>
          <table className="report-container">
            <thead className="report-header ">
              <tr>
                <th className="report-header-cell pdf-table-th">
                  <div className="header-info">
                    <div
                      className={`p-2 flex items-center gap-3 w-full`}
                      style={{
                        backgroundColor: `${
                          headerBgColor !== null ? headerBgColor.hex : "#C8102E"
                        }`,
                      }}
                    >
                      <div className="1/12">
                        <img src={Logo} className="w-12 h-12" alt="logo" />
                      </div>
                      <h1
                        className={`text-3xl m-0 mt-1 ActionNBA-Bold-Web w-11/12`}
                        style={{
                          color: `${
                            headerTextColor !== null
                              ? headerTextColor.hex
                              : "#FFFFFF"
                          }`,
                        }}
                      >
                        {headerText}
                      </h1>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tfoot className="report-footer">
              <tr>
                <td className="report-footer-cell pdf-table-td">
                  <div className="footer-info">
                    <div className={"page-footer"}>
                      {" "}
                      <div
                        style={{
                          backgroundColor: `${
                            footerBgColor !== null
                              ? footerBgColor.hex
                              : "#C8102E"
                          }`,
                        }}
                        className={`footer-pdf`}
                      ></div>
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
            <tbody className="report-content">
              <tr>
                <td className="report-content-cell">
                  <div className="main px-6">
                    <div className="w-full flex-none md:flex justify-between">
                      <div>
                        <div className="flex gap-2 ">
                          <img
                            src={CalendarPdf}
                            className="w-6 h-6"
                            alt="calendar"
                          />
                          <p className="text-lg ActionNBA-Bold-Web text-center mb-2">
                            {programDetails?.overallStartDate ===
                            programDetails?.overallEndDate ? (
                              <p>
                                {moment(
                                  programDetails?.overallStartDate
                                ).format("ddd, MMM DD, YYYY")}{" "}
                              </p>
                            ) : (
                              <p>
                                {moment(
                                  programDetails?.overallStartDate
                                ).format("ddd, MMM DD, YYYY")}{" "}
                                -{" "}
                                {moment(programDetails?.overallEndDate).format(
                                  "ddd, MMM DD, YYYY"
                                )}
                              </p>
                            )}
                          </p>
                        </div>
                        <div className="flex gap-2 ">
                          <img
                            src={LocationPdf}
                            className="w-6 h-6"
                            alt="location"
                          />
                          <p className="text-lg ActionNBA-Bold-Web text-center mb-2">
                            {programDetails?.city !== "" &&
                              programDetails?.city + ","}{" "}
                            {programDetails?.stateName !== "" &&
                              programDetails?.stateName != null &&
                              programDetails?.stateName + ","}
                            {programDetails?.countryName}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="flex gap-2">
                          <img
                            src={StudentPdf}
                            className="w-6 h-6"
                            alt="student"
                          />
                          <p className="text-lg ActionNBA-Bold-Web text-center mb-2">
                            {programDetailsCount.youthsCount} Youths
                          </p>
                        </div>
                        <div className="flex gap-2 ">
                          <img
                            src={TeacherPdf}
                            className="w-6 h-6"
                            alt="teacher"
                          />
                          <p className="text-lg ActionNBA-Bold-Web text-center mb-2">
                            {programDetailsCount.teacherCount} Coaches
                          </p>
                        </div>
                      </div>
                    </div>
                    {ckEditorData !== "" && (
                      <p className="text-[#C8102E] text-2xl ActionNBA-Bold-Web text-center my-3">
                        Event Recap
                      </p>
                    )}
                    <div dangerouslySetInnerHTML={{ __html: ckEditorData }} />

                    {socialMediaLinks && socialMediaLinks.length > 0 && (
                      <p className="text-[#C8102E] text-2xl ActionNBA-Bold-Web text-center my-3">
                        News, Content and Social Media Highlights{" "}
                      </p>
                    )}
                    <div className="my-2 ">
                      <div className="flex flex-wrap justify-around	">
                        {socialMediaLinks?.map((link) => {
                          return (
                            <div className="my-2">
                              <div className="flex gap-2 justify-center items-center mb-4">
                                <img
                                  src={LinkPdf}
                                  className="w-6 h-6"
                                  alt="link"
                                />
                                <a
                                  className="underline text-xl"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={link.url}
                                >
                                  {link.title}
                                </a>
                              </div>
                              {link?.src && link.src !== null && (
                                <img
                                  alt={link.title}
                                  src={
                                    typeof link?.src === "object"
                                      ? URL.createObjectURL(link?.src)
                                      : link?.src
                                  }
                                  className="block h-[10rem] w-full"
                                />
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div>
                      <div className="w-full mt-1 flex justify-center items-center">
                        {allFileSrc?.map((image, index) => {
                          if (index === 0) {
                            return (
                              <img
                                alt={image.name}
                                src={
                                  typeof image.src === "object"
                                    ? URL.createObjectURL(image.src)
                                    : image.src
                                }
                                className="block h-full w-full object-inherit"
                              />
                            );
                          }
                        })}
                      </div>
                    </div>
                    {/* forcing a new page creation */}
                    {/* <div className="pagebreak"></div> */}
                    <div className="">
                      <div className=" flex flex-wrap justify-between">
                        {allFileSrc?.map((images, index) => {
                          if (index !== 0) {
                            return (
                              <div className=" w-6/12 ">
                                <div className="w-full h-full p-3">
                                  <img
                                    alt={images.name}
                                    src={
                                      typeof images.src === "object"
                                        ? URL.createObjectURL(images.src)
                                        : images.src
                                    }
                                    className="block h-full w-full object-inherit"
                                  />
                                </div>
                              </div>
                            );
                          }
                        })}
                        {allImageUrl?.map((url) => {
                          return (
                            <div className="flex w-3/6 flex-wrap">
                              <div className="w-full p-1 md:p-2">
                                <span>{url}</span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default RecapReport;
