import React, { useEffect, useState } from "react";
import { AutoComplete } from "../../sharedComponent";
// import Loader from "../../sharedComponent/Loader/Loader";
// import APIHelper from "../../utils/config/apiHelper";
// import APIURLConstant from "../../utils/config/apiURLConstant";
import { Multiselect } from "multiselect-react-dropdown";
import Select from "react-select";

const ComponentTagsSelector = (props) => {
  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#0D47A1",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#FFFFFF",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "white",
      ":hover": {
        backgroundColor: "#0D47A1",
        color: "white",
      },
    }),
  };

  const [componentList, setComponentList] = useState([]);
  const [componentListValue, setComponentListValue] = useState([]);
  const [componentInputValue, setComponentInputValue] = useState("");
  const [selectedComponentTags, setSelectedComponentTags] = useState([]);
  const [componentSelectorList, setComponentSelectorList] = useState([]);
  const [selectedComponentList, setSelectedComponentList] = useState([]);

  useEffect(() => {
    setComponentList(props.componentList);
  }, [props.componentList]);

  useEffect(() => {
    setComponentSelectorList([]);
    setSelectedComponentList([]);
  }, [props.isReset]);

  useEffect(() => {
    setComponentSelectorList(props.componentSelectorList);
  }, [props.componentSelectorList]);

  useEffect(() => {
    props.setSelectedComponentList(selectedComponentList);
  }, [selectedComponentList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    props.setSelectedComponentTags(selectedComponentTags);
  }, [selectedComponentTags]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedComponentList.length > 0) props.isComponentValid(true);
    else props.isComponentValid(false);
  }, [selectedComponentList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedComponentTags(props.selectedComponentTags);
    setSelectedComponentList(props.selectedComponentList);
    setComponentListValue(props.selectedComponentTags);
  }, [props.loadComponentsInEidtMode]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleComponentOptions = (value) => {
    setSelectedComponentTags(value);
    let changedSelectedComponentList = [];
    let changedComponentList = [];
    for (let i = 0; i < value.length; i++) {
      changedSelectedComponentList.push(
        selectedComponentList.filter((item) => item.tagID === value[i].tagID)
      );
      changedComponentList.push(
        componentSelectorList.filter((item) => item.tagID === value[i].tagID)
      );
    }
    const flatselectedComponentList = changedSelectedComponentList.flat(1);
    const flatComponentList = changedComponentList.flat(1);
    setSelectedComponentList(flatselectedComponentList);
    setComponentSelectorList(flatComponentList);
  };

  const handleChangecomponentSelectorList = (value) => {
    setSelectedComponentList([...selectedComponentList, ...value]);
    props.setIsBackButton(false);
    if (value.length > 0) props.isComponentValid(true);
    else props.isComponentValid(false);
  };
  const handleRemoveComponent = (index) => {
    const updatedComponent = selectedComponentList?.filter(
      (_, i) => i !== index
    );

    if (updatedComponent && updatedComponent !== undefined) {
      setSelectedComponentList(updatedComponent);
    }
  };

  return (
    <div>
      {/* <div className="flex-none lg:flex md:flex-none w-full">
        <div className="md:w-12/12 lg:w-6/12 flex-none md:flex lg:flex lg:space-x-4 py-4">
          <p className="text-sm required pb-4 lg:p-0 md:p-0 lg:text-end text-center text-[#394150] md:w-4/12 lg:w-4/12 w-full font-bold">
            Component Tags :
          </p>
          <div className="w-full lg:w-6/12 ">
            <AutoComplete
              isCratable={false}
              tagSuggestion={componentList}
              inputValue={componentInputValue}
              setInputValue={setComponentInputValue}
              value={componentListValue}
              setValue={setComponentListValue}
              onChange={handleComponentOptions}
              placeholder={"Please enter component tag name."}
              styles={colourStyles}
            />
          </div>
        </div>
      </div> */}
      <div>
        <div className="flex-none lg:flex lg:space-x-4 py-4">
          <p className="text-sm required pb-4 lg:p-0 md:p-0 lg:mb-0 mb-2 lg:text-end text-center text-[#394150] lg:w-2/12  w-full font-bold">
            Component Selector:
          </p>
          <div className="w-full lg:w-3/12 tagSelector">
            {/* <Multiselect
                displayValue="componentName"
                // showCheckbox={true}
                onSelect={handleChangecomponentSelectorList}
                options={componentSelectorList}
                selectedValues={[]}
                placeholder={"Select Component"}
                onRemove={handleChangecomponentSelectorList}
              /> */}
            <Select
              isMulti
              name="componentName"
              options={componentSelectorList}
              onChange={handleChangecomponentSelectorList}
              value={[]}
              placeholder="Select Component"
            />
          </div>

          <div className="lg:w-6/12 w-full flex-none lg:flex lg:space-x-4 py-4 ">
            <div className="text-sm lg:p-0 md:p-0 lg:text-end text-center text-[#394150]  lg:w-4/12 w-full font-bold">
              <p>Components Selected :</p>
              <p className="text-red-900">
                {`(`}
                {selectedComponentList.length}
                {`)`}
              </p>
            </div>
            <div className="w-full ">
              {selectedComponentList.map((item, index) => {
                return (
                  <div className="flex bg-grey-300 justify-between border py-1 px-2">
                    <p>{item.label}</p>
                    <button
                      className="text-red-500"
                      onClick={() => handleRemoveComponent(index)}
                    >
                      X
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComponentTagsSelector;
