import { useState } from "react";
import TableView from "./Table.view";

Table.defaultProps = {
  data: [],
  column: [],
};

export default function Table(props) {
  const [isAllSelected, setAllSelected] = useState(false);

  return (
    <TableView
      tableData={props.data}
      column={props.column}
      onClickSelectAll={props.onClickSelectAll}
      onSelectTableRow={props.onSelectTableRow}
      setAllSelected={setAllSelected}
      isAllSelected={isAllSelected}
      handleClickEdit={props.handleClickEdit}
      handleClickView={props.handleClickView}
      handleOpenModule={props.handleOpenModule}
      showCheckboxInHeader={props.showCheckboxInHeader ? props.showCheckboxInHeader : true}
      {...props}
    />
  );
}
