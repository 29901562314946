import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../store/context";
import APIHelper from "../../utils/config/apiHelper";
import APIURLConstant from "../../utils/config/apiURLConstant";
import { useNavigate } from "react-router-dom";

export const ProgramCategoryAndTypeSidebar = (props) => {
  const { userCategories, userDetails, userProgramTypesAll } =
    useContext(AppContext);
  const [programCategory, setProgramCategory] = useState([]);
  const [programType, setProgramType] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [allProgramList, setAllprogramList] = useState([]);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [programtypesLoading, setprogramtypesLoading] = useState(false);
  const [programsLoading, setProgramLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (userDetails?.email !== "") {
      getProgramCategory();
      getProgramType();
    }
  }, [userDetails]);

  const getProgramCategory = () => {
    if (userCategories === undefined || userCategories?.length === 0) {
      setCategoryLoading(true);
      const payload = {
        RoleId: userDetails.roleIDList,
        UserID: 0,
      };
      APIHelper.post(APIURLConstant.GET_PROGRAMCATEGORY_BY_USER_ROLE, payload)
        .then((res) => {
          if (res && res.length > 0) {
            const programCategory = res.map((item) => {
              return {
                value: item.programCategoryID,
                label: item.category,
              };
            });
            if (programCategory && programCategory.length > 0) {
              setProgramCategory([...programCategory]);
              const category = [...programCategory];
            }
          }
          setCategoryLoading(false);
        })
        .catch((err) => {
          console.log(err);
          props.setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: `Failed to load Program Categories`,
          });
          setCategoryLoading(false);
        });
    } else {
      const categoriesUpdated = userCategories ?? [];
      console.log(categoriesUpdated);
      setProgramCategory(categoriesUpdated);
    }
  };
  const getProgramType = () => {
    if (userProgramTypesAll === undefined || userProgramTypesAll.length === 0) {
      setProgramType([]);
      setprogramtypesLoading(true);
      APIHelper.get(APIURLConstant.GET_PROGRAMTYPESALL)
        .then((res) => {
          if (res) {
            const programType = res;
            if (programType && programType.length > 0) {
              setProgramType([...programType]);
            }
            setprogramtypesLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          props.setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: `Failed to load Program Types`,
          });
          setprogramtypesLoading(false);
        });
    } else {
      setProgramType([...userProgramTypesAll]);
    }
  };
  const handleProgramLoad = (programType, item) => {
    if (
      selectedType !== null &&
      selectedType !== undefined &&
      selectedType?.programTypeID === programType.programTypeID
    ) {
      setSelectedType(null);
    } else {
      setSelectedType(programType);
      setAllprogramList([]);
      const payLoad = {
        programCategoryID: programType.programCategoryID,
        programTypeID: programType.programTypeID,
        userEmail: userDetails?.email,
      };
      setProgramLoading(true);
      APIHelper.post(APIURLConstant.GET_PROGRAM_BY_CATEGORY_TYPE, payLoad)
        .then((res) => {
          if (res) {
            let dataUpdated = [];
            // setAllprogramList(res);

            const newArray = res;

            setAllprogramList(newArray);
            setProgramLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setProgramLoading(false);
        });
    }
  };
  const handleNavigateCreateProgram = (selectedProgram) => {
    console.log(selectedProgram);
    if (selectedProgram?.programID) {
      navigate(`/program-details/${selectedProgram.programID}`, {
        state: { selectedProgram: [selectedProgram] },
      });
    }
  };

  const ProgramListRender = () => {
    return (
      <div className=" ">
        {programsLoading ? (
          <div className="flex justify-center w-full p-2 border-t">
            <span class="loader"></span>
          </div>
        ) : (
          <>
            {" "}
            {allProgramList &&
              allProgramList.length > 0 &&
              allProgramList.map((program) => {
                if (program?.programID) {
                  return (
                    <div className="border-b " data-te-offcanvas-dismiss>
                      <button
                        data-te-sidenav-toggle-ref
                        data-te-target="#category-sidenav"
                        aria-controls="#category-sidenav"
                        aria-haspopup="true"
                        onClick={() => {
                          setSelectedType(null);
                          setAllprogramList([]);
                          handleNavigateCreateProgram(program);
                        }}
                        className={`py-1 pl-9 pr-1 text-left w-full flex gap-2 items-center text-blue-900`}
                      >
                        <span>{program?.localProgramName}</span>
                      </button>
                    </div>
                  );
                }
              })}
          </>
        )}
      </div>
    );
  };

  return (
    <div
      className="overflow-y-auto overflow-x-hidden"
      id="accordionCategoryAndType"
    >
      {categoryLoading ? (
        <div className="flex justify-center w-full p-2 border-t">
          <span class="loader"></span>
        </div>
      ) : (
        <>
          {programCategory &&
            programCategory.length > 0 &&
            programCategory.map((item) => {
              return (
                <div className="rounded border-b border-blue-800 bg-white ">
                  <h2 className="mb-0" id={`headingTwo${item.value}`}>
                    <button
                      className="text-blue-900 rounded text-md font-bold group relative flex gap-2 w-full items-center  border-0 bg-white py-2 px-2 text-left text-base transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-neutral-800 dark:[&:not([data-te-collapse-collapsed])]:text-primary-400 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]"
                      type="button"
                      data-te-collapse-init
                      data-te-collapse-collapsed
                      data-te-target={`#collapseCategoryAndType${item.value}`}
                      aria-expanded="false"
                      aria-controls={`collapseCategoryAndType${item.value}`}
                    >
                      {" "}
                      <span
                        className="  h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:mr-0 group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white"
                        data-te-value={item.value}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="h-6 w-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      </span>
                      {item.label}
                    </button>
                  </h2>
                  <div
                    id={`collapseCategoryAndType${item.value}`}
                    className="!visible hidden"
                    data-te-collapse-item
                    aria-labelledby={`headingTwo${item.value}`}
                    data-te-parent="#accordionCategoryAndType"
                  >
                    <div className="py-2">
                      {programtypesLoading ? (
                        <div className="flex justify-center w-full p-2">
                          <span class="loader"></span>
                        </div>
                      ) : (
                        <ul>
                          {programType.map((prgType) => {
                            if (prgType.programCategoryID === item.value) {
                              return (
                                <li className="border border-slate-300 w-full text-blue-900 text-base">
                                  <button
                                    onClick={() => {
                                      handleProgramLoad(prgType, item);
                                    }}
                                    className="flex gap-2 text-left w-full  pl-4"
                                  >
                                    <span
                                      className={`text-slate-400 ${
                                        selectedType &&
                                        selectedType?.programTypeID ===
                                          prgType.programTypeID
                                          ? "rotate-180"
                                          : ""
                                      }`}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="h-6 w-6"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                        />
                                      </svg>
                                    </span>
                                    <span className=" text-blue-900 text-base">
                                      {prgType.type}
                                    </span>
                                  </button>
                                  {selectedType !== null &&
                                  selectedType !== undefined &&
                                  selectedType?.programTypeID ===
                                    prgType.programTypeID ? (
                                    <ProgramListRender />
                                  ) : null}
                                </li>
                              );
                            }
                          })}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </>
      )}
    </div>
  );
};
