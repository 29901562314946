import React from "react";

import Logo from "../../assets/ypp-logo.svg";

const PDFDesign = (props) => {
  return (
    <div className="pb-4">
      <table className="report-container">
        <thead className="report-header ">
          <tr>
            <th className="report-header-cell pdf-table-th">
              <div className="header-info">
                <div
                  className={`p-2 flex items-center gap-2 w-full bg-[#C8102E]`}
                >
                  <div className="">
                    <img src={Logo} className="w-7 h-12" alt="logo" />
                  </div>
                  <h1
                    className={`text-[3.25rem] leading-[0] text-[#FFFFFF] font-semibold m-0 mt-1 ActionNBA-Bold-Web `}
                  >
                    Huddle
                  </h1>
                </div>
              </div>
            </th>
          </tr>
        </thead>

        <tbody className="report-content">
          <tr>
            <td className="report-content-cell">
              <div className="main px-2">
                {props.modulePDFJSON !== undefined &&
                  props.modulePDFJSON !== null &&
                  props.modulePDFJSON.map((item, i) => {
                    return (
                      <div>
                        <p className="text-lg font-semibold">
                          Q{i + 1}: {item.questionText}
                        </p>
                        {Array.isArray(item.answerText) ? (
                          Object.keys(item.answerText[0]).map((ans, index) => {
                            return (
                              <p className="text-base font-normal">
                                {item.answerText[0][ans]}
                              </p>
                            );
                          })
                        ) : (
                          <p className="text-base font-normal">
                            {item.answerText}
                          </p>
                        )}
                      </div>
                    );
                  })}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default PDFDesign;
