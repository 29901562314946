import React, { useEffect, useState, useRef } from "react";
import { useContext } from "react";
import Loader from "../../sharedComponent/Loader/Loader";
import { AppContext } from "../../store/context";
import APIHelper from "../../utils/config/apiHelper";
import APIURLConstant from "../../utils/config/apiURLConstant";
import useOutsideClick from "../../sharedComponent/outSideClick/outSideClick";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import Notification from "../../assets/notification-white.svg";
import ViewNotification from "../../modules/NotificationManagement/ViewNotification";

const NotificationBell = (props) => {
  const navigation = useNavigate();
  const notificationDiv = useRef();
  const { userDetails } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [selectedNotificationForView, setSelectedNotificationForView] =
    useState(null);
  // useEffect(() => {
  //   if (userDetails?.userID) {
  //     getNotifications();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userDetails]);

  useEffect(() => {
    if (userDetails.userID)
      setInterval(getNotifications(showNotification), 300000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails.userID]);

  const getNotifications = (closePopUp) => {
    setShowNotification(closePopUp);
    const payload = {
      UserId: userDetails.userID,
    };
    APIHelper.post(APIURLConstant.GET_NOTIFICATIONS_BY_USERID, payload)
      .then((data) => {
        if (data) {
          setLoading(false);
          setNotificationData(data);
        } else {
          console.log("No Notifications!");
          setLoading(false);
          setShowNotification(!showNotification);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setShowNotification(!showNotification);
      });
  };

  useOutsideClick(notificationDiv, () => {
    if (showNotification === true) {
      setShowNotification(false);
    }
  });

  const getProgramDetailsByID = (
    programID,
    notificationID,
    isDelete,
    isRead,
    isNotificationDelete,
    notificationType,
    programStatus,
    resourceID,
    sectionName,
    notificationData,
    moduleID
  ) => {
    if (notificationType === "Admin Custom Notification") {
      updateNotification(
        programID,
        notificationID,
        isDelete,
        isRead,
        isNotificationDelete,
        notificationType,
        resourceID,
        null,
        sectionName,
        notificationData,
        moduleID
      );
    } else if (programStatus !== "UnPublish") {
      setLoading(true);
      const payload = {
        RecordCount: 0,
        RowCount: 1,
        Status: "null",
        UserEmail: userDetails.email,
        CategoryIDs: "null",
        RegionIDs: "null",
        TypeIDs: "null",
        ProgramId: programID,
      };
      APIHelper.post(APIURLConstant.GET_PROGRAM_LIST, payload)
        .then((res) => {
          updateNotification(
            programID,
            notificationID,
            isDelete,
            isRead,
            isNotificationDelete,
            notificationType,
            resourceID,
            res,
            sectionName,
            null,
            moduleID
          );
          //setLoading(false);
          setShowNotification(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          props.setToasterMessage({
            showToaster: true,
            errorType: "error",
            message:
              "Failed to get details to redirect on program details page",
          });
        });
    } else {
      props.setToasterMessage({
        showToaster: true,
        errorType: "error",
        message: "This program is unpublished",
      });
    }
  };

  const updateNotification = (
    programID,
    notificationID,
    isDelete,
    isRead,
    isNotificationDelete,
    notificationType,
    resourceID,
    res1,
    sectionName = null,
    notificationData = null,
    moduleID
  ) => {
    setLoading(true);
    const notificationObject = {
      NotificationID: notificationID,
      ProgramID: programID,
      CreatedBy: userDetails.userID,
      IsDelete: isDelete,
      IsRead: isRead,
      IsNotificationDelete: isNotificationDelete,
      ToUserID: userDetails.userID,
      NotificationType: notificationType,
      ResourceID: resourceID,
    };

    APIHelper.post(APIURLConstant.ADD_UPDATE_NOTIFICATIONS, notificationObject)
      .then((res) => {
        setLoading(false);
        if (res && resourceID === null) {
          if (
            notificationType !== "Program Delete Notification" &&
            notificationType !== "Admin Custom Notification"
          ) {
            if (
              notificationType == "Program Comment Notification" ||
              notificationType == "Program Feedback Notification"
            ) {
              navigation(`/program-details/${programID}/comment`, {
                state: { selectedProgram: res1 },
              });
            } else if (notificationType == "Module Feedback Notification") {
              navigation(
                `/program-details/${programID}/admin/${moduleID}/feedback`
              );
            } else {
              navigation(`/program-details/${programID}`, {
                state: { selectedProgram: res1 },
              });
            }
          }
          getNotifications(false);
        } else {
          // navigation("/resource-list", {
          //   state: { category: sectionName },
          // });
          console.log("here");
          if (notificationData !== null && notificationData !== undefined) {
            setSelectedNotificationForView(notificationData);
          }
          getNotifications(false);
        }

        // setToasterMessage({
        //   showToaster: true,
        //   errorType: "success",
        //   message: message,
        // })
      })
      .catch((err) => {
        // setToasterMessage({
        //   showToaster: true,
        //   errorType: "error",
        //   message: `Failed to Create Program`,
        // });
        console.log(err);
        setLoading(false);
      });
  };

  const notificationsRedirection = () => {
    setShowNotification(false);
    navigation("/notifications", {
      state: { notificationData: notificationData },
    });
  };
  const getModuleNameByID = (moduleId) => {
    let moduleName = "";
    if (moduleId == 1) {
      moduleName = "Participants Module";
    } else if (moduleId == 2) {
      moduleName = "Staffing Module";
    } else if (moduleId == 3) {
      moduleName = "Youth Safety Module";
    } else if (moduleId == 4) {
      moduleName = "Legal & Registration Module";
    } else if (moduleId == 5) {
      moduleName = "Security Module";
    } else if (moduleId == 6) {
      moduleName = "Risk Management Module";
    } else if (moduleId == 7) {
      moduleName = "Medical Module";
    } else if (moduleId == 8) {
      moduleName = "COVID Module";
    } else if (moduleId == 9) {
      moduleName = "Media Module";
    } else if (moduleId == 10) {
      moduleName = "After-Action Report (AAR) Module";
    }
    return moduleName;
  };

  const checkNotificationCount = (createdByIDs) => {
    if (createdByIDs) {
      function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
      }

      let b = createdByIDs && createdByIDs.split(",");
      let updateIds = [];
      b?.length > 0 &&
        b?.forEach((i) => {
          if (i.length > 0) {
            updateIds.push(i);
          }
        });
      let unique = b.filter(onlyUnique);

      return updateIds.length;
    }
  };

  return (
    <div>
      <Loader showLoading={loading} />
      {props.isMobileView ? (
        <div className="mt-2 relative">
          <button
            className="relative  inline-flex w-fit"
            onClick={() => getNotifications(true)}
          >
            {notificationData[0]?.unReadCount ? (
              <span className="absolute bottom-auto left-auto right-0 top-0 z-10 inline-block -translate-y-1/2 translate-x-2/4 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 rounded-full bg-red-700 px-2 py-1 text-center align-baseline text-xs font-bold leading-none text-white">
                {notificationData[0]?.unReadCount}
              </span>
            ) : null}
            <img src={Notification} alt="notification" className="h-6 w-6" />
          </button>
        </div>
      ) : (
        <li>
          <div className="mt-2 relative">
            <button
              className="relative  inline-flex w-fit"
              onClick={() => getNotifications(true)}
            >
              {notificationData[0]?.unReadCount ? (
                <span className="absolute bottom-auto left-auto right-0 top-0 z-10 inline-block -translate-y-1/2 translate-x-2/4 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 rounded-full bg-red-700 px-2 py-1 text-center align-baseline text-xs font-bold leading-none text-white">
                  {notificationData[0]?.unReadCount}
                </span>
              ) : null}
              <img src={Notification} alt="notification" className="h-6 w-6" />
            </button>
          </div>
        </li>
      )}
      <div>
        {showNotification && (
          <div
            ref={notificationDiv}
            className={`${
              props.isMobileView ? "w-full left-0 right-0" : "right-1"
            } h-96 absolute drop-shadow-2xl border rounded bg-white z-[1200] lg:w-[40rem] overflow-hidden  top-14 `}
          >
            {/* Header */}
            <div className="flex justify-between px-2 py-4">
              <p className="text-lg text-[#C8102E] font-semibold">
                Notification
              </p>
              <div className="flex gap-2 flex-wrap">
                {notificationData.length > 0 ? (
                  <button
                    onClick={() => notificationsRedirection()}
                    className={`text-sm ${
                      notificationData[0]?.unReadCount
                        ? "border-r-2 pr-2 border-black"
                        : ""
                    }`}
                  >
                    View All
                  </button>
                ) : null}

                {notificationData[0]?.unReadCount ? (
                  <button
                    onClick={() => {
                      updateNotification(0, 0, false, true, false, "All");
                    }}
                    className="text-sm"
                  >
                    Mark All as Read
                  </button>
                ) : null}
              </div>
            </div>
            {/* Body */}
            <div className="overflow-y-auto h-full pb-16">
              {notificationData.map((item) => {
                return (
                  <div
                    className={`px-2 mx-1 py-2 mb-[2px] border rounded flex justify-between ${
                      !item.isRead ? "bg-white" : "bg-[#EAEAEA]"
                    }`}
                    key={Math.random()}
                  >
                    <div
                      className="w-5/6 cursor-pointer"
                      onClick={() => {
                        getProgramDetailsByID(
                          item.programID,
                          item.notificationID,
                          false,
                          true,
                          false,
                          item.notificationType,
                          item.programStatus,
                          item.resourceID,
                          item.sectionName,
                          item,
                          item.moduleID
                        );
                      }}
                    >
                      {item.notificationType ===
                      "Resource Creation Notification" ? (
                        <p
                          className={`text-sm   ${
                            !item.isRead ? "font-bold" : ""
                          }`}
                        >
                          {`${item.userName} has added resource ${item.resourceName} for
                          ${item.sectionName} category`}
                        </p>
                      ) : item.notificationType ===
                        "Module Feedback Notification" ? (
                        <p
                          className={`text-sm    ${
                            !item.isRead ? "font-bold" : ""
                          }`}
                        >
                          {item.userName} has added Feedback on{" "}
                          {getModuleNameByID(item.moduleID)} for{" "}
                          {item.localProgramName}
                        </p>
                      ) : item.notificationType ===
                        "Program Date Confirm Notification" ? (
                        <p
                          className={`text-sm    ${
                            !item.isRead ? "font-bold" : ""
                          }`}
                        >
                          {item.userName} has confirmed program date for{" "}
                          {item.localProgramName}
                        </p>
                      ) : item.notificationType ===
                        "Program Publish/Unpublish Notification" ? (
                        <p
                          className={`text-sm    ${
                            !item.isRead ? "font-bold" : ""
                          }`}
                        >
                          {item.userName} has {item.programStatus.toLowerCase()}{" "}
                          {item.localProgramName}
                        </p>
                      ) : item.notificationType ===
                          "Program Comment Notification" &&
                        (checkNotificationCount(item.createdByIDs) === 1 ||
                          checkNotificationCount(item.createdByIDs) === 0) ? (
                        <p
                          className={`text-sm    ${
                            !item.isRead ? "font-bold" : ""
                          }`}
                        >
                          {item.userName} has commented on{" "}
                          {item.localProgramName}
                        </p>
                      ) : item.notificationType ===
                        "Program Delete Notification" ? (
                        <p
                          className={`text-sm    ${
                            !item.isRead ? "font-bold" : ""
                          }`}
                        >
                          {item.userName} has deleted {item.localProgramName}{" "}
                          {item.deletedProgramCount !== null &&
                          item.deletedProgramCount !== 1
                            ? `and ${item.deletedProgramCount} more programs`
                            : ""}
                        </p>
                      ) : item.notificationType ===
                        "Program Edit Notification" ? (
                        <p
                          className={`text-sm    ${
                            !item.isRead ? "font-bold" : ""
                          }`}
                        >
                          {item.userName} has edited {item.localProgramName}
                        </p>
                      ) : item.notificationType ===
                        "Admin Custom Notification" ? (
                        <p
                          className={`text-sm    ${
                            !item.isRead ? "font-bold" : ""
                          }`}
                        >
                          {item.userName} sent a custom Notification:{" "}
                          {item.subject}
                        </p>
                      ) : item.notificationType ===
                        "Two Weeks Before Email To Module Users" ? (
                        <p
                          className={`text-sm    ${
                            !item.isRead ? "font-bold" : ""
                          }`}
                        >
                          Please review the modules of the Program(s) :{" "}
                          {item.commentText}
                        </p>
                      ) : //  item.notificationType ===
                      //   "Review Pending- 3 Days To Go To Program(s)" ? (
                      //   <p
                      //     className={`text-sm  truncate  ${
                      //       !item.isRead ? "font-bold" : ""
                      //     }`}
                      //   >
                      //     Please review the details of the Program(s) :{" "}
                      //     {item.commentText}
                      //   </p>
                      // ) :
                      // item.notificationType ===
                      //   "Review Pending- 2 Days To Go To Program(s)" ? (
                      //   <p
                      //     className={`text-sm  truncate  ${
                      //       !item.isRead ? "font-bold" : ""
                      //     }`}
                      //   >
                      //     Please review the details of the Program(s) :{" "}
                      //     {item.commentText}
                      //   </p>
                      // ) :
                      item?.notificationType?.includes("Review Pending") ? (
                        <p
                          className={`text-sm    ${
                            !item.isRead ? "font-bold" : ""
                          }`}
                        >
                          Please review the details of the Program(s) :{" "}
                          {item.commentText}
                        </p>
                      ) : (
                        <p
                          className={`text-sm    ${
                            !item.isRead ? "font-bold" : ""
                          }`}
                        >
                          {item.userName} and{" "}
                          {checkNotificationCount(item.createdByIDs)} other has
                          commented on {item.localProgramName}
                        </p>
                      )}
                      {/* {
                                                item.notificationCount === 1 ?
                                                    <p className="text-sm font-bold truncate">
                                                        {item.userName} has commented on {item.localProgramName}
                                                    </p> :
                                                    <p className="text-sm font-bold truncate">
                                                        {item.userName} and {item.notificationCount - 1} other has commented on {item.localProgramName}
                                                    </p>
                                            } */}
                      <span className="text-xs">
                        {moment(item.modifiedDate).format("MM/DD/YY")}
                        {/* {userDetails.firstName + userDetails.lastName} */}
                      </span>
                    </div>
                    <div className="flex justify-end gap-4 w-1/6">
                      {/* {!item.isRead ? (
                        <button
                          className="text-blue-900"
                          data-te-toggle="tooltip"
                          title="mark as read"
                          onClick={() => {
                            updateNotification(
                              item.programID,
                              item.notificationID,
                              false,
                              true,
                              false,
                              item.notificationType,
                              item.sectionName
                            );
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          className="text-blue-900 mt-1"
                          data-te-toggle="tooltip"
                          title="mark as unread"
                          onClick={() => {
                            updateNotification(
                              item.programID,
                              item.notificationID,
                              false,
                              false,
                              false,
                              item.notificationType
                            );
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                            />
                          </svg>
                        </button>
                      )} */}
                      <button
                        onClick={() => {
                          updateNotification(
                            item.programID,
                            item.notificationID,
                            true,
                            false,
                            false,
                            item.notificationType,
                            item.moduleID
                          );
                        }}
                        className="text-[#1D428A]"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      {selectedNotificationForView !== null && (
        <ViewNotification
          notification={selectedNotificationForView}
          setSelectedNotificationForView={setSelectedNotificationForView}
        />
      )}
    </div>
  );
};

export default NotificationBell;
