/* eslint-disable */
import { LogLevel } from "@azure/msal-browser";

let CLIENT_ID = process.env.REACT_APP_DEV_CLIENT;
let TENANT_ID = process.env.REACT_APP_TENANT_ID;
let RETURN_URL = process.env.REACT_APP_LOCAL_APP;

if (window.location.href.includes(process.env.REACT_APP_DEV_APP)) {
  RETURN_URL = process.env.REACT_APP_DEV_APP;
}
if (window.location.href.includes(process.env.REACT_APP_QA_APP)) {
  CLIENT_ID = process.env.REACT_APP_QA_CLIENT;
  RETURN_URL = process.env.REACT_APP_QA_APP;
}
if (window.location.href.includes(process.env.REACT_APP_CT_APP)) {
  CLIENT_ID = process.env.REACT_APP_CT_CLIENT;
  RETURN_URL = process.env.REACT_APP_CT_APP;
}
if (window.location.href.includes(process.env.REACT_APP_PROD_APP)) {
  CLIENT_ID = process.env.REACT_APP_PROD_CLIENT;
  RETURN_URL = process.env.REACT_APP_PROD_APP;
}

export const msalConfig = {
  auth: {
    clientId: CLIENT_ID,
    authority: `https://login.microsoftonline.com/${TENANT_ID}/`,
    redirectUri: `/signin-oidc`,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            // console.debug(message);
            return;
          case LogLevel.Warning:
            // console.warn(message);
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: [`api://${CLIENT_ID}/User.Login`],
};
