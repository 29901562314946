import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TagIcon from "../../assets/tag-button.svg";

import APIHelper from "../../utils/config/apiHelper";
import APIURLConstant from "../../utils/config/apiURLConstant";
import { AppContext } from "../../store/context";
import { Modal } from "../../sharedComponent";
import ArrowIcon from "../../assets/arrow-down.svg";
import { Link } from "react-router-dom";

const ProgramListForDate = () => {
  const location = useLocation();
  const { userDetails } = useContext(AppContext);
  const [programList, setProgramList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showTagModal, setShowTagModal] = useState(false);
  const [selectedListOfTags, setSelectedListOfTags] = useState([]);

  useEffect(() => {
    if (location.pathname && userDetails.email != "") {
      getProgramList();
    }
  }, [location, userDetails]);

  const getProgramList = () => {
    const data = location.pathname.split("/")[2];
    console.log(data);
    setLoading(true);
    const payLoad = {
      startDate: data,
      userId: userDetails?.userID,
    };
    APIHelper.post(APIURLConstant.GET_PROGRAM_LIST_BY_START_DATE, payLoad)
      .then((res) => {
        console.log(res);
        setProgramList(res);
        setLoading(false);
      })
      .catch((err) => {
        setProgramList([]);
        console.log(err);
        setLoading(false);
      });
  };

  const handleOpenTagList = (tags) => {
    setShowTagModal(true);
    setSelectedListOfTags(tags);
  };

  return (
    <div className="mt-9">
      <div className="mx-auto md:p-7 p-2 overflow-x-hidded">
        <div>
          <p className="text-[#c8102e] font-semibold text-[19px] py-3">
            Reviewer Programs
          </p>
        </div>
        <div className="p-3">
          {loading ? (
            <div className="flex justify-center h-full w-full p-2 ">
              <span className="loader"></span>
            </div>
          ) : (
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg ">
              <table className=" w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                <thead className=" text-[#323232] uppercase bg-gray-100">
                  <tr>
                    <th scope="col" className={`px-3 py-3 whitespace-nowrap`}>
                      LOCAL PROGRAM NAME
                    </th>
                    <th scope="col" className={`px-3 py-3 whitespace-nowrap`}>
                      PROGRAM TYPE
                    </th>
                    <th scope="col" className={`px-3 py-3 whitespace-nowrap`}>
                      PROGRAM CATEGORY
                    </th>
                    <th scope="col" className={`px-3 py-3 whitespace-nowrap`}>
                      START
                    </th>
                    <th scope="col" className={`px-3 py-3 whitespace-nowrap`}>
                      END
                    </th>
                    <th scope="col" className={`px-3 py-3 whitespace-nowrap`}>
                      REGION
                    </th>

                    <th scope="col" className={`px-3 py-3 whitespace-nowrap`}>
                      STATUS
                    </th>
                    <th scope="col" className={`px-3 py-3 whitespace-nowrap`}>
                      STAGE
                    </th>
                    <th scope="col" className={`px-3 py-3 whitespace-nowrap`}>
                      CREATED BY
                    </th>
                    <th scope="col" className={`px-3 py-3 whitespace-nowrap`}>
                      REVIEWER
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {programList && programList.length > 0 ? (
                    programList.map((item, index) => {
                      const tags =
                        item.programCategory &&
                        item.programCategory.split(/[,]+/);
                      return (
                        <tr
                          key={index}
                          className="bg-white border-b text-[#394150] dark:bg-gray-800 dark:border-gray-700"
                        >
                          <td className={`px-3 py-3   `}>
                            <Link
                              className="text-[#1D428A]"
                              to={`/program-details/${item?.programID}`}
                              target="_blank"
                            >
                              {item?.localProgramName}
                            </Link>
                          </td>
                          <td className={`px-3 py-3   `}>
                            {item?.programType}
                          </td>
                          <td className={`px-3 py-3   `}>
                            <div className="flex flex-wrap">
                              {tags &&
                                tags.length > 0 &&
                                // eslint-disable-next-line array-callback-return
                                tags.map((allTags, index) => {
                                  if (allTags.length !== 0 && index < 2) {
                                    return (
                                      <>
                                        <p className="bg-[#394150] mr-2 w-fit mb-2 flex rounded">
                                          <span className="px-2 mx-1  whitespace-nowrap text-white text-sm font-normal">
                                            {allTags}
                                          </span>
                                          <img
                                            src={TagIcon}
                                            className="h-15 w-5 mr-1 "
                                            alt="Tag"
                                          />
                                        </p>
                                      </>
                                    );
                                  }
                                })}
                              {tags && tags.length > 2 && (
                                <button
                                  onClick={() => handleOpenTagList(tags)}
                                  className="pl-1 bg-[#394150] w-fit mb-2 rounded flex align-center text-center"
                                >
                                  <span className="text-white text-sm ">
                                    view...
                                  </span>
                                  <img
                                    src={ArrowIcon}
                                    className="h-3 w-5 arrow-img mt-1"
                                    alt="arrow"
                                  />
                                </button>
                              )}
                            </div>
                          </td>
                          <td className={`px-3 py-3   `}>
                            {item?.overallStartDate}
                          </td>
                          <td className={`px-3 py-3   `}>
                            {item?.overallEndDate}
                          </td>
                          <td className={`px-3 py-3   `}>{item?.regionName}</td>

                          <td className={`px-3 py-3   `}>
                            {item?.programStatus}
                          </td>
                          <td className={`px-3 py-3   `}>
                            {item?.programStage}
                          </td>
                          <td className={`px-3 py-3   `}>{item?.userName}</td>
                          <td className={`px-3 py-3   `}>
                            {item?.programReviewer}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td>No Data</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      {/* TagList Popup */}
      <Modal
        showModal={showTagModal}
        handleClose={() => setShowTagModal(false)}
        isTagList={true}
        data={selectedListOfTags}
        title={"Tag List"}
      />
    </div>
  );
};
export default ProgramListForDate;
