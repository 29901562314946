import React from "react";

const ComponentQuestionSelector = (props) => {
  return (
    <div>
      <div className="bg-white border border-gray-200 rounded-lg shadow-md w-full p-4 mt-4 drop-shadow-md drop-shadow-lg">
        {props.questionListForSelectedComponent &&
        props.questionListForSelectedComponent.length > 0 ? (
          props.questionListForSelectedComponent.map((item, index) => {
            return (
              <div key={index}>
                <div className="flex justify-between border-b-2 border-blue-900 w-full px-2">
                  <p className="text-md text-[#394150] font-bold">
                    {item.componentName}
                  </p>
                  <button type="button">
                    <svg
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 text-blue-900 mt-1"
                      onClick={() => props.handleRemoveComponent(item)}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-2">
                  {item.questionList &&
                    item.questionList.map((question, index) => {
                      return (
                        <div className="flex gap-2 pb-2">
                          <input
                            type="checkbox"
                            checked={question.isChecked}
                            onChange={(e) =>
                              props.handleChangeCheckBox(
                                e.target.checked,
                                item,
                                index
                              )
                            }
                          />
                          <p className="font-bold text-blue-900 text-sm">
                            {question.questionText}
                          </p>
                        </div>
                      );
                    })}
                  <button
                    className="px-2 text-sm mt-4 border border-blue-900 h-8 rounded-md text-[#394150]"
                    onClick={() => {
                      props.setShowAddQA(true);
                      props.setComponentId(item.componentID);
                      props.getTagsList();
                      props.setIndex(index);
                    }}
                  >
                    Add Q&A Selector
                  </button>
                </div>
              </div>
            );
          })
        ) : (
          <p className="text-md">No Q&A</p>
        )}
      </div>
    </div>
  );
};
export default ComponentQuestionSelector;
