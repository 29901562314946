// import Moment from 'moment';
// import momentTz from 'moment-timezone';
// import CultureInfo, { TIME_ZONE_EST } from './cultureInfo';
// import AppConstants from '../constants/appConstants';
// import { padStart } from 'lodash-es';
// import * as XLSX from 'xlsx';
// import { FULL_PERMISSION, NO_ACCESS_PERMISSION } from '../constants/Constant';
// import { isIOS, isChrome, isMobile, isTablet } from 'react-device-detect';
import { AES, enc } from 'crypto-js';

export default class Helper {

    static setStorage(key, value, expires) {
        if (expires === undefined || expires === null) {
            expires = 24 * 60 * 60; // default: seconds for 1 day
        } else {
            expires = Math.abs(expires); //make sure it's positive
        }

        var now = Date.now(); //millisecs since epoch time, lets deal only with integer
        var schedule = now + expires * 1000;
        try {
            if (key === 'accessToken' || key === 'chk' || key === 'impersonateEmail' || key === 'reporturl' || key === 'pageSize') {
                localStorage.setItem(key, JSON.stringify(value));
                localStorage.setItem(key + '_expiresIn', schedule);
            } else {
                if (typeof value == 'string') {
                    const encryptedString = AES.encrypt(
                        value,
                        process.env.REACT_APP_ENCRYPT_KEY
                    );
                    localStorage.setItem(key, JSON.stringify(encryptedString));
                    localStorage.setItem(key + '_expiresIn', schedule);
                } else {
                    const encrypted = AES.encrypt(
                        JSON.stringify(value),
                        process.env.REACT_APP_ENCRYPT_KEY
                    ).toString();
                    localStorage.setItem(key, JSON.stringify(encrypted));
                    localStorage.setItem(key + '_expiresIn', schedule);
                }
            }
        } catch (e) {
            console.error(e);
            return false;
        }
        return true;
    }

    static removeStorage(key) {
        try {
            localStorage.removeItem(key);
            localStorage.removeItem(key + '_expiresIn');
        } catch (e) {
            return false;
        }
        return true;
    }

    static getStorage(key) {
        var now = Date.now(); //epoch time, lets deal only with integer
        // set expiration for storage
        var expiresIn = localStorage.getItem(key + '_expiresIn');
        if (expiresIn === undefined || expiresIn === null) {
            expiresIn = 0;
        }

        if (expiresIn < now) {
            // Expired
            this.removeStorage(key);
            return null;
        } else {
            try {
                if (key === 'accessToken' || key === 'chk' || key === 'impersonateEmail' || key === 'reporturl' || key === 'pageSize') {
                    var value = localStorage.getItem(key);
                    return JSON.parse(value);
                } else {
                    const decrypted = AES.decrypt(
                        JSON.parse(localStorage.getItem(key)),
                        process.env.REACT_APP_ENCRYPT_KEY
                    );
                    const decryptedString = decrypted.toString(enc.Utf8);
                    return JSON.parse(decryptedString);
                }
            } catch (e) {
                console.error(e);
                return null;
            }
        }
    }

}