import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import APIHelper from "../../utils/config/apiHelper";
import APIURLConstant from "../../utils/config/apiURLConstant";
import { AppContext } from "../../store/context";
import MethodAndVariable from "../../utils/config/variableConstant";
import Loader from "../../sharedComponent/Loader/Loader";
import Toaster from "../../sharedComponent/Toaster/Toaster";

const alphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const UserDirectory = () => {
  const { userDetails } = useContext(AppContext);
  const [userList, setUserList] = useState([]);
  const [originalUserList, setOriginalUserList] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [alphabetText, setAlphabetText] = useState("");
  const [loading, setLoading] = useState(false);
  const [toasterMessage, setToasterMessage] = useState({
    showToaster: false,
    errorType: "",
    message: "",
  });
  useEffect(() => {
    if (userDetails?.email !== "") {
      getUserList();
    }
  }, [userDetails]);

  const getUserList = () => {
    setLoading(true);
    APIHelper.post(APIURLConstant.GET_USERS_LIST, {
      searchText: searchText,
      recordCount: 0,
      rowCount: 10000,
      status: true,
      roleId: 0,
      regionIds: "null",
    })
      .then((data) => {
        setOriginalUserList(data);
        data = data.filter(
          (item) => item.email.toLowerCase() !== userDetails.email.toLowerCase()
        );
        setUserList(data);
        //setOriginalUserList(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: "Failed to load User Directory ",
        });
      });
  };

  const handleSearchUserList = () => {
    if (searchText.length > 0) {
      setAlphabetText("");
      const searchTextTrimmed = searchText.trim();
      let newUserList = [];
      if (searchTextTrimmed.includes(" ")) {
        newUserList = originalUserList.filter(
          (item) =>
            (item.firstName.toLowerCase() ===
              searchTextTrimmed.split(" ")[0].toLowerCase() &&
              item.lastName.toLowerCase() ===
                searchTextTrimmed.split(" ")[1].toLowerCase()) ||
            item.roleList
              ?.toLowerCase()
              .includes(searchTextTrimmed.toLowerCase())
        );
      } else {
        newUserList = originalUserList.filter(
          (item) =>
            item.firstName
              .toLowerCase()
              .includes(searchTextTrimmed.toLowerCase()) ||
            item.lastName
              .toLowerCase()
              .includes(searchTextTrimmed.toLowerCase()) ||
            item.email
              .toLowerCase()
              .includes(searchTextTrimmed.toLowerCase()) ||
            item.roleList
              ?.toLowerCase()
              .includes(searchTextTrimmed.toLowerCase())
        );
      }
      setUserList(newUserList);
    } else {
      setUserList(originalUserList);
    }
  };

  const resetFilter = () => {
    setSearchText("");
    setAlphabetText("");
    setUserList(originalUserList);
  };

  const handleAlphabetSearch = (value) => {
    setAlphabetText(value);
    setSearchText("");
    if (value.length > 0) {
      const newUserList = originalUserList.filter((item) =>
        item.firstName
          .substring(0, 1)
          .toLowerCase()
          .includes(value.toLowerCase())
      );
      setUserList(newUserList);
    } else {
      setUserList(originalUserList);
    }
  };

  return (
    <div className=" mt-16 mx-auto md:p-7 p-2">
      <Loader showLoading={loading} />
      <p className="text-[#c8102e] font-semibold text-[19px] py-3">
        User Directory List
      </p>
      <div className="flex gap-4 flex-wrap mb-4 mt-2">
        {alphabet?.map((item) => (
          <button
            onClick={() => handleAlphabetSearch(item)}
            className={` text-sm ${
              item === alphabetText
                ? "text-white bg-blue-500 h-7 w-7 rounded-full  "
                : "text-blue-800"
            }`}
          >
            {item}
          </button>
        ))}
      </div>
      <div className="grid grid-cols-1  lg:grid-cols-2 gap-2 pb-4 mt-2">
        <div className="flex gap-2 ">
          <input
            type="text"
            placeholder="Search"
            className="w-full h-10 focus:outline-none focus:shadow-outline border px-2 border-grey-300 rounded "
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
          />
          <button
            disabled={!searchText}
            className={`rounded-md text-white rounded-md p-1 px-[1rem] h-10 text-sm
                      ${searchText ? "bg-blue-900" : "bg-gray-400"}
                    `}
            onClick={() => {
              handleSearchUserList();
            }}
          >
            Search
          </button>
        </div>
        <div className=" flex flex flex-wrap space-x-2 ">
          <button
            className={`
              rounded-md text-white rounded-md p-1 px-[1rem] h-10 text-sm
                      ${
                        searchText.length > 0 || alphabetText.length > 0
                          ? "bg-blue-900"
                          : "bg-gray-400"
                      }`}
            onClick={() => {
              resetFilter();
            }}
          >
            Reset
          </button>
        </div>
      </div>

      {userList !== undefined && userList.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 mt-2">
          {userList?.map((item) => (
            <div className="bg-white border shadow-md w-full rounded px-2 py-2 flex gap-1 md:flex-nowrap flex-wrap items-center ">
              {/* <img
                src="https://ukmars.org/ukmars/wp-content/uploads/2020/07/pexels-photo-4709374.jpeg"
                alt="user-image"
                className="h-10 w-10 rounded-full object-fill"
              /> */}
              <div className="overflow-hidden w-10">
                <p className="bg-[#1D428A] text-[0.8rem] h-8 w-8  flex items-center justify-center rounded-full text-white cursor-pointer">
                  {MethodAndVariable.getInitials(
                    item.firstName,
                    item.lastName,
                    ""
                  )}
                </p>
              </div>
              <div className="w-full">
                <div className="flex-none lg:flex flex-wrap justify-between w-full">
                  <p className="text-base font-bold truncate">
                    {item.firstName} {item.lastName}
                  </p>

                  {item.roleList !== null && (
                    <div className="font-normal text-[0.9rem] flex flex-wrap gap-1 ">
                      <span className="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-4 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                          />
                        </svg>
                      </span>
                      <p>{item.roleList}</p>
                    </div>
                  )}
                </div>

                <div className="flex-none lg:flex flex-wrap justify-between w-full mt-1">
                  {/* {item.roleList !== null && (
                    <div className="font-normal text-[0.9rem] flex flex-wrap gap-1 ">
                      <div className="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-4 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                          />
                        </svg>
                      </div>
                      <p>{item.roleList}</p>
                    </div>
                  )} */}
                  {item.email !== null && (
                    <div className="font-normal text-[0.9rem] flex flex-wrap gap-1">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-4 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                          />
                        </svg>
                      </div>
                      <a href={`mailto:${item.email}`}>{item.email}</a>
                    </div>
                  )}
                  {item.contactNumber !== null && (
                    <div className="font-normal text-[0.9rem] flex flex-wrap gap-1 ">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-4 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                          />
                        </svg>
                      </div>
                      <p>{item.contactNumber}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex w-full justify-center py-2 border rounded">
          No Data
        </div>
      )}

      <Toaster
        toasterMessage={toasterMessage}
        setToasterMessage={setToasterMessage}
      />
    </div>
  );
};
export default UserDirectory;
