import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
} from "recharts";
const initalData = [
  {
    name: "Coaches",
    actual: 0,
    reached: 0,
    totalDirect: 0,
    totalReached: 0,
  },
  {
    name: "Youths",
    actual: 0,
    reached: 0,
  },
  {
    name: "Referees",
    actual: 0,
    reached: 0,
  },
  {
    name: "Teams",
    actual: 0,
    reached: 0,
  },
];

const ParticipantReachBar = (props) => {
  const [chartData, setChartData] = useState(initalData);

  useEffect(() => {
    if (props?.data) {
      setChartData(props?.data);
    }
  }, [props]);
  return (
    <div className="w-full overflow-x-auto">
      <BarChart
        width={450}
        height={298}
        margin={{
          top: 5,
          right: 20,
          left: 20,
          bottom: 5,
        }}
        data={chartData}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis />
        <XAxis fontSize={12} dataKey="name" />
        <Tooltip />

        <Bar
          barSize={30}
          dataKey={`${props?.isActual ? "actual" : "reached"}`}
          fill={`${props?.isActual ? "#00b050" : "#4472c4"}`}
        />
      </BarChart>
      <div className="flex  flex-wrap justify-start md:justify-center  gap-3 ml-6 mb-2">
        {props?.isActual ? (
          <p className="text-[#00b050] text-sm ">
            Total Direct : {chartData[0]?.totalDirect}
          </p>
        ) : (
          <p className="text-[#4472c4] text-sm ">
            Total Reach : {chartData[0]?.totalReached}
          </p>
        )}
      </div>
    </div>
  );
};
export default ParticipantReachBar;
