import React, { useEffect, useState } from "react";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import { SurveyQuestionDropdown, ReactQuestionFactory } from "survey-react-ui";
import { Serializer, RendererFactory } from "survey-core";
import "survey-core/survey.i18n.js";
import "survey-creator-core/survey-creator-core.i18n.js";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";
import "./surveyComponent.css";
import APIHelper from "../../utils/config/apiHelper";
import APIURLConstant from "../../utils/config/apiURLConstant";
import { useLocation } from "react-router-dom";
import Toaster from "../Toaster/Toaster";
import Loader from "../Loader/Loader";
import Select from "react-select";

class CustomSelect extends SurveyQuestionDropdown {
  constructor(props) {
    super(props);
  }
  // Convert the `visibleChoices` array to a format supported by React Select
  get options() {
    return this.question.visibleChoices.map((c) => {
      return { value: c.value, label: c.value };
    });
  }
  // Retrieve an option object based on the question value
  get selectedOption() {
    return this.options.filter((o) => o.value == this.question.value);
  }
  // Set the question value to the selected option value
  onSelectChange = (selectedOption) => {
    this.setValueCore(selectedOption.value);
  };
  renderElement() {
    // If the question is non-editable, render a stylized div
    if (this.isDisplayMode) {
      return (
        <div
          id={this.question.inputId}
          className={this.question.getControlClass()}
          disabled
        >
          {this.question.displayValue || this.question.placeholder}
        </div>
      );
    }

    // Otherwise, render the React Select component
    return (
      <Select
        id={this.question.inputId}
        value={this.selectedOption}
        onChange={this.onSelectChange}
        options={this.options}
        required={this.question.isRequired}
        menuPortalTarget={document.querySelector("body")}
      />
    );
  }
}
class CustomMultiSelect extends SurveyQuestionDropdown {
  constructor(props) {
    super(props);
  }
  // Convert the `visibleChoices` array to a format supported by React Select
  get options() {
    return this.question.visibleChoices.map((c) => {
      return { value: c.value, label: c.value };
    });
  }
  // Retrieve an option object based on the question value
  get selectedOption() {
    return this.options.filter((element) =>
      this.question.value.includes(element.value)
    );
  }
  // Set the question value to the selected option value
  onSelectChange = (selectedOption) => {
    console.log(this.question.value, "==>", this.options);
    const value = [];
    selectedOption.forEach((item) => {
      value.push(item.value);
    });
    this.setValueCore(value);
  };
  renderElement() {
    // If the question is non-editable, render a stylized div
    if (this.isDisplayMode) {
      return (
        <div
          id={this.question.inputId}
          className={this.question.getControlClass()}
          disabled
        >
          {this.question.displayValue || this.question.placeholder}
        </div>
      );
    }

    // Otherwise, render the React Select component
    return (
      <Select
        id={this.question.inputId}
        value={this.selectedOption}
        isMulti
        onChange={this.onSelectChange}
        options={this.options}
        required={this.question.isRequired}
        menuPortalTarget={document.querySelector("body")}
      />
    );
  }
}

// Register the CustomSelect component as a renderer under a custom name "sv-dropdown-react"
ReactQuestionFactory.Instance.registerQuestion("sv-dropdown-react", (props) => {
  return React.createElement(CustomSelect, props);
});
ReactQuestionFactory.Instance.registerQuestion("tagbox", (props) => {
  return React.createElement(CustomMultiSelect, props);
});
// Register "sv-dropdown-react" as a renderer for questions whose `type` is "dropdown" and `renderAs` property is "dropdown-react"
RendererFactory.Instance.registerRenderer(
  "dropdown",
  "dropdown-react",
  "sv-dropdown-react"
);
RendererFactory.Instance.registerRenderer(
  "tagbox",
  "dropdown-react",
  "sv-dropdown-react"
);
Serializer.findProperty("dropdown", "renderAs").defaultValue = "dropdown-react";

const SurveyComponent = () => {
  const creatorOptions = {};
  const creator = new SurveyCreator(creatorOptions);
  const [moduleDetail, setModuleDetail] = useState({});
  const [surveyJson, setSurveyJson] = useState({
    title: "",
    pages: [{ name: "page1", elements: [{}] }],
  });
  const [questionDetail, setQuestionDetail] = useState({});
  const [programDetail, setProgramDetail] = useState(null);
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [toasterMessage, setToasterMessage] = useState({
    showToaster: false,
    errorType: "",
    message: "",
  });

  useEffect(() => {
    const stateparam = location.state;
    setProgramDetail(stateparam.moduleDetails);
    if (stateparam.moduleDetails) {
      let moduleProps = stateparam.moduleDetails;
      setModuleDetail(moduleProps);
      setSurveyJson({
        ...surveyJson,
        title: moduleProps.programModuleName,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (moduleDetail.programModuleID) {
      getModuleQuestionList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleDetail]);

  const getModuleQuestionList = () => {
    setLoading(true);
    APIHelper.post(APIURLConstant.GET_MODULE_QUESTION_JSON, {
      ModuleID: moduleDetail.programModuleID,
    })
      .then((res) => {
        if (res.questionsJSON) {
          setQuestionDetail(res);
          const obj = JSON.parse(res.questionsJSON);
          setSurveyJson(JSON.parse(obj));
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  //Automatically save survey definition on changing. Hide "Save" button
  creator.isAutoSave = false;
  //Show state button here
  creator.showState = true;
  creator.allowEditSurveyTitle = false;

  //Setting this callback will make visible the "Save" button
  creator.saveSurveyFunc = function (saveNo, callback) {
    //save the survey JSON
    if (creator.text) {
      setLoading(true);
      let payLoad = {
        moduleQuestionJSID: questionDetail
          ? questionDetail.moduleQuestionJSID
          : 0,
        moduleID: moduleDetail.programModuleID,
        questionsJSON: JSON.stringify(creator.text),
        isActive: true,
      };
      const message =
        questionDetail && questionDetail.moduleQuestionJSID === 0
          ? "Module question added successfully"
          : "Module question updated successfully";
      APIHelper.post(APIURLConstant.POST_MODULE_QUESTION_JSON, payLoad)
        .then((res) => {
          setLoading(false);
          setToasterMessage({
            showToaster: true,
            errorType: "success",
            message: message,
          });
          getModuleQuestionList();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: "Error while adding a module question",
          });
        });
    }
    callback(saveNo, true);
  };

  creator.text = JSON.stringify(surveyJson);
  //If you get JSON from your database then you can use creator.JSON property
  //creator.JSON = yourJSON;
  return (
    <>
      <Loader showLoading={loading} />

      <div className="mx-auto lg:mt-0 mt-5">
        <div className="p-2 flex-none md:flex ">
          <p className="lg:text-3xl text-xl text-blue-900 font-bold leading-9 w-full md:w-2/6">
            {moduleDetail ? moduleDetail.programModuleName : "Module "}
          </p>
        </div>
        <SurveyCreatorComponent creator={creator} />
        <Toaster
          toasterMessage={toasterMessage}
          setToasterMessage={setToasterMessage}
        />
      </div>
    </>
  );
};
export default SurveyComponent;
