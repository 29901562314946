/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import APIHelper from "../../utils/config/apiHelper";
import APIURLConstant from "../../utils/config/apiURLConstant";
import MethodAndVariable from "../../utils/config/variableConstant";
import Select from "react-select";
import Validations from "../../utils/config/validations";
import Toaster from "../../sharedComponent/Toaster/Toaster";
import Loader from "../../sharedComponent/Loader/Loader";
import DatePicker from "react-datepicker";
import "../../utils/Common.css";
import { Modal } from "../../sharedComponent";
import TextareaAutosize from "react-textarea-autosize";
import CloseIcon from "../../assets/close-blue.svg";
import { Multiselect } from "multiselect-react-dropdown";
import { AutoComplete } from "../../sharedComponent";
// import TagIcon from "../../assets/tag-button.svg";
import ComponentTagsSelector from "./ComponetTagsSelector";
import ComponentQuestionSelector from "./ComponentQuestionSelector";
import { useLocation } from "react-router-dom";
import { unionBy } from "lodash/array";
import { AppContext } from "../../store/context";
import CategoryAndTypeQuestions from "./CategoryAndTypeQuestions";
import moment from "moment";

const CreateProgram = () => {
  const [programData, setProgramData] = useState(
    MethodAndVariable.setProgram()
  );
  const [agencyAndSponcerError, setAgencyAndSponcerError] = useState({
    agencyError: false,
    sponcerError: false,
    reviewerError: false,
  });
  const [programCategory, setProgramCategory] = useState([]);
  const [selectedProgramCategory, setSelectedProgramCategory] = useState([]);
  const [programType, setProgramType] = useState([]);
  const [isPogramTypeDisabled, setIsPogramTypeDisabled] = useState(false);
  const [selectedProgramType, setSelectedProgramType] = useState([]);
  const [programDuration, setProgramDuration] = useState([]);
  const [selectedProgramDuration, setSelectedProgramDuration] = useState([]);
  const [localProgramName, setLocalProgramName] = useState("");
  const [programDescription, setProgramDescription] = useState("");
  const [overallDateStart, setOverallDateStart] = useState("");
  const [overallDateEnd, setOverallDateEnd] = useState("");
  const [regions, setRegions] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [reviewerList, setReviewerList] = useState([]);
  const [selectedReviewer, setSelectedReviewer] = useState([]);
  const [countryOfProgram, setCountryOfProgram] = useState([]);
  const [stateOfProgram, setStateOfProgram] = useState([]);
  const [selectedCountryOfProgram, setSelectedCountryOfProgram] = useState([]);
  const [selectedStateOfProgram, setSelectedStateOfProgram] = useState([]);
  const [agencyInput, setAgencyInput] = useState("");
  const [agencySuggestions, setAgencySuggesstion] = useState([]);
  const [agencyValue, setAgencyValue] = useState([]);
  const [selectedAgency, setSelectedAgency] = useState([]);
  const [sponcerInput, setSponcerInput] = useState("");
  const [cityInput, setCityInput] = useState("");
  const [sponcerSuggestions, setSponcerSuggesstion] = useState([]);
  const [sponcerValue, setSponcerValue] = useState([]);
  const [selectedSponcer, setSelectedSponcer] = useState([]);
  const [agencyTags, setAgencyTags] = useState([]);
  const [agencyAndSponcerIds, setAgencyAndSponcersIds] = useState({
    agency: "",
    sponcer: "",
  });
  const [isSaveAsDraft, setIsSaveAsDraft] = useState(false);
  const [sponsorInput, setSponsorInput] = useState("");
  const [sponsorTags, setSponsorTags] = useState([]);
  const [toasterMessage, setToasterMessage] = useState({
    showToaster: false,
    errorType: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [componentLoading, setComponentLoading] = useState(false);
  const [isClone, setIsClone] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({
    title: "",
    message: "",
  });
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const createOption = (label) => ({
    label,
    value: label,
  });
  const [cardValue, setCardValue] = useState(0);
  const [
    questionListForSelectedComponent,
    setQuestionListForSelectedComponent,
  ] = useState([]);
  const [showAddQA, setShowAddQA] = useState(false);
  const [categoryTagsList, setCategoryTagsList] = useState([]);
  const [tagInputValue, setTagInputValue] = useState("");
  const [categoryTagsValue, setCategoryTagsValue] = useState([]);
  const [qaSelectorsList, setQaSelectorsList] = useState([]);
  const [qaSelectorsvalue, setQaSelectorValue] = useState([]);
  const [selectedQaSelectors, setSelectedQaSelectors] = useState([]);
  const [showAddComponent, setShowAddComponent] = useState(false);
  const [componentId, setComponentId] = useState(-1);
  const [isComponentValid, setIsComponentValid] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isBackButton, setIsBackButton] = useState(false);
  const [componentList, setComponentList] = useState([]);
  const [selectedComponentTags, setSelectedComponentTags] = useState([]);
  const [selectedComponentList, setSelectedComponentList] = useState([]);
  const [componentSelectorList, setComponentSelectorList] = useState([]);
  const [programId, setProgramId] = useState(0);
  const [index, setIndex] = useState(-1);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [showInEidtMode, setShowInEidtMode] = useState(false);
  const [loadComponentsInEidtMode, setLoadComponentsInEidtMode] =
    useState(false);
  const navigate = useNavigate();
  const {
    userDetails,
    userCategories,
    userRegions,
    dispatchRegions,
    dispatchCategories,
  } = useContext(AppContext);
  const [categoryTypeQuestionIDs, setCategoryTypeQuestionIDs] = useState("");
  const [categoryTypeQuestionList, setCategoryTypeQuestionList] = useState([]);
  const [programStatus, setProgramStatus] = useState(
    MethodAndVariable.VariableConstant.publish
  );

  const location = useLocation();

  useEffect(() => {
    // if (location && location.state && location.state.regions) {
    //   setRegions(location.state.regions);
    // }
    if (userDetails.email != "") {
      getProgramRegions();

      // if (location && location.state && location.state.regions) {
      //   setSelectedRegions(location.state.regions)
      // }
    }
    if (location && location.state && location.state.isClone !== undefined) {
      setIsClone(location.state.isClone);
    } else {
      setIsClone(false);
    }
    // if (userDetails.userID && (location && location.state && !location.state.regions)) {
    //   getProgramRegions();
    // }
    //getProgramCountries();
    //set details in Edit Mode
    if (location && location.state && location.state.selectedProgram) {
      setSelectedProgram(location.state.selectedProgram);
      setShowInEidtMode(true);

      location.state.selectedProgram[0]?.programStatus?.toLowerCase() ===
      MethodAndVariable.VariableConstant.publish.toLocaleLowerCase()
        ? setProgramStatus(MethodAndVariable.VariableConstant.unpublish)
        : setProgramStatus(MethodAndVariable.VariableConstant.publish);
    }
  }, [location.state, userDetails.regionIDs]);

  useEffect(() => {
    if (Object.keys(selectedRegions).length !== 0) {
      getProgramCountries();
    }
  }, [selectedRegions]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      Object.keys(selectedCountryOfProgram).length !== 0 &&
      selectedCountryOfProgram.label === "United States"
    ) {
      getProgramStates();
    }
  }, [selectedCountryOfProgram]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    openProgramInEditMode();
  }, [selectedProgram]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (isClone === true) {
      openProgramInEditMode();
    }
  }, [isClone]);

  useEffect(() => {
    getProgramType();
  }, [selectedProgramCategory]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //if (showAddComponent) getComponentList();
  }, [showAddComponent]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userDetails.roleIDList.length > 0) {
      if (!location.state?.programCategory) {
        getProgramCategory();
      } else if (location.state.fromPage === "programDetailsPage") {
        getProgramCategory();
      } else {
        setProgramCategory(location.state?.programCategory);
      }
      getProgramDurations();
      getAgencyList();
      getSponcerList();
    }
  }, [userDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const getReviewerList = () => {
    setLoading(true);
    const payLoad = {
      programCategoryID: selectedProgramCategory?.value,
      programRegionID: selectedRegions?.value,
    };
    APIHelper.post(APIURLConstant.GET_REVIEWERS_LIST, payLoad)
      .then((res) => {
        console.log(res);
        const reviewers = res.map((item) => {
          return {
            value: item.programReviewerID,
            label: item.reviewerName,
            ...item,
          };
        });
        setReviewerList(reviewers);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProgramRegions = () => {
    if (userRegions === undefined || userRegions?.length === 0) {
      setLoading(true);
      APIHelper.post(APIURLConstant.GET_PROGRAMREGIONS, {
        RegionIDs: userDetails?.regionIDs ? userDetails?.regionIDs : "null",
      })
        .then((res) => {
          if (res) {
            const programRegions = res.map((item) => {
              return {
                value: item.regionID,
                label: item.regionName,
              };
            });
            if (programRegions && programRegions.length > 0) {
              const programRegion = location.state.selectedProgram[0];
              setRegions([...programRegions]);
              if (location && location.state && location.state.regions) {
                console.log(location.state);
                setSelectedRegions({
                  label: programRegion.regionName,
                  value: programRegion.regionID,
                });
                const region = [...programRegions];
                dispatchRegions("ADD_REGIONS", region);
              }
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: `Failed to load Program Regions`,
          });
        });
    } else {
      const regionsUpdated = userRegions ?? [];
      setRegions([...regionsUpdated]);
    }
  };

  const getAgencyList = () => {
    setLoading(true);
    APIHelper.post(APIURLConstant.GET_AGENCY_LIST(userDetails?.email))
      .then((res) => {
        if (res) {
          const agenciesList = res.map((item) => {
            return {
              value: item.agencyID,
              label: item.agencyNames,
            };
          });
          if (agenciesList && agenciesList.length > 0) {
            setAgencySuggesstion([...agenciesList]);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: "Error while getting a agency",
        });
      });
  };

  const getSponcerList = () => {
    setLoading(true);
    APIHelper.post(APIURLConstant.GET_SPONCER_LIST(userDetails?.email))
      .then((res) => {
        if (res) {
          const sponcersList = res.map((item) => {
            return {
              value: item.sponsorID,
              label: item.sponsorNames,
            };
          });
          if (sponcersList && sponcersList.length > 0) {
            setSponcerSuggesstion([...sponcersList]);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: "Error while getting a sponcer",
        });
      });
  };

  useEffect(() => {
    if (isClone !== undefined && isClone === false) {
      if (selectedProgram && selectedProgram.length > 0) {
        if (agencySuggestions && agencySuggestions.length > 0) {
          const agencyData = selectedProgram[0]?.agency;
          const finalData = [];

          agencyData?.length > 0 &&
            agencyData.split(",").map((id) =>
              agencySuggestions.forEach((allDataID) => {
                if (allDataID.value === parseInt(id)) {
                  finalData.push(allDataID);
                }
              })
            );

          setSelectedAgency(finalData);
          setAgencyValue(finalData);
        }
        if (sponcerSuggestions && sponcerSuggestions.length > 0) {
          const sponcerData = selectedProgram[0]?.sponsors;
          const finalData = [];
          sponcerData?.length > 0 &&
            sponcerData.split(",").map((id) =>
              sponcerSuggestions.forEach((allDataID) => {
                if (allDataID.value === parseInt(id)) {
                  finalData.push(allDataID);
                }
              })
            );

          setSelectedSponcer(finalData);
          setSponcerValue(finalData);
        }
      }
    }
  }, [agencySuggestions, sponcerSuggestions, selectedProgram]);

  useEffect(() => {
    if (selectedProgramType !== null && selectedProgramType.label) {
      // let ids = [];
      // for (let i = 0; i < selectedComponentTags.length; i++) {
      //   ids.push(selectedComponentTags[i].tagID);
      // }
      setComponentLoading(true);
      setSelectedComponentList([]);
      APIHelper.post(
        APIURLConstant.GET_COMPONENT_LIST_BY_PROGRAM_CATEGORY_AND_TYPE,
        {
          programCategory: selectedProgramCategory.label,
          programType: selectedProgramType.label,
          componentIDList: isClone ? selectedProgram[0]?.componentList : null,
        }
      )
        .then((res) => {
          let updatedTags = res.map((item) => {
            return {
              label: item.componentName,
              value: item.componentID,
              count: 0,
              ...item,
            };
          });
          if (
            isAllComponentRequired(updatedTags) ||
            (updatedTags && updatedTags.length === 0)
          ) {
            //call API to load all Components
            setComponentLoading(true);
            APIHelper.post(APIURLConstant.GET_COMPONENTSELECTED_LIST, {
              TagIDList: null,
            }).then((res) => {
              updatedTags = res.map((item) => {
                return {
                  label: item.componentName,
                  value: item.componentID,
                  count: 0,
                  ...item,
                };
              });
              if (updatedTags && updatedTags.length > 0) {
                setUpdatedTagsForProcessing(updatedTags);
                setComponentLoading(false);
              }
            });
          } else {
            setUpdatedTagsForProcessing(updatedTags);
            setComponentLoading(false);
          }
          //setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setComponentLoading(false);
        });
    }
  }, [selectedProgramType]);

  const isAllComponentRequired = (updatedTags) => {
    return (
      isClone &&
      (updatedTags.length === 0 ||
        selectedProgram[0]?.componentList === null ||
        updatedTags.length ===
          [
            ...new Set(
              selectedProgram[0]?.componentList
                .split(",")
                .map((item) => item.trim())
            ),
          ].length)
    );
  };

  const setUpdatedTagsForProcessing = (updatedTags) => {
    setComponentSelectorList([...updatedTags]);
    //Edit Mode
    if (selectedProgram && selectedProgram[0]?.componentList) {
      let componentsInEditMode = [];
      selectedProgram[0]?.componentList.split(",").forEach((component) => {
        let componentArr = updatedTags.filter(
          (item) => item.value === parseInt(component)
        )[0];
        if (componentArr !== undefined) {
          componentsInEditMode.push(componentArr);
        }
      });
      // let withoutDuplicates = unionBy(
      //   selectedComponentList,
      //   componentsInEditMode,
      //   "componentID"
      // );
      setSelectedComponentList([...componentsInEditMode]);
      setLoadComponentsInEidtMode(true);
    }
  };

  const handleChangeCategoryTag = (value) => {
    //setSelectedTags(value);
    GetQuestionsByTagIds(value);
    //setErrorMessage(null);
  };

  const openProgramInEditMode = () => {
    if (selectedProgram && selectedProgram.length > 0) {
      //setShowAddComponent(true);

      if (isClone !== undefined && !isClone) {
        setCityInput(
          selectedProgram[0]?.city !== null ? selectedProgram[0]?.city : ""
        );
        setSelectedRegions({
          label: selectedProgram[0].regionName,
          value: selectedProgram[0].regionID,
        });
        setStateData(selectedProgram[0].regionID, "regionID");
        setSelectedCountryOfProgram({
          label: selectedProgram[0].countryName,
          value: selectedProgram[0].countryID,
        });
        setSelectedStateOfProgram({
          label: selectedProgram[0].stateName,
          value: selectedProgram[0].stateID,
        });
        setStateData(selectedProgram[0].countryID, "countryID");
        setStateData(selectedProgram[0].stateID, "stateID");
        setLocalProgramName(selectedProgram[0].localProgramName);
        setStateData(selectedProgram[0].localProgramName, "localProgramName");
        setOverallDateStart(new Date(selectedProgram[0].overallStartDate));
        setStateData(
          new Date(selectedProgram[0].overallStartDate),
          "overallDateStart"
        );
        setOverallDateEnd(new Date(selectedProgram[0].overallEndDate));
        setStateData(
          new Date(selectedProgram[0].overallEndDate),
          "overallDateEnd"
        );
        setAgencyTags(selectedProgram[0].agency.split(";"));
        setStateData(selectedProgram[0].agency, "agency");
        setSponsorTags(selectedProgram[0].sponsors.split(";"));
        setStateData(selectedProgram[0].sponsors, "sponsor");
      } else {
        selectedProgram[0].programStatus = "";
        setProgramStatus(MethodAndVariable.VariableConstant.publish);
        setCityInput("");
        setSelectedRegions([]);
        setStateData("", "regionID");
        setSelectedCountryOfProgram([]);
        setSelectedStateOfProgram([]);
        setStateData("", "countryID");
        setStateData("", "stateID");
        setLocalProgramName("");
        setStateData("", "localProgramName");
        setOverallDateStart("");
        setStateData("", "overallDateStart");
        setOverallDateEnd("");
        setStateData("", "overallDateEnd");
        setAgencyTags([]);
        setStateData("", "agency");
        setSponsorTags([]);
        setStateData("", "sponsor");
        selectedProgram[0].programStatus = "";
        setSelectedAgency([]);
        setAgencyValue([]);
        setSelectedSponcer([]);
        setSponcerValue([]);
      }
      setSelectedProgramCategory({
        label: selectedProgram[0].programCategory,
        value: selectedProgram[0].programCategoryID,
      });

      setStateData(selectedProgram[0].programCategoryID, "programCategoryID");
      getProgramType();
      setSelectedProgramDuration({
        label: selectedProgram[0].duration,
        value: selectedProgram[0].programDurationID,
      });
      setStateData(selectedProgram[0].programDurationID, "programDurationID");
      setProgramDescription(selectedProgram[0].programDescription);
      setStateData(selectedProgram[0].programDescription, "programDescription");

      setStateData(
        isClone !== undefined && isClone ? 0 : selectedProgram[0].programID,
        "programID"
      );
      setIsConfirmed(selectedProgram[0].isConfirmed);
      //if (selectedProgram[0].tagList) setShowAddComponent(true);
      if (selectedProgram[0].componentList) setShowAddComponent(true);
      //setSelectedComponentTags(selectedProgram[0].tagList.split(','))
    }
  };

  const handleChangeQaSelector = (value) => {
    setSelectedQaSelectors(value);
    setQaSelectorValue(value);
  };

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#0D47A1",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#FFFFFF",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "white",
      ":hover": {
        backgroundColor: "#0D47A1",
        color: "white",
      },
    }),
  };

  const GetQuestionsByTagIds = (tags) => {
    setLoading(true);
    let ids = [];
    if (tags !== null && tags !== undefined) {
      for (let i = 0; i < tags.length; i++) {
        ids.push(tags[i].tagID);
      }
      // tags.map((item) => {
      //   ids.push(item.tagID);
      // });
      APIHelper.post(APIURLConstant.GET_QUESTIONS_BY_TAG_IDS, {
        TagIds: ids.join(","),
      })
        .then((res) => {
          const updatedQaSelectors = res.map((item) => {
            return {
              label: item.questionText,
              value: item.questionText,
              ...item,
            };
          });
          if (updatedQaSelectors && updatedQaSelectors.length > 0) {
            // setQaSelectorsList([...updatedQaSelectors]);
            let selectedValue;
            questionListForSelectedComponent[index]?.questionList?.forEach(
              (item) => {
                selectedValue = updatedQaSelectors.filter(
                  (val) => val?.questionID !== item?.questionID
                );
              }
            );
            setQaSelectorsList(selectedValue);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const getTagsList = () => {
    //TODO: Need to remove Hard- code Type, instead we can use Enum.
    setLoading(true);
    APIHelper.post(APIURLConstant.GET_TAGS_BY_TYPE_NAME, {
      Type: MethodAndVariable.VariableConstant.category,
    })
      .then((res) => {
        if (res) {
          const updatedTags = res.map((item) => {
            return {
              label: item.tagName,
              value: item.tagName,
              ...item,
            };
          });
          if (updatedTags && updatedTags.length > 0) {
            setCategoryTagsList([...updatedTags]);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getProgramCategory = () => {
    if (userCategories === undefined || userCategories?.length === 0) {
      setLoading(true);
      const payload = {
        RoleId: userDetails.roleIDList,
        UserID: 0,
      };
      APIHelper.post(APIURLConstant.GET_PROGRAMCATEGORY_BY_USER_ROLE, payload)
        .then((res) => {
          if (res) {
            const programCategory = res.map((item) => {
              return {
                value: item.programCategoryID,
                label: item.category,
              };
            });
            if (programCategory && programCategory.length > 0) {
              setProgramCategory([...programCategory]);
              const category = [...programCategory];

              dispatchCategories("ADD_CATEGORY", category);
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: `Failed to load Program Categories`,
          });
          setLoading(false);
        });
    } else {
      const categoriesUpdated = userCategories ?? [];
      setProgramCategory(categoriesUpdated);
    }
  };

  const changeProgramStatus = (programID, status) => {
    setLoading(true);
    const payload = {
      ProgramIds: programID.toString(),
      Status: status,
    };
    APIHelper.post(APIURLConstant.CHANGE_PROGRAM_STATUS, payload)
      .then((res) => {
        setLoading(false);
        setProgramStatus(MethodAndVariable.VariableConstant.publish);
        setToasterMessage({
          showToaster: true,
          errorType: "success",
          message:
            programStatus?.toLowerCase() ===
            MethodAndVariable.VariableConstant.publish.toLocaleLowerCase()
              ? `Program successfully published  `
              : "Program successfully unpublished",
        });
        updateNotification(
          programID,
          "Program Publish/Unpublish Notification",
          false,
          true
        );

        if (
          (location.state &&
            location.state?.selectedProgram !== undefined &&
            location.state?.selectedProgram[0]?.programStatus?.toLowerCase() ===
              programStatus?.toLowerCase()) ||
          (location.state &&
            location.state?.selectedProgram &&
            location.state?.selectedProgram[0]?.isConfirmed !== isConfirmed)
        ) {
          if (
            isConfirmed &&
            programStatus?.toLowerCase() ===
              MethodAndVariable.VariableConstant.publish.toLocaleLowerCase()
          ) {
            updateNotification(
              programID,
              "Program Date Confirm Notification",
              false,
              false
            );
          }
          if (!isConfirmed) {
            updateNotification(
              programID,
              "Program Date Confirm Notification",
              true,
              false
            );
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message:
            programStatus?.toLowerCase() ===
            MethodAndVariable.VariableConstant.publish.toLocaleLowerCase()
              ? `Error in publishing the program  `
              : "Error in unpublishing the program",
        });
      });
  };

  const getProgramType = () => {
    setProgramType([]);
    setSelectedProgramType([]);
    setStateData("", "programTypeID");
    if (selectedProgramCategory && selectedProgramCategory.value) {
      setLoading(true);
      APIHelper.post(APIURLConstant.GET_PROGRAMTYPE, {
        ProgramCategoryID: selectedProgramCategory.value,
      })
        .then((res) => {
          if (res) {
            const programType = res.map((item) => {
              return {
                value: item.programTypeID,
                label: item.type,
              };
            });
            if (programType && programType.length > 0) {
              setProgramType([...programType]);
              setIsPogramTypeDisabled(false);
              if (showInEidtMode) {
                const programTypeInEditMode = programType.filter(
                  (item) => item.value === selectedProgram[0].programTypeID
                );
                if (programTypeInEditMode?.length > 0) {
                  setSelectedProgramType(programTypeInEditMode[0]);
                  setStateData(programTypeInEditMode[0].value, "programTypeID");
                  getQuestions(
                    selectedProgramCategory.value,
                    programTypeInEditMode[0].value
                  );
                }
              }
            } else {
              setIsPogramTypeDisabled(true);
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: `Failed to load Program Types`,
          });
          setLoading(false);
        });
    }
  };

  const getProgramDurations = () => {
    setLoading(true);
    APIHelper.get(APIURLConstant.GET_PROGRAMDURATIONS())
      .then((res) => {
        if (res) {
          const programDurations = res.map((item) => {
            return {
              value: item.programDurationID,
              label: item.duration,
            };
          });
          if (programDurations && programDurations.length > 0) {
            setProgramDuration([...programDurations]);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: `Failed to load Program Durations`,
        });
      });
  };

  const getProgramStates = () => {
    setLoading(true);
    APIHelper.post(APIURLConstant.GET_PROGRAMSTATES_BY_COUNTRY, {
      CountryID: selectedCountryOfProgram.value,
    })
      .then((res) => {
        if (res) {
          const countriesByRegion = res.filter(
            (country) => country.regionID === selectedRegions.value
          );
          const otherCountries = res.filter(
            (country) => country.regionID !== selectedRegions.value
          );

          const programStates = res.map((item) => {
            return {
              value: item.stateID,
              label: item.stateName,
            };
          });
          if (programStates && programStates.length > 0) {
            setStateOfProgram([...programStates]);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: `Failed to load Program Countries`,
        });
      });
  };

  const getProgramCountries = () => {
    if (selectedRegions.value != undefined) {
      setLoading(true);
      APIHelper.get(
        APIURLConstant.GET_PROGRAMCOUNTRIES_BY_REGION(selectedRegions.value)
      )
        .then((res) => {
          if (res) {
            const countriesByRegion = res.filter(
              (country) => country.regionID === selectedRegions.value
            );
            const otherCountries = res.filter(
              (country) => country.regionID !== selectedRegions.value
            );

            const programCountries = [
              ...countriesByRegion,
              ...otherCountries,
            ].map((item) => {
              return {
                value: item.countryID,
                label: item.countryName,
              };
            });
            if (programCountries && programCountries.length > 0) {
              setCountryOfProgram([...programCountries]);
            }
            if (
              ["United States", "Mexico", "Brazil", "Canada"].includes(
                countriesByRegion[0].countryName
              )
            ) {
              setSelectedCountryOfProgram({
                label: countriesByRegion[0].countryName,
                value: countriesByRegion[0].countryID,
              });
              setStateData(countriesByRegion[0].countryID, "countryID");
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: `Failed to load Program Countries`,
          });
        });
    }
  };

  const onSelectChange = (e, type) => {
    switch (type) {
      case "programCategoryID":
        setSelectedProgramCategory(e);
        break;
      case "programTypeID":
        setSelectedProgramType(e);
        getQuestions(selectedProgramCategory.value, e.value);
        break;
      case "programDurationID":
        setSelectedProgramDuration(e);
        break;
      case "regionID":
        setSelectedRegions(e);
        setSelectedCountryOfProgram([]);
        setSelectedStateOfProgram([]);
        break;
      case "countryID":
        setSelectedCountryOfProgram(e);
        setSelectedStateOfProgram([]);
        break;
      case "stateID":
        setSelectedStateOfProgram(e);
      default:
        break;
    }
    setStateData(e.value, type);
  };

  const setInputStateData = (e, type) => {
    e.preventDefault();
    switch (type) {
      case "localProgramName":
        setLocalProgramName(e.target.value);
        break;
      case "programDescription":
        setProgramDescription(e.target.value);
        break;
      default:
        break;
    }
    setStateData(e.target.value, type);
  };

  const setStateData = (value, type) => {
    const completeProgramData = programData;
    completeProgramData[type].value = value;
    completeProgramData[type].validationClass = "";
    completeProgramData[type].validationMessage = "";
    setProgramData(completeProgramData);
  };

  const handleNextBtn = (cardValue) => {
    if (cardValue === 0 && showAddComponent && !isComponentValid) {
      setToasterMessage({
        showToaster: true,
        errorType: "error",
        message: `Please select Componet Tag and Selector`,
      });
    } else {
      setCardValue(1);
      //if (selectedComponentList?.length > 0)
      //getQuestionListForSelectedComponent();
    }
  };

  const handleChangeCheckBox = (value, item, index) => {
    const updatedList = questionListForSelectedComponent.filter((data) => {
      if (
        item.componentID === data.componentID &&
        item.sequence === data.sequence
      ) {
        data.questionList[index]["isChecked"] = value;
      }
      return item;
    });
    setQuestionListForSelectedComponent(updatedList);
  };

  const handleRemoveComponent = (item) => {
    setSelectedComponentList(
      selectedComponentList.filter(
        (component) => item.componentID !== component.componentID
      )
    );
    setQuestionListForSelectedComponent(
      questionListForSelectedComponent.filter(
        (component) => item.componentID !== component.componentID
      )
    );
  };

  // get Question list for selected Component

  const getQuestionListForSelectedComponent = () => {
    setLoading(true);
    let selectedComponentIds = [];
    selectedComponentList.forEach((item) => {
      selectedComponentIds.push(item.componentID);
    });
    ////TODO- No need to call API multipletimes if SAME component is selected
    //const isComponentQuestionAvailable = questionListForSelectedComponent.filter((item)=> {return questionListForSelectedComponent.includes(item)} ).length > 0 ? true : false
    //if (!isComponentQuestionAvailable) {
    APIHelper.post(APIURLConstant.GET_QUESTIONS_FOR_SELECTED_COMPONENT, {
      ComponentIDList: selectedComponentIds.join(","),
      ProgramId:
        showInEidtMode && selectedProgram.length > 0
          ? selectedProgram[0].programID
          : 0,
    })
      .then((res) => {
        let filteredArray = [];
        const seen = new Set();
        res.forEach((el) => {
          const duplicate = seen.has(el.componentID);
          seen.add(el.componentID);
          if (!duplicate) {
            const obj = {
              componentID: el.componentID,
              componentName: el.componentName,
              questionList: [],
              sequence: el.sequence,
            };
            filteredArray.push(obj);
          }
        });
        res.forEach((item) => {
          filteredArray.forEach((data, index) => {
            if (
              item.componentID === data.componentID &&
              item.sequence === data.sequence
            ) {
              const qusObj = {
                questionID: item.questionID,
                questionText: item.questionText,
                isMandatory: item.isMandatory,
                isDeleted: item.isDeleted,
                isActive: item.isActive,
                answerTypeID: item.answerTypeID,
                answerOptions: item.answerOptions,
                createdBy: item.createdBy,
                createdDate: item.createdDate,
                modifiedBy: item.modifiedBy,
                modifiedDate: item.modifiedDate,
                details: item.details,
                srNumber: item.srNumber,
                tagList: item.tagList,
                answerType: item.answerType,
                isChecked:
                  item.isSelected && item.isSelected === true
                    ? true
                    : item.isSelected === null || item.isSelected === undefined
                    ? true
                    : false,
              };
              if (
                questionListForSelectedComponent.find(
                  (k) => k.componentID === item.componentID
                ) !== undefined
              ) {
                const newQuestionList = questionListForSelectedComponent.find(
                  (k) => k.componentID === item.componentID
                )["questionList"];
                filteredArray[index]["questionList"] = [...newQuestionList];
              } else {
                filteredArray[index]["questionList"] = [
                  ...data.questionList,
                  qusObj,
                ];
              }
            }
          });
        });
        const newFilteredArray = addDuplicateComponentQuestions(
          selectedComponentIds,
          filteredArray
        );
        setQuestionListForSelectedComponent(newFilteredArray);
        //setQuestionListForSelectedComponent(filteredArray);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    // }
    // else {
    //   const newFilteredArray = addDuplicateComponentQuestions(
    //     selectedComponentIds,
    //     questionListForSelectedComponent.filter((item)=> {return questionListForSelectedComponent.includes(item)} )
    //   );
    //   setQuestionListForSelectedComponent(newFilteredArray);
    // }
  };

  const addDuplicateComponentQuestions = (
    selectedComponentIds,
    filteredArray
  ) => {
    let componentQuestions = [];
    selectedComponentIds.forEach((component) => {
      let duplicateComponent = {};
      duplicateComponent = filteredArray.filter(
        (item) => item.componentID === component
      );
      const clone = structuredClone(duplicateComponent);
      let compCount = componentQuestions.filter(
        (compQues) => compQues.componentID === clone[0].componentID
      ).length;
      clone[0]["sequence"] = compCount === 0 ? 1 : compCount + 1; //componentQuestions.filter((compQues)=> compQues.componentID === duplicateComponent.componentID && !(sequence in compQues)).length + 1
      componentQuestions.push(clone[0]);
    });
    // for (let i = 0; i < componentQuestions.length; i++) {
    //   componentQuestions[i][0]['sequence'] = i + 1
    // }
    return componentQuestions;
  };

  const handleDateChange = (value, dateType) => {
    if (dateType === "overallDateStart") {
      if (overallDateEnd && overallDateEnd < value) {
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: `Over all start date should be less than end date`,
        });
      } else {
        setOverallDateStart(value);
        setStateData(value, "overallDateStart");
      }
    } else {
      if (overallDateStart && overallDateStart > value) {
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: `Over all end date should be greater than start date`,
        });
      } else {
        setOverallDateEnd(value);
        setStateData(value, "overallDateEnd");
      }
    }
  };

  const goToComponent = () => {
    setShowModal(false);
    setCardValue(0);
  };

  const handleClose = () => {
    setShowModal(false);
    setCardValue(0);
  };

  const saveADraft = () => {
    setLoading(true);
    if (validateForm()) {
      // alert('Success');
      setIsSaveAsDraft(true);
      createAgencyAndSponcerIds();
    } else {
      // alert('Fail');
      setLoading(false);
      if (cardValue === 1) {
        setShowModal(true);
        setModalMessage({
          title: "Validation Failed",
          message: "Please go to Main Form and fill missing data!",
        });
      } else {
        setToasterMessage({
          errorType: "error",
          showToaster: true,
          message: "Please fill the required fields*",
        });
      }
    }
  };

  const validateForm = () => {
    let stateData = programData;
    let isFormValid = true;
    let validationResult;

    if (selectedAgency.length === 0) {
      agencyAndSponcerError.agencyError = true;
      isFormValid = false;
    }
    if (selectedSponcer.length === 0) {
      agencyAndSponcerError.sponcerError = true;
      isFormValid = false;
    }
    // if (selectedReviewer.length === 0) {
    //   agencyAndSponcerError.reviewerError = true;
    //   isFormValid = false;
    // }
    // validationResult = Validations.Validate(stateData.sponsor.value, {
    //   Required: true,
    // });
    // if (!validationResult.isValid) {
    //   isFormValid = validationResult.isValid;
    //   stateData.sponsor.validationMessage = validationResult.validationMessage;
    //   stateData.sponsor.validationClass = "error";
    // }

    // validationResult = Validations.Validate(stateData.agency.value, {
    //   Required: true,
    // });
    // if (!validationResult.isValid) {
    //   isFormValid = validationResult.isValid;
    //   stateData.agency.validationMessage = validationResult.validationMessage;
    //   stateData.agency.validationClass = "error";
    // }

    validationResult = Validations.Validate(stateData.countryID.value, {
      Required: true,
    });
    if (!validationResult.isValid) {
      isFormValid = validationResult.isValid;
      stateData.countryID.validationMessage =
        validationResult.validationMessage;
      stateData.countryID.validationClass = "error";
    }

    validationResult = Validations.Validate(stateData.regionID.value, {
      Required: true,
    });
    if (!validationResult.isValid) {
      isFormValid = validationResult.isValid;
      stateData.regionID.validationMessage = validationResult.validationMessage;
      stateData.regionID.validationClass = "error";
    }

    validationResult = Validations.Validate(stateData.overallDateStart.value, {
      Required: true,
    });
    if (!validationResult.isValid) {
      isFormValid = validationResult.isValid;
      stateData.overallDateStart.validationMessage =
        validationResult.validationMessage;
      stateData.overallDateStart.validationClass = "error";
    }

    validationResult = Validations.Validate(stateData.overallDateEnd.value, {
      Required: true,
    });
    if (!validationResult.isValid) {
      isFormValid = validationResult.isValid;
      stateData.overallDateEnd.validationMessage =
        validationResult.validationMessage;
      stateData.overallDateEnd.validationClass = "error";
    }

    validationResult = Validations.Validate(
      stateData.programDescription.value,
      { Required: true }
    );
    if (!validationResult.isValid) {
      isFormValid = validationResult.isValid;
      stateData.programDescription.validationMessage =
        validationResult.validationMessage;
      stateData.programDescription.validationClass = "error";
    }

    validationResult = Validations.Validate(stateData.localProgramName.value, {
      Required: true,
    });
    if (!validationResult.isValid) {
      isFormValid = validationResult.isValid;
      stateData.localProgramName.validationMessage =
        validationResult.validationMessage;
      stateData.localProgramName.validationClass = "error";
    }

    validationResult = Validations.Validate(stateData.programDurationID.value, {
      Required: true,
    });
    if (!validationResult.isValid) {
      isFormValid = validationResult.isValid;
      stateData.programDurationID.validationMessage =
        validationResult.validationMessage;
      stateData.programDurationID.validationClass = "error";
    }

    if (!isPogramTypeDisabled) {
      validationResult = Validations.Validate(stateData.programTypeID.value, {
        Required: true,
      });
      if (!validationResult.isValid) {
        isFormValid = validationResult.isValid;
        stateData.programTypeID.validationMessage =
          validationResult.validationMessage;
        stateData.programTypeID.validationClass = "error";
      }
    }

    validationResult = Validations.Validate(stateData.programCategoryID.value, {
      Required: true,
    });
    if (!validationResult.isValid) {
      isFormValid = validationResult.isValid;
      stateData.programCategoryID.validationMessage =
        validationResult.validationMessage;
      stateData.programCategoryID.validationClass = "error";
    }

    setProgramData(stateData);
    return isFormValid;
  };

  const saveProgramAsDraft = () => {
    setLoading(true);
    const endDateMonth =
      (new Date(programData.overallDateEnd.value)?.getMonth() + 1).toString()
        .length === 2
        ? new Date(programData.overallDateEnd.value)?.getMonth() + 1
        : `0${new Date(programData.overallDateEnd.value)?.getMonth() + 1}`;

    const startDateMonth =
      (new Date(programData.overallDateStart.value)?.getMonth() + 1).toString()
        .length === 2
        ? new Date(programData.overallDateStart.value)?.getMonth() + 1
        : `0${new Date(programData.overallDateStart.value)?.getMonth() + 1}`;
    const programObj = {
      ProgramID:
        isClone !== undefined && isClone ? 0 : programData.programID.value,
      LocalProgramName: programData.localProgramName.value,
      ProgramCategoryID: programData.programCategoryID.value
        ? Number(programData.programCategoryID.value)
        : null,
      ProgramTypeID: programData.programTypeID.value
        ? Number(programData.programTypeID.value)
        : null,
      ProgramDurationID: programData.programDurationID.value
        ? Number(programData.programDurationID.value)
        : null,
      // LeagueEventID: programData.programCategoryID.value,
      ProgramDescription: programData.programDescription.value,
      // OverallStartDate: programData.overallDateStart.value
      //   ? `${new Date(programData.overallDateStart.value)?.getFullYear()}-0${
      //       new Date(programData.overallDateStart.value)?.getMonth() + 1
      //     }-${
      //       new Date(programData.overallDateStart.value)?.getDate().toString()
      //         .length === 1
      //         ? +"0" + new Date(programData.overallDateStart.value)?.getDate()
      //         : new Date(programData.overallDateStart.value)?.getDate()
      //     }`.toString()
      //   : null,
      // OverallEndDate: programData.overallDateEnd.value
      //   ? `${new Date(programData.overallDateEnd.value)?.getFullYear()}-0${
      //       new Date(programData.overallDateEnd.value)?.getMonth() + 1
      //     }-${
      //       new Date(programData.overallDateEnd.value)?.getDate().toString()
      //         .length === 1
      //         ? +"0" + new Date(programData.overallDateEnd.value)?.getDate()
      //         : new Date(programData.overallDateEnd.value)?.getDate()
      //     }`.toString()
      //   : null,
      // OverallStartDate: programData.overallDateStart.value
      //   ? `${new Date(programData.overallDateStart.value)?.getFullYear()}-0${
      //       new Date(programData.overallDateStart.value)?.getMonth() + 1
      //     }-${(
      //       "0" + new Date(programData.overallDateStart.value).getDate()
      //     ).slice(-2)}`.toString()
      //   : null,
      OverallStartDate: programData.overallDateStart.value
        ? `${new Date(
            programData.overallDateStart.value
          )?.getFullYear()}-${startDateMonth}-${(
            "0" + new Date(programData.overallDateStart.value).getDate()
          ).slice(-2)}`.toString()
        : null,
      OverallEndDate: programData.overallDateEnd.value
        ? `${new Date(
            programData.overallDateEnd.value
          )?.getFullYear()}-${endDateMonth}-${(
            "0" + new Date(programData.overallDateEnd.value).getDate()
          ).slice(-2)}`.toString()
        : null,
      // OverallEndDate: programData.overallDateEnd.value
      //   ? `${new Date(programData.overallDateEnd.value)?.getFullYear()}-0${
      //       new Date(programData.overallDateEnd.value)?.getMonth() + 1
      //     }-${(
      //       "0" + new Date(programData.overallDateEnd.value).getDate()
      //     ).slice(-2)}`.toString()
      //   : null,
      RegionID: programData.regionID.value
        ? Number(programData.regionID.value)
        : null,
      CountryID: programData.countryID.value
        ? Number(programData.countryID.value)
        : null,
      StateID: programData.stateID.value
        ? Number(programData.stateID.value)
        : null,
      Agency: agencyAndSponcerIds.agency ?? "",
      Sponsors: agencyAndSponcerIds.sponcer ?? "",
      CreatedBy: userDetails.userID,
      CreatedDate: programData.createdDate.value,
      ModifiedBy: userDetails.userID,
      ModifiedDate: programData.modifiedDate.value,
      Status:
        !isClone && selectedProgram?.length > 0
          ? selectedProgram[0].programStatus
          : programData.status.value,
      CategoryTypeQuestionIDs: categoryTypeQuestionIDs,
      IsConfirmed: isConfirmed,
      // ComponentTagID: programData.componentTagID.value
      city: cityInput,
      // programReviewerID: selectedReviewer?.value,
    };

    createProgram(
      programObj,
      selectedProgram?.length > 0
        ? isClone !== undefined && isClone
          ? "Program cloned successfully"
          : "Program updated successfully"
        : "Program successfully drafted"
    );
  };

  const publishProgram = () => {
    setLoading(true);

    const endDateMonth =
      (new Date(programData.overallDateEnd.value)?.getMonth() + 1).toString()
        .length === 2
        ? new Date(programData.overallDateEnd.value)?.getMonth() + 1
        : `0${new Date(programData.overallDateEnd.value)?.getMonth() + 1}`;

    const startDateMonth =
      (new Date(programData.overallDateStart.value)?.getMonth() + 1).toString()
        .length === 2
        ? new Date(programData.overallDateStart.value)?.getMonth() + 1
        : `0${new Date(programData.overallDateStart.value)?.getMonth() + 1}`;
    const programObj = {
      ProgramID:
        isClone !== undefined && isClone ? 0 : programData.programID.value,
      LocalProgramName: programData.localProgramName.value,
      ProgramCategoryID: programData.programCategoryID.value
        ? Number(programData.programCategoryID.value)
        : null,
      ProgramTypeID: programData.programTypeID.value
        ? Number(programData.programTypeID.value)
        : null,
      ProgramDurationID: programData.programDurationID.value
        ? Number(programData.programDurationID.value)
        : null,
      // LeagueEventID: programData.programCategoryID.value,
      ProgramDescription: programData.programDescription.value,
      OverallStartDate: programData.overallDateStart.value
        ? `${new Date(
            programData.overallDateStart.value
          )?.getFullYear()}-${startDateMonth}-${(
            "0" + new Date(programData.overallDateStart.value).getDate()
          ).slice(-2)}`.toString()
        : null,
      OverallEndDate: programData.overallDateEnd.value
        ? `${new Date(
            programData.overallDateEnd.value
          )?.getFullYear()}-${endDateMonth}-${(
            "0" + new Date(programData.overallDateEnd.value).getDate()
          ).slice(-2)}`.toString()
        : null,
      // OverallStartDate: programData.overallDateStart.value
      //   ? `${new Date(programData.overallDateStart.value)?.getFullYear()}-0${
      //       new Date(programData.overallDateStart.value)?.getMonth() + 1
      //     }-${(
      //       "0" + new Date(programData.overallDateStart.value).getDate()
      //     ).slice(-2)}`.toString()
      //   : // ${new Date(programData.overallDateStart.value)?.getDate().toString()
      //     //   .length === 1
      //     //   ? +"0" + new Date(programData.overallDateStart.value)?.getDate()
      //     //   : new Date(programData.overallDateStart.value)?.getDate()
      //     // }
      //     null,
      // OverallEndDate: programData.overallDateEnd.value
      //   ? `${new Date(programData.overallDateEnd.value)?.getFullYear()}-0${
      //       new Date(programData.overallDateEnd.value)?.getMonth() + 1
      //     }-${(
      //       "0" + new Date(programData.overallDateEnd.value).getDate()
      //     ).slice(-2)}`.toString()
      //   : // ${new Date(programData.overallDateEnd.value)?.getDate().toString()
      //     //   .length === 1
      //     //   ? +"0" + new Date(programData.overallDateEnd.value)?.getDate()
      //     //   : new Date(programData.overallDateEnd.value)?.getDate()
      //     // }
      //     null,
      // OverallStartDate: programData.overallDateStart.value
      //   ? `${new Date(programData.overallDateStart.value)?.getFullYear()}-0${new Date(programData.overallDateStart.value)?.getMonth() + 1
      //     }-${new Date(programData.overallDateStart.value)?.getDate().toString()
      //       .length === 1
      //       ? +"0" + new Date(programData.overallDateStart.value)?.getDate()
      //       : new Date(programData.overallDateStart.value)?.getDate()
      //     }`.toString()
      //   : null,
      // OverallEndDate: programData.overallDateEnd.value
      //   ? `${new Date(programData.overallDateEnd.value)?.getFullYear()}-0${new Date(programData.overallDateEnd.value)?.getMonth() + 1
      //     }-${new Date(programData.overallDateEnd.value)?.getDate().toString()
      //       .length === 1
      //       ? +"0" + new Date(programData.overallDateEnd.value)?.getDate()
      //       : new Date(programData.overallDateEnd.value)?.getDate()
      //     }`.toString()
      //   : null,
      RegionID: programData.regionID.value
        ? Number(programData.regionID.value)
        : null,
      CountryID: programData.countryID.value
        ? Number(programData.countryID.value)
        : null,
      StateID: programData.stateID.value
        ? Number(programData.stateID.value)
        : null,
      Agency: agencyAndSponcerIds.agency ?? "",
      Sponsors: agencyAndSponcerIds.sponcer ?? "",
      CreatedBy: userDetails.userID,
      CreatedDate: programData.createdDate.value,
      ModifiedBy: userDetails.userID,
      ModifiedDate: programData.modifiedDate.value,
      Status: programStatus,
      CategoryTypeQuestionIDs: categoryTypeQuestionIDs,
      // ComponentTagID: programData.componentTagID.value
      city: cityInput,
      // programReviewerID: selectedReviewer?.value,
    };

    createProgram(programObj, "Program successfully published");
  };

  const createProgram = (programObj, message) => {
    APIHelper.post(APIURLConstant.CREATE_PROGRAM, programObj)
      .then((data) => {
        setToasterMessage({
          showToaster: true,
          errorType: "success",
          message: message,
        });
        // //call API to store extra-configurations
        // //first we need to update ProgramID in extra-config state
        // let requestObj = {
        //   answers: [],
        // };
        // extraConfiguration.forEach((config) => {
        //   config["programID"] = data;
        //   setProgramId(data);
        //   requestObj.answers.push({ ...config });
        // });
        // if (requestObj.answers && requestObj.answers.length > 0) {
        //   //call API here...
        //   APIHelper.post(
        //     APIURLConstant.CREATE_ANSWERS_FOR_EXTRACONFIGS,
        //     requestObj
        //   )
        //     .then((data) => {
        //       setToasterMessage({
        //         showToaster: true,
        //         errorType: "success",
        //         message: `Program Updated Successfully With Extra Configurations`,
        //       });
        //     })
        //     .catch((err) => {
        //       setToasterMessage({
        //         showToaster: true,
        //         errorType: "error",
        //         message: `Failed to Update Program With Extra Configurations`,
        //       });
        //       console.log(err);
        //       setLoading(false);
        //     });
        // }

        handleFinshProgram(
          data,
          (selectedProgram !== null &&
            selectedProgram[0]?.programStatus ===
              MethodAndVariable.VariableConstant.publish) ||
            (selectedProgram !== null &&
              selectedProgram[0]?.programStatus ===
                MethodAndVariable.VariableConstant.unpublish)
            ? "/program-list"
            : selectedProgram === null
            ? "/program-list"
            : "/my-programs"
        );

        let isRedirect = true;
        if (message === "Program successfully published") {
          isRedirect = false;
          updateNotification(
            data,
            "Program Publish/Unpublish Notification",
            false,
            true
          );
        } else if (message === "Program updated successfully") {
          if (
            userDetails.userID ===
              location.state?.selectedProgram[0]?.createdBy &&
            location.state?.selectedProgram[0]?.programState ===
              "Needs additional Information"
          ) {
            updateNotification(
              data,
              "Program Feedback In Review Notification",
              false,
              true
            );
          } else {
            updateNotification(data, "Program Edit Notification", false, true);
          }
        }

        if (
          (location.state &&
            location.state?.selectedProgram !== undefined &&
            location.state?.selectedProgram[0]?.programStatus?.toLowerCase() ===
              programStatus?.toLowerCase()) ||
          (location.state &&
            location.state?.selectedProgram !== undefined &&
            location.state?.selectedProgram[0]?.isConfirmed !== isConfirmed)
        ) {
          if (isConfirmed) {
            resetForm();
            updateNotification(
              data,
              "Program Date Confirm Notification",
              false,
              false
            );
          } else {
            resetForm();
            updateNotification(
              data,
              "Program Date Confirm Notification",
              true,
              false
            );
          }
        } else {
          if (isRedirect) {
            if (
              (selectedProgram !== null &&
                selectedProgram[0]?.programStatus ===
                  MethodAndVariable.VariableConstant.publish) ||
              (selectedProgram !== null &&
                selectedProgram[0]?.programStatus ===
                  MethodAndVariable.VariableConstant.unpublish)
            ) {
              navigate("/program-list");
            } else {
              navigate("/my-programs");
            }
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: `Failed to Create Program`,
        });
        console.log(err);
        setLoading(false);
      });
  };

  const resetForm = () => {
    setSelectedProgramCategory([]);
    setProgramType([]);
    setSelectedProgramType([]);
    setSelectedProgramDuration([]);
    setLocalProgramName("");
    setProgramDescription("");
    setOverallDateStart("");
    setOverallDateEnd("");
    setSelectedRegions([]);
    setSelectedCountryOfProgram([]);
    setSelectedStateOfProgram([]);
    setAgencyInput("");
    setSponsorInput("");
    setAgencyTags([]);
    setSponsorTags([]);
    setComponentList([]);
    setComponentSelectorList([]);
    setSelectedComponentList([]);
    setSelectedComponentTags([]);
    setCardValue(0);
    setIsConfirmed(false);
    const newProgramData = MethodAndVariable.setProgram();
    setProgramData(newProgramData);
  };

  const createAgencyAndSponcerIds = async () => {
    let agencyIds = "";
    //Comma separated TagIds we need for creation of Question
    const existingAgencies = selectedAgency.filter((item) => {
      return !item.__isNew__;
    });
    existingAgencies.forEach((item) => {
      agencyIds += "," + item.value;
    });
    //Insert new tags into DB and fetch Ids.
    const newAgencies = selectedAgency.filter((tag) => {
      return tag.__isNew__;
    });
    let sponcersIds = "";
    //Comma separated TagIds we need for creation of Question
    const existingSponcers = selectedSponcer.filter((item) => {
      return !item.__isNew__;
    });
    existingSponcers.forEach((item) => {
      sponcersIds += "," + item.value;
    });
    //Insert new tags into DB and fetch Ids.
    const newSponcers = selectedSponcer.filter((tag) => {
      return tag.__isNew__;
    });

    if (newAgencies && newAgencies.length > 0) {
      let agencyNames = "";
      newAgencies.forEach((item) => {
        agencyNames += "," + item.value;
      });

      //remove first comma
      agencyNames = agencyNames.slice(1);
      const payLoad = {
        agencyID: 0,
        agencyNames: agencyNames,
        createdBy: userDetails?.userID,
      };

      setLoading(true);
      await APIHelper.post(APIURLConstant.INSERT_AGENCY, payLoad)
        .then((res) => {
          agencyIds = agencyIds.slice(1);
          agencyIds += "," + res;
          agencyAndSponcerIds.agency = agencyIds;
          setAgencyAndSponcersIds({
            ...agencyAndSponcerIds,
          });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      agencyAndSponcerIds.agency = agencyIds;
      setAgencyAndSponcersIds({
        ...agencyAndSponcerIds,
      });
    }
    if (newSponcers && newSponcers.length > 0) {
      let sponcersName = "";
      newSponcers.forEach((item) => {
        sponcersName += "," + item.value;
      });

      //remove first comma
      sponcersName = sponcersName.slice(1);
      const payLoad = {
        sponsorID: 0,
        sponsorNames: sponcersName,
        createdBy: userDetails?.userID,
      };

      setLoading(true);
      await APIHelper.post(APIURLConstant.INSERT_SPONCER, payLoad)
        .then((res) => {
          sponcersIds = sponcersIds.slice(1);
          sponcersIds += "," + res;
          agencyAndSponcerIds.sponcer = sponcersIds;
          setAgencyAndSponcersIds({
            ...agencyAndSponcerIds,
          });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      agencyAndSponcerIds.sponcer = sponcersIds;
      setAgencyAndSponcersIds({
        ...agencyAndSponcerIds,
      });
    }
  };

  const onClickpublishProgram = async () => {
    if (validateForm()) {
      createAgencyAndSponcerIds();
    } else {
      setToasterMessage({
        errorType: "error",
        showToaster: true,
        message: "Please fill the required fields*",
      });
      setCardValue(0);
    }
  };

  useEffect(() => {
    if (
      agencyAndSponcerIds.agency !== "" &&
      agencyAndSponcerIds.sponcer !== ""
    ) {
      if (isSaveAsDraft) {
        saveProgramAsDraft();
        setIsSaveAsDraft(false);
      } else {
        handlePublishProgram();
      }
    }
  }, [agencyAndSponcerIds]);

  useEffect(() => {
    if (selectedComponentList?.length > 0 && !isBackButton) {
      getQuestionListForSelectedComponent();
    }
  }, [selectedComponentList]);

  const handlePublishProgram = () => {
    // if (!isClone && selectedProgram?.length > 0) {
    //   changeProgramStatus(selectedProgram[0]?.programID, programStatus);
    // } else if (isClone || selectedProgram === null) {
    //   publishProgram();
    // }
    publishProgram();
  };

  const onBlur = (e, type) => {
    switch (type) {
      case "agency":
        if (agencyInput.length > 0 && agencyTags && agencyTags.length < 1) {
          setShowModal(true);
          setModalMessage({
            title: type,
            message: `Please press enter to add ${type}!`,
          });
          setAgencyInput("");
        }
        break;
      case "sponsor":
        if (sponsorInput.length > 0 && sponsorTags && sponsorTags.length < 1) {
          setShowModal(true);
          setModalMessage({
            title: type,
            message: `Please press enter to add ${type}!`,
          });
          setSponsorInput("");
        }
        break;
      default:
        break;
    }
  };

  const onChange = (e, type) => {
    const { value } = e.target;
    switch (type) {
      case "agency":
        //setAgency(value)
        setAgencyInput(value);
        break;
      case "sponsor":
        //setSponsor(value);
        setSponsorInput(value);
        break;
      default:
        break;
    }
  };

  const onKeyDown = (e, type) => {
    const { key } = e;
    switch (type) {
      case "agency":
        const trimmedInput = agencyInput.trim();
        if (key === "Enter" && trimmedInput.length) {
          e.preventDefault();
          setAgencyTags((prevState) => [...prevState, trimmedInput]);
          setStateData([...agencyTags, trimmedInput].join(";"), type);
          setAgencyInput("");
        } else if (agencyTags.includes(trimmedInput)) {
          //e.preventDefault();
          //setAgencyInput("");
        }
        break;
      case "sponsor":
        const trimmedSponsorInput = sponsorInput.trim();
        if (key === "Enter" && trimmedSponsorInput.length) {
          e.preventDefault();
          setSponsorTags((prevState) => [...prevState, trimmedSponsorInput]);
          setStateData([...sponsorTags, trimmedSponsorInput].join(";"), type);
          setSponsorInput("");
        } else if (sponsorTags.includes(trimmedSponsorInput)) {
          //e.preventDefault();
          //setSponsorInput("");
        }
        break;
      default:
        break;
    }
  };

  const deleteTag = (e, index, type) => {
    switch (type) {
      case "agency":
        setAgencyTags((prevState) => prevState.filter((tag, i) => i !== index));
        e.preventDefault();
        break;
      case "sponsor":
        setSponsorTags((prevState) =>
          prevState.filter((tag, i) => i !== index)
        );
        e.preventDefault();
        break;
      default:
        break;
    }
  };

  const getComponentList = () => {
    setLoading(true);
    APIHelper.post(APIURLConstant.GET_COMPONENTS, { Type: "Component" })
      .then((res) => {
        const updatedTags = res.map((item) => {
          return {
            label: item.tagName,
            value: item.tagID,
            ...item,
          };
        });
        if (updatedTags && updatedTags.length > 0) {
          setComponentList([...updatedTags]);
          //Edit Mode
          let componentTagsInEditMode = [];
          selectedProgram[0]?.tagList.split(",").forEach((tag) => {
            componentTagsInEditMode.push(
              updatedTags.filter((item) => item.value === parseInt(tag))[0]
            );
          });
          //selectedComponentTags(componentTagsInEditMode)
          setSelectedComponentTags(componentTagsInEditMode);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleAddAditionalQues = () => {
    questionListForSelectedComponent?.forEach((item) => {
      if (item.componentID === componentId) {
        selectedQaSelectors?.forEach((value) =>
          item.questionList.push({ ...value, ...{ isChecked: true } })
        );
      }
    });
    const selectedQues = questionListForSelectedComponent;
    setQuestionListForSelectedComponent(selectedQues);
    setComponentId(-1);
    resetAddAditionalForm();
  };

  const resetAddAditionalForm = () => {
    setShowAddQA(false);
    setCategoryTagsList([]);
    setQaSelectorsList([]);
    setTagInputValue("");
    setCategoryTagsValue([]);
    setQaSelectorValue([]);
    setIndex(-1);
    setIsConfirmed(false);
  };

  const onConfirmedChange = (e) => {
    setIsConfirmed(!isConfirmed);
    //onSelectChange(e, "isConfirmed")
  };

  const RenderAddQASelector = () => {
    return (
      <>
        <div className="justify-center md:ustify-center md:items-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          {" "}
          <Loader showLoading={loading} />
          <div className="relative w-full h-full max-w-lg md:h-auto">
            {/*content*/}
            <div className="rounded-lg shadow-2xl flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start opacity-6 justify-between px-3 py-3 border-b border-solid border-slate-200 rounded-t">
                <h3 className="text-2xl font-semibold">Add Q&A Selector</h3>
                <button
                  className="p-1 ml-auto outline-none focus:outline-none"
                  onClick={() => {
                    resetAddAditionalForm();
                  }}
                >
                  <img
                    src={CloseIcon}
                    className="h-15 w-5 transparent "
                    alt="close"
                  />
                </button>
              </div>
              {/*body*/}
              <div className="relative p-4 flex-auto">
                <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                  <p className="text-sm required pb-4 lg:p-0 md:p-0 lg:text-end text-center text-blue-900 md:w-2/6 lg:w-2/6  w-full font-bold">
                    Category Tags :
                  </p>
                  <div className="w-full md:w-4/6 lg:w-4/6  w-full ">
                    {/* <input
                      type="text"
                      disabled={true}
                      value="NBA"
                      className="w-full h-8 focus:outline-none focus:shadow-outline border px-3 border-grey-500 rounded text-white bg-blue-900"
                    /> */}

                    <AutoComplete
                      tagSuggestion={categoryTagsList}
                      inputValue={tagInputValue}
                      isCratable={false}
                      setInputValue={setTagInputValue}
                      value={categoryTagsValue}
                      setValue={setCategoryTagsValue}
                      onChange={handleChangeCategoryTag}
                      styles={colourStyles}
                      placeholder={"Please Type category tag name."}
                    />
                  </div>
                </div>
                <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                  <p className="text-sm required pb-4 lg:p-0 md:p-0 lg:text-end text-center text-[#394150] md:w-2/6 lg:w-2/6  w-full font-bold">
                    Q&A Selector :
                  </p>
                  <div className="w-full md:w-4/6 lg:w-4/6  w-full ">
                    <Multiselect
                      displayValue="questionText"
                      showCheckbox={true}
                      onSelect={handleChangeQaSelector}
                      options={qaSelectorsList}
                      selectedValues={qaSelectorsvalue}
                      placeholder={"Select Q&A..."}
                    />
                  </div>
                </div>
              </div>

              {/*footer*/}
              <div>
                <div className="flex space-x-4 items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="border h-8 border-blue-900 w-2/6 text-blue-900 font-semibold p-1 rounded-md"
                    type="button"
                    onClick={() => {
                      resetAddAditionalForm();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-blue-900 h-8 p-1 w-2/6 font-semibold rounded-md text-white"
                    type="button"
                    onClick={() => {
                      handleAddAditionalQues();
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    );
  };

  const setAddComponent = (e, show) => {
    e.preventDefault();
    setShowAddComponent(show);
    setIsComponentValid(!show);
  };

  // const handleFinishBtn = () => {
  //   if (validateForm()) {
  //     if (programId === 0) {
  //       saveProgramAsDraft();
  //     } else {
  //       handleFinshProgram(programId);
  //     }
  //   } else {
  //     setToasterMessage({
  //       errorType: "error",
  //       showToaster: true,
  //       message: "Please fill the required fields*",
  //     });
  //     setCardValue(0);
  //   }
  // };

  const handleFinshProgram = (id, redirectURL) => {
    if (id !== 0) {
      let obj1 = [];
      let obj2 = [];

      selectedComponentList.forEach((item) => {
        const ids = {
          componentID: item.componentID,
          tagID: item.tagID,
        };
        obj1.push(ids);
      });
      questionListForSelectedComponent.forEach((item) => {
        if (item.questionList.length > 0) {
          let question = item.questionList;

          question.forEach((qus) => {
            const qusIds = {
              componentID: item.componentID,
              questionID: qus.questionID,
              isSelected: qus.isChecked,
              sequence: item.sequence,
            };
            obj2.push(qusIds);
          });
        }
      });
      setLoading(true);
      APIHelper.post(APIURLConstant.POST_CREATE_PROGRAM_COMPONENT_TAGS(id), {
        programComponentTags: obj1,
      })
        .then((res) => {
          APIHelper.post(APIURLConstant.POST_CREATE_PROGRAM_QUESTIONS(id), {
            programQuestions: obj2,
          })
            .then((res) => {
              setLoading(false);
              resetForm();
              navigate(redirectURL);
            })
            .catch((err) => {
              console.log(err);
              resetForm();
              setCardValue(0);
              setLoading(false);
            });
        })
        .catch((err) => {
          resetForm();
          console.log(err);
          setLoading(false);
        });
    }
  };

  const handleChangeCheckBoxCT = (value, index) => {
    //this function is for change in program category and type question selection
    let changedQuestionList = categoryTypeQuestionList;
    changedQuestionList[index].isChecked = value;
    setCategoryTypeQuestionList(changedQuestionList);
    let questionID = "";
    if (value)
      questionID =
        categoryTypeQuestionIDs + "," + changedQuestionList[index].questionID;
    // if (index === 0)
    //   questionID = categoryTypeQuestionIDs.replace(changedQuestionList[index].questionID, '')
    // else
    else
      questionID = categoryTypeQuestionIDs.replace(
        "," + changedQuestionList[index].questionID,
        ""
      );
    setCategoryTypeQuestionIDs(questionID);
  };

  const getQuestions = (categoryID, typeID) => {
    setLoading(true);
    if (categoryID && typeID) {
      APIHelper.post(APIURLConstant.GET_QUESTIONS_BY_PROGRAMCATEGORYANDTYPE, {
        ProgramCategoryID: categoryID,
        ProgramTypeID: typeID,
      })
        .then((res) => {
          const updatedQaSelectors = res.map((item) => {
            return {
              label: item.questionText,
              value: item.questionText,
              isChecked: true,
              ...item,
            };
          });

          let questionIDs = "";
          for (let i = 0; i < updatedQaSelectors.length; i++) {
            if (!showInEidtMode) {
              questionIDs =
                questionIDs + "," + updatedQaSelectors[i].questionID;
            } else {
              updatedQaSelectors[i].isChecked =
                selectedProgram[0].categoryTypeQuestionIDs &&
                selectedProgram[0].categoryTypeQuestionIDs.includes(
                  updatedQaSelectors[i].questionID
                )
                  ? true
                  : false;
              questionIDs = selectedProgram[0].categoryTypeQuestionIDs;
            }
          }

          setCategoryTypeQuestionIDs(questionIDs);
          if (updatedQaSelectors && updatedQaSelectors.length > 0) {
            setCategoryTypeQuestionList(updatedQaSelectors);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const updateNotification = (
    programID,
    notificationType,
    isNotificationDelete,
    redirect
  ) => {
    setLoading(true);
    const notificationObject = {
      NotificationID: 0,
      ProgramID: programID,
      CreatedBy: userDetails.userID,
      IsDelete: false,
      IsRead: false,
      IsNotificationDelete: isNotificationDelete ? isNotificationDelete : false,
      ToUserID: 0,
      NotificationIDs: null,
      NotificationType: notificationType,
      SendEmail: !isNotificationDelete,
    };
    APIHelper.post(APIURLConstant.ADD_UPDATE_NOTIFICATIONS, notificationObject)
      .then((data) => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    if (redirect) navigate("/program-list");
  };

  const handleChangeAgency = (value) => {
    setSelectedAgency(value);
  };

  const handleChangeSponcer = (value) => {
    setSelectedSponcer(value);
  };

  return (
    <div className="mt-16">
      <div className="mb-11">
        <div className="mx-auto md:p-7 ">
          {/* Loader */}

          <Loader showLoading={loading} />
          <div className="grid grid-cols-2">
            <p className="text-[#c8102e] font-semibold text-[19px] px-2">
              {selectedProgram !== null ? "Edit a Program" : "Create a Program"}
            </p>
            <p className="text-sm font-semibold  font-500 text-[#1D428A] ">
              <span
                className={`${
                  cardValue === 0 ? "border-b-2 border-blue-900" : ""
                }`}
              >
                Main Form
              </span>
              &nbsp;&gt;&gt;&nbsp;
              <span
                className={`${
                  cardValue === 1 ? "border-b-2 border-blue-900" : ""
                }`}
              >
                Component
              </span>
            </p>
          </div>
          {cardValue === 0 ? (
            // Program Form
            <div
              className="bg-white border border-gray-200 rounded-lg shadow-md w-full p-4 mt-4 drop-shadow-md drop-shadow-lg"
              // onSubmit={handleSubmit}
            >
              <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                <p className="required text-sm pb-4 lg:p-0 md:p-0 lg:text-end text-center text-[#394150] md:w-2/12   w-full font-bold">
                  Program Category:
                </p>
                <div className="w-full lg:w-3/12 md:w-6/12">
                  <Select
                    value={selectedProgramCategory}
                    options={programCategory}
                    className="w-full focus:outline-none focus:shadow-outline text-gray-900 rounded"
                    placeholder="Select Program Category"
                    onChange={(e) => onSelectChange(e, "programCategoryID")}
                  />
                  {programData.programCategoryID.validationMessage ? (
                    <span className="text-red-500 text-sm pl-2">
                      {programData.programCategoryID.validationMessage}
                    </span>
                  ) : null}
                </div>
              </div>

              <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                <p className="required text-sm pb-4 lg:p-0 md:p-0 lg:text-end text-center text-[#394150] md:w-2/12  w-full font-bold">
                  Program Type:
                </p>
                <div className="w-full lg:w-3/12 md:w-6/12">
                  <Select
                    isDisabled={isPogramTypeDisabled}
                    value={selectedProgramType}
                    options={programType}
                    className="w-full focus:outline-none focus:shadow-outline text-gray-900 rounded"
                    placeholder="Select Program Type"
                    onChange={(e) => onSelectChange(e, "programTypeID")}
                  />
                  {programData.programTypeID.validationMessage ? (
                    <span className="text-red-500 text-sm pl-2">
                      {programData.programTypeID.validationMessage}
                    </span>
                  ) : null}
                </div>
              </div>

              <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                <p className="required text-sm pb-4 lg:p-0 md:p-0 lg:text-end text-center text-[#394150] md:w-2/12  w-full font-bold">
                  Program Duration:
                </p>
                <div className="w-full lg:w-3/12 md:w-6/12">
                  <Select
                    value={selectedProgramDuration}
                    options={programDuration}
                    className="w-full  focus:outline-none focus:shadow-outline text-gray-900 rounded"
                    placeholder="Select Program Duration"
                    onChange={(e) => onSelectChange(e, "programDurationID")}
                  />
                  {programData.programDurationID.validationMessage ? (
                    <span className="text-red-500 text-sm pl-2">
                      {programData.programDurationID.validationMessage}
                    </span>
                  ) : null}
                </div>
              </div>

              <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                <p className="required text-sm pb-4 lg:p-0 md:p-0 lg:text-end text-center text-[#394150] md:w-2/12 w-full font-bold">
                  Local Program Name:
                </p>
                <div className="w-full lg:w-10/12 md:w-10/12">
                  <input
                    value={localProgramName}
                    className="h-10 px-2 w-full border focus:outline-none focus:shadow-outline border-grey-300 text-gray-900 rounded"
                    placeholder=" Add a Local Program Name"
                    onChange={(e) => setInputStateData(e, "localProgramName")}
                  />
                  {programData.localProgramName.validationMessage ? (
                    <span className="text-red-500 text-sm pl-2">
                      {programData.localProgramName.validationMessage}
                    </span>
                  ) : null}
                </div>
              </div>

              <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                <p className="required text-sm pb-4 lg:p-0 md:p-0 lg:text-end text-center text-[#394150] md:w-2/12 w-full font-bold">
                  Program Description:
                </p>
                <div className="w-full lg:w-10/12 md:w-10/12">
                  <TextareaAutosize
                    type="text"
                    // id="qustion"
                    value={programDescription}
                    maxRows={3}
                    minRows={2}
                    onChange={(e) => setInputStateData(e, "programDescription")}
                    placeholder=" Add a Program Description"
                    className="h-10 px-2 w-full border focus:outline-none focus:shadow-outline border-grey-300 text-gray-900 rounded minHeight"
                  />
                  {programData.programDescription.validationMessage ? (
                    <span className="text-red-500 text-sm pl-2">
                      {programData.programDescription.validationMessage}
                    </span>
                  ) : null}
                </div>
              </div>

              <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                <p className="required text-sm pb-4 lg:p-0 md:p-0 lg:text-end text-center text-[#394150] md:w-2/12 w-full font-bold">
                  Overall Dates:
                </p>
                <div className="w-full md:w-10/12 flex flex-wrap">
                  <div className="lg:w-4/12 w-full">
                    <div className="flex gap-2 ">
                      <DatePicker
                        className="h-9 w-full pl-1 border focus:outline-none border border-grey-300 focus:shadow-outline border-grey-300 text-gray-900 rounded"
                        selected={programData.overallDateStart.value}
                        placeholderText={"MM/DD/YYYY"}
                        onChange={(date) =>
                          handleDateChange(date, "overallDateStart")
                        }
                        ref={startDateRef}
                        wrapperClassName="w-full"
                        //minDate={new Date()}
                        //dateFormat="MM/dd/yyyy"
                        dateFormat="dd MMMM yyyy"
                      />
                      <button
                        className="text-blue-900"
                        onClick={() => startDateRef.current.setOpen(true)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6 mt-2"
                        >
                          <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                          <path
                            fillRule="evenodd"
                            d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>
                    {programData.overallDateStart.validationMessage ? (
                      <div className="w-full lg:w-6/12 md:w-6/12 ">
                        <span className="text-red-500 text-sm">
                          {programData.overallDateStart.validationMessage}
                        </span>
                      </div>
                    ) : null}
                  </div>

                  <span className="px-2">-</span>
                  <div className="lg:w-4/12 w-full">
                    <div>
                      <div className="flex gap-2">
                        <DatePicker
                          className="h-9 w-full pl-1 border focus:outline-none focus:shadow-outline border-grey-300 text-gray-900 rounded"
                          selected={programData.overallDateEnd.value}
                          placeholderText={"MM/DD/YYYY"}
                          onChange={(date) =>
                            handleDateChange(date, "overallDateEnd")
                          }
                          wrapperClassName="w-full"
                          minDate={
                            programData.overallDateStart.value
                              ? programData.overallDateStart.value
                              : new Date()
                          }
                          dateFormat="dd MMMM yyyy"
                          ref={endDateRef}
                        />
                        <button
                          className="text-blue-900"
                          onClick={() => endDateRef.current.setOpen(true)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6 mt-2"
                          >
                            <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                            <path
                              fillRule="evenodd"
                              d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>
                      {programData.overallDateEnd.validationMessage ? (
                        <div className="w-full lg:w-6/12 md:w-6/12 ">
                          <span className="text-red-500 text-sm">
                            {programData.overallDateEnd.validationMessage}
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="flex-none lg:flex md:flex lg:space-x-4 py-4"
                onChange={(e) => onConfirmedChange(e)}
              >
                <p className="text-sm pb-4 lg:p-0 md:p-0 lg:text-end text-center text-[#394150] md:w-2/12  w-full font-bold">
                  Is Confirmed:
                </p>
                <div className="w-full lg:w-3/12 gap-4 flex md:w-6/12">
                  <div>
                    <input
                      type="radio"
                      value="Confirmed"
                      name="Confirmed"
                      checked={isConfirmed}
                      className="relative float-left mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-blue-900 checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-blue-900 dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                    />
                    <label className="font-medium ml-1">Confirmed</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      value="Tentative"
                      name="Tentative"
                      checked={!isConfirmed}
                      className="relative float-left mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-blue-900 checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-blue-900 dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                    />
                    <label className="font-medium ml-1">Tentative</label>
                  </div>
                </div>
              </div>
              <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                <p className="required text-sm pb-4 lg:p-0 md:p-0 lg:text-end text-center text-[#394150] md:w-2/12  w-full font-bold">
                  Region:
                </p>
                <div className="w-full lg:w-3/12 md:w-6/12">
                  <Select
                    value={selectedRegions}
                    options={regions}
                    className=" w-full focus:outline-none focus:shadow-outline text-gray-900 rounded"
                    placeholder="Select Region"
                    onChange={(e) => onSelectChange(e, "regionID")}
                  />

                  {programData.regionID.validationMessage ? (
                    <span className="text-red-500 text-sm pl-2">
                      {programData.regionID.validationMessage}
                    </span>
                  ) : null}
                </div>
              </div>

              <div className="flex-none lg:flex  py-4 w-full">
                <div className="flex-none md:flex lg:w-6/12 w-full md:space-x-4">
                  <p className="required text-sm pb-4 lg:p-0 text-center lg:text-end md:p-0 md:w-1/6 lg:w-2/6 w-full text-[#394150] font-bold">
                    Country Of Program:
                  </p>
                  <div className="w-full  lg:w-3/6">
                    <Select
                      value={selectedCountryOfProgram}
                      options={countryOfProgram}
                      className=" lg:ml-0 ml-1 w-full focus:outline-none focus:shadow-outline text-gray-900 rounded"
                      placeholder="Select Country"
                      onChange={(e) => onSelectChange(e, "countryID")}
                    />
                    {programData.countryID.validationMessage ? (
                      <span className="text-red-500 text-sm pl-2">
                        {programData.countryID.validationMessage}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="flex-none md:flex lg:w-6/12 w-full md:space-x-4 mt-2 lg:mt-0">
                  {stateOfProgram &&
                    stateOfProgram.length > 0 &&
                    selectedCountryOfProgram.label === "United States" && (
                      <p className="required text-sm pb-4 lg:p-0 text-center lg:text-end md:p-0 md:w-1/6 lg:w-2/6 w-full text-[#394150] font-bold">
                        State Of Program:
                      </p>
                    )}

                  {stateOfProgram &&
                    stateOfProgram.length > 0 &&
                    selectedCountryOfProgram.label === "United States" && (
                      <div className="w-full  lg:w-3/6 ">
                        <Select
                          value={selectedStateOfProgram}
                          options={stateOfProgram}
                          className="lg:ml-0 ml-1 focus:outline-none focus:shadow-outline  text-gray-900 rounded"
                          placeholder="Select State"
                          onChange={(e) => onSelectChange(e, "stateID")}
                        />
                      </div>
                    )}
                </div>
              </div>

              {/* <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                        <p className="required text-sm pb-4 lg:p-0 md:p-0 lg:text-end text-center text-blue-900 md:w-2/12 w-full font-bold">
                            Agency:
                        </p>
                        <div className="w-full lg:w-10/12 md:w-10/12 drop-shadow-md drop-shadow-lg">
                            <input value={agency}
                                className="h-10 w-full border focus:outline-none focus:shadow-outline border-grey-300 text-gray-900 rounded"
                                placeholder=" Add a agency or multiple agency"
                                onChange={(e) => setInputStateData(e, 'agency')}
                                id="overallDateStart"
                            >
                            </input>
                        </div>
                    </div> */}

              <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                <p className="text-sm p-0 pr-1 lg:text-end text-center text-[#394150] md:w-2/12 w-full font-bold">
                  City:
                </p>
                <div className="w-full lg:w-3/12 md:w-6/12 ">
                  <input
                    type="text"
                    value={cityInput}
                    placeholder="Enter a City"
                    onChange={(e) => setCityInput(e.target.value)}
                    className="w-full h-10 focus:outline-none focus:shadow-outline border px-3 border-grey-500 rounded"
                  />
                </div>
              </div>

              <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                <p className="required text-sm pb-4 lg:p-0 md:p-0 lg:text-end text-center text-[#394150] md:w-2/12 w-full font-bold">
                  Agency / Partners:
                </p>
                <div className="w-full  lg:w-10/12 md:w-10/12 ">
                  <AutoComplete
                    tagSuggestion={agencySuggestions}
                    isCloseOutsideClick={true}
                    createOption={createOption}
                    inputValue={agencyInput}
                    setInputValue={setAgencyInput}
                    value={agencyValue}
                    setValue={setAgencyValue}
                    className={` rounded `}
                    onChange={(e) => {
                      handleChangeAgency(e);
                      agencyAndSponcerError.agencyError = false;
                      setAgencyAndSponcerError({
                        ...agencyAndSponcerError,
                      });
                    }}
                    placeholder={"Please Type agency and select..."}
                    styles={colourStyles}
                  />
                  {agencyAndSponcerError.agencyError ? (
                    <span className="text-red-500 text-sm pl-2">
                      Value is required
                    </span>
                  ) : null}
                  {/* <div className="flex w-full md:w-4/6 lg:w-3/6 flex-wrap mb-2">
                    {agencyTags.map((tag, index) => (
                      <div className="tag border  p-1 bg-slate-100 mr-1 mb-1">
                        <span className="text-md font-medium ">{tag}</span>
                        <button
                          className="px-2 text-red-500"
                          onClick={(e) => deleteTag(e, index, "agency")}
                        >
                          x
                        </button>
                      </div>
                    ))}
                  </div>
                  <input
                    className="h-10 w-full px-2 border shadow-xl focus:outline-none focus:shadow-outline border-grey-300 text-gray-900 rounded"
                    value={agencyInput}
                    placeholder="Enter a Agency tag"
                    onKeyDown={(e) => onKeyDown(e, "agency")}
                    onChange={(e) => onChange(e, "agency")}
                    onBlur={(e) => onBlur(e, "agency")}
                  /> */}
                </div>
              </div>

              {/* <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                        <p className="required text-sm pb-4 lg:p-0 md:p-0 lg:text-end text-center text-blue-900 md:w-2/12 w-full font-bold">
                            {`Sponsor(s):`}
                        </p>
                        <div className="w-full lg:w-10/12 md:w-10/12 drop-shadow-md drop-shadow-lg">
                            <input value={sponsor}
                                className="h-10 w-full border focus:outline-none focus:shadow-outline border-grey-300 text-gray-900 rounded"
                                placeholder=" Add a Sponsor(s)"
                                onChange={(e) => setInputStateData(e, 'sponsor')}
                            >
                            </input>
                        </div>
                    </div> */}
              <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                <p className="required text-sm pb-4 lg:p-0 md:p-0 lg:text-end text-center text-[#394150] md:w-2/12 w-full font-bold">
                  Sponsor(s):
                </p>
                <div className="w-full lg:w-10/12 md:w-10/12">
                  <AutoComplete
                    tagSuggestion={sponcerSuggestions}
                    isCloseOutsideClick={true}
                    createOption={createOption}
                    inputValue={sponcerInput}
                    setInputValue={setSponcerInput}
                    value={sponcerValue}
                    setValue={setSponcerValue}
                    className={` rounded `}
                    onChange={(e) => {
                      handleChangeSponcer(e);
                      agencyAndSponcerError.sponcerError = false;
                      setAgencyAndSponcerError({
                        ...agencyAndSponcerError,
                      });
                    }}
                    placeholder={"Please Type sponcer and select..."}
                    styles={colourStyles}
                  />
                  {agencyAndSponcerError.sponcerError ? (
                    <span className="text-red-500 text-sm pl-2">
                      value is required
                    </span>
                  ) : null}
                  {/* <div className="flex w-full md:w-4/6 lg:w-3/6 flex-wrap mb-2">
                    {sponsorTags.map((tag, index) => (
                      <div className="tag border  p-1 bg-slate-100 mr-1 mb-1">
                        <span className="text-md font-medium ">{tag}</span>
                        <button
                          className="px-2 text-red-500"
                          onClick={(e) => deleteTag(e, index, "sponsor")}
                        >
                          x
                        </button>
                      </div>
                    ))}
                  </div> */}
                  {/* <input
                    className="h-10  w-full px-2 shadow-xl border focus:outline-none focus:shadow-outline border-grey-300 text-gray-900 rounded"
                    value={sponsorInput}
                    placeholder="Enter a Sponsor tag"
                    onKeyDown={(e) => onKeyDown(e, "sponsor")}
                    onChange={(e) => onChange(e, "sponsor")}
                    onBlur={(e) => onBlur(e, "sponsor")}
                  /> */}
                </div>
              </div>

              {/* <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                <p className="required text-sm pb-4 lg:p-0 md:p-0 lg:text-end text-center text-[#394150] md:w-2/12 w-full font-bold">
                  Reviewer:
                </p>
                <div className="w-full lg:w-3/12 md:w-6/12 ">
                  <Select
                    value={selectedReviewer}
                    options={reviewerList}
                    className=" lg:ml-0 ml-1 w-full focus:outline-none focus:shadow-outline text-gray-900 rounded"
                    placeholder="Select Reviewer"
                    onChange={(e) => {
                      setSelectedReviewer(e);
                      agencyAndSponcerError.reviewerError = false;
                      setAgencyAndSponcerError({
                        ...agencyAndSponcerError,
                      });
                    }}
                  />
                </div>
              </div> */}
              {agencyAndSponcerError.reviewerError ? (
                <div className="w-full lg:w-6/12 md:w-6/12 text-center">
                  <span className="text-red-500 text-sm">
                    value is required
                  </span>
                </div>
              ) : null}
              {/* {programTypeConfiguaration &&
            programTypeConfiguaration.length > 0 ? (
              <div>
                {programTypeConfiguaration.map((configuaration) => {
                  return (
                    <div>
                      {
                        <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                          <p className="text-sm pb-4 lg:p-0 md:p-0 lg:text-end text-center text-blue-900 md:w-2/12 w-full font-bold">
                            {`${configuaration.configurationText}`}
                          </p>
                          {configuaration.isOptionsRequired &&
                          configuaration.answerOptions.split(";").length > 0 ? (
                            <div>{renderSwitch(configuaration)}</div>
                          ) : (
                            <div className="w-full lg:w-10/12 md:w-10/12 drop-shadow-md drop-shadow-lg">
                              {configuaration.answerType ===
                              "Text Field Short" ? (
                                <input
                                  value={configuaration.answerText}
                                  className="h-10 w-full border focus:outline-none focus:shadow-outline border-grey-300 text-gray-900 rounded"
                                  placeholder={`${configuaration.configurationText}`}
                                  onBlur={(e) =>
                                    setExtraConfigurationData(e, configuaration)
                                  }
                                ></input>
                              ) : (
                                <TextareaAutosize
                                  type="text"
                                  value={configuaration.answerText}
                                  maxRows={3}
                                  minRows={2}
                                  onBlur={(e) =>
                                    setExtraConfigurationData(e, configuaration)
                                  }
                                  placeholder={`${configuaration.configurationText}`}
                                  className="h-15 w-full border focus:outline-none focus:shadow-outline border-grey-300 text-gray-900 rounded minHeight"
                                />
                              )}
                            </div>
                          )}
                        </div>
                      }
                    </div>
                  );
                })}
              </div>
            ) : null} */}
              <div>
                <div className="flex justify-end mt-4">
                  <button
                    className="text-sm px-[1rem] font-medium h-8 border-[#1D428A] border rounded-[3px] text-[#1D428A] "
                    onClick={(e) => {
                      setAddComponent(e, !showAddComponent);
                    }}
                  >
                    {!showAddComponent ? "Add Component" : "Hide Component"}
                  </button>
                </div>

                {showAddComponent ? (
                  <div>
                    {componentLoading ? (
                      <div className="flex w-full justify-center py-2">
                        <span className="loader"></span>
                      </div>
                    ) : (
                      <ComponentTagsSelector
                        isReset={showAddComponent}
                        isComponentValid={setIsComponentValid}
                        setIsBackButton={setIsBackButton}
                        componentList={componentList}
                        componentSelectorList={componentSelectorList}
                        setSelectedComponentList={setSelectedComponentList}
                        setSelectedComponentTags={setSelectedComponentTags}
                        selectedComponentTags={selectedComponentTags}
                        selectedComponentList={selectedComponentList}
                        loadComponentsInEidtMode={loadComponentsInEidtMode}
                        selectedProgramCategoryLabel={
                          selectedProgramCategory.label
                        }
                        selectedProgramTypeLabel={selectedProgramType.label}
                      />
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            // Compnent Form
            <div>
              <CategoryAndTypeQuestions
                categoryTypeQuestionList={categoryTypeQuestionList}
                handleChangeCheckBoxCT={handleChangeCheckBoxCT}
              />
              <ComponentQuestionSelector
                questionListForSelectedComponent={
                  questionListForSelectedComponent
                }
                setShowAddQA={setShowAddQA}
                setComponentId={setComponentId}
                getTagsList={getTagsList}
                handleChangeCheckBox={handleChangeCheckBox}
                handleRemoveComponent={handleRemoveComponent}
                setIndex={setIndex}
              />
            </div>
          )}
          <div className="flex flex-wrap justify-between gap-2 p-4">
            <button
              className="text-sm px-[1rem] h-[2.2rem] border-[#1D428A] border  rounded-[3px] p-1 text-[#1D428A] "
              onClick={() => {
                if (location.state && location.state.fromPage !== undefined) {
                  if (location.state.fromPage === "programList") {
                    navigate("/program-list");
                  } else if (location.state.fromPage === "myProgramList") {
                    navigate("/my-programs");
                  } else {
                    navigate(-1);
                  }
                } else {
                  navigate(-1);
                }
              }}
            >
              Cancel
            </button>
            <div className="flex flex-wrap gap-2 lg:justify-end ">
              {cardValue === 1 && (
                <button
                  className=" text-sm border-[#1D428A] border px-[1rem] h-[2.2rem] p-1 rounded-[3px] text-[#1D428A]"
                  type="button"
                  onClick={() => {
                    setCardValue(0);
                    setIsBackButton(true);
                  }}
                >
                  Back
                </button>
              )}
              {!isClone && selectedProgram?.length > 0 && (
                <button
                  onClick={() => setIsClone(true)}
                  className={`${
                    isClone ? "bg-[#ccc]" : "bg-[#1D428A]"
                  } text-sm p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white`}
                >
                  Clone
                </button>
              )}
              <button
                // className={
                //   selectedProgram?.length > 0 &&
                //   (selectedProgram[0]?.programStatus ===
                //     MethodAndVariable.VariableConstant.publish ||
                //     selectedProgram[0]?.programStatus ===
                //       MethodAndVariable.VariableConstant.unpublish)
                //     ? "bg-gray-300 text-sm p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white "
                //     : "bg-blue-900 text-sm p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white "
                // }
                className={
                  "bg-[#1D428A] text-sm p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white "
                }
                onClick={() => saveADraft()}
                // disabled={
                //   selectedProgram?.length > 0 &&
                //   (selectedProgram[0]?.programStatus ===
                //     MethodAndVariable.VariableConstant.publish ||
                //     selectedProgram[0]?.programStatus ===
                //       MethodAndVariable.VariableConstant.unpublish)
                //     ? true
                //     : false
                // }
                data-gacategory="Create Program"
                data-gaaction={
                  selectedProgram?.length > 0 &&
                  (selectedProgram[0]?.programStatus ===
                    MethodAndVariable.VariableConstant.publish ||
                    selectedProgram[0]?.programStatus ===
                      MethodAndVariable.VariableConstant.unpublish)
                    ? `Save`
                    : `Save as Draft`
                }
                data-galabel={`${selectedRegions?.label ?? ""}  Program`}
              >
                {selectedProgram?.length > 0 &&
                (selectedProgram[0]?.programStatus ===
                  MethodAndVariable.VariableConstant.publish ||
                  selectedProgram[0]?.programStatus ===
                    MethodAndVariable.VariableConstant.unpublish)
                  ? "Save"
                  : "Save as Draft"}
              </button>
              <button
                className="bg-[#1D428A] px-[1rem] h-[2.2rem]  rounded-[3px] p-1 text-sm text-white"
                onClick={() => onClickpublishProgram()}
                // disabled={
                //   !userDetails?.roleNameList
                //     ?.split(",")
                //     ?.includes("Youth Safety Admin") ?? true
                // }
                data-gacategory="Create Program"
                data-gaaction={
                  programStatus === MethodAndVariable.VariableConstant.publish
                    ? `Publish `
                    : `UnPublish `
                }
                data-galabel={
                  programStatus === MethodAndVariable.VariableConstant.publish
                    ? `${selectedRegions?.label ?? ""} Program`
                    : `${selectedRegions?.label ?? ""} Program`
                }
              >
                {programStatus === MethodAndVariable.VariableConstant.publish
                  ? "Publish"
                  : programStatus ===
                    MethodAndVariable.VariableConstant.unpublish
                  ? "Unpublish"
                  : ""}
              </button>
              {selectedProgramType && selectedProgramType.value ? (
                cardValue === 0 &&
                userDetails?.roleNameList
                  ?.split(",")
                  ?.includes("Youth Safety Admin") ? (
                  <button
                    className="bg-[#1D428A] text-sm p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white"
                    type="button"
                    // onClick={() => (cardValue === 0 ? handleNextBtn(cardValue) : null)}
                    //TODO: Code Optimization- in below lines same code is repeated twice. We can add state variable
                    //to evaluate role and accordingly handel below conditions..
                    onClick={() =>
                      cardValue === 0 &&
                      userDetails?.roleNameList
                        ?.split(",")
                        ?.includes("Youth Safety Admin")
                        ? handleNextBtn(cardValue)
                        : null
                    }
                  >
                    Next
                  </button>
                ) : null
              ) : null}
            </div>
          </div>
          <Toaster
            toasterMessage={toasterMessage}
            setToasterMessage={setToasterMessage}
          />
          {showAddQA && <RenderAddQASelector />}
        </div>
        {/* Modal Popup */}
        <Modal
          showModal={showModal}
          handleClose={() => setShowModal(false)}
          modalMessage={modalMessage}
          handleClick={() => setShowModal(false)}
          showOkayButton={true}
        />
        {/* Modal Popup for validations */}
        <Modal
          showModal={showModal}
          handleClose={handleClose}
          modalMessage={modalMessage}
          handleClick={goToComponent}
          showOkayButton={true}
        />
      </div>
    </div>
  );
};

export default CreateProgram;
