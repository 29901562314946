import React from "react";
import { Draggable } from "react-beautiful-dnd";
import TagIcon from "../../assets/tag-button.svg";
import ArrowIcon from "../../assets/arrow-down.svg";
import EditIcon from "../../assets/edit.svg";
import ArrorDownBlue from "../../assets/arrow-down-blue.svg";

const DraggableListItem = (props) => {
  const { item, index, firstChild, secondChild } = props;

  const tags =
    item.userRoleNames.value && item.userRoleNames.value.split(/[,]+/);

  return (
    <Draggable draggableId={`draggable_${index}`} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {/* <div>{item.userRoleNames.value}</div> */}
          <div
            className={`border-[#ccc]   ${
              firstChild !== undefined &&
              firstChild !== null &&
              firstChild[0]?.sectionID === item.sectionID?.value
                ? "border rounded-tl-lg rounded-tr-lg"
                : "border mb-1 rounded"
            } bg-white py-4 px-2 md:px-4  w-full `}
            key={Math.random()}
          >
            <div className="w-full flex-none md:flex gap-2">
              {props.showChildAccordion !== undefined &&
                props.showChildAccordion === true && (
                  <button
                    onClick={() => props.handleClickAccordion(item)}
                    data-te-toggle="tooltip"
                    title="View-child-Categories"
                    className=" "
                  >
                    <img
                      src={ArrorDownBlue}
                      className={`h-[1rem] w-5 ${
                        firstChild !== undefined &&
                        firstChild !== null &&
                        firstChild[0]?.sectionID === item.sectionID?.value
                          ? "rotate-180"
                          : ""
                      } `}
                      alt="child"
                    />
                  </button>
                )}
              <div className=" flex justify-center md:justify-start md:w-4/12 w-full">
                {item.sectionName.value}
              </div>
              <div className="flex justify-center md:justify-start md:w-6/12 w-full ">
                <div className="flex flex-wrap gap-2 items-center h-full">
                  {tags &&
                    tags.length > 0 &&
                    // eslint-disable-next-line array-callback-return
                    tags.map((allTags, index) => {
                      if (allTags.length !== 0 && index < 2) {
                        return (
                          <>
                            <p className="bg-[#394150]  w-fit flex rounded">
                              <span className="px-2 mx-1  whitespace-nowrap text-white text-sm font-normal">
                                {allTags}
                              </span>
                              <img
                                src={TagIcon}
                                className="h-15 w-5 mr-1 "
                                alt="Tag"
                              />
                            </p>
                          </>
                        );
                      }
                    })}
                  {tags && tags.length > 2 && (
                    <button
                      onClick={() => props.handleOpenTagList(tags)}
                      className="pl-1 bg-[#394150] w-fit rounded flex align-center text-center"
                    >
                      <span className="text-white text-sm ">view...</span>
                      <img
                        src={ArrowIcon}
                        className="h-3 w-5 arrow-img mt-1"
                        alt="arrow"
                      />
                    </button>
                  )}
                </div>
              </div>
              <div className="flex justify-center  md:w-1/12 w-full">
                {item.isActive.value === true ? "Active" : "InActive"}
              </div>
              <div className="flex gap-2 justify-center md:justify-end md:w-1/12 w-full">
                <button
                  onClick={() => props.handleClickEdit(item)}
                  data-te-toggle="tooltip"
                  title="Edit"
                >
                  <img src={EditIcon} className="h-15 w-5 " alt="edit" />
                </button>
                <button
                  className="text-[#c8102e]"
                  data-te-toggle="tooltip"
                  title="Delete"
                  onClick={() => props.handleDeleteUser(item)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                {props.showSubCategory !== undefined &&
                  props.showSubCategory === true && (
                    <button
                      onClick={() => props.handleClickSubCat(item)}
                      data-te-toggle="tooltip"
                      title="Sub-Categories"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-6 h-6 text-blue-900"
                      >
                        <path
                          fillRule="evenodd"
                          d="M19.5 21a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3h-5.379a.75.75 0 0 1-.53-.22L11.47 3.66A2.25 2.25 0 0 0 9.879 3H4.5a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h15Zm-6.75-10.5a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25v2.25a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V10.5Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  )}
              </div>
            </div>
          </div>
          {firstChild !== undefined && firstChild !== null ? (
            <div className="mb-2">
              {firstChild.map((child1) => {
                if (child1.sectionID === item.sectionID.value)
                  return (
                    <div className="w-full bg-white   ">
                      <div className="flex flex-wrap gap-2 pl-6 py-2 border-x border-b border-[#ccc]">
                        <button
                          onClick={() => props.handleClickSubAccordion(child1)}
                          data-te-toggle="tooltip"
                          title="View-sub-child"
                          className=" "
                        >
                          <img
                            src={ArrorDownBlue}
                            className={`h-[1rem] w-5 ${
                              secondChild !== undefined &&
                              secondChild !== null &&
                              child1.subSectionID ===
                                secondChild[0]?.parentSubSectionID
                                ? "rotate-180"
                                : ""
                            } `}
                            alt="child"
                          />
                        </button>
                        <p className=" ">{child1?.subSectionName}</p>
                      </div>
                      {secondChild !== undefined && secondChild !== null && (
                        <>
                          {secondChild?.map((child2) => {
                            if (
                              child2.parentSubSectionID === child1.subSectionID
                            ) {
                              return (
                                <div className="w-full bg-white flex flex-wrap justify-between border-x border-b border-[#ccc] pl-8  pr-4 py-2">
                                  <p className="pl-6 truncate  ">
                                    {child2?.subSectionName}
                                  </p>
                                </div>
                              );
                            }
                          })}
                        </>
                      )}
                    </div>
                  );
              })}
            </div>
          ) : null}
        </div>
      )}
    </Draggable>
  );
};

export default DraggableListItem;
