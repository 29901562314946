import React from "react";
import CloseIcon from "../../assets/close-blue.svg";
import TagIcon from "../../assets/tag-button.svg";

Modal.defaultProps = {
  isModalVisible: false,
};

export default function Modal(props) {
  // const { title, message } = props.modalMessage;

  return (
    <>
      {props.showModal ? (
        <>
          {props.isTagList !== undefined && props.isTagList === true ? (
            <div
              id="medium-modal"
              tabIndex="-1"
              className="justify-center md:ustify-center md:items-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[10000] outline-none focus:outline-none"
            >
              <div className="relative w-full h-full max-w-lg md:h-auto">
                <div className="relative bg-white rounded-lg shadow-xl dark:bg-gray-900">
                  <div className="flex items-center justify-between py-2 px-4 border-b rounded-t dark:border-gray-600">
                    <h3 className="text-xl font-medium text-blue-900 dark:text-white">
                      {props.title}
                    </h3>
                    <button
                      className="p-1 ml-auto outline-none focus:outline-none"
                      onClick={() => props.handleClose()}
                      data-te-toggle="tooltip"
                      title="Close"
                    >
                      <img
                        src={CloseIcon}
                        className="h-15 w-5 transparent "
                        alt="close"
                      />
                    </button>
                  </div>
                  <div className="p-4 flex flex-wrap">
                    {props.data !== undefined &&
                      props.data.length > 0 &&
                      props.data.map((tags) => {
                        return (
                          <p className=" bg-[#394150] mr-2 w-fit mb-2 flex rounded">
                            <span className="px-2 mx-1  whitespace-nowrap text-white text-sm font-weight-700">
                              {tags}
                            </span>
                            <img
                              src={TagIcon}
                              className="h-15 w-5 mr-1 "
                              alt="Tag"
                            />
                          </p>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[10000] outline-none focus:outline-none">
              <div className="relative mx-auto">
                {/*content*/}
                <div className="rounded-lg shadow-2xl flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start opacity-6 justify-between px-3 py-3 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-2xl font-semibold">
                      {props.modalMessage.title}
                    </h3>
                    <button
                      className="p-1 ml-auto outline-none focus:outline-none"
                      onClick={() => props.handleClose()}
                    >
                      <img
                        src={CloseIcon}
                        className="h-15 w-5 transparent "
                        alt="close"
                      />
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto text-center">
                    <p className="my-4 text-slate-500 text-lg leading-relaxed">
                      {props.modalMessage.message}
                    </p>
                  </div>
                  {/*footer*/}
                  <div>
                    {props.showOkayButton ? (
                      <div className="flex space-x-4 items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        <button
                          className="border h-8 border-blue-900 w-2/6 text-blue-900 font-semibold p-1 rounded-md"
                          type="button"
                          onClick={() => props.handleClose()}
                        >
                          Okay
                        </button>
                      </div>
                    ) : (
                      <div className="flex space-x-4 items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        <button
                          className="border h-8 border-blue-900 w-2/6 text-blue-900 font-semibold p-1 rounded-md"
                          type="button"
                          onClick={() => props.handleClose()}
                        >
                          NO
                        </button>
                        <button
                          className="bg-blue-900 h-8 p-1 w-2/6 font-semibold rounded-md text-white"
                          type="button"
                          onClick={() => props.handleClick()}
                        >
                          YES
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
