import React, { useEffect, useState, useContext, useRef } from "react";
import APIHelper from "../../utils/config/apiHelper";
import APIURLConstant from "../../utils/config/apiURLConstant";

const CategoryAndTypeQuestions = (props) => {
    // const [questionListForSelectedComponent, setQuestionListForSelectedComponent] = useState([]);

    const [categoryTypeQuestionList, setCategoryTypeQuestionList] = useState([]);
    const [questionIDs, setQuestionIDs] = useState('');

    // const prevItemIdRef = useRef();
    // useEffect(() => {
    //     prevItemIdRef.current = props.selectedProgramCategory && props.selectedProgramCategory.value
    //         + ','
    //         + props.selectedProgramType && props.selectedProgramType.value;
    // });
    // const prevItemId = prevItemIdRef.current;

    // useEffect(() => {
    //     if (prevItemId !== props.selectedProgramCategory && props.selectedProgramCategory.value
    //         + ','
    //         + props.selectedProgramType && props.selectedProgramType.value) {
    //         getQuestions(props.selectedProgramCategory && props.selectedProgramCategory.value,
    //             props.selectedProgramType && props.selectedProgramType.value);
    //     }
    // }, [props.selectedProgramType.value && props.selectedProgramCategory.value])

    // const getQuestions = (categoryID, typeID) => {
    //     setLoading(true);
    //     if (categoryID && typeID) {
    //         APIHelper.get(APIURLConstant.GET_QUESTIONS_BY_PROGRAMCATEGORYANDTYPE(categoryID, typeID))
    //             .then((res) => {
    //                 const updatedQaSelectors = res.map((item) => {
    //                     return {
    //                         label: item.questionText,
    //                         value: item.questionText,
    //                         isChecked: true,
    //                         ...item,
    //                     };
    //                 });
    //                 let questionIDs = '';
    //                 for (let i = 0; i < updatedQaSelectors.length; i++) {
    //                     questionIDs = questionIDs + ',' + updatedQaSelectors[i].questionID;
    //                 }
    //                 setQuestionIDs(questionIDs);
    //                 if (updatedQaSelectors && updatedQaSelectors.length > 0) {
    //                     // setQaSelectorsList([...updatedQaSelectors]);
    //                     let selectedValue;
    //                     setCategoryTypeQuestionList(updatedQaSelectors);
    //                     // questionListForSelectedComponent[index]?.questionList?.forEach(
    //                     //     (item) => {
    //                     //         selectedValue = updatedQaSelectors.filter(
    //                     //             (val) => val?.questionID !== item?.questionID
    //                     //         );
    //                     //     }
    //                     // );
    //                     // setQaSelectorsList(selectedValue);
    //                 }
    //                 // setLoading(false);
    //             })
    //             .catch((err) => {
    //                 // setLoading(false);
    //             });
    //     }
    // }

    // const handleChangeCheckBox = (value, index) => {
    //     let changedQuestionList = categoryTypeQuestionList;
    //     changedQuestionList[index].isChecked = value;
    //     setCategoryTypeQuestionList(changedQuestionList);
    //     let questionID = '';
    //     if (value)
    //         questionID = questionIDs + ',' + changedQuestionList[index].questionID;
    //     else
    //         questionID = questionIDs.replace(changedQuestionList[index].questionID, '')
    //     setQuestionIDs(questionID);
    // };

    return (
        <div>
            <div className="bg-white border border-gray-200 rounded-lg shadow-md w-full p-4 mt-4 drop-shadow-md drop-shadow-lg">
                {props.categoryTypeQuestionList &&
                    props.categoryTypeQuestionList.length > 0 ? (
                        props.categoryTypeQuestionList.map((item, index) => {
                        return (
                            <div key={index}>
                                <div className="flex gap-2 pb-2">
                                    <input
                                        type="checkbox"
                                        checked={item.isChecked}
                                        onChange={(e) => props.handleChangeCheckBoxCT(e.target.checked, index)}
                                    />
                                    <p className="font-bold text-blue-900 text-sm">
                                        {item.questionText}
                                    </p>
                                </div>
                                {/* <div className="flex justify-between border-b-2 border-blue-900 w-full px-2">
                                    <p className="text-md text-blue-900 font-bold">
                                        {item.componentName}
                                    </p>
                                    <button type="button">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-6 h-6 text-blue-900 mt-1"
                                            onClick={() => props.handleRemoveComponent(item)}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                    </button>
                                </div> */}
                                <div className="p-2">
                                    {/* {item.questionList &&
                                        item.questionList.map((question, index) => {
                                            return (
                                                <div className="flex gap-2 pb-2">
                                                    <input
                                                        type="checkbox"
                                                        checked={question.isChecked}
                                                        onChange={(e) =>
                                                            props.handleChangeCheckBox(
                                                                e.target.checked,
                                                                item,
                                                                index
                                                            )
                                                        }
                                                    />
                                                    <p className="font-bold text-blue-900 text-sm">
                                                        {question.questionText}
                                                    </p>
                                                </div>
                                            );
                                        })} */}
                                    {/* <button
                                        className="px-2 text-sm mt-4 border border-blue-900 h-8 rounded-md text-blue-900"
                                        onClick={() => {
                                            props.setShowAddQA(true);
                                            props.setComponentId(item.componentID);
                                            props.getTagsList();
                                            props.setIndex(index);
                                        }}
                                    >
                                        Add Q&A Selector
                                    </button> */}
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <p className="text-md">No Additional questions for selected Program category and Type</p>
                )}
            </div>
            {/* <p>This is Category And Type Questions</p>
            <p>
                {props.selectedProgramCategory && props.selectedProgramCategory.label}
                {props.selectedProgramType && props.selectedProgramType.label}
            </p> */}
        </div>
    )
}

export default CategoryAndTypeQuestions;
