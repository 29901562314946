import React, { useEffect, useState, useContext, useRef } from "react";
import { ChromePicker } from "react-color";
import Logo from "../../../assets/ypp-logo.svg";
import CalendarPdf from "../../../assets/calendar_pdf.svg";
import LocationPdf from "../../../assets/location_pdf.svg";
import StudentPdf from "../../../assets/student_pdf.svg";
import TeacherPdf from "../../../assets/teacher_pdf.svg";
import LinkPdf from "../../../assets/link_pdf.svg";
import { AppContext } from "../../../store/context";
import moment from "moment";
import CloseIcon from "../../../assets/close-blue.svg";
import { Editor } from "@tinymce/tinymce-react";

import Loader from "../../../sharedComponent/Loader/Loader";
import IsUrl from "validator/lib/isURL";
import { useReactToPrint } from "react-to-print";
import "./Recap_Report.css";
import APIHelper from "../../../utils/config/apiHelper";
import APIURLConstant from "../../../utils/config/apiURLConstant";
import Toaster from "../../../sharedComponent/Toaster/Toaster";

const CreateRecapReport = (props) => {
  const { userDetails } = useContext(AppContext);
  const componentRef = useRef();

  const [headerBgColor, setHeaderBgColor] = useState(null);
  const [footerBgColor, setFooterBgColor] = useState(null);
  const [headerTextColor, setHeaderTextColor] = useState(null);
  const [headerText, setHeaderText] = useState();
  const [recapTab, setRecapTab] = useState(0);
  const [ckEditorData, setCkEditorData] = useState("");
  const [socialMediainput, setSocialMediaInput] = useState("");
  const [socialMediatitle, setSocialMediaTitle] = useState("");
  const [socialMediaLinks, setSocialMediaLinks] = useState([]);
  const [allFileSrc, setAllFileSrc] = useState([]);
  const [socialMediaImg, setSocialMediaImg] = useState(null);
  const [socialMediaFiles, setSocialMediaFiles] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [fileDetails, setFileDetails] = useState([]);
  const [showFileUpload, setShowFileUpload] = useState(true);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [programData, setProgramData] = useState(null);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const socialFileRef = useRef(null);
  const [imageUrlText, setImageUrlText] = useState("");
  const [allImageUrl, setAllImageUrl] = useState([]);
  const [linkErrorMessage, setLinkErrorMessage] = useState(false);
  const [titleErrorMessage, setTitleErrorMessage] = useState(false);
  const [imageErrorMessage, setImageErrorMesage] = useState(false);
  const [showLinkAdd, setShowLinkAdd] = useState(false);
  const [validLinkError, setValidLinkError] = useState(false);
  const [programDetailsCount, setprogramDetailsCount] = useState({
    youthsCount: 0,
    teacherCount: 0,
  });
  const [toasterMessage, setToasterMessage] = useState({
    showToaster: false,
    errorType: "",
    message: "",
  });
  const [errorMessage, setErrorMessage] = useState({
    documentTitle: false,
    description: false,
    category: false,
    fileSrc: false,
  });
  const editorRef = useRef(null);
  const [showPreview, setShowPreview] = useState(false);

  const resetState = () => {};

  useEffect(() => {
    if (props && props.programDetails) {
      let data = props.programDetails;
      //setHeaderText(data.localProgramName);
      setHeaderText(
        props.recapReport && props.recapReport.headerText
          ? props.recapReport.headerText
          : data.localProgramName
      );
      setHeaderBgColor({
        hex:
          props.recapReport && props.recapReport.headerColor
            ? props.recapReport.headerColor
            : "#C8102E",
        rgb: null,
      });
      setHeaderTextColor({
        hex:
          props.recapReport && props.recapReport.headerTextColor
            ? props.recapReport.headerTextColor
            : "#FFFFFF",
        rgb: null,
      });
      setFooterBgColor({
        hex:
          props.recapReport && props.recapReport.footerColor
            ? props.recapReport.footerColor
            : "#C8102E",
        rgb: null,
      });

      //componentRef.current.innerText = props?.recapReport?.eventRecap
      setCkEditorData(props?.recapReport?.eventRecap);
      setProgramData(data);
    }
  }, [props]);

  useEffect(() => {
    if (props.recapReport && props.recapReport.recapReportID) {
      try {
        setLoading(true);
        APIHelper.post(
          APIURLConstant.GET_RECAP_REPORT_SOCIALMEDIA_IMAGES_BY_REPORTID,
          { RecapReportID: props.recapReport.recapReportID }
        )
          .then((res) => {
            console.log(res);
            if (res && res.length > 0) {
              //
              const imageFileData = res.filter(
                (image) => !image.isSocialMediaResource
              );
              let imageFiles = [];
              let imageFilesDetails = [];
              imageFileData.forEach((item) => {
                imageFiles.push({
                  mediaLinkID: item.mediaLinkID,
                  id: imageFiles ? imageFiles.length + 1 : 1,
                  name: item.title,
                  imageOrder: item.imageOrder,
                  src: item.mainPath ? item.mainPath : new File([], item.title), //item.mainPath ? item.mainPath : null ,
                });
                imageFilesDetails.push({
                  FileName: item.title,
                  IsSocialMediaResource: false,
                  MediaOrder: 0,
                  ImageOrder: item.imageOrder,
                  IsDeleted: false,
                });
              });

              setAllFileSrc((prevState) => [...prevState, ...imageFiles]);
              setFileDetails((prevState) => [
                ...prevState,
                ...imageFilesDetails,
              ]);

              const socialMediaLinksData = res.filter(
                (image) => image.isSocialMediaResource
              );
              let socialMediaFiles = [];
              let socialMediaLinksDetails = [];
              socialMediaLinksData.forEach((item) => {
                socialMediaFiles.push({
                  mediaLinkID: item.mediaLinkID,
                  id: socialMediaFiles ? socialMediaFiles.length + 1 : 1,
                  title: item.mTitle,
                  mediaOrder: item.MediaOrder,
                  url: item.url, //item.mainPath ? item.mainPath : item.url,
                  src: item.mainPath ? item.mainPath : null, //item.mainPath ? item.mainPath : null ,
                });
                socialMediaLinksDetails.push({
                  FileName: item.title,
                  IsSocialMediaResource: true,
                  MediaOrder: item.mediaOrder,
                  ImageOrder: 0,
                  IsDeleted: false,
                });
              });

              setSocialMediaLinks((prevState) => [
                ...prevState,
                ...socialMediaFiles,
              ]);
              setFileDetails((prevState) => [
                ...prevState,
                ...socialMediaLinksDetails,
              ]);
            }
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  const handleChangeHeaderColor = (e) => {
    const newColor = {
      hex: e.hex,
      rgb: "(" + e.rgb.r + "," + e.rgb.g + "," + e.rgb.b + ")",
    };
    setHeaderBgColor(newColor);
  };
  const handleChangeHeaderTextColor = (e) => {
    const newColor = {
      hex: e.hex,
      rgb: "(" + e.rgb.r + "," + e.rgb.g + "," + e.rgb.b + ")",
    };
    setHeaderTextColor(newColor);
  };
  const handleChangeFooterColor = (e) => {
    const newColor = {
      hex: e.hex,
      rgb: "(" + e.rgb.r + "," + e.rgb.g + "," + e.rgb.b + ")",
    };
    setFooterBgColor(newColor);
  };
  const handleAddLink = () => {
    const trimmedInput = socialMediainput.trim();
    const trimmedTitle = socialMediatitle.trim();

    if (trimmedTitle.length === 0) {
      setTitleErrorMessage(true);
      return false;
    } else if (trimmedInput.length === 0) {
      setLinkErrorMessage(true);
      return false;
    } else {
      if (IsUrl(trimmedInput)) {
        let obj = {
          id: socialMediaLinks !== undefined ? socialMediaLinks.length + 1 : 1,
          title: socialMediatitle,
          url: socialMediainput,
          src: socialMediaImg !== undefined ? socialMediaImg : null,
        };
        setSocialMediaLinks((prevState) => [...prevState, obj]);

        //
        const newFiles = fileDetails.map((file) => {
          if (file.title === obj.title) {
            return { ...file, URL: obj?.url };
          }
          return file;
        });

        setFileDetails(newFiles);
        //
        //let fileData = fileDetails[fileDetails.length - 1]
        //const fileDetailsUpdated = fileDetails.splice(fileDetails.length - 1, 1)
        if (obj.src === null) {
          //fileData['URL'] = obj?.url
          //fileData['FileName'] = obj?.title
          let fileData = {
            FileName: obj?.title,
            URL: obj?.url,
            IsSocialMediaResource: true,
            MediaOrder: socialMediaLinks ? socialMediaLinks.length + 1 : 0,
            ImageOrder: 0,
            IsDeleted: false,
          };
          setFileDetails([...fileDetails, fileData]);
        } else {
          const currentFileIndex = fileDetails.length - 1;
          // 2. Mark the todo as complete
          const updatedFile = {
            ...fileDetails[currentFileIndex],
            URL: obj?.url,
          };
          // 3. Update the todo list with the updated todo
          const newFiles = [
            ...fileDetails.slice(0, currentFileIndex),
            updatedFile,
            ...fileDetails.slice(currentFileIndex + 1),
          ];
          setFileDetails(newFiles);
        }
        //setFileDetails([...fileDetails, fileData])

        socialFileRef.current.value = null;
        setSocialMediaInput("");
        setSocialMediaTitle("");
        setSocialMediaImg(null);
        setShowLinkAdd(false);
      } else {
        setValidLinkError(true);
      }
    }
  };

  const handleDeleteImgUrl = (index) => {
    setAllImageUrl((prevState) => prevState.filter((i) => i !== index));
  };

  const handleDeleteLink = (index) => {
    // console.log(index);
    let updateData = [];
    socialMediaLinks &&
      socialMediaLinks.forEach((item) => {
        if (item.id !== index.id) {
          updateData.push(item);
        }
      });
    const fileData = fileDetails.filter(
      (item) => item.FileName === index.title
    )[0];
    const fileDetailsUpdated = fileDetails.filter(
      (i) => i.FileName !== index.title
    );
    if (fileData && fileData.IsDeleted === false) {
      fileData["IsDeleted"] = true;
    }
    setFileDetails([...fileDetailsUpdated, fileData]);
    //setFileDetails((prevState) => [...prevState, fileData]);
    setSocialMediaLinks(updateData);
  };
  const handleChangeFile = (event) => {
    if (event.target.files && event.target.files[0]) {
      const dataAll = event.target.files;
      const newData = [];

      Object.keys(dataAll).forEach((key, index) => {
        newData.push({
          id: allFileSrc ? allFileSrc.length + index + 1 : 1,
          name: dataAll[key].name,
          src: dataAll[key],
        });
      });

      setAllFileSrc((prevState) => [...prevState, ...newData]);
      handleChangeAllFiles(event, "imageFiles");
      fileInputRef.current.value = null;
    }
  };

  const handleChangeAllFiles = (event, type) => {
    let file = event.currentTarget.files;
    let files = [];
    let fileSrcs = [];
    errorMessage.fileSrc = false;
    console.log(file);
    if (file.length > 0) {
      Object.values(file).forEach((item) => {
        let blob = item.slice(0, item.size, item.type);
        let extension = item.name.substring(item.name.lastIndexOf(".") + 1);
        let fileName = item.name.substring(0, item.name.lastIndexOf("."));
        let newFileName =
          fileName + "_" + new Date().getTime() + "." + extension;
        setUploadedFileName(newFileName);
        const newFile = new File([blob], newFileName, { type: item.type });
        const fileData = {
          FileName: newFileName,
          IsSocialMediaResource: type === "SocialMedia" ? true : false,
          MediaOrder: type === "SocialMedia" ? socialMediaFiles.length + 1 : 0,
          ImageOrder: type !== "SocialMedia" ? imageFiles.length + 1 : 0,
          IsDeleted: false,
        };
        setFileDetails((prevState) => [...prevState, fileData]);
        files.push({
          fileName: newFileName,
          createdDate: new Date(),
          file: newFile,
        });
        fileSrcs.push({
          fileName: newFileName,
        });
      });
      console.log("files", files);

      if (type === "SocialMedia") {
        //setSocialMediaFiles(...socialMediaFiles, files)
        setSocialMediaFiles((prevState) => [...prevState, files]);
      } else {
        setImageFiles((prevState) => [...prevState, files]);
      }

      //setFileSrc(fileSrcs);
    } else {
      if (
        selectedDocument &&
        selectedDocument.documentName &&
        selectedDocument.documentName !== ""
      ) {
        const input = document.getElementById("uploadFile");
        var filesNew = [new File(["content"], selectedDocument.documentName)];
        input.files = new FileListItems(filesNew);
      } else {
        //setFileSrc([]);
        setSocialMediaFiles([]);
        setImageFiles([]);
        setShowFileUpload(true);
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: "Please select a file.",
        });
      }
    }
  };

  function FileListItems(files) {
    var b = new ClipboardEvent("").clipboardData || new DataTransfer();
    for (var i = 0, len = files.length; i < len; i++) b.items.add(files[i]);
    return b.files;
  }

  const handleSocialLinkFile = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSocialMediaImg(event.target.files[0]);
      handleChangeAllFiles(event, "SocialMedia");
    }
  };
  const handleAddImgUrl = () => {
    const trimmedInput = imageUrlText.trim();
    if (trimmedInput.length) {
      if (IsUrl(trimmedInput)) {
        setAllImageUrl((prevState) => [...prevState, trimmedInput]);
        setImageUrlText("");
      } else {
        setImageErrorMesage(true);
      }
    }
  };

  const handleDeleteSrc = (index) => {
    setAllFileSrc((prevState) => prevState.filter((i) => i.id !== index.id));
    const fileData = fileDetails.filter(
      (item) => item.FileName === index.name
    )[0];
    const fileDetailsUpdated = fileDetails.filter(
      (i) => i.FileName !== index.name
    );
    if (fileData && fileData.IsDeleted === false) {
      fileData["IsDeleted"] = true;
    }
    setFileDetails([...fileDetailsUpdated, fileData]);
    //setFileDetails((prevState) => [...prevState, fileData]);
  };

  const handleDownload = () => {
    if (
      programDetailsCount.youthsCount === 0 &&
      programDetailsCount.teacherCount === 0
    ) {
      setLoading(true);
      let data = props.programDetails;
      const payLoad = {
        programID: data?.programID,
        UserEmail: userDetails.email,
      };
      APIHelper.post(APIURLConstant.GET_PARTICIPANTS_COUNT, payLoad)
        .then((res) => {
          if (res) {
            setprogramDetailsCount({
              youthsCount: res.youthsCount,
              teacherCount: res.coachesCount,
            });
          }
          setShowPreview(true);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: "Error while getting program Youths & Teachers count",
          });
          setLoading(false);
        });
    } else {
      setShowPreview(true);
    }
  };
  const handlePrint = useReactToPrint({
    onPrintError: (error) => console.log(error),
    content: () => componentRef.current,
    // onAfterPrint: () => saveToDatabase(),
    documentTitle: "Recap_Report",
    removeAfterPrint: true,
  });

  const saveToDatabase = () => {
    setLoading(true);
    let data = props.programDetails;
    console.log(data);
    const payLoad = {
      recapReportID: props?.recapReport?.recapReportID ?? 0,
      programID: data?.programID,
      headerText: headerText,
      headerColor: headerBgColor ? headerBgColor.hex : "#C8102E",
      headerTextColor: headerTextColor ? headerTextColor.hex : "#FFFFFF",
      footerColor: footerBgColor ? footerBgColor.hex : "#C8102E",
      eventRecap: ckEditorData, //componentRef.current.innerText,
      imagesURL: imageUrlText,
      createdBy: userDetails.userID,
      createdDate: null,
      modifiedBy: userDetails.userID,
      modifiedDate: null,
      isActive: true,
      isDeleted: false,
      //fileSource: null,
    };
    APIHelper.post(APIURLConstant.CREATE_RECAP_REPORT, payLoad)
      .then((res) => {
        if (res) {
          const recapReportId = res;
          if (recapReportId > 0) {
            let imagesForm = new FormData();
            imagesForm.append("DelegationKey", null);
            imagesForm.append("MediaLinkID", 0);
            imagesForm.append("RecapReportID", recapReportId);
            imagesForm.append("Title", headerText);
            imagesForm.append("URL", "");
            imagesForm.append("MainPath", "");
            imagesForm.append("MediaOrder", 0);
            imagesForm.append("IsSocialMediaResource", false);
            imagesForm.append("ImageOrder", 1);
            imagesForm.append("IsDeleted", false);
            if (socialMediaLinks.length > 0) {
              socialMediaLinks.forEach((item) => {
                imagesForm.append("MediaTitle", item.title);
              });
            }

            if (imageFiles.length > 0) {
              imageFiles.forEach((file) => {
                imagesForm.append("FileSource", file[0].file); //file[0].file
              });
            }

            if (socialMediaFiles.length > 0) {
              socialMediaFiles.forEach((file) => {
                imagesForm.append("FileSource", file[0].file);
              });
            }

            if (socialMediaFiles.length === 0 && imageFiles.length === 0) {
              imagesForm.append("FileSource", null);
            }

            // fileDetails.forEach((file) => {
            //   imagesForm.append("FileDetails[]", file)
            // });
            //imagesForm.append("FileDetails", fileDetails)
            //TO DO
            // if (fileDetails.length > 0) {
            //   for (let i=0; i < fileDetails.length; i++) {
            //     if (fileDetails[i] !== undefined && fileDetails[i].IsSocialMediaResource) {
            //       fileDetails[i]['URL'] = socialMediaLinks[i]?.url
            //       fileDetails[i]['FileName'] = socialMediaLinks[i]?.title
            //     }
            //   }
            // }
            imagesForm.append("FileDetails", JSON.stringify(fileDetails));
            //fileDetails.forEach((item) => imagesForm.append("FileDetails[]", item))

            if (imageFiles.length > 0 || socialMediaFiles.length > 0) {
              APIHelper.post(
                APIURLConstant.CREATE_RECAP_REPORT_SOCIALMEDIALINKS,
                imagesForm
              )
                .then((data) => {
                  setToasterMessage({
                    showToaster: true,
                    errorType: "success",
                    message: `SOCIAL MEDIA LINKS uploaded Successfully`,
                  });
                  setLoading(false);
                  //setRecapTab(0)
                  props?.setShowCreateRecapReport(false);
                  props?.getRecapReportList();
                })
                .catch((err) => {
                  setToasterMessage({
                    showToaster: true,
                    errorType: "error",
                    message: `Failed to upload Document`,
                  });
                  console.log(err);
                  setLoading(false);
                });
            } else {
              setLoading(false);
              //setRecapTab(0)
              props?.setShowCreateRecapReport(false);
              props?.getRecapReportList();
            }
          } else {
            setLoading(false);
          }
        }
        //setLoading(false);
      })
      .catch((error) => {
        console.log(error.response.data);
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: "Error while saving recap report details!",
        });
        setLoading(false);
      });
  };

  //TODO: Need to implement another solution for this. Here we are checking whether 'Title' is having any number
  //OR not, if having number means Image is uploaded in Azure. If not having number means no image is uploaded while
  //creating this Social Media Link and if there is no image than no need to load blank image.
  function hasNumber(myString) {
    return /\d/.test(myString);
  }

  const RecapReportPDFData = () => {
    return (
      <table className="report-container">
        <thead className="report-header ">
          <tr>
            <th className="report-header-cell pdf-table-th">
              <div className="header-info">
                <div
                  className={`p-2 flex items-center gap-3 w-full`}
                  style={{
                    backgroundColor: `${
                      headerBgColor !== null ? headerBgColor.hex : "#C8102E"
                    }`,
                  }}
                >
                  <div className="1/12">
                    <img src={Logo} className="w-12 h-12" alt="logo" />
                  </div>
                  <h1
                    className={`text-3xl m-0 mt-1 ActionNBA-Bold-Web w-11/12`}
                    style={{
                      color: `${
                        headerTextColor !== null
                          ? headerTextColor.hex
                          : "#FFFFFF"
                      }`,
                    }}
                  >
                    {headerText}
                  </h1>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tfoot className="report-footer">
          <tr>
            <td className="report-footer-cell pdf-table-td">
              <div className="footer-info">
                <div className={"page-footer"}>
                  {" "}
                  <div
                    style={{
                      backgroundColor: `${
                        footerBgColor !== null ? footerBgColor.hex : "#C8102E"
                      }`,
                    }}
                    className={`footer-pdf`}
                  ></div>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
        <tbody className="report-content">
          <tr>
            <td className="report-content-cell">
              <div className="main px-6">
                <div className="w-full flex-none md:flex justify-between">
                  <div>
                    <div className="flex gap-2 ">
                      <img
                        src={CalendarPdf}
                        className="w-6 h-6"
                        alt="calendar"
                      />
                      <p className="text-lg ActionNBA-Bold-Web text-center mb-2">
                        {programData?.overallStartDate ===
                        programData?.overallEndDate ? (
                          <p>
                            {moment(programData?.overallStartDate).format(
                              "dddd, MMMM DD, YYYY"
                            )}{" "}
                          </p>
                        ) : (
                          <p>
                            {moment(programData?.overallStartDate).format(
                              "dddd, MMMM DD, YYYY"
                            )}{" "}
                            -{" "}
                            {moment(programData?.overallEndDate).format(
                              "dddd, MMMM DD, YYYY"
                            )}
                          </p>
                        )}
                      </p>
                    </div>
                    <div className="flex gap-2 ">
                      <img
                        src={LocationPdf}
                        className="w-6 h-6"
                        alt="location"
                      />
                      <p className="text-lg ActionNBA-Bold-Web text-center mb-2">
                        {programData?.regionName}, {programData?.countryName}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="flex gap-2">
                      <img src={StudentPdf} className="w-6 h-6" alt="student" />
                      <p className="text-lg ActionNBA-Bold-Web text-center mb-2">
                        {programDetailsCount.youthsCount} Youths
                      </p>
                    </div>
                    <div className="flex gap-2 ">
                      <img src={TeacherPdf} className="w-6 h-6" alt="teacher" />
                      <p className="text-lg ActionNBA-Bold-Web text-center mb-2">
                        {programDetailsCount.teacherCount} Coaches
                      </p>
                    </div>
                  </div>
                </div>
                {ckEditorData !== "" && (
                  <p className="text-[#C8102E] text-2xl ActionNBA-Bold-Web text-center my-3">
                    Event Recap
                  </p>
                )}
                <div dangerouslySetInnerHTML={{ __html: ckEditorData }} />

                {socialMediaLinks && socialMediaLinks.length > 0 && (
                  <p className="text-[#C8102E] text-2xl ActionNBA-Bold-Web text-center my-3">
                    News, Content and Social Media Highlights{" "}
                  </p>
                )}
                <div className="my-2 ">
                  <div className="flex flex-wrap justify-around	">
                    {socialMediaLinks?.map((link) => {
                      console.log(link);
                      return (
                        <div className="my-2">
                          <div className="flex gap-2 justify-center items-center mb-4">
                            <img src={LinkPdf} className="w-6 h-6" alt="link" />
                            <a
                              className="underline text-xl"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={link.url}
                            >
                              {link.title}
                            </a>
                          </div>
                          {link?.src && link.src !== null && (
                            <img
                              alt={link.title}
                              src={
                                typeof link?.src === "object"
                                  ? URL.createObjectURL(link?.src)
                                  : link?.src
                              }
                              className="block h-[10rem] w-full"
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div>
                  <div className="w-full mt-1 flex justify-center items-center">
                    {allFileSrc?.map((image, index) => {
                      if (index === 0) {
                        return (
                          <img
                            alt={image.name}
                            src={
                              typeof image.src === "object"
                                ? URL.createObjectURL(image.src)
                                : image.src
                            }
                            className="block h-full w-full object-inherit"
                          />
                        );
                      }
                    })}
                  </div>
                </div>
                {/* forcing a new page creation */}
                {/* <div className="pagebreak"></div> */}
                <div className="">
                  <div className=" flex flex-wrap justify-between">
                    {allFileSrc?.map((images, index) => {
                      if (index !== 0) {
                        return (
                          <div className=" w-6/12 ">
                            <div className="w-full h-full p-3">
                              <img
                                alt={images.name}
                                src={
                                  typeof images.src === "object"
                                    ? URL.createObjectURL(images.src)
                                    : images.src
                                }
                                className="block h-full w-full object-inherit"
                              />
                            </div>
                          </div>
                        );
                      }
                    })}
                    {allImageUrl?.map((url) => {
                      return (
                        <div className="flex w-3/6 flex-wrap">
                          <div className="w-full p-1 md:p-2">
                            <span>{url}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <>
      <Loader showLoading={loading} />
      <div className="shadow-xl rounded bg-white w-full p-2 border my-3 overflow-x-hidden">
        {recapTab === 0 ? (
          <>
            <div className="flex-none md:flex w-full gap-2 my-2">
              <label className="text-[#1D428A] font-bold whitespace-nowrap mt-1">
                Header text :
              </label>
              <input
                className="h-10 border rounded w-full px-2 focus:outline-none focus:shadow-outline border-grey-400 text-gray-900"
                value={headerText}
                onChange={(e) => setHeaderText(e.target.value)}
              />
            </div>
            <div className="flex flex-wrap justify-even">
              <div className="flex-wrap flex gap-1 mb-2">
                <p className="text-[#1D428A] font-bold w-full ">
                  Header color :
                </p>
                <div className="w-full md:w-5/6">
                  <ChromePicker
                    color={
                      headerBgColor !== null ? headerBgColor.hex : "#C8102E"
                    }
                    onChange={(e) => handleChangeHeaderColor(e)}
                    disableAlpha
                    renderers={false}
                  />
                </div>
              </div>
              <div className="flex-wrap flex gap-1 mb-2">
                <p className="text-[#1D428A] font-bold w-full ">
                  Header text color :
                </p>
                <div className="w-full">
                  <ChromePicker
                    color={
                      headerTextColor !== null ? headerTextColor.hex : "#FFFFFF"
                    }
                    onChange={(e) => handleChangeHeaderTextColor(e)}
                    disableAlpha
                    renderers={false}
                  />
                </div>
              </div>
              <div className="flex-wrap flex gap-1 mb-2">
                <p className="text-[#1D428A] font-bold w-full">
                  Footer color :
                </p>
                <div className="w-full">
                  <ChromePicker
                    color={
                      footerBgColor !== null ? footerBgColor.hex : "#C8102E"
                    }
                    onChange={(e) => handleChangeFooterColor(e)}
                    disableAlpha
                    renderers={false}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="h-fit md:h-3/5 overflow-hidden">
            <div className="my-2">
              <label className="text-[#1D428A] font-bold my-1">
                Event Recap :
              </label>
              <Editor
                apiKey="3gsgwk706d50qes50bns71i979twmm7lk9eni57p2bk46w00"
                onInit={(evt, editor) => (editorRef.current = editor)}
                value={ckEditorData}
                // initialValue={ckEditorData}
                init={{
                  height: 300,
                  menubar: true,
                  plugins: [
                    "advlist",
                    "autolink",
                    // "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    // "table",
                    "code",
                    "help",
                    "wordcount",
                    // "powerpaste",
                  ],
                  toolbar:
                    "undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | link image code",
                  // powerpaste_allow_local_images: true,
                  // powerpaste_word_import: "prompt",
                  // powerpaste_html_import: "prompt",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
                onEditorChange={(content) => {
                  setCkEditorData(content);
                }}
              />
              {/* <CKEditor
                editor={Editor}
                config={{
                  image: {
                    upload: ["jpeg", "png", "gif", "bmp", "webp", "tiff"],
                  },
                  enterMode: 3,
                }}
                data={ckEditorData}
                onChange={(event, editor) => {
                  setCkEditorData(editor.getData());
                }}
              /> */}
            </div>

            <div className="flex-none md:flex gap-2 my-2 w-full">
              <label className="text-[#1D428A] font-bold whitespace-nowrap my-1 w-full md:w-2/12">
                Socia media links :
              </label>
              <div className="w-full md:w-10/12">
                <div className="flex-none md:flex w-full gap-3">
                  <button
                    onClick={() => setShowLinkAdd(true)}
                    className="bg-[#1D428A] p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white text-sm"
                  >
                    Add
                  </button>
                </div>

                <div className=" w-full mb-2">
                  {socialMediaLinks.map((link, index) => (
                    <div className="border flex justify-between  p-1 bg-slate-100 mr-1 my-1">
                      <span className="w-5/6 text-md font-medium truncate">
                        {link.title}
                      </span>
                      <button
                        className="w-1/6  text-right px-2 text-red-500"
                        onClick={() => handleDeleteLink(link)}
                      >
                        x
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex-none md:flex gap-2 my-2 w-full">
              <label className="text-[#1D428A] font-bold whitespace-nowrap my-1 w-full md:w-2/12">
                Add event images :
              </label>
              <div className="w-full md:w-6/12">
                <input
                  id="file-input"
                  accept="image/png, image/jpeg"
                  onChange={(e) => handleChangeFile(e)}
                  type={"file"}
                  ref={fileInputRef}
                  className="w-full text-gray-900 border border-gray-400 rounded cursor-pointer bg-gray-50 focus:outline-none"
                  aria-describedby="file_input_help"
                />
                <div className=" w-full my-2">
                  {allFileSrc?.map((src, index) => (
                    <div className="border flex justify-between p-1 bg-slate-100 mr-1 my-1">
                      <span className="text-md font-medium ">{src.name}</span>
                      <button
                        className="px-2 text-red-500"
                        onClick={() => handleDeleteSrc(src)}
                      >
                        x
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex-none md:flex gap-2 my-2 w-full">
              <label className="text-[#1D428A] font-bold whitespace-nowrap my-1 w-full md:w-2/12">
                Add images URL :
              </label>
              <div className="w-full md:w-10/12">
                <div className="flex-none md:flex w-full gap-3">
                  <input
                    value={imageUrlText}
                    onChange={(e) => {
                      setImageUrlText(e.target.value);
                      setImageErrorMesage(false);
                    }}
                    className={`h-10 border text-gray-900 rounded pl-2 w-full  md:w-5/6 focus:outline-none focus:shadow-outline 
                    ${
                      imageErrorMessage ? "border-red-400" : "border-grey-400"
                    }`}
                  />
                  <button
                    onClick={() => handleAddImgUrl()}
                    className="bg-[#1D428A] p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white text-sm"
                  >
                    Add
                  </button>
                </div>
                {imageErrorMessage && (
                  <div className="my-1 px1">
                    <span className="text-red-500 text-sm italic ">
                      Please enter valid URL
                    </span>
                  </div>
                )}
                <div className=" w-full mb-2">
                  {allImageUrl.map((url) => (
                    <div className="border flex justify-between  p-1 bg-slate-100 mr-1 my-1">
                      <span className="w-5/6 text-md font-medium truncate">
                        {url}
                      </span>
                      <button
                        className="w-1/6  text-right px-2 text-red-500"
                        onClick={() => handleDeleteImgUrl(url)}
                      >
                        x
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="my-3">
          {recapTab === 0 ? (
            <button
              className="bg-[#1D428A] p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white text-sm"
              type="button"
              onClick={() => setRecapTab(1)}
            >
              Next
            </button>
          ) : (
            <div className="flex gap-3 flex-wrap">
              <button
                className="bg-white border text-[#1D428A] border-[#1D428A] p-1 px-[1rem] h-[2.2rem]  rounded-[3px]"
                type="button"
                onClick={() => setRecapTab(0)}
              >
                Back
              </button>
              <button
                className="bg-[#1D428A] text-white p-1 px-[1rem] h-[2.2rem]  rounded-[3px]"
                type="button"
                onClick={() => {
                  handleDownload();
                }}
              >
                {props?.recapReport?.recapReportID ? "Update" : "Preview"}
              </button>
            </div>
          )}
        </div>
      </div>

      {/* PDF Preview */}
      <div className="hidden">
        <div ref={componentRef}>
          <RecapReportPDFData />
        </div>
      </div>
      {showPreview && (
        <div className="fixed left-0 top-0 z-[1055]  h-full w-full  overflow-y-auto overflow-x-hidden outline-none">
          <div className="pointer-events-none relative flex justify-center h-full w-auto translate-y-[-50px] transition-all duration-300 ease-in-out ">
            <div className="pointer-events-auto relative flex h-full max-h-[100%] w-4/6 flex-col overflow-hidden rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
              <div className="relative overflow-y-auto py-4 px-2 h-5/6 mt-[3rem]">
                <RecapReportPDFData />
                {/* <div className="">
                  {" "}
                  <div
                    style={{
                      backgroundColor: `${
                        footerBgColor !== null ? footerBgColor.hex : "#C8102E"
                      }`,
                    }}
                    className="h-[20px] w-full"
                  ></div>
                </div> */}
              </div>

              <div className="flex gap-2 justify-end p-2 border-t h-[3rem]">
                <button
                  onClick={() => {
                    setShowPreview(false);
                    saveToDatabase();
                  }}
                  className="bg-[#1D428A] text-white p-1 px-[1rem] h-[2.2rem]  rounded-[3px]"
                  type="button"
                  data-galabel={`Create Recap report  ${headerText}`}
                >
                  Save
                </button>
                {/* <button
                  onClick={() => {
                    setShowPreview(false);
                    saveToDatabase();
                    handlePrint();
                  }}
                  className="bg-[#1D428A] text-white p-1 px-[1rem] h-[2.2rem]  rounded-[3px]"
                  type="button"
                >
                  Print
                </button>{" "} */}
                <button
                  onClick={() => setShowPreview(false)}
                  className="bg-[#FFF] text-[#1D428A] border border-[#1D428A] p-1 px-[1rem] h-[2.2rem]  rounded-[3px]"
                  type="button"
                >
                  cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Popup for Add link */}
      {showLinkAdd === true ? (
        <div className="justify-center md:ustify-center md:items-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[10000] outline-none focus:outline-none">
          <div className=" mx-auto md:w-3/6 w-full mx-1">
            <div className="rounded-lg shadow-2xl flex flex-col w-full bg-white ">
              <div className="flex items-start opacity-6 justify-between px-3 py-3 border-b border-solid border-slate-200 rounded">
                <h3 className="text-2xl font-semibold">Add Social Link</h3>
                <button
                  className="p-1 ml-auto outline-none focus:outline-none"
                  onClick={() => setShowLinkAdd(false)}
                >
                  <img
                    src={CloseIcon}
                    className="h-15 w-5 transparent "
                    alt="close"
                  />
                </button>
              </div>
              <div className=" py-6 px-2 flex-auto">
                <div className="flex-none md:flex gap-2 my-3">
                  <label className="w-full md:w-1/6 text-center md:text-end required text-[#1D428A] font-bold whitespace-nowrap mt-1">
                    Title :
                  </label>
                  <div className="w-full md:w-5/6">
                    <input
                      type={"text"}
                      value={socialMediatitle}
                      placeholder="Title"
                      className={`h-10 border text-gray-900 w-full rounded pl-2 w-fullfocus:outline-none focus:shadow-outline
                      ${
                        titleErrorMessage ? "border-red-400" : "border-grey-400"
                      }`}
                      onChange={(e) => {
                        setSocialMediaTitle(e.target.value);
                        setTitleErrorMessage(false);
                      }}
                    />
                    {titleErrorMessage && (
                      <div className="my-1 px1">
                        <span className="text-red-500 text-sm italic ">
                          Please enter title
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-none md:flex gap-2 my-3">
                  <label className="w-full md:w-1/6 text-center md:text-end required text-[#1D428A] font-bold whitespace-nowrap mt-1">
                    URL :
                  </label>
                  <div className="w-full md:w-5/6">
                    <input
                      type={"text"}
                      value={socialMediainput}
                      placeholder="Enter URL"
                      className={`h-10 border text-gray-900 w-full rounded pl-2 w-fullfocus:outline-none focus:shadow-outline
                    ${linkErrorMessage ? "border-red-400" : "border-grey-400"}`}
                      onChange={(e) => {
                        setSocialMediaInput(e.target.value);
                        setLinkErrorMessage(false);
                        setValidLinkError(false);
                      }}
                    />
                    {linkErrorMessage && (
                      <div className="my-1 px1">
                        <span className="text-red-500 text-sm italic ">
                          Please enter link
                        </span>
                      </div>
                    )}
                    {validLinkError && (
                      <div className="my-1 px1">
                        <span className="text-red-500 text-sm italic ">
                          Please enter valid link
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-none md:flex gap-2 my-3">
                  <label className="w-full md:w-1/6 text-center md:text-end text-[#1D428A] font-bold whitespace-nowrap mt-1">
                    Image :
                  </label>
                  <div className="w-full  md:w-5/6">
                    <input
                      accept="image/png, image/jpeg"
                      type={"file"}
                      className="w-full text-gray-900 border border-gray-400 rounded cursor-pointer bg-gray-50 focus:outline-none"
                      aria-describedby="file_input_help"
                      onChange={(e) => handleSocialLinkFile(e)}
                      ref={socialFileRef}
                    />
                  </div>
                </div>
              </div>
              <div className="flex space-x-4 items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="border border-[#1D428A] w-20 h-[2.2rem] text-[#1D428A] rounded-[3px] rounded-[3px]"
                  type="button"
                  onClick={() => setShowLinkAdd(false)}
                >
                  CLOSE
                </button>
                <button
                  className="bg-[#1D428A] w-20 h-[2.2rem]  rounded-[3px] text-white text-sm"
                  type="button"
                  onClick={() => handleAddLink()}
                >
                  ADD
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <Toaster
        toasterMessage={toasterMessage}
        setToasterMessage={setToasterMessage}
      />
    </>
  );
};
export default CreateRecapReport;
