class MethodAndVariable {
  static VariableConstant = {
    category: "Category",
    publish: "Published",
    unpublish: "Unpublished",
  };

  static setAnswerType = (data) => {
    let answerType = [];
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        answerType.push({
          answerTypeID: data[i].answerTypeID,
          type: data[i].type,
          details: data[i].details,
          isOptionsRequired: data[i].isOptionsRequired,
        });
      }
    } else {
      answerType.push({
        answerTypeID: 0,
        type: "No Aswer Type Added in DB",
        details: null,
        isOptionsRequired: false,
      });
    }
    return answerType;
  };

  static setQuestion = (data) => {
    let questionList = [];
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        questionList.push({
          questionID: {
            value: data[i].questionID,
            validationClass: null,
            validationMessage: null,
            disable: false,
          },
          questionText: {
            value: data[i].questionText,
            validationClass: null,
            validationMessage: null,
            disable: false,
          },
          isMandatory: {
            value: data[i].isMandatory,
            validationClass: null,
            validationMessage: null,
            disable: false,
          },
          isDeleted: {
            value: data[i].isDeleted,
            validationClass: null,
            validationMessage: null,
            disable: false,
          },
          isActive: {
            value: data[i].isActive,
            validationClass: null,
            validationMessage: null,
            disable: false,
          },
          answerTypeID: {
            value: data[i].answerTypeID,
            validationClass: null,
            validationMessage: null,
            disable: false,
          },
          answerOptions: {
            value: data[i].answerOptions,
            validationClass: null,
            validationMessage: null,
            disable: false,
          },
          createdBy: {
            value: data[i].createdBy,
            validationClass: null,
            validationMessage: null,
            disable: false,
          },
          createdDate: {
            value: data[i].createdDate,
            validationClass: null,
            validationMessage: null,
            disable: false,
          },
          modifiedBy: {
            value: data[i].modifiedBy,
            validationClass: null,
            validationMessage: null,
            disable: false,
          },
          modifiedDate: {
            value: data[i].modifiedDate,
            validationClass: null,
            validationMessage: null,
            disable: false,
          },
          details: {
            value: data[i].details,
            validationClass: null,
            validationMessage: null,
            disable: false,
          },
          tagList: {
            value: data[i].tagList,
            validationClass: null,
            validationMessage: null,
            disable: false,
          },
          isSelected: {
            value: false,
            validationClass: null,
            validationMessage: null,
            disable: false,
          },
          answerType: {
            value: data[i].answerType,
            validationClass: null,
            validationMessage: null,
            disable: false,
          },
          programCategoryID: {
            value: data[i].programCategoryID,
            validationClass: null,
            validationMessage: null,
            disable: false,
          },
          programTypeID: {
            value: data[i].programTypeID,
            validationClass: null,
            validationMessage: null,
            disable: false,
          },
          totalCount: {
            value: data[i].totalCount,
            validationClass: null,
            validationMessage: null,
            disable: true,
          },
        });
      }
    } else {
      questionList.push({
        questionID: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        questionText: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        isMandatory: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        isDeleted: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        isActive: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        answerTypeID: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        answerOptions: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        createdBy: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        createdDate: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        modifiedBy: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        modifiedDate: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        details: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        tagList: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        isSelected: {
          value: false,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        answerType: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
      });
    }
    return questionList;
  };

  static setProgram = (data) => {
    let program;
    if (data && data.length > 0) {
      program = {
        programID: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        programCategory: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        programCategoryID: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        programType: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        programTypeID: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        programDuration: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        programDurationID: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        localProgramName: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        programDescription: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        overallDateStart: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        overallDateEnd: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        regions: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        regionID: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        countryOfProgram: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        countryID: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        stateOfProgram: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        stateID: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        agency: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        sponsor: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        status: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        componentTagID: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        createdBy: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        createdDate: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        modifiedBy: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        modifiedDate: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        isConfirmed: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
      };
    } else {
      program = {
        programID: {
          value: 0,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        programCategory: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        programCategoryID: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        programType: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        programTypeID: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        programDuration: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        programDurationID: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        localProgramName: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        programDescription: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        overallDateStart: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        overallDateEnd: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        regions: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        regionID: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        countryOfProgram: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        countryID: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        stateOfProgram: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        stateID: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        agency: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        sponsor: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        status: {
          value: "Draft",
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        componentTagID: {
          value: ``,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        createdBy: {
          value: 0,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        createdDate: {
          value: new Date(),
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        modifiedBy: {
          value: 0,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        modifiedDate: {
          value: new Date(),
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
        isConfirmed: {
          value: false,
          validationClass: null,
          validationMessage: null,
          disable: false,
        },
      };
    }
    return program;
  };

  static getInitials = (firstName, lastName, userName) => {
    let initials = firstName ? firstName.charAt(0).toUpperCase() : "";
    initials = lastName
      ? initials + lastName.charAt(0).toUpperCase()
      : initials + "";

    initials = initials
      ? initials
      : userName && userName.split(" ")[0].charAt(0).toUpperCase();
    return initials;
  };

  static getENVDetails = () => {
    let RETURN_URL = process.env.REACT_APP_LOCAL_APP;
    let CLIENT_ID = process.env.REACT_APP_DEV_CLIENT;

    if (window.location.href.includes(process.env.REACT_APP_DEV_APP)) {
      RETURN_URL = process.env.REACT_APP_DEV_APP;
    } else if (window.location.href.includes(process.env.REACT_APP_QA_APP)) {
      RETURN_URL = process.env.REACT_APP_QA_APP;
      CLIENT_ID = process.env.REACT_APP_QA_CLIENT;
    } else if (
      window.location.href.includes(process.env.REACT_APP_PROD_APP) ||
      window.location.href.includes(process.env.REACT_APP_PROD_APP_ALTER)
    ) {
      RETURN_URL = process.env.REACT_APP_PROD_APP;
      CLIENT_ID = process.env.REACT_APP_PROD_CLIENT;
    }
    return { RETURN_URL, CLIENT_ID };
  };
}

export default MethodAndVariable;
