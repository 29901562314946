import React, { useContext, useEffect, useState } from "react";
import DeleteIcon from "../../assets/delete.svg";
import EditIcon from "../../assets/edit.svg";
import ModuleMedical from "../../assets/module_Medical.svg";
import ModuleAfterActionReport from "../../assets/module_AfterActionReport.svg";
import ModuleCovid from "../../assets/module_Covid.svg";
import ModuleLegal from "../../assets/module_Legal.svg";
import ModuleParticipateInfo from "../../assets/module_ParticipateInfo.svg";
import ModuleRiskManagement from "../../assets/module_RiskManagement.svg";
import ModuleSatffing from "../../assets/module_Satffing.svg";
import ModuleSecurity from "../../assets/module_Security.svg";
import ModuleYouthSafety from "../../assets/module_YouthSafety.svg";
import ModuleMedia from "../../assets/module_Media.svg";
import APIHelper from "../../utils/config/apiHelper";
import APIURLConstant from "../../utils/config/apiURLConstant";
import Loader from "../../sharedComponent/Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../store/context";

const Modules = () => {
  const [allModules, setAllModules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [programDetail, setProgramDetail] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(true);
  const { userDetails } = useContext(AppContext);

  useEffect(() => {
    if (userDetails.email !== "") {
      if (location.state !== null) {
        const stateValue = location.state;
        console.log(stateValue);
        setProgramDetail(stateValue.programDetails);
      }
      getProgramModules();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, navigate, userDetails]);

  const getProgramModules = () => {
    setLoading(true);
    let programID = null;
    if (location.state !== null) {
      const stateValue = location.state;
      programID = stateValue.programDetails.programID;
    }
    const payload = {
      UserId: userDetails.userID,
      ProgramID: programID !== null ? programID : 0,
    };
    APIHelper.post(APIURLConstant.GET_PROGRAM_MODULES, payload)
      .then((res) => {
        if (res && res.length > 0) {
          setAllModules(
            res.sort(
              (a, b) =>
                a.moduleStatus !== null &&
                a.moduleStatus.localeCompare(b.moduleStatus)
            )
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // module click event

  const handleModuleClick = (moduleDetail) => {
    const userType = isAdmin ? "admin" : "user";
    switch (moduleDetail?.programModuleID) {
      case 1:
        navigate(
          `${userType}/participant-module/${moduleDetail?.programModuleID}`,
          {
            state: {
              moduleDetails: moduleDetail,
              programDetail: programDetail,
            },
          }
        );

        break;
      case 2:
        navigate(
          `${userType}/staffing-module/${moduleDetail?.programModuleID}`,
          {
            state: {
              moduleDetails: moduleDetail,
              programDetail: programDetail,
            },
          }
        );
        break;
      case 3:
        navigate(
          `${userType}/youth-safety-module/${moduleDetail?.programModuleID}`,
          {
            state: {
              moduleDetails: moduleDetail,
              programDetail: programDetail,
            },
          }
        );
        break;
      case 4:
        navigate(
          `${userType}/legal-registration-module/${moduleDetail?.programModuleID}`,
          {
            state: {
              moduleDetails: moduleDetail,
              programDetail: programDetail,
            },
          }
        );
        break;
      case 5:
        navigate(
          `${userType}/security-module/${moduleDetail?.programModuleID}`,
          {
            state: {
              moduleDetails: moduleDetail,
              programDetail: programDetail,
            },
          }
        );
        break;
      case 6:
        navigate(
          `${userType}/risk-management-module/${moduleDetail?.programModuleID}`,
          {
            state: {
              moduleDetails: moduleDetail,
              programDetail: programDetail,
            },
          }
        );
        break;
      case 7:
        navigate(
          `${userType}/medical-module/${moduleDetail?.programModuleID}`,
          {
            state: {
              moduleDetails: moduleDetail,
              programDetail: programDetail,
            },
          }
        );
        break;
      case 8:
        navigate(`${userType}/covid-module/${moduleDetail?.programModuleID}`, {
          state: { moduleDetails: moduleDetail, programDetail: programDetail },
        });
        break;
      case 9:
        navigate(`${userType}/media-module/${moduleDetail?.programModuleID}`, {
          state: { moduleDetails: moduleDetail, programDetail: programDetail },
        });
        break;
      case 10:
        navigate(
          `${userType}/after-action-report-module/${moduleDetail?.programModuleID}`,
          {
            state: {
              moduleDetails: moduleDetail,
              programDetail: programDetail,
            },
          }
        );
        break;
      default:
        navigate("/program-list");
    }
  };

  return (
    <>
      <Loader showLoading={loading} />
      <div className="mx-auto md:p-7 p-2 mt-9 overflow-x-hidded ">
        {programDetail === null && (
          <div className="flex-none md:flex  mb-2">
            <p className="text-[#c8102e] font-semibold text-[19px] w-full py-3">
              Modules
            </p>
          </div>
        )}
        <div className="bg-white border border-gray-200 rounded-lg shadow-md w-full">
          {allModules.map((module) => {
            return (
              <div
                className=" border-b p-2 border-grey-900 "
                key={Math.random()}
              >
                <button
                  className="w-full grid md:grid-cols-3 grid-cols-1 lg:grid-cols-3 "
                  onClick={() => handleModuleClick(module)}
                >
                  <div className="flex gap-2 place-self-center md:place-self-start">
                    <img
                      src={(() => {
                        switch (module.programModuleID) {
                          case 1:
                            return ModuleParticipateInfo;
                          case 2:
                            return ModuleSatffing;
                          case 3:
                            return ModuleYouthSafety;
                          case 4:
                            return ModuleLegal;
                          case 5:
                            return ModuleSecurity;
                          case 6:
                            return ModuleRiskManagement;
                          case 7:
                            return ModuleMedical;
                          case 8:
                            return ModuleCovid;
                          case 9:
                            return ModuleMedia;
                          case 10:
                            return ModuleAfterActionReport;
                          default:
                            return "";
                        }
                      })()}
                      className="w-15 h-15"
                      alt={module.programModuleName}
                    />

                    <p className="text-lg cursor-pointer text-blue-900 font-semibold">
                      {module.programModuleName}
                    </p>
                  </div>
                  <div>
                    <p className="text-blue-900 text-lg">
                      Status :
                      <span className="text-lg text-blue-900 font-semibold">
                        {module.moduleStatus !== null
                          ? module.moduleStatus
                          : "To Do"}
                      </span>
                    </p>
                  </div>
                  <div className="md:place-self-end place-self-center">
                    <img
                      data-te-toggle="tooltip"
                      title="Edit"
                      src={EditIcon}
                      // onClick={() => props.handleClickEdit(item)}
                      className="h-15 w-5 mr-3"
                      alt="edit"
                    />
                  </div>
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default Modules;
