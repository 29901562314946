import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../store/context";
import Loader from "../../sharedComponent/Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import UnauthorizedUI from "../../sharedComponent/AccessComponents/unauthorized";
import Select from "react-select";
import APIHelper from "../../utils/config/apiHelper";
import APIURLConstant from "../../utils/config/apiURLConstant";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import ButtonContainer from "../../sharedComponent/ButtonContainer/ButtonContainer";
import Toaster from "../../sharedComponent/Toaster/Toaster";
import DraggableList from "../../sharedComponent/draggableList/draggableList";
import { Modal } from "../../sharedComponent";

const SubCategoriesL2 = () => {
  const { userDetails } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [selectedCategoryForEdit, setSelectedCategoryForEdit] = useState(null);
  const [selectedCategoryForDelete, setSelectedCategoryForDelete] =
    useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showTagModal, setShowTagModal] = useState(false);
  const [selectedListOfTags, setSelectedListOfTags] = useState([]);
  const [role, setRole] = useState(null);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [userRoleList, setUserRoleList] = useState([]);
  const [parentDetails, setParentDetails] = useState(null);
  const [parentDetailsL1, setParentDetailsL1] = useState(null);
  const [modalMessage, setModalMessage] = useState({
    title: "",
    message: "",
  });
  const [errorMessage, setErrorMessage] = useState({
    categoryName: false,
  });
  const [toasterMessage, setToasterMessage] = useState({
    showToaster: false,
    errorType: "",
    message: "",
  });
  const [category, setCategory] = useState(null);
  const [subCategoryL1, setSubCategoryL1] = useState(null);
  const [subCategoryL1List, setSubCategoryL1List] = useState(null);

  const isAccessible = userDetails && userDetails.roleIDList === "1" ? 1 : -1;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state !== undefined) {
      const data = location?.state;
      setParentDetails(data?.categoryDetails);
      setParentDetailsL1(data?.categoryDetailsL1);
    }
  }, [location.state]);
  useEffect(() => {
    if (userDetails.email !== "") {
      getUsersRoles();
    }
  }, [userDetails]);

  useEffect(() => {
    if (parentDetails !== null) {
      getSubCategoryList();
    }
  }, [parentDetails]);
  useEffect(() => {
    if (parentDetailsL1 !== null) {
      getSubCategoriesBySubsectionId();
    }
  }, [parentDetailsL1]);
  useEffect(() => {
    if (parentDetails !== null && parentDetailsL1 !== null) {
      setCategory({
        value: parentDetails?.sectionID,
        label: parentDetails?.sectionName,
      });
      setSubCategoryL1({
        value: parentDetailsL1?.sectionID,
        label: parentDetailsL1?.sectionName,
      });
    }
  }, [parentDetails, parentDetailsL1]);

  const getSubCategoryList = () => {
    setLoading(true);
    const payLoad = {
      subSectionID: null,
      sectionID: parentDetails?.sectionID,
    };

    APIHelper.post(APIURLConstant.GET_SUBCATEGORIES_BY_CATEGORY_ID, payLoad)

      .then((res) => {
        const updatedTags = [];
        res.map((item) => {
          if (item.isDeleted === false && item.parentSubSectionID === 0) {
            updatedTags.push({
              label: item.subSectionName,
              value: item.subSectionID,
              ...item,
            });
          }
        });
        console.log(updatedTags);
        if (updatedTags && updatedTags.length > 0) {
          setSubCategoryL1List([...updatedTags]);
        } else {
          setSubCategoryL1List([]);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getUsersRoles = () => {
    setLoading(true);
    APIHelper.get(APIURLConstant.GET_USER_ROLES)
      .then((res) => {
        const updatedTags = res.map((item) => {
          return {
            label: item.roleName,
            value: item.roleName,
            ...item,
          };
        });
        if (updatedTags && updatedTags.length > 0) {
          const selectedRole = updatedTags.filter(
            (element) => element?.roleID === 1
          );
          setUserRoleList([...updatedTags]);
          selectedRole[0]["isDisabled"] = true;
          setSelectedRole(selectedRole);
          setRole(selectedRole);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getSubCategoriesBySubsectionId = () => {
    setLoading(true);
    const payLoad = {
      subSectionID: parentDetailsL1?.sectionID,
      sectionID: null,
    };
    APIHelper.post(APIURLConstant.GET_SUBCATEGORIES_BY_SUBSECTION_ID, payLoad)
      .then((res) => {
        setLoading(false);
        if (res && res.length > 0) {
          let dataList = [];
          if (res?.length > 0) {
            res.forEach((item) => {
              if (item.isDeleted === false && item.parentSubSectionID !== 0) {
                dataList.push({
                  parentSectionID: {
                    value: item.sectionID,
                  },
                  parentSubSectionID: {
                    value: item.parentSubSectionID,
                  },
                  sectionID: {
                    value: item.subSectionID,
                  },
                  sectionName: {
                    value: item.subSectionName,
                  },
                  userRoleIDs: {
                    value: item.userRoleIDs,
                  },
                  isSelected: {
                    value: false,
                  },
                  // totalCount: {
                  //   value: item.totalCount,
                  // },
                  isActive: {
                    value: item.isActive,
                  },
                  isDeleted: {
                    value: item.isDeleted,
                  },
                  createdDate: {
                    value: item.createdDate,
                  },
                  userRoleNames: {
                    value: item.userRoleNames,
                  },
                });
              }
            });
          }
          console.log(dataList);
          setSubCategoryList(dataList);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleSelect = (e) => {
    const adminRole = userRoleList?.filter((element) => element?.roleID === 1);
    if (e.length === 0) {
      setSelectedRole(adminRole);
      return false;
    } else {
      const isAdminRole = e.find((element) => element?.roleID === 1);
      isAdminRole ? setSelectedRole(e) : setSelectedRole([...e, ...adminRole]);
    }
  };

  const resetForm = () => {
    setCategoryName("");
    const selectedRole = role.filter((element) => element?.roleID === 1);
    setErrorMessage({ categoryName: false });

    setCategory({
      label: parentDetails?.sectionName,
      value: parentDetails?.sectionID,
    });
    setSubCategoryL1({
      value: parentDetailsL1?.sectionID,
      label: parentDetailsL1?.sectionName,
    });
    setSelectedCategoryForEdit(null);
    setSelectedRole(selectedRole);
  };

  const handleCancel = () => {
    setShowCreateForm(false);
    resetForm();
  };
  const handleOpenList = (tags) => {
    setShowTagModal(true);
    setSelectedListOfTags(tags);
  };
  const validateForm = () => {
    let isFormValid = true;

    if (categoryName === "") {
      errorMessage.categoryName = true;
      isFormValid = false;
    }

    return isFormValid;
  };
  const handleClickEdit = (item) => {
    setModalMessage({
      title: "Edit",
      message: "Do you want to edit this category ?",
    });
    setSelectedCategoryForEdit(item);
    setShowModal(true);
  };

  const handleEditConfirm = () => {
    if (selectedCategoryForEdit !== null) {
      setShowModal(false);
      setCategoryName(selectedCategoryForEdit?.sectionName?.value);
      const ids = selectedCategoryForEdit?.userRoleIDs?.value?.split(",");

      const selectedRoles = userRoleList?.filter((item) => {
        return ids?.includes(item?.roleID?.toString());
      });
      setRole(selectedRoles);
      setSelectedRole(selectedRoles);
      setShowCreateForm(true);
    }
  };

  const handleClickDelete = (item) => {
    setModalMessage({
      title: "Delete",
      message: "Do you want to delete this category ?",
    });
    setSelectedCategoryForDelete(item);
    setShowModal(true);
  };

  const handleDeleteConfirm = () => {
    if (selectedCategoryForDelete !== null) {
      const payload = {
        subSectionID: selectedCategoryForDelete?.sectionID?.value,
        sectionID: parentDetails?.sectionID,
        subSectionName: categoryName,
        parentSubSectionID: subCategoryL1?.value,
        userRoleIDs: selectedRole
          ?.map((item) => {
            return item.roleID;
          })
          .join(","),
        sortOrder: 0,
        createdBy: parseInt(userDetails?.userID),
        createdDate: new Date(),
        modifiedBy: parseInt(userDetails?.userID),
        modifiedDate: new Date(),
        isActive: false,
        isDeleted: true,
        userRoleNames: "",
      };
      createCategory(payload, "delete");
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const payload = {
        subSectionID:
          selectedCategoryForEdit !== null
            ? selectedCategoryForEdit?.sectionID?.value
            : 0,
        sectionID: parentDetails?.sectionID,
        subSectionName: categoryName,
        parentSubSectionID: subCategoryL1?.value,
        userRoleIDs: selectedRole
          ?.map((item) => {
            return item.roleID;
          })
          .join(","),
        sortOrder: 0,
        createdBy: parseInt(userDetails?.userID),
        createdDate: new Date(),
        modifiedBy: parseInt(userDetails?.userID),
        modifiedDate: new Date(),
        isActive: true,
        isDeleted: false,
        userRoleNames: "",
      };
      createCategory(
        payload,
        selectedCategoryForEdit !== null ? "edit" : "create"
      );
    } else {
      setToasterMessage({
        showToaster: true,
        errorType: "error",
        message: "Please enter required fields",
      });
    }
  };

  const createCategory = (payLoad, type) => {
    setLoading(true);

    APIHelper.post(APIURLConstant.CREATE_SUBCATEGORY, payLoad)
      .then((res) => {
        const messageString =
          type === "create"
            ? "Sub category created successfully."
            : type === "delete"
            ? "Sub category deleted successfully."
            : "Sub category updated successfully.";

        setToasterMessage({
          showToaster: true,
          errorType: "success",
          message: messageString,
        });
        setShowCreateForm(false);

        setLoading(false);
        if (type !== "delete") {
          // if (subCategoryL1?.value !== parentDetailsL1?.sectionID) {
          //   const nameL1 = parentDetails?.sectionName;
          //   const nameL2 = subCategoryL1?.label;

          //   if (nameL1 !== undefined && nameL2 !== undefined) {
          //     navigate(
          //       `/${nameL1.replace(/\s+/g, "-").toLowerCase()}/${nameL2
          //         .replace(/\s+/g, "-")
          //         .toLowerCase()}/sub-categories`,
          //       {
          //         state: {
          //           categoryDetails: {
          //             sectionName: parentDetails?.sectionName,
          //             sectionID: parentDetails?.sectionID,
          //           },
          //           categoryDetailsL1: {
          //             sectionName: subCategoryL1?.label,
          //             sectionID: subCategoryL1?.value,
          //           },
          //         },
          //       }
          //     );
          //   }
          // } else {
          getSubCategoriesBySubsectionId();
          resetForm();
          // }
        } else {
          setSelectedCategoryForDelete(null);
          getSubCategoriesBySubsectionId();
          setShowModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
        const messageString =
          type === "create"
            ? "Error while creating a Sub category."
            : type === "delete"
            ? "Error while deleting Sub category."
            : "Error while editing Sub category.";
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: messageString,
        });
        setLoading(false);
      });
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          {/* <input type="checkbox" checked={props.isSelected} onChange={null} />{" "} */}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  return (
    <div>
      {isAccessible === 1 ? (
        <div className="mt-16 md:mt-9 ">
          <Loader showLoading={loading} />
          <div className="mx-auto p-2 md:p-7">
            {showCreateForm ? (
              <div>
                <p className="text-[#c8102e] font-semibold text-[19px] py-3">
                  {selectedCategoryForEdit === null
                    ? "Create Sub Category"
                    : "Edit Sub Category"}
                </p>
                <div className="bg-white border border-gray-200 rounded-lg shadow-md w-full p-4">
                  <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                    <div className="md:w-2/12 lg:2/12 w-full">
                      <p className="text-sm pb-4 lg:p-0 md:p-0 lg:text-end lg:pt-2 text-center required text-[#394150] font-bold">
                        Parent Category :
                      </p>
                    </div>
                    <div className="w-full lg:w-4/12 md:w-7/12">
                      <Select
                        value={category}
                        options={[category]}
                        className={`w-full focus:outline-none  focus:shadow-outline text-gray-900 rounded`}
                        placeholder="Select Category"
                        isDisabled={true}
                      />
                    </div>
                  </div>
                  <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                    <div className="md:w-2/12 lg:2/12 w-full">
                      <p className="text-sm pb-4 lg:p-0 md:p-0 lg:text-end lg:pt-2 text-center required text-[#394150] font-bold">
                        Sub Parent Category :
                      </p>
                    </div>
                    <div className="w-full lg:w-4/12 md:w-7/12">
                      <Select
                        value={subCategoryL1}
                        options={subCategoryL1List}
                        className={`w-full focus:outline-none  focus:shadow-outline text-gray-900 rounded`}
                        placeholder="Select  Category"
                        onChange={(e) => {
                          setSubCategoryL1(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                    <div className="md:w-2/12 lg:2/12 w-full">
                      <p className="text-sm pb-4 lg:p-0 md:p-0 lg:text-end lg:pt-2 text-center required text-[#394150] font-bold">
                        Sub Category Name :
                      </p>
                    </div>
                    <div className="w-full lg:w-8/12 md:w-7/12">
                      <input
                        value={categoryName}
                        onChange={(e) => {
                          setCategoryName(e.target.value);
                          setErrorMessage({ category: false });
                        }}
                        placeholder="Please enter category name"
                        className={`question-input resize-none border textAreaHeight py-1 w-full border focus:outline-none focus:shadow-outline
                   
                    ${
                      errorMessage && errorMessage.categoryName === true
                        ? "border-red-400"
                        : "border-grey-400"
                    } text-gray-900 rounded pl-2`}
                      />
                      {errorMessage && errorMessage.categoryName === true && (
                        <div className="text-start">
                          <span className="text-red-500 text-sm">
                            Please enter the category name{" "}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                    <div className="md:w-2/12 lg:2/12 w-full">
                      <p className="text-sm pb-4 lg:p-0 md:p-0 lg:text-end lg:pt-2 text-center required text-[#394150] font-bold">
                        Role :
                      </p>
                    </div>
                    <div className="w-full lg:w-4/12 md:w-7/12">
                      <ReactSelect
                        options={userRoleList}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                        }}
                        onChange={(e) => handleSelect(e)}
                        value={selectedRole}
                      />
                    </div>
                  </div>
                  <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                    <div className=" md:w-2/12 w-full" />
                    <ButtonContainer
                      handleSubmit={() => handleSubmit()}
                      handleCancel={() => handleCancel()}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="flex-none md:flex justify-between  w-full py-3 md:text-start ">
                  <p className="text-[#c8102e] font-semibold text-[19px] w-full">
                    Sub Category List
                  </p>
                  <div className="w-full rounded-md">
                    <ol className="list-reset flex">
                      <li>
                        <button
                          onClick={() => navigate("/create-category")}
                          className="text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                        >
                          Category
                        </button>
                      </li>
                      <li>
                        <span className="mx-2 text-neutral-500 dark:text-neutral-400">
                          /
                        </span>
                      </li>
                      <li>
                        <button
                          onClick={() => {
                            const name = parentDetails?.sectionName;
                            if (name !== undefined) {
                              navigate(
                                `/${name
                                  .replace(/\s+/g, "-")
                                  .toLowerCase()}/sub-categories`,
                                {
                                  state: {
                                    categoryDetails: {
                                      sectionName: parentDetails?.sectionName,
                                      sectionID: parentDetails?.sectionID,
                                    },
                                  },
                                }
                              );
                            }
                          }}
                          className="text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                        >
                          {parentDetailsL1 !== null &&
                            parentDetailsL1?.sectionName}
                        </button>
                      </li>
                      <li>
                        <span className="mx-2 text-neutral-500 dark:text-neutral-400">
                          /
                        </span>
                      </li>
                      <li className="text-neutral-500 dark:text-neutral-400">
                        Sub Categories
                      </li>
                    </ol>
                  </div>

                  <div className="flex flex-wrap gap-2 w-full justify-end">
                    <button
                      type="button"
                      onClick={() => {
                        setShowCreateForm(true);
                      }}
                      className="bg-blue-900 p-1 h-8 w-20 rounded-md text-white"
                    >
                      Add
                    </button>
                  </div>
                </div>
                <DraggableList
                  data={subCategoryList}
                  isDeleteButton={true}
                  showSubCategory={false}
                  handleClickEdit={handleClickEdit}
                  handleDeleteUser={handleClickDelete}
                  handleOpenTagList={handleOpenList}
                  isSubCategory={true}
                  // onClickSelectAll={onClickSelectAll}
                  // onSelectTableRow={onSelectTableRow}
                  // sortStatusText={sortStatusText}
                  // sortStatus={sortStatus}
                  // handleSort={handleSort}
                  // onDragEnd={onDragEnd}
                />
              </div>
            )}
          </div>
          <Modal
            showModal={showTagModal}
            handleClose={() => setShowTagModal(false)}
            isTagList={true}
            data={selectedListOfTags}
            title={"Assigned User Roles"}
          />
          <Toaster
            toasterMessage={toasterMessage}
            setToasterMessage={setToasterMessage}
          />
          <Modal
            showModal={showModal}
            handleClose={() => {
              setShowModal(false);
              setSelectedCategoryForEdit(null);
            }}
            modalMessage={modalMessage}
            handleClick={() => {
              modalMessage.title === "Edit"
                ? handleEditConfirm()
                : handleDeleteConfirm();
            }}
          />
        </div>
      ) : (
        <UnauthorizedUI />
      )}
    </div>
  );
};
export default SubCategoriesL2;
