import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import DraggableListItem from "./draggableListItem";
import APIHelper from "../../utils/config/apiHelper";
import APIURLConstant from "../../utils/config/apiURLConstant";
import Loader from "../../sharedComponent/Loader/Loader";
import Toaster from "../../sharedComponent/Toaster/Toaster";

const DraggableList = (props) => {
  const { data } = props;
  const [tags, setTags] = useState(null);
  const [loading, setLoading] = useState(false);
  const [toasterMessage, setToasterMessage] = useState({
    showToaster: false,
    errorType: "",
    message: "",
  });
  useEffect(() => {
    if (data && data.length > 0) {
      setTags(data);
    } else {
      setTags(null);
    }
  }, [data]);

  function onDragEnd(result) {
    const newItems = [...tags];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setTags(newItems);
    let sortOrder = 0;
    const payload = newItems.map((item) => {
      return {
        SectionID: item.sectionID.value,
        SectionName: item.sectionName.value,
        SortOrder: ++sortOrder,
      };
    });
    if (props.isSubCategory === undefined) {
      createCategory(payload);
    }
  }

  const createCategory = (payload) => {
    setLoading(true);
    APIHelper.post(APIURLConstant.UPDATE_RESOURCE_CATEGORY_ORDER, {
      documentSections: payload,
    })
      .then((data) => {
        setToasterMessage({
          showToaster: true,
          errorType: "success",
          message: "Category order updated successfully",
        });
        setLoading(false);
      })
      .catch((err) => {
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: `Failed to update category order`,
        });
        setLoading(false);
      });
  };

  return (
    <div className="">
      {tags && tags.length > 0 && (
        <div>
          <Loader showLoading={loading} />
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-list">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {tags.length > 0 ? (
                    tags.map((item, index) => {
                      return (
                        <DraggableListItem
                          key={`drag_item_${index}`}
                          item={item}
                          index={index}
                          {...props}
                        />
                      );
                      // if (item.isDeleted === false) {
                      //     return (
                      //         <div
                      //             className="border-[#ccc] border bg-white py-4 px-2 md:px-4 mb-1 gap-2 rounded w-full grid grid-cols-1 md:grid-cols-2 "
                      //             key={Math.random()}
                      //         >
                      //             <div className="w-full flex gap-2">
                      //                 <span className="text-blue-900">
                      //                     <svg
                      //                         xmlns="http://www.w3.org/2000/svg"
                      //                         viewBox="0 0 24 24"
                      //                         fill="currentColor"
                      //                         className="w-6 h-6"
                      //                     >
                      //                         <path
                      //                             fillRule="evenodd"
                      //                             d="M18.97 3.659a2.25 2.25 0 00-3.182 0l-10.94 10.94a3.75 3.75 0 105.304 5.303l7.693-7.693a.75.75 0 011.06 1.06l-7.693 7.693a5.25 5.25 0 11-7.424-7.424l10.939-10.94a3.75 3.75 0 115.303 5.304L9.097 18.835l-.008.008-.007.007-.002.002-.003.002A2.25 2.25 0 015.91 15.66l7.81-7.81a.75.75 0 011.061 1.06l-7.81 7.81a.75.75 0 001.054 1.068L18.97 6.84a2.25 2.25 0 000-3.182z"
                      //                             clipRule="evenodd"
                      //                         />
                      //                     </svg>
                      //                 </span>
                      //                 <div>
                      //                     <p className="font-bold text-base">
                      //                         {item.documentTitle}
                      //                     </p>
                      //                     <p
                      //                         className="font-normal text-base truncate w-24 md:w-60"
                      //                         data-te-toggle="tooltip"
                      //                         title={item.description}
                      //                     >
                      //                         {item.description}
                      //                     </p>
                      //                 </div>
                      //             </div>
                      //             <div className="w-full flex flex-wrap gap-4 justify-end">
                      //                 <button
                      //                     onClick={() => {
                      //                         setSelectedItem(item);
                      //                         setShowModal(true);
                      //                         setModalMessage({
                      //                             title: "Edit",
                      //                             message: "Do you want to Edit this resource?",
                      //                         });
                      //                     }}
                      //                     data-te-toggle="tooltip"
                      //                     title="Edit"
                      //                 >
                      //                     <img
                      //                         src={EditIcon}
                      //                         className="h-5 w-5 mr-1"
                      //                         alt="edit"
                      //                     />
                      //                 </button>
                      //                 <a
                      //                     className="text-blue-900 mt-3"
                      //                     href={item.mainPath}
                      //                     download
                      //                     data-te-toggle="tooltip"
                      //                     title="Download"
                      //                 >
                      //                     <svg
                      //                         xmlns="http://www.w3.org/2000/svg"
                      //                         viewBox="0 0 24 24"
                      //                         fill="currentColor"
                      //                         className="w-5 h-5"
                      //                     >
                      //                         <path
                      //                             fillRule="evenodd"
                      //                             d="M12 2.25a.75.75 0 01.75.75v11.69l3.22-3.22a.75.75 0 111.06 1.06l-4.5 4.5a.75.75 0 01-1.06 0l-4.5-4.5a.75.75 0 111.06-1.06l3.22 3.22V3a.75.75 0 01.75-.75zm-9 13.5a.75.75 0 01.75.75v2.25a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V16.5a.75.75 0 011.5 0v2.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V16.5a.75.75 0 01.75-.75z"
                      //                             clipRule="evenodd"
                      //                         />
                      //                     </svg>
                      //                 </a>
                      //                 <button
                      //                     onClick={() => viewFile(item)}
                      //                     data-te-toggle="tooltip"
                      //                     title="View"
                      //                 >
                      //                     <svg
                      //                         xmlns="http://www.w3.org/2000/svg"
                      //                         fill="none"
                      //                         viewBox="0 0 24 24"
                      //                         strokeWidth="1.5"
                      //                         stroke="currentColor"
                      //                         className="w-6 h-6 text-blue-900 ml-2"
                      //                     >
                      //                         <path
                      //                             strokeLinecap="round"
                      //                             strokeLinejoin="round"
                      //                             d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                      //                         />
                      //                         <path
                      //                             strokeLinecap="round"
                      //                             strokeLinejoin="round"
                      //                             d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      //                         />
                      //                     </svg>{" "}
                      //                 </button>
                      //                 <button
                      //                     onClick={() => {
                      //                         setSelectedItem(item);
                      //                         setShowModal(true);
                      //                         setModalMessage({
                      //                             title: "Delete",
                      //                             message: "Do you want to delete this file?",
                      //                         });
                      //                     }}
                      //                     className="text-[#c8102e]"
                      //                     data-te-toggle="tooltip"
                      //                     title="Delete"
                      //                 >
                      //                     <svg
                      //                         xmlns="http://www.w3.org/2000/svg"
                      //                         viewBox="0 0 24 24"
                      //                         fill="currentColor"
                      //                         className="w-5 h-5"
                      //                     >
                      //                         <path
                      //                             fillRule="evenodd"
                      //                             d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                      //                             clipRule="evenodd"
                      //                         />
                      //                     </svg>
                      //                 </button>
                      //             </div>
                      //         </div>
                      //     );
                      // }
                    })
                  ) : (
                    <div className="shadow-xl bg-white py-4 px-2 mb-2 flex gap-2 rounded w-full justify-center">
                      <p>No data</p>
                    </div>
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
      <Toaster
        toasterMessage={toasterMessage}
        setToasterMessage={setToasterMessage}
      />
    </div>
  );
};

export default DraggableList;
