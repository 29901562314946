import React from "react";
import { useRef } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../sharedComponent/Loader/Loader";
import { AppContext } from "../../store/context";
import APIHelper from "../../utils/config/apiHelper";
import APIURLConstant from "../../utils/config/apiURLConstant";
import DeleteIcon from "../../assets/delete.svg";
import EditIcon from "../../assets/edit.svg";
import { Modal } from "../../sharedComponent";
import Toaster from "../../sharedComponent/Toaster/Toaster";
import ResourceListView from "./ResourceListView";
import Select, { components } from "react-select";
import moment from "moment";
const sortingOption = [
  {
    label: "ASC",
    value: "0",
  },
  {
    label: "DSC",
    value: "1",
  },
];
const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

const ResourceList = () => {
  const navigate = useNavigate();
  const { userDetails } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [tabSelected, setTabSelected] = useState(0);
  const [tabSelectedSub, setTabSelectedSub] = useState(0);
  const [tabSelectedChildSub, setTabSelectedChildSub] = useState(0);
  const [allCategoryList, setAllCateoryList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({
    title: "",
    message: "",
  });
  const [toasterMessage, setToasterMessage] = useState({
    showToaster: false,
    errorType: "",
    message: "",
  });
  const [selectedItem, setSelectedItem] = useState(null);
  const categoryHeader = useRef(null);
  const categoryHeaderSub = useRef(null);
  const categoryHeaderChildSub = useRef(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedPath, setSelectedPath] = useState(null);
  const [childSubsecionList, setChildSubSectionList] = useState(null);
  const [resourcesList, setResourcesList] = useState(null);
  const [originalResourceList, setOriginalResourceList] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [sortingValue, setSortingValue] = useState(sortingOption[0]);
  const location = useLocation();
  const [showScrollerL1, setShowScrollerL1] = useState(false);
  const [showScrollerL2, setShowScrollerL2] = useState(false);
  const [showScrollerL3, setShowScrollerL3] = useState(false);

  useEffect(() => {
    if (location.state) {
      setSelectedItem(location.state.category);
    }
  }, [location.state]);

  useEffect(() => {
    if (userDetails.email !== "") {
      getCategoryList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  // useEffect(() => {
  //   const getResourceListByCategory = () => {
  //     setLoading(true);
  //     APIHelper.get(APIURLConstant.GET_RESOURCE_LIST(0))
  //       .then((res) => {
  //         let updatedCategories = allCategoryList;
  //         if (res && res.length > 0) {
  //           for (let i = 0; i < res.length; i++) {
  //             let findIndexValue = updatedCategories.findIndex(
  //               (data) => data.sectionID === res[i].sectionID
  //             );

  //             if (findIndexValue > -1) {
  //               updatedCategories[findIndexValue].documentArray = [
  //                 ...updatedCategories[findIndexValue].documentArray,
  //                 res[i],
  //               ];
  //             }
  //           }
  //         }
  //         setCategoryList(updatedCategories);
  //         console.log(updatedCategories);
  //         setLoading(false);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setLoading(false);
  //       });
  //   };
  //   if (allCategoryList && allCategoryList.length > 0) {
  //     getResourceListByCategory();
  //   }
  // }, [allCategoryList]);
  useEffect(() => {
    if (categoryList && categoryList?.length > 0) {
      filterResources();
      const showL1 =
        document.getElementById("categoryHeader")?.scrollWidth -
        document.getElementById("categoryHeader")?.offsetWidth;
      const showL2 =
        document.getElementById("categoryHeaderSub")?.scrollWidth -
        document.getElementById("categoryHeaderSub")?.offsetWidth;
      const showL3 =
        document.getElementById("categoryHeaderChildSub")?.scrollWidth -
        document.getElementById("categoryHeaderChildSub")?.offsetWidth;

      setShowScrollerL1(showL1 > 0 ? true : false);

      setShowScrollerL2(showL2 > 0 ? true : false);
      setShowScrollerL3(showL3 > 0 ? true : false);
    }
  }, [tabSelected, tabSelectedSub, tabSelectedChildSub, categoryList]);
  useEffect(() => {
    if (
      allCategoryList[tabSelected]?.childSubSection &&
      allCategoryList[tabSelected]?.childSubSection !== null &&
      allCategoryList[tabSelected]?.childSubSection?.length > 0
    ) {
      let childSubs = [];
      allCategoryList[tabSelected]?.childSubSection?.forEach(
        (chilSub, childSubIndex) => {
          if (
            allCategoryList[tabSelected]?.subSections[tabSelectedSub]
              .isParent === false &&
            chilSub.parentSubSectionID ==
              allCategoryList[tabSelected]?.subSections[
                tabSelectedSub
              ].subSectionID?.trim("")
          ) {
            childSubs.push(chilSub);
          }
        }
      );
      const data = allCategoryList[tabSelected]?.subSections[tabSelectedSub];
      const parentData = {
        childSubSectionID: data?.subSectionID,
        childSubSectionName: data?.subSectionName,
        parentSectionID: 1,
        parentSubSectionID: data?.subSectionID,
        isParent: true,
      };

      const updatedData = [parentData].concat(childSubs);

      setChildSubSectionList(childSubs?.length > 0 ? updatedData : null);
    } else {
      setChildSubSectionList(null);
    }
  }, [tabSelectedSub]);

  const filterResources = () => {
    const sectionID = categoryList[tabSelected]?.sectionID;
    const subSectionID = categoryList[tabSelected].subSections[tabSelectedSub];
    const childSubsectionID =
      childSubsecionList &&
      childSubsecionList !== null &&
      childSubsecionList?.length > 0
        ? childSubsecionList[tabSelectedChildSub]
        : null;
    if (sectionID !== 0) {
      getDocuments(
        sectionID,
        subSectionID?.isParent === false ? subSectionID?.subSectionID : 0,
        childSubsectionID !== null &&
          childSubsectionID?.isParent === false &&
          childSubsectionID.parentSubSectionID == subSectionID.subSectionID
          ? childSubsectionID?.childSubSectionID
          : 0,
        subSectionID?.isParent !== false
          ? true
          : childSubsectionID !== null && childSubsectionID?.isParent !== false
          ? true
          : false
      );
    } else {
      getAllResources();
    }
  };
  const getAllResources = () => {
    setLoading(true);
    APIHelper.get(APIURLConstant.GET_RESOURCE_LIST(0))
      .then((res) => {
        const data = res?.filter((item) => item.documentTitle !== null);

        data?.sort(compareValues("documentTitle"));
        if (data?.length > 0) {
          setResourcesList(data);
          setOriginalResourceList(data);
        } else {
          setResourcesList(null);
          setOriginalResourceList(null);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: "Error while getting a Resources",
        });
      });
  };
  const getDocuments = (
    sectionID,
    subSectionID,
    childSubSectionID,
    isAllDocsRequired
  ) => {
    console.log(sectionID, parseInt(subSectionID), parseInt(childSubSectionID));
    setLoading(true);
    setSortingValue(sortingOption[0]);
    setSearchText("");

    APIHelper.post(
      APIURLConstant.GET_RESOURCE_BY_CATEGORY(
        sectionID,
        parseInt(subSectionID),
        parseInt(childSubSectionID),
        isAllDocsRequired
      )
    )
      .then((res) => {
        const data = res?.filter((item) => item.documentTitle !== null);

        data?.sort(compareValues("documentTitle"));
        if (data?.length > 0) {
          setResourcesList(data);
          setOriginalResourceList(data);
        } else {
          setResourcesList(null);
          setOriginalResourceList(null);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: "Error while getting a Resources",
        });
      });
  };

  const getCategoryList = () => {
    setLoading(true);
    const payload = {
      SectionID: 0,
    };
    APIHelper.post(APIURLConstant.GET_DOCUMENT_SECTION_BY_ID, payload)
      .then((res) => {
        const filterredTags = res.filter((item) =>
          item?.userRoleIDs?.includes(parseInt(userDetails?.roleIDList))
        );

        const updatedTags = filterredTags.map((item) => {
          const subsectionList =
            item.subSections !== null ? item.subSections?.split(",") : null;
          let subsectionListUpdated = [];

          if (subsectionList !== null) {
            subsectionList?.forEach((sub, index) => {
              const splitedData = sub?.split(";");
              subsectionListUpdated.push({
                subSectionID: splitedData[0].trim(""),
                subSectionName: splitedData[1],
                parentSectionID: splitedData[2].trim(""),
                isParent: false,
              });
            });
          }
          const insetParent = [
            {
              subSectionID: item.sectionID,
              subSectionName: item.sectionName,
              parentSectionID: item.sectionID,
              isParent: true,
            },
          ];

          const childSubsectionList =
            item.childSubSections !== null
              ? item.childSubSections?.split(",")
              : null;

          let childSubsectionListUpdated = [];
          if (childSubsectionList !== null) {
            childSubsectionList?.forEach((sub, index) => {
              const childSplitedData = sub?.split(";");
              childSubsectionListUpdated.push({
                childSubSectionID: childSplitedData[0].trim(""),
                childSubSectionName: childSplitedData[1],
                parentSubSectionID: childSplitedData[2].trim(""),
                parentSectionID: item.sectionID,
                isParent: false,
              });
            });
          }

          return {
            sectionID: item.sectionID,
            sectionName: item.sectionName,

            subSections: insetParent.concat(subsectionListUpdated),
            childSubSection: childSubsectionListUpdated,
            isAllResources: false,
          };
        });
        const InsertAllResources = [
          {
            sectionID: 0,
            sectionName: "All Resources",

            subSections: [],
            childSubSection: [],
            isAllResources: true,
          },
        ];

        if (updatedTags && updatedTags.length > 0) {
          setAllCateoryList(InsertAllResources.concat(updatedTags));
          setCategoryList(InsertAllResources.concat(updatedTags));
          //set tab if landing from Notification Bell Icon...
          if (location?.state?.category !== undefined) {
            setTabSelected(
              updatedTags.findIndex(
                (item) => item.sectionName === location.state.category
              )
            );
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleScroll = (scroll) => {
    categoryHeader.current.scrollLeft += scroll;
  };

  const handleScrollSub = (scroll) => {
    categoryHeaderSub.current.scrollLeft += scroll;
  };

  const handleScrollChildSub = (scroll) => {
    categoryHeaderChildSub.current.scrollLeft += scroll;
  };
  const handleDelete = () => {
    if (selectedItem !== null) {
      setLoading(true);
      setShowModal(false);
      const data = selectedItem;
      let resourceForm = new FormData();
      resourceForm.append("DocumentID", data.documentID);
      resourceForm.append("DocumentName", data.documentName);
      resourceForm.append("SectionID", data.sectionID);
      resourceForm.append("SectionName", data.sectionName);
      resourceForm.append("MainPath", data.mainPath);
      resourceForm.append("CreatedBy", data.createdBy);
      resourceForm.append("ModifiedBy", data.modifiedBy);
      resourceForm.append("IsActive", true);
      resourceForm.append("IsDeleted", true);
      resourceForm.append("FileSource", data.fileSource);

      resourceForm.append("Description", data.description);
      // resourceForm.append('FileSource', state.src.attach(Downgraded).get());
      APIHelper.post(APIURLConstant.CREATE_DOCUMENT, resourceForm)
        .then((data) => {
          setToasterMessage({
            showToaster: true,
            errorType: "success",
            message: `Document deleted successfully`,
          });
          setLoading(false);
          getCategoryList();
          setSelectedItem(null);
        })

        .catch((err) => {
          setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: `Error while deleting a document`,
          });
          console.log(err);
          setLoading(false);
        });
    }
  };

  const handleEdit = () => {
    setShowModal(false);
    setModalMessage({
      title: "",
      message: "",
    });
    if (selectedItem !== null) {
      navigate("/create-resource", {
        state: { selectedDocument: selectedItem },
      });
    }
  };

  const viewFile = (item) => {
    setShowViewModal(true);
    setSelectedPath(item.mainPath);
    // navigate("/resource-list/view", {
    //   state: { link: item.mainPath },
    // });
  };
  const handleCloseView = () => {
    setSelectedPath(null);
    setShowViewModal(false);
  };

  const handleSearchResourceList = (value) => {
    setSearchText(value);
    if (value.length > 0) {
      const filteredList = originalResourceList?.filter((item) =>
        item.documentTitle?.toLowerCase().includes(value.toLowerCase())
      );
      setResourcesList(filteredList);
    } else {
      setResourcesList(originalResourceList);
    }
  };

  function compareValues(key, order = "asc") {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === "desc" ? comparison * -1 : comparison;
    };
  }
  const handleChangeSorting = (e) => {
    setSortingValue(e);
    const data =
      e.value === "0"
        ? resourcesList.sort(compareValues("documentTitle"))
        : resourcesList.sort(compareValues("documentTitle", "desc"));
  };
  return (
    <div>
      <Loader showLoading={loading} />
      <div className="mx-auto mt-9 p-7">
        <div className="w-full flex justify-between flex-wrap  py-3">
          <p className="text-[#c8102e] font-semibold text-[19px]">
            Resource List
          </p>
          <button
            onClick={() => navigate("/create-resource")}
            className="bg-blue-900 p-1 px-[1rem] h-[2.2rem] rounded-[3px] text-white"
          >
            Add
          </button>
        </div>
        <div>
          <div className="bg-neutral-100 mb-3  overflow-hidden flex w-full border border-[#ccc] rounded  justify-between">
            {showScrollerL1 === true && (
              <button
                onClick={() => handleScroll(-500)}
                className="w-8 text-slate-400"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.72 12.53a.75.75 0 010-1.06l7.5-7.5a.75.75 0 111.06 1.06L9.31 12l6.97 6.97a.75.75 0 11-1.06 1.06l-7.5-7.5z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            )}

            <div
              ref={categoryHeader}
              className="flex flex-row overflow-x-auto w-full scroll-smooth no-scrollbar"
              id="categoryHeader"
            >
              {categoryList &&
                categoryList.length > 0 &&
                categoryList.map((item, index) => {
                  return (
                    <div>
                      <div key={Math.random()}>
                        <div
                          className={` cursor-pointer font-medium px-4 py-2 text-md whitespace-nowrap  hover:isolate  hover:bg-neutral-300 focus:isolate ${
                            tabSelected === index
                              ? " border-x-0 border-b-2 border-t-0 border-[#1D428A] text-[#1D428A]  "
                              : "text-neutral-500"
                          }`}
                          onClick={() => {
                            setTabSelected(index);
                            setTabSelectedSub(0);
                            setTabSelectedChildSub(0);
                          }}
                        >
                          {item.sectionName}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            {showScrollerL1 === true && (
              <button
                onClick={() => handleScroll(500)}
                className="w-8 text-slate-400"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            )}
          </div>
          {allCategoryList[tabSelected]?.subSections &&
            allCategoryList[tabSelected]?.subSections !== null &&
            allCategoryList[tabSelected]?.subSections?.length > 0 && (
              <div className="bg-neutral-100 mb-5  overflow-hidden flex w-full border border-[#ccc] rounded  justify-between">
                {showScrollerL2 === true && (
                  <button
                    onClick={() => handleScrollSub(-500)}
                    className="w-8 text-slate-400"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.72 12.53a.75.75 0 010-1.06l7.5-7.5a.75.75 0 111.06 1.06L9.31 12l6.97 6.97a.75.75 0 11-1.06 1.06l-7.5-7.5z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                )}
                <div
                  ref={categoryHeaderSub}
                  className="flex flex-row overflow-x-auto w-full scroll-smooth no-scrollbar"
                  id="categoryHeaderSub"
                >
                  {allCategoryList[tabSelected]?.subSections &&
                    allCategoryList[tabSelected]?.subSections !== null &&
                    allCategoryList[tabSelected]?.subSections?.length > 0 &&
                    allCategoryList[tabSelected]?.subSections?.map(
                      (sub, subIndex) => {
                        if (
                          sub?.parentSectionID ==
                          allCategoryList[tabSelected].sectionID
                        ) {
                          return (
                            <div
                              className={` cursor-pointer font-medium px-4 py-2 text-md whitespace-nowrap  hover:isolate  hover:bg-neutral-300 focus:isolate ${
                                tabSelectedSub === subIndex
                                  ? " border-x-0 border-b-2 border-t-0 border-[#1D428A] text-[#1D428A]  "
                                  : "text-neutral-500"
                              }`}
                              onClick={() => {
                                setTabSelectedSub(subIndex);
                                setTabSelectedChildSub(0);
                              }}
                            >
                              {sub.subSectionName} {sub?.isParent && "*"}
                            </div>
                          );
                        }
                      }
                    )}
                </div>
                {showScrollerL2 === true && (
                  <button
                    onClick={() => handleScrollSub(500)}
                    className="w-8 text-slate-400"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                )}
              </div>
            )}
          {childSubsecionList &&
            childSubsecionList !== null &&
            childSubsecionList?.length > 0 && (
              <div className="bg-neutral-100 mb-5  overflow-hidden flex w-full border border-[#ccc] rounded  justify-between">
                {showScrollerL3 === true && (
                  <button
                    onClick={() => handleScrollChildSub(-500)}
                    className="w-8 text-slate-400"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.72 12.53a.75.75 0 010-1.06l7.5-7.5a.75.75 0 111.06 1.06L9.31 12l6.97 6.97a.75.75 0 11-1.06 1.06l-7.5-7.5z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                )}
                <div
                  ref={categoryHeaderChildSub}
                  className="flex flex-row overflow-x-auto w-full scroll-smooth no-scrollbar"
                  id="categoryHeaderChildSub"
                >
                  {childSubsecionList?.map((chilSub, childSubIndex) => {
                    if (
                      allCategoryList[tabSelected]?.subSections[tabSelectedSub]
                        ?.isParent === false &&
                      chilSub.parentSubSectionID ==
                        allCategoryList[tabSelected]?.subSections[
                          tabSelectedSub
                        ].subSectionID?.trim("")
                    ) {
                      return (
                        <div
                          className={` cursor-pointer font-medium px-4 py-2 text-md whitespace-nowrap  hover:isolate  hover:bg-neutral-300 focus:isolate ${
                            tabSelectedChildSub === childSubIndex
                              ? " border-x-0 border-b-2 border-t-0 border-[#1D428A] text-[#1D428A]  "
                              : "text-neutral-500"
                          }`}
                          onClick={() => setTabSelectedChildSub(childSubIndex)}
                        >
                          {chilSub.childSubSectionName}{" "}
                          {chilSub?.isParent && "*"}
                        </div>
                      );
                    }
                  })}
                </div>
                {showScrollerL3 === true && (
                  <button
                    onClick={() => handleScrollChildSub(500)}
                    className="w-8 text-slate-400"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                )}
              </div>
            )}

          <div className="">
            <div className="flex flex-wrap justify-between pb-4">
              <div className="w-full  md:w-4/12">
                <div className="flex">
                  <input
                    type="text"
                    placeholder="Search"
                    className="w-full h-10 focus:outline-none focus:shadow-outline border-y border-l px-2 border-grey-300 rounded-l "
                    onChange={(e) => handleSearchResourceList(e.target.value)}
                    value={searchText}
                    onKeyUp={(e) => {
                      if (e.key === "Enter" && searchText?.length > 0) {
                        handleSearchResourceList(e.target.value);
                      }
                    }}
                  />
                  <div className="h-10 border-y border-r border-grey-300 rounded-r flex align-center">
                    {searchText !== undefined && searchText.length > 0 && (
                      <button
                        className="  pl-2 "
                        onClick={() => {
                          setSearchText("");
                          setResourcesList(originalResourceList);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18 18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full md:w-2/12 flex justify-end items-center">
                <Select
                  defaultValue={sortingOption[0]}
                  value={sortingValue}
                  options={sortingOption}
                  classNamePrefix="select"
                  className={`w-[9rem] basic-single focus:outline-none focus:shadow-outline text-gray-900 rounded`}
                  placeholder="Sort by Name"
                  onChange={(e) => handleChangeSorting(e)}
                  components={{
                    ValueContainer: CustomValueContainer,
                  }}
                  styles={{
                    container: (provided, state) => ({
                      ...provided,
                      // marginTop: 70,
                    }),
                    valueContainer: (provided, state) => ({
                      ...provided,
                      overflow: "visible",
                    }),
                    placeholder: (provided, state) => ({
                      ...provided,
                      position: "absolute",
                      top:
                        state.hasValue || state.selectProps.inputValue
                          ? -13
                          : "50%",
                      backgroundColor:
                        state.hasValue || state.selectProps.inputValue
                          ? "white"
                          : "transparent",
                      transition: "top 0.1s, font-size 0.1s",
                      fontSize:
                        (state.hasValue || state.selectProps.inputValue) && 13,
                    }),
                  }}
                />
              </div>
            </div>
            {resourcesList !== null && resourcesList?.length > 0 ? (
              resourcesList?.map((item) => {
                if (item.isDeleted === false) {
                  return (
                    <div
                      className="border-[#ccc] border bg-white p-2 md:px-4 mb-1 gap-2 rounded w-full flex-none md:flex "
                      key={Math.random()}
                    >
                      <div className="w-full flex gap-2 md:w-4/12 items-center">
                        <span className="text-blue-900">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              fillRule="evenodd"
                              d="M18.97 3.659a2.25 2.25 0 00-3.182 0l-10.94 10.94a3.75 3.75 0 105.304 5.303l7.693-7.693a.75.75 0 011.06 1.06l-7.693 7.693a5.25 5.25 0 11-7.424-7.424l10.939-10.94a3.75 3.75 0 115.303 5.304L9.097 18.835l-.008.008-.007.007-.002.002-.003.002A2.25 2.25 0 015.91 15.66l7.81-7.81a.75.75 0 011.061 1.06l-7.81 7.81a.75.75 0 001.054 1.068L18.97 6.84a2.25 2.25 0 000-3.182z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                        <div>
                          <button onClick={() => viewFile(item)}>
                            <p className="font-bold text-base text-[#0000EE] lg:text-black">
                              {item.documentTitle}
                            </p>
                          </button>
                          {/* {(item.description !== null ||
                            item.description !== "null") && (
                            <p
                              className="font-normal text-base truncate w-24 md:w-60"
                              data-te-toggle="tooltip"
                              title={item.description}
                            >
                              {item.description}
                            </p>
                          )} */}
                          <p className="text-[12px]">
                            <span className="italic">Created By</span>:{" "}
                            {item.createdByName},{" "}
                            <span className="italic">Created on</span>:{" "}
                            {moment(item.createdDate).format("MM/DD/YYYY")}
                          </p>
                        </div>
                      </div>
                      <div className="w-full flex items-center md:w-4/12 ">
                        {allCategoryList[tabSelected]?.isAllResources ===
                          true && (
                          <div className="text-sm font-semibold flex  items-center  font-500 ">
                            {item?.sectionName}
                            {item?.subSectionName !== null && (
                              <div className="flex items-center">
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-5 h-4"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5"
                                    />
                                  </svg>
                                </span>
                                <span>{item?.subSectionName}</span>
                              </div>
                            )}
                            {item?.childSubSectionName !== null && (
                              <div className="flex items-center">
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-5 h-4"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5"
                                    />
                                  </svg>
                                </span>
                                <span>{item?.subSectionName}</span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="w-full md:w-4/12 flex flex-wrap justify-end gap-2">
                        <button>
                          <a
                            className="text-blue-900 mt-3"
                            href={item.mainPath}
                            download
                            data-te-toggle="tooltip"
                            title="Download"
                            target="_blank"
                            data-galabel="ResourceList Download-Resource"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="w-5 h-5"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12 2.25a.75.75 0 01.75.75v11.69l3.22-3.22a.75.75 0 111.06 1.06l-4.5 4.5a.75.75 0 01-1.06 0l-4.5-4.5a.75.75 0 111.06-1.06l3.22 3.22V3a.75.75 0 01.75-.75zm-9 13.5a.75.75 0 01.75.75v2.25a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V16.5a.75.75 0 011.5 0v2.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V16.5a.75.75 0 01.75-.75z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </a>
                        </button>
                        <button
                          onClick={() => viewFile(item)}
                          data-te-toggle="tooltip"
                          title="View"
                          data-galabel="ResourceList View-Resource"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-5 h-5 text-blue-900"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>{" "}
                        </button>
                        <button
                          onClick={() => {
                            setSelectedItem(item);
                            setShowModal(true);
                            setModalMessage({
                              title: "Edit",
                              message: "Do you want to Edit this resource?",
                            });
                          }}
                          data-te-toggle="tooltip"
                          title="Edit"
                          data-galabel="ResourceList Edit-Resource"
                        >
                          <img src={EditIcon} className="h-5 w-5" alt="edit" />
                        </button>
                        <button
                          onClick={() => {
                            setSelectedItem(item);
                            setShowModal(true);
                            setModalMessage({
                              title: "Delete",
                              message: "Do you want to delete this file?",
                            });
                          }}
                          className="text-[#c8102e]"
                          data-te-toggle="tooltip"
                          title="Delete"
                          data-galabel="ResourceList Delete-Resource"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  );
                }
              })
            ) : (
              <div className="shadow-xl bg-white py-4 px-2 mb-2 flex gap-2 rounded w-full justify-center">
                <p>No data</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* View Resoource Modal*/}
      {showViewModal === true ? (
        <ResourceListView
          selectedPath={selectedPath}
          handleCloseView={handleCloseView}
        />
      ) : null}
      <Modal
        showModal={showModal}
        handleClose={() => {
          setShowModal(false);
          setSelectedItem(null);
        }}
        modalMessage={modalMessage}
        handleClick={
          modalMessage.title === "Edit"
            ? handleEdit
            : modalMessage.title === "Delete"
            ? handleDelete
            : null
        } //{handleDelete}
      />
      {/* Toaster */}

      <Toaster
        toasterMessage={toasterMessage}
        setToasterMessage={setToasterMessage}
      />
    </div>
  );
};
export default ResourceList;
