import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ProgramCategoryView from "./ProgramCategory/ProgramCategoryView";
import APIHelper from "../../utils/config/apiHelper";
import TextareaAutosize from "react-textarea-autosize";

import APIURLConstant from "../../utils/config/apiURLConstant";
import JSONUtils from "../../utils/config/jsonUtils";
import ProgramQuestions from "../ProgramDetails/ProgramView/ProgramQuestions";
import Toaster from "../../sharedComponent/Toaster/Toaster";
import { AppContext } from "../../store/context";
import { useContext } from "react";
import CloneIcon from "../../assets/program_clone.svg";
import EditIcon from "../../assets/edit.svg";
import ReviewerCommentIcon from "../../assets/review2.svg";
import ModuleMedical from "../../assets/module_Medical.svg";
import ModuleAfterActionReport from "../../assets/module_AfterActionReport.svg";
import ModuleCovid from "../../assets/module_Covid.svg";
import ModuleLegal from "../../assets/module_Legal.svg";
import ModuleParticipateInfo from "../../assets/module_ParticipateInfo.svg";
import ModuleRiskManagement from "../../assets/module_RiskManagement.svg";
import ModuleSatffing from "../../assets/module_Satffing.svg";
import ModuleSecurity from "../../assets/module_Security.svg";
import ModuleYouthSafety from "../../assets/module_YouthSafety.svg";
import ModuleMedia from "../../assets/module_Media.svg";
import moment from "moment";
import Loader from "../../sharedComponent/Loader/Loader";
import DeleteIcon from "../../assets/delete.svg";
import MethodAndVariable from "../../utils/config/variableConstant";
import { Modal } from "../../sharedComponent";
import RecapReport from "./RecapReport";
import PDFDesign from "./PDFDesign";
import CommentIcon from "../../assets/comment_icon.svg";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { Mention, MentionsInput } from "react-mentions";
import MentionStyle from "./MentionStyle";

import merge from "./MentionInputContainers/merge";
import defaultStyle from "./MentionInputContainers/defaultStyle";

const ProgramDetails = () => {
  const { userDetails } = useContext(AppContext);
  const componentRef = useRef();
  const headerTabRef = useRef(null);
  const commentButtonRef = useRef(null);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [cardValue, setCardValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showReviewComment, setShowRevieweComment] = useState(false);
  const [reviewerComment, setReviewerComment] = useState("");
  const [tabActive, setTabActive] = useState(0);
  const [qAndAJSON, setQAndAJSON] = useState({});
  const [allModules, setAllModules] = useState([]);
  const [allComments, setAllComments] = useState([]);
  const [selectedCommentForDelete, setSelectedCommentForDelete] = useState({});
  const [moduleAnswersJSON, setModuleAnswersJSON] = useState(null);
  const [moduleQuestionsJSON, setModuleQuestionsJSON] = useState(null);
  const [modulePDFJSON, setModulePDFJSON] = useState(null);
  const [recapReportList, setRecapReportList] = useState([]);
  const [
    questionListForSelectedComponent,
    setQuestionListForSelectedComponent,
  ] = useState([]);
  const [toasterMessage, setToasterMessage] = useState({
    showToaster: false,
    errorType: "",
    message: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({
    title: "",
    message: "",
  });

  let count = 0;
  let countBody = 0;
  const navigate = useNavigate();
  const [showPDF, setShowPDF] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const location = useLocation();
  const [sortDirection, setSortDirection] = useState("");
  const [sortDirection1, setSortDirection1] = useState("");

  //comment state's
  const [commentInput, setCommentInput] = useState("");
  const [editItem, setEditItem] = useState(null);
  const [replyComment, setReplyComment] = useState(null);
  const [showLikedList, setShowLikedList] = useState(null);
  const [showRecapReport, setShowRecapReport] = useState(false);
  const [userList, setUserList] = useState([]);
  const [taggedUserIds, setTaggedUserIds] = useState([]);

  //PDF state's

  useEffect(() => {
    if (location.state) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      if (
        location.state.tabTwoActive !== undefined &&
        location.state.tabTwoActive === true
      ) {
        setTabActive(1);
      } else {
        setTabActive(0);
      }

      setSelectedProgram(location.state.selectedProgram);
    }
  }, [location.state]);

  useEffect(() => {
    if (
      userDetails.email !== "" &&
      selectedProgram !== null &&
      selectedProgram[0].programCategoryID &&
      selectedProgram[0].programTypeID
    ) {
      setCardValue(0);
      getQuestions(
        selectedProgram[0].programCategoryID,
        selectedProgram[0].programTypeID
      );
      getRecapReportList();
      getProgramModules();
      getProgramComments();
      getQuestionListForSelectedComponent();
      if (
        userDetails.roleIDList === "1" ||
        userDetails.roleIDList === "3" ||
        userDetails.roleIDList === "5" ||
        userDetails.roleIDList === "6"
      ) {
        setShowDownload(true);
      } else {
        setShowDownload(false);
      }
      if (
        userDetails?.roleIDList === "7" ||
        userDetails?.roleIDList === "8" ||
        userDetails?.roleIDList === "9" ||
        userDetails?.roleIDList === "10"
      ) {
        setShowRecapReport(false);
      } else {
        setShowRecapReport(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProgram, userDetails]);

  useEffect(() => {
    if (userDetails?.email !== "") {
      getUserList();
    }
  }, [userDetails]);

  useEffect(() => {
    console.log(location);
    if (
      (location.state == null || location.state == undefined) &&
      selectedProgram == null
    ) {
      getProgramDetailsById();
    }
  }, [location.pathname, location.state]);

  const getProgramDetailsById = (programId) => {
    const programID = location.pathname.split("/")[2];
    setLoading(true);

    APIHelper.get(APIURLConstant.GET_PROGRAM_DETAILS_BY_ID(programID))
      .then((res) => {
        console.log(res);
        setSelectedProgram([res]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getUserList = () => {
    setLoading(true);
    APIHelper.post(APIURLConstant.GET_ALL_USERS_LIST)
      .then((data) => {
        data = data.filter(
          (item) => item.email.toLowerCase() !== userDetails.email.toLowerCase()
        );
        const users = data.map((item) => {
          return {
            id: item.userID,
            display: item.firstName + " " + item.lastName,
          };
        });
        setUserList(users);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: "Failed to load Users ",
        });
      });
  };

  //TO DO: Need to replace with API call
  const users = [
    {
      id: "isaac",
      display: "Isaac Newton",
    },
    {
      id: "sam",
      display: "Sam Victor",
    },
    {
      id: "emma",
      display: "emmanuel@nobody.com",
    },
  ];

  //moduleQuestionsJSON
  useEffect(() => {
    if (
      moduleQuestionsJSON !== null &&
      moduleQuestionsJSON.length > 0 &&
      moduleAnswersJSON !== null
    ) {
      let modulePDF = [];
      moduleQuestionsJSON.forEach((questionItem) => {
        questionItem.elements
          //?.filter((item) => item.type !== "file")
          .forEach((question) => {
            let answersPDF;
            if (
              question.type !== "multipletext" &&
              question.type !== "text" &&
              question.type !== "file" &&
              question.type !== "paneldynamic"
            ) {
              if (
                moduleAnswersJSON[question.name] &&
                moduleAnswersJSON[question.name].length > 1
              ) {
                // answersPDF = question.choices.filter((choice) =>
                // {
                //   return moduleAnswersJSON[question.name].forEach((ans) => {
                //     return choice.value == ans})})[0].text
                let ansArr = [];
                typeof moduleAnswersJSON[question.name] === "object" &&
                  moduleAnswersJSON[question.name].forEach((ans) => {
                    ansArr.push(
                      question.choices?.filter((choice) => {
                        return choice == ans;
                      })[0]
                    );
                  });
                answersPDF = ansArr.join(",");
                if (
                  answersPDF.length === 0 &&
                  typeof moduleAnswersJSON[question.name] === "string" &&
                  moduleAnswersJSON[question.name].length > 0
                ) {
                  answersPDF = moduleAnswersJSON[question.name];
                }
              } else {
                if (
                  question.type == "rating" ||
                  question.type == "comment" ||
                  question.type == "boolean"
                ) {
                  answersPDF = moduleAnswersJSON[question.name].toString();
                } else {
                  answersPDF = question.choices?.filter((choice) => {
                    return (
                      choice.value == moduleAnswersJSON[question.name] ||
                      choice == moduleAnswersJSON[question.name]
                    );
                  })[0];
                }
              }
            } else if (question.type === "multipletext") {
              let myArr = [];
              typeof question.items === "object" &&
                question.items.forEach((que) => {
                  myArr.push(
                    que.title + ": ",
                    moduleAnswersJSON[question.name] !== undefined &&
                      moduleAnswersJSON[question.name][que.name]
                      ? moduleAnswersJSON[question.name][que.name] + ".  "
                      : ""
                  );
                });
              answersPDF = myArr.join(" ");
            } else if (question.type === "paneldynamic") {
              let myArr = [];
              typeof question.templateElements === "object" &&
                question.templateElements.forEach((que) => {
                  myArr.push(
                    que.title + ": ",
                    moduleAnswersJSON[question.name] !== undefined &&
                      moduleAnswersJSON[question.name][0][que.name]
                      ? moduleAnswersJSON[question.name][0][que.name] + ".  "
                      : ""
                  );
                });
              answersPDF = myArr.join(" ");
            } else {
              answersPDF = question.title;
              answersPDF =
                answersPDF + ": " + moduleAnswersJSON[question.name] !==
                  undefined && moduleAnswersJSON[question.name]
                  ? moduleAnswersJSON[question.name]
                  : "";
            }

            let objPDF = {
              questionText: question.title,
              answerText: answersPDF,
              // answerText:
              //   question.type === "file" &&
              //   moduleAnswersJSON[question.name] &&
              //   moduleAnswersJSON[question.name].length > 0
              //     ? moduleAnswersJSON[question.name][0].name //moduleAnswersJSON[question.name][0].name
              //     : moduleAnswersJSON[question.name] && moduleAnswersJSON[question.name].length > 0 && typeof(moduleAnswersJSON[question.name]) == 'object' ?
              //     moduleAnswersJSON[question.name].join(',') :
              //     JSON.stringify(moduleAnswersJSON[question.name]), //moduleAnswersJSON[question.name],
            };
            modulePDF.push(objPDF);
          });
      });

      if (modulePDF.length > 0) {
        setModulePDFJSON(modulePDF);
        setShowPDF(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleQuestionsJSON, moduleAnswersJSON]);
  const getRecapReportList = () => {
    setLoading(true);
    APIHelper.post(APIURLConstant.GET_RECAP_REPORT_BY_PROGRAMID, {
      ProgramId: selectedProgram[0]?.programID,
    })
      .then((res) => {
        setRecapReportList(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const handleOpenReviewPopup = () => {
    setShowRevieweComment(true);
  };

  const handleClickSubmitReview = () => {
    setShowModal(true);
    setModalMessage({
      title: "Submit to Review",
      message: "Are you sure you want to submit for review?",
    });
  };

  const handleDownloadModule = useReactToPrint({
    onPrintError: (error) => console.log(error),
    content: () => componentRef.current,
    documentTitle: "Module",
    removeAfterPrint: true,
    onAfterPrint: () => {
      setModulePDFJSON(null);
    },
  });

  useEffect(() => {
    if (modulePDFJSON && modulePDFJSON !== null) {
      handleDownloadModule();
    }
  }, [handleDownloadModule, modulePDFJSON]);

  const handleModuleDownload = (moduleDetail) => {
    setLoading(true);
    setModuleQuestionsJSON(null);
    setModuleAnswersJSON(null);
    APIHelper.post(APIURLConstant.GET_MODULE_ANSWERJS_BY_PROGRAM_AND_MODULE, {
      ProgramID: selectedProgram[0].programID,
      ModuleID: moduleDetail.programModuleID,
    })
      .then((res) => {
        if (res.moduleAnswerJS) {
          const obj = JSON.parse(res.moduleAnswerJS);
          setModuleAnswersJSON({ ...obj });
        }
        if (res.questionsJSON) {
          const obj = JSON.parse(res.questionsJSON);
          const pages = JSON.parse(obj);
          const elements = pages.pages.map((item) => {
            return { ...item.elements };
          });
          console.log(obj);
          console.log(pages);
          console.log(elements);
          const page0 = pages.pages;
          console.log(page0);
          //setModuleQuestionsJSON(page0);
          setModuleQuestionsJSON(page0);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getProgramComments = () => {
    if (selectedProgram[0].programID) {
      setLoading(true);
      APIHelper.post(APIURLConstant.GET_PROGRAM_COMMENT_LIST, {
        ProgramID: selectedProgram[0].programID,
      })
        .then((res) => {
          let commentList = [];
          if (res && res.length > 0) {
            res.forEach((comments) => {
              commentList.push({
                programCommentID: comments.programCommentID,
                programID: comments.programID,
                comment: JSON.parse(comments.comment),
              });
            });
          }

          setAllComments(commentList);
          const showCommentPath = location?.pathname?.split("/")[3];
          if (showCommentPath == "comment") {
            commentButtonRef?.current?.click();
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const getQuestions = (categoryID, typeID) => {
    setLoading(true);
    if (categoryID && typeID) {
      APIHelper.post(APIURLConstant.GET_QUESTIONS_BY_PROGRAMCATEGORYANDTYPE, {
        ProgramCategoryID: categoryID,
        ProgramTypeID: typeID,
      })
        .then((res) => {
          let updatedQaSelectors = res.map((item) => {
            if (
              selectedProgram !== null &&
              selectedProgram[0]?.categoryTypeQuestionIDs !== null &&
              selectedProgram[0]?.categoryTypeQuestionIDs.includes(
                item.questionID
              )
            ) {
              return {
                label: item.questionText,
                value: item.questionText,
                ...item,
              };
            }
          });

          updatedQaSelectors = updatedQaSelectors.filter((element) => {
            return element !== undefined;
          });

          if (updatedQaSelectors && updatedQaSelectors.length > 0) {
            const questionsJSON =
              JSONUtils.getQuestionsJSON(updatedQaSelectors);
            setQAndAJSON(questionsJSON);
          }

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  // get Question list for selected Component

  const getQuestionListForSelectedComponent = () => {
    if (selectedProgram !== null && selectedProgram !== undefined) {
      setLoading(true);
      APIHelper.post(APIURLConstant.GET_QUESTIONS_FOR_SELECTED_COMPONENT, {
        ComponentIDList: selectedProgram[0].componentList,
        ProgramId: selectedProgram[0].programID,
      })
        .then((res) => {
          let filteredArray = [];
          const seen = new Set();
          res.forEach((el) => {
            const duplicate = seen.has(
              JSON.stringify({
                componentID: el.componentID,
                sequence: el.sequence,
              })
            );
            seen.add(
              JSON.stringify({
                componentID: el.componentID,
                sequence: el.sequence,
              })
            );

            //const duplicate = seen.has(el.componentID);
            //seen.add(el.componentID);
            if (!duplicate) {
              const obj = {
                componentID: el.componentID,
                componentName: el.componentName,
                questionList: [],
                sequence: el.sequence,
              };
              filteredArray.push(obj);
            }
          });
          res.forEach((item) => {
            filteredArray.forEach((data, index) => {
              if (
                item.componentID === data.componentID &&
                item.sequence === data.sequence &&
                item.isSelected
              ) {
                const qusObj = {
                  questionID: item.questionID,
                  questionText: item.questionText,
                  isMandatory: item.isMandatory,
                  isDeleted: item.isDeleted,
                  isActive: item.isActive,
                  answerTypeID: item.answerTypeID,
                  answerOptions: item.answerOptions,
                  createdBy: item.createdBy,
                  createdDate: item.createdDate,
                  modifiedBy: item.modifiedBy,
                  modifiedDate: item.modifiedDate,
                  details: item.details,
                  srNumber: item.srNumber,
                  tagList: item.tagList,
                  answerType: item.answerType,
                  isChecked:
                    item.isSelected && item.isSelected === true
                      ? true
                      : item.isSelected === null ||
                        item.isSelected === undefined
                      ? true
                      : false,
                };
                if (
                  questionListForSelectedComponent.find(
                    (k) => k.componentID === item.componentID
                  ) !== undefined
                ) {
                  const newQuestionList = questionListForSelectedComponent.find(
                    (k) => k.componentID === item.componentID
                  )["questionList"];
                  filteredArray[index]["questionList"] = [...newQuestionList];
                } else {
                  filteredArray[index]["questionList"] = [
                    ...data.questionList,
                    qusObj,
                  ];
                }
              }
            });
          });
          setQuestionListForSelectedComponent(filteredArray);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const getQAndAJSONForComponents = (componentID) => {
    if (questionListForSelectedComponent.length > 0) {
      const questionAnsJSON = questionListForSelectedComponent.filter(
        (item) => {
          return item.componentID === componentID;
        }
      );
      const updatedQaSelectors = questionAnsJSON[0].questionList.map((item) => {
        return {
          label: item.questionText,
          value: item.questionText,
          ...item,
        };
      });
      const questionsJSON = JSONUtils.getQuestionsJSON(updatedQaSelectors);
      return questionsJSON;
    }
  };

  const removeSpecialCharFromComponentName = (componentName) => {
    if (componentName.includes("\n")) {
      componentName = componentName.replaceAll("\n", "");
    }

    if (componentName.includes(" ")) {
      componentName = componentName.replaceAll(" ", "-");
    }

    if (componentName.includes("#")) {
      componentName = componentName.replaceAll("#", "");
    }

    if (componentName.includes(".")) {
      componentName = componentName.replaceAll(".", "");
    }

    return componentName;
  };
  const getProgramModules = () => {
    setLoading(true);
    let programID = null;
    if (location.state !== null) {
      const stateValue = location.state;
      programID = stateValue.selectedProgram[0]?.programID;
    }
    const payload = {
      UserId: userDetails.userID,
      ProgramID: programID !== null ? programID : 0,
    };
    APIHelper.post(APIURLConstant.GET_PROGRAM_MODULES, payload)
      .then((res) => {
        if (res && res.length > 0) {
          let updatedModule = [];
          res?.forEach((item) => {
            updatedModule.push({
              ...item,
              moduleStatus:
                item?.moduleStatus == null
                  ? "To Do"
                  : item?.moduleStatus == "Not Approved"
                  ? "Requires Follow Up"
                  : item?.moduleStatus,
            });
          });
          setAllModules(updatedModule);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const goToPreviousPath = () => {
    if (
      location?.state?.isFromDashboard !== undefined &&
      location.state?.isFromDashboard !== null &&
      location?.state?.isFromDashboard === true
    ) {
      navigate("/");
    } else {
      navigate("/program-list");
    }
  };

  const handleSortModuleName = () => {
    let sortStatus = "";
    setSortDirection1("");
    if (sortDirection == "" || sortDirection == "DSC") {
      sortStatus = "ASC";
    } else {
      sortStatus = "DSC";
    }

    setSortDirection(sortStatus);
    let sortableItems = [...allModules];

    const dataSorted = sortableItems.sort((a, b) => {
      if (
        a.programModuleName.toLowerCase() < b.programModuleName.toLowerCase()
      ) {
        return sortStatus === "ASC" ? -1 : 1;
      }
      if (
        a.programModuleName.toLowerCase() > b.programModuleName.toLowerCase()
      ) {
        return sortStatus === "ASC" ? 1 : -1;
      }
      return 0;
    });

    setAllModules(dataSorted);
  };

  const handleSortModuleStatus = () => {
    setSortDirection("");

    let sortStatus = "";
    if (sortDirection1 == "" || sortDirection1 == "DSC") {
      sortStatus = "ASC";
    } else {
      sortStatus = "DSC";
    }

    setSortDirection1(sortStatus);
    let sortableItems = [...allModules];

    const dataSorted = sortableItems.sort((a, b) => {
      if (a.moduleStatus.toLowerCase() < b.moduleStatus.toLowerCase()) {
        return sortStatus === "ASC" ? -1 : 1;
      }
      if (a.moduleStatus.toLowerCase() > b.moduleStatus.toLowerCase()) {
        return sortStatus === "ASC" ? 1 : -1;
      }
      return 0;
    });

    setAllModules(dataSorted);
  };

  // module click event

  const handleModuleClick = (moduleDetail, isfeedbackTab) => {
    console.log(moduleDetail);
    const userType =
      userDetails && userDetails.roleIDList === "1" ? "admin" : "user";
    switch (moduleDetail?.programModuleID) {
      case 1:
        navigate(`${userType}/${moduleDetail?.programModuleID}`, {
          state: {
            moduleDetails: moduleDetail,
            programDetail: selectedProgram && selectedProgram[0],
            isfeedbackTab: isfeedbackTab,
          },
        });

        break;
      case 2:
        navigate(`${userType}/${moduleDetail?.programModuleID}`, {
          state: {
            moduleDetails: moduleDetail,
            programDetail: selectedProgram && selectedProgram[0],
            isfeedbackTab: isfeedbackTab,
          },
        });
        break;
      case 3:
        navigate(`${userType}/${moduleDetail?.programModuleID}`, {
          state: {
            moduleDetails: moduleDetail,
            programDetail: selectedProgram && selectedProgram[0],
            isfeedbackTab: isfeedbackTab,
          },
        });
        break;
      case 4:
        navigate(`${userType}/${moduleDetail?.programModuleID}`, {
          state: {
            moduleDetails: moduleDetail,
            programDetail: selectedProgram && selectedProgram[0],
            isfeedbackTab: isfeedbackTab,
          },
        });
        break;
      case 5:
        navigate(`${userType}/${moduleDetail?.programModuleID}`, {
          state: {
            moduleDetails: moduleDetail,
            programDetail: selectedProgram && selectedProgram[0],
            isfeedbackTab: isfeedbackTab,
          },
        });
        break;
      case 6:
        navigate(`${userType}/${moduleDetail?.programModuleID}`, {
          state: {
            moduleDetails: moduleDetail,
            programDetail: selectedProgram && selectedProgram[0],
            isfeedbackTab: isfeedbackTab,
          },
        });
        break;
      case 7:
        navigate(`${userType}/${moduleDetail?.programModuleID}`, {
          state: {
            moduleDetails: moduleDetail,
            programDetail: selectedProgram && selectedProgram[0],
            isfeedbackTab: isfeedbackTab,
          },
        });
        break;
      case 8:
        navigate(`${userType}/${moduleDetail?.programModuleID}`, {
          state: {
            moduleDetails: moduleDetail,
            programDetail: selectedProgram && selectedProgram[0],
            isfeedbackTab: isfeedbackTab,
          },
        });
        break;
      case 9:
        navigate(`${userType}/${moduleDetail?.programModuleID}`, {
          state: {
            moduleDetails: moduleDetail,
            programDetail: selectedProgram && selectedProgram[0],
            isfeedbackTab: isfeedbackTab,
          },
        });
        break;
      case 10:
        navigate(`${userType}/${moduleDetail?.programModuleID}`, {
          state: {
            moduleDetails: moduleDetail,
            programDetail: selectedProgram && selectedProgram[0],
            isfeedbackTab: isfeedbackTab,
          },
        });
        break;
      default:
        navigate("/program-list");
    }
  };

  const handlePostComment = () => {
    if (commentInput !== "") {
      let commentString = [];
      if (allComments && allComments.length > 0) {
        if (editItem === null) {
          commentString = [
            ...allComments[0].comment,
            {
              ID: allComments[0].comment.length + 1,
              author: userDetails.firstName + " " + userDetails.lastName,
              authorID: userDetails.userID,
              message: commentInput,
              createdDate: new Date(),
              modifiedDate: "",
              modifiedBy: "",
              likes: [],
              isDelete: 0,
              isReplied: replyComment !== null ? true : false,
              repliedTo: replyComment !== null ? replyComment?.author : null,
              repliedMessage:
                replyComment !== null ? replyComment?.message : null,
              repliedMessageDate:
                replyComment !== null ? replyComment?.createdDate : null,
              isReviwerComment: false,
            },
          ];
        } else {
          allComments[0]?.comment.forEach((item) => {
            if (item.ID === editItem.ID) {
              commentString.push({
                ID: item.ID,
                author: item.author,
                authorID: item.authorID,
                message: commentInput,
                createdDate: item.createdDate,
                modifiedDate: new Date(),
                modifiedBy: userDetails.firstName + " " + userDetails.lastName,
                likes: item.likes,
                isDelete: 0,
                isReplied: item?.isReplied !== null ? true : false,
                repliedTo: item?.repliedTo,
                repliedMessage: item?.repliedMessage,
                repliedMessageDate: item?.repliedMessageDate,
                isReviwerComment: item?.isReviwerComment,
              });
            } else {
              commentString.push({
                ...item,
              });
            }
          });
        }
      } else {
        commentString = [
          {
            ID: allComments.length > 0 ? allComments[0].comment.length + 1 : 1,
            author: userDetails.firstName + " " + userDetails.lastName,
            authorID: userDetails.userID,
            message: commentInput,
            createdDate: new Date(),
            modifiedDate: "",
            modifiedBy: "",
            likes: [],
            isDelete: 0,
            isReplied: replyComment !== null ? true : false,
            repliedTo: replyComment !== null ? replyComment?.author : null,
            repliedMessage:
              replyComment !== null ? replyComment?.message : null,
            repliedMessageDate:
              replyComment !== null ? replyComment?.createdDate : null,
            isReviwerComment: false,
          },
        ];
      }
      setLoading(true);
      const payLoad = {
        programCommentID:
          allComments.length > 0 ? allComments[0].programCommentID : 0,
        programID: selectedProgram[0].programID,
        comment: JSON.stringify(commentString),
      };

      let message =
        editItem === null
          ? "Comment posted successfully"
          : "Comment edited successfully";

      APIHelper.post(APIURLConstant.POST_EDIT_PROGRAM_COMMENT, payLoad)
        .then((res) => {
          console.log(res);
          setLoading(false);
          setCommentInput("");
          setEditItem(null);
          setReplyComment(null);
          getProgramComments();
          setToasterMessage({
            showToaster: true,
            errorType: "success",
            message: message,
          });
          if (editItem === null)
            updateNotification(
              selectedProgram[0].programID,
              false,
              null,
              "Program Comment Notification"
            );
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: "Error while posting a co0mment",
          });
        });
    }
  };

  const handleDeleteComment = (item) => {
    setSelectedCommentForDelete(item);
    setShowModal(true);
    setModalMessage({
      title: "Delete",
      message: `Are you sure you want to delete this comment ?`,
    });
  };

  const handleDeletCommentConfirm = (item) => {
    let updatedComments = [];
    allComments.length > 0 &&
      allComments[0]?.comment?.forEach((value) => {
        if (value.ID === item.ID) {
          updatedComments.push({
            ...value,
            isDelete: 1,
          });
        } else {
          updatedComments.push(value);
        }
      });
    if (updatedComments.length > 0) {
      setLoading(true);
      const payLoad = {
        programCommentID:
          allComments.length > 0 ? allComments[0].programCommentID : 0,
        programID: selectedProgram[0].programID,
        comment: JSON.stringify(updatedComments),
      };
      APIHelper.post(APIURLConstant.POST_EDIT_PROGRAM_COMMENT, payLoad)
        .then((res) => {
          console.log(res);
          setLoading(false);
          setToasterMessage({
            showToaster: true,
            errorType: "success",
            message: "Program comment deleted successfully",
          });
          setShowModal(false);
          setSelectedCommentForDelete({});
          getProgramComments();
          updateNotification(
            selectedProgram[0].programID,
            true,
            item.authorID,
            "Program Comment Notification"
          );
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: "Error while deleting",
          });
        });
    }
  };
  const handleSubmitReviewConfirm = () => {
    setShowModal(false);
    setModalMessage({
      title: "",
      message: "",
    });
    updateNotification(
      selectedProgram[0].programID,
      false,
      null,
      "Program Feedback In Review Notification"
    );
  };

  const handleEditComment = (item) => {
    setCommentInput(item.message);
    setEditItem(item);
  };

  const handleClickReply = (item) => {
    setReplyComment(item);
  };

  const formatCommentInput = (
    commentValue,
    newValue,
    newPlainTextValue,
    mentions
  ) => {
    //'Hello @[Shardul4 Pathak4](54), @[Vikram Tanksale](65), @[Prem Kumar](49),\nHow are you?'
    if (commentValue.includes("@")) {
      let formatedValue = commentValue.substring(1);
      var arry = formatedValue.split(" @");
      var returnArr = [];
      //var obj = {}
      for (var a of arry) {
        var obj = {};
        var value = a.substring(a.lastIndexOf("[") + 1, a.lastIndexOf("]"));
        var key = a.substring(a.lastIndexOf("(") + 1, a.lastIndexOf(")"));
        obj[`userName`] = value;
        obj[`id`] = key;
        returnArr.push(obj);
      }
      console.log(mentions);
      returnArr = returnArr
        .filter((item) => item.id !== "")
        .map((element) => element.id);
      if (returnArr.length > 0) {
        if (taggedUserIds.length > 0) {
          returnArr.push(taggedUserIds.join(","));
        }
        //returnArr.push(taggedUserIds)
        setTaggedUserIds(returnArr);
      }
      console.log(returnArr);
    }
    setCommentInput(newPlainTextValue);
  };

  const handleLikeComment = (item) => {
    let updatedComments = [];
    let itsNewLikeMessage = false;
    allComments.length > 0 &&
      allComments[0]?.comment?.forEach((value) => {
        if (value.ID === item.ID) {
          let likesList = value.likes;
          const userName = userDetails.firstName + userDetails.lastName;
          const itsNewLike = likesList.includes(userName);
          itsNewLikeMessage = !itsNewLike;
          const allLikes = [...likesList, userName];
          const updateLikes = itsNewLike
            ? allLikes.filter((name) => name !== userName)
            : allLikes;
          updatedComments.push({
            ...value,
            likes: updateLikes,
          });
        } else {
          updatedComments.push(value);
        }
      });
    if (updatedComments.length > 0) {
      setLoading(true);
      const payLoad = {
        programCommentID:
          allComments.length > 0 ? allComments[0].programCommentID : 0,
        programID: selectedProgram[0].programID,
        comment: JSON.stringify(updatedComments),
      };
      let message = itsNewLikeMessage
        ? "Program liked successfully."
        : "Program unliked successfully.";
      APIHelper.post(APIURLConstant.POST_EDIT_PROGRAM_COMMENT, payLoad)
        .then((res) => {
          setLoading(false);
          setToasterMessage({
            showToaster: true,
            errorType: "success",
            message: message,
          });
          getProgramComments();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: "Error while like.",
          });
        });
    }
  };

  const updateNotification = (
    programID,
    isNotificationDelete,
    createdBy,
    notificationTypeName
  ) => {
    setLoading(true);
    const notificationObject = {
      NotificationID: 0,
      ProgramID: programID,
      CreatedBy: createdBy ? createdBy : userDetails.userID,
      IsDelete: false,
      IsRead: false,
      IsNotificationDelete: isNotificationDelete,
      ToUserID: 0,
      NotificationIDs: null,
      NotificationType: notificationTypeName, //"Program Comment Notification",
      SendEmail: !isNotificationDelete,
      CommentText: commentInput,
      MentionedIDs: taggedUserIds.length > 0 ? taggedUserIds.join(",") : null,
    };
    APIHelper.post(APIURLConstant.ADD_UPDATE_NOTIFICATIONS, notificationObject)
      .then((data) => {
        setLoading(false);
        if (notificationTypeName == "Program Feedback In Review Notification") {
          setToasterMessage({
            showToaster: true,
            message: "Program submitted successfully for review.",
            errorType: "success",
          });
          // getProgramDetailsById();
        }
      })

      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const handleScroll = (scroll) => {
    headerTabRef.current.scrollLeft += scroll;
  };
  let style = merge({}, defaultStyle, {
    input: {
      overflow: "auto",
      height: 70,
    },
    highlighter: {
      boxSizing: "border-box",
      overflow: "hidden",
      height: 70,
    },
  });
  const handleReviewerComment = (buttonType) => {
    if (reviewerComment && reviewerComment.length > 0) {
      let commentString = [];
      if (allComments && allComments.length > 0) {
        commentString = [
          ...allComments[0].comment,
          {
            ID: allComments[0].comment.length + 1,
            author: `${userDetails.firstName} ${userDetails.lastName}`,
            authorID: userDetails.userID,
            message: reviewerComment,
            createdDate: new Date(),
            modifiedDate: "",
            modifiedBy: "",
            likes: [],
            isDelete: 0,
            isReplied: false,
            repliedTo: null,
            repliedMessage: null,
            repliedMessageDate: null,
            isReviwerComment: true,
          },
        ];
      } else {
        commentString = [
          {
            ID: allComments.length > 0 ? allComments[0].comment.length + 1 : 1,
            author: userDetails.firstName + userDetails.lastName,
            authorID: userDetails.userID,
            message: reviewerComment,
            createdDate: new Date(),
            modifiedDate: "",
            modifiedBy: "",
            likes: [],
            isDelete: 0,
            isReplied: false,
            repliedTo: null,
            repliedMessage: null,
            repliedMessageDate: null,
            isReviwerComment: true,
          },
        ];
      }

      const payLoad = {
        programCommentID:
          allComments.length > 0 ? allComments[0].programCommentID : 0,
        programID: selectedProgram[0].programID,
        comment: JSON.stringify(commentString),
      };
      console.log(commentString);
      postReviewerComment(payLoad, buttonType);
    } else {
      setToasterMessage({
        errorType: "error",
        showToaster: true,
        message: "Please enter the Review comment",
      });
    }
  };

  const postReviewerComment = (payLoad, buttonType) => {
    setLoading(true);
    APIHelper.post(APIURLConstant.POST_EDIT_PROGRAM_COMMENT, payLoad)
      .then((res) => {
        console.log(res);
        setLoading(false);
        setReviewerComment("");
        setShowRevieweComment(false);
        getProgramComments();
        setToasterMessage({
          showToaster: true,
          errorType: "success",
          message: "Reviewer comment added successfully.",
        });
        updateReviewCommentNotification(
          selectedProgram[0].programID,
          false,
          buttonType
        );
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: "Error while posting a Reviewer comment",
        });
      });
  };
  const updateReviewCommentNotification = (
    programID,
    isNotificationDelete,
    buttonType
  ) => {
    const notificationObject = {
      NotificationID: 0,
      ProgramID: programID,
      CreatedBy: userDetails.userID,
      IsDelete: false,
      IsRead: false,
      IsNotificationDelete: isNotificationDelete,
      ToUserID: 0,
      NotificationIDs: null,
      NotificationType: "Program Feedback Notification",
      SendEmail: !isNotificationDelete,
      CommentText: reviewerComment,
      MentionedIDs: taggedUserIds.length > 0 ? taggedUserIds.join(",") : null,
      ProgramState: buttonType,
    };
    APIHelper.post(APIURLConstant.ADD_UPDATE_NOTIFICATIONS, notificationObject)
      .then((data) => {
        setLoading(false);
      })

      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      <Loader showLoading={loading} />
      <div className="overflow-x-hidden oveflow-y-auto mt-16">
        <div className="block lg:hidden">
          <nav
            id="category-sidenav"
            className="fixed left-0 top-[3.8rem] category-side-nav z-[1035] h-full w-full -translate-x-full overflow-hidden bg-white shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] data-[te-sidenav-hidden='false']:translate-x-0 "
            data-te-sidenav-init
            data-te-sidenav-hidden="true"
            data-te-sidenav-mode="over"
            data-te-sidenav-content="#content"
          >
            <ul
              className="relative m-0 list-none px-[0.2rem]"
              data-te-sidenav-menu-ref
            >
              <li className="relative">
                <a
                  className=" h-full items-center  rounded-[5px] outline-none transition duration-300 ease-linear"
                  data-te-sidenav-link-ref
                >
                  {selectedProgram != null && selectedProgram != undefined && (
                    <ProgramCategoryView
                      selectedProgram={selectedProgram ? selectedProgram : null}
                      setSelectedProgram={setSelectedProgram}
                    />
                  )}
                </a>
              </li>
            </ul>
          </nav>

          <div className="p-5" id="content">
            <button
              className=" flex gap-2 text-sm text-white rounded bg-blue-900 py-1 px-2 transition duration-150 ease-in-out"
              data-te-sidenav-toggle-ref
              data-te-target="#category-sidenav"
              aria-controls="#category-sidenav"
              aria-haspopup="true"
            >
              <span>
                {selectedProgram !== null
                  ? selectedProgram[0]?.programCategory +
                    " - " +
                    selectedProgram[0]?.programType
                  : ""}
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path
                  fillRule="evenodd"
                  d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="w-full flex-none lg:flex">
          <div className="lg:w-3/12 w-full hidden lg:block p-4 bg-slate-300 h-full lg:h-screen overflow-y-scroll ">
            {selectedProgram != null && selectedProgram != undefined && (
              <ProgramCategoryView
                selectedProgram={selectedProgram ? selectedProgram : null}
                setSelectedProgram={setSelectedProgram}
              />
            )}
          </div>
          <div className="lg:w-9/12 h-full lg:h-screen overflow-y-scroll w-full px-4 bg-white ">
            <div className="flex justify-between">
              <div className="flex flex-wrap w-full">
                <button
                  className="text-[#1D428A] mr-2"
                  onClick={goToPreviousPath}
                  data-te-toggle="tooltip"
                  title="Back"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-4.28 9.22a.75.75 0 000 1.06l3 3a.75.75 0 101.06-1.06l-1.72-1.72h5.69a.75.75 0 000-1.5h-5.69l1.72-1.72a.75.75 0 00-1.06-1.06l-3 3z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <button
                  onClick={() => setTabActive(0)}
                  className={`font-medium hidden md:block px-4 py-2 text-md ${
                    tabActive === 0
                      ? "text-[#1D428A] border-b-2 border-[#C8102E] "
                      : " text-neutral-500"
                  }  `}
                >
                  {selectedProgram
                    ? selectedProgram[0].localProgramName
                    : "Program Details"}
                </button>
                <button
                  onClick={() => setTabActive(1)}
                  className={`font-medium hidden md:block px-4 py-2 text-md ${
                    tabActive === 1
                      ? "text-[#1D428A] border-b-2 border-[#C8102E] "
                      : " text-neutral-500"
                  }  `}
                >
                  Modules
                </button>
                {showRecapReport ? (
                  <button
                    onClick={() => setTabActive(2)}
                    className={`font-medium hidden md:block px-4 py-2 text-md ${
                      tabActive === 2
                        ? "text-[#1D428A] border-b-2 border-[#C8102E] "
                        : " text-neutral-500"
                    }  `}
                  >
                    Recap Report
                  </button>
                ) : null}
                <div className="bg-neutral-100 mb-5 block md:hidden  overflow-hidden flex w-full border border-[#ccc] rounded  justify-between">
                  <button
                    onClick={() => handleScroll(-500)}
                    className="w-8 text-slate-400"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.72 12.53a.75.75 0 010-1.06l7.5-7.5a.75.75 0 111.06 1.06L9.31 12l6.97 6.97a.75.75 0 11-1.06 1.06l-7.5-7.5z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>

                  <div
                    ref={headerTabRef}
                    className="flex flex-row overflow-x-auto w-full scroll-smooth no-scrollbar"
                  >
                    <button
                      onClick={() => setTabActive(0)}
                      className={`font-medium px-4 py-2 text-md whitespace-nowrap ${
                        tabActive === 0
                          ? "text-[#1D428A] border-b-2 border-[#C8102E] "
                          : " text-neutral-500"
                      }  `}
                    >
                      {selectedProgram
                        ? selectedProgram[0].localProgramName
                        : "Program Details"}
                    </button>
                    <button
                      onClick={() => setTabActive(1)}
                      className={`font-medium px-4 py-2 text-md whitespace-nowrap ${
                        tabActive === 1
                          ? "text-[#1D428A] border-b-2 border-[#C8102E] "
                          : " text-neutral-500"
                      }  `}
                    >
                      Modules
                    </button>
                    {showRecapReport ? (
                      <button
                        onClick={() => setTabActive(2)}
                        className={`font-medium px-4 py-2 text-md whitespace-nowrap ${
                          tabActive === 2
                            ? "text-[#1D428A] border-b-2 border-[#C8102E] "
                            : " text-neutral-500"
                        }  `}
                      >
                        Recap Report
                      </button>
                    ) : null}
                  </div>
                  <button
                    onClick={() => handleScroll(500)}
                    className="w-8 text-slate-400"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              {/* Comment section start */}
              <div className="">
                <>
                  <div className="fixed z-[100] w-[60px] h-[60px] right-10 bottom-8 transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300">
                    <button
                      type="button"
                      data-te-offcanvas-toggle
                      data-te-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                      data-te-ripple-init
                      data-te-ripple-color="light"
                      className="inline-block grid  place-items-center p-2 bg-white rounded-full comment-icon-shadow"
                      data-te-toggle="tooltip"
                      title="Comment"
                      ref={commentButtonRef}
                    >
                      <img
                        src={CommentIcon}
                        className="w-9 h-6"
                        alt="comment"
                      />
                      <span className="text-[#1D428A] text-[12px] font-bold">
                        Comments
                      </span>
                    </button>
                  </div>
                  <div
                    className="invisible fixed bottom-0 right-0 top-0 z-[1045] flex w-full md:w-5/12 max-w-full translate-x-full flex-col border-none bg-white bg-clip-padding text-neutral-700 shadow-sm outline-none transition duration-300 ease-in-out dark:bg-neutral-800 dark:text-neutral-200 [&[data-te-offcanvas-show]]:transform-none"
                    tabIndex="-1"
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                    data-te-offcanvas-init
                  >
                    <div className="flex items-center justify-between p-4">
                      <h5
                        className="font-semibold text-xl text-[#1D428A] leading-normal"
                        id="commentSectionLabel"
                      >
                        Comments
                      </h5>
                      <button
                        type="button"
                        className="box-content border-none hover:no-underline hover:opacity-75 text-[#1D428A]"
                        data-te-offcanvas-dismiss
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="h-6 w-6"
                          data-te-toggle="tooltip"
                          title="Close"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="flex-grow px-1 py-2 overflow-hidden">
                      <div className="p-1 h-3/4 overflow-y-auto border-t-2">
                        {allComments &&
                          allComments.length > 0 &&
                          allComments[0]?.comment.map((comment) => {
                            const userName =
                              userDetails.firstName +
                              " " +
                              userDetails.lastName;
                            if (comment.isDelete === 0) {
                              return (
                                <div
                                  className={`py-2 px-4 my-2 rounded-lg ${
                                    comment?.isReviwerComment != undefined &&
                                    comment?.isReviwerComment === true
                                      ? "bg-[#73B9D7] text-white"
                                      : "bg-white"
                                  }  text-wrap w-full shadow-lg`}
                                >
                                  <div className="flex justify-between pb-3">
                                    {comment.modifiedDate === "" ? (
                                      <div className="flex flex-wrap gap-2">
                                        <p className="text-xs font-bold">
                                          {comment.author}
                                        </p>
                                        <p className="text-xs ">
                                          {moment(comment.createdDate).format(
                                            "MM/DD/YY hh:mm a"
                                          )}
                                        </p>
                                      </div>
                                    ) : (
                                      <div className="flex flex-wrap gap-2">
                                        <p className="text-xs font-bold">
                                          {comment.author}
                                        </p>
                                        <p className="text-xs font-thin">
                                          {moment(comment.modifiedDate).format(
                                            "MM/DD/YY hh:mm a"
                                          )}{" "}
                                          edited
                                        </p>
                                      </div>
                                    )}
                                    <div className="flex gap-2 flex-wrap">
                                      <button
                                        className="text-[#1D428A]"
                                        data-te-toggle="tooltip"
                                        title="Reply"
                                        onClick={() =>
                                          handleClickReply(comment)
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="w-4 h-4"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M9.53 2.47a.75.75 0 0 1 0 1.06L4.81 8.25H15a6.75 6.75 0 0 1 0 13.5h-3a.75.75 0 0 1 0-1.5h3a5.25 5.25 0 1 0 0-10.5H4.81l4.72 4.72a.75.75 0 1 1-1.06 1.06l-6-6a.75.75 0 0 1 0-1.06l6-6a.75.75 0 0 1 1.06 0Z"
                                            clipRule="evenodd"
                                          />
                                        </svg>
                                      </button>
                                      <div
                                        onMouseOver={() =>
                                          setShowLikedList(comment.ID)
                                        }
                                        onMouseLeave={() =>
                                          setShowLikedList(null)
                                        }
                                      >
                                        <button
                                          onClick={() =>
                                            handleLikeComment(comment)
                                          }
                                          className="text-[#1D428A] flex justify-between gap-1"
                                        >
                                          {comment.likes.includes(userName) ? (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                              fill="currentColor"
                                              className="w-4 h-4"
                                            >
                                              <path d="M7.493 18.75c-.425 0-.82-.236-.975-.632A7.48 7.48 0 016 15.375c0-1.75.599-3.358 1.602-4.634.151-.192.373-.309.6-.397.473-.183.89-.514 1.212-.924a9.042 9.042 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75 2.25 2.25 0 012.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H14.23c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23h-.777zM2.331 10.977a11.969 11.969 0 00-.831 4.398 12 12 0 00.52 3.507c.26.85 1.084 1.368 1.973 1.368H4.9c.445 0 .72-.498.523-.898a8.963 8.963 0 01-.924-3.977c0-1.708.476-3.305 1.302-4.666.245-.403-.028-.959-.5-.959H4.25c-.832 0-1.612.453-1.918 1.227z" />
                                            </svg>
                                          ) : (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              strokeWidth={1.5}
                                              stroke="currentColor"
                                              className="w-4 h-4"
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"
                                              />
                                            </svg>
                                          )}

                                          <span className="text-blue text-xs font-thin">
                                            {comment.likes.length}
                                          </span>
                                        </button>
                                        {comment.likes.length > 0 &&
                                          showLikedList === comment.ID && (
                                            <div className="absolute bg-white shadow-lg right-1 border">
                                              <p className="text-md text-[#1D428A] p-2">
                                                Likes
                                              </p>
                                              <hr className=" h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
                                              {comment.likes.map((name) => (
                                                <div className="flex gap-2 p-2">
                                                  <div className="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                                                    <span className="font-medium text-gray-600 dark:text-gray-300">
                                                      {MethodAndVariable.getInitials(
                                                        name
                                                      )}
                                                    </span>
                                                  </div>
                                                  <p className="text-md ">
                                                    {name}
                                                  </p>
                                                </div>
                                              ))}
                                            </div>
                                          )}
                                      </div>
                                      {(userDetails.roleNameList ===
                                        "Youth Safety Admin" ||
                                        (comment.authorID &&
                                          comment.authorID ===
                                            userDetails.userID)) && (
                                        <>
                                          <button
                                            onClick={() =>
                                              handleEditComment(comment)
                                            }
                                            data-te-toggle="tooltip"
                                            title="Edit"
                                          >
                                            <img
                                              src={EditIcon}
                                              className="h-4 w-4"
                                              alt="edit"
                                            />
                                          </button>
                                          <button
                                            onClick={() =>
                                              handleDeleteComment(comment)
                                            }
                                            data-te-toggle="tooltip"
                                            title="Delete"
                                            data-galabel={`Delete Program Comment ${selectedProgram[0]?.localProgramName}`}
                                          >
                                            <img
                                              src={DeleteIcon}
                                              className="h-4 w-4 "
                                              alt="delete"
                                            />
                                          </button>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  {comment?.isReplied !== undefined &&
                                  comment?.repliedTo !== null &&
                                  comment?.isReplied === true ? (
                                    <div>
                                      <div className="border w-full bg-reply p-2">
                                        <div className="flex gap-2 flex-wrap">
                                          <p className="text-xs font-bold">
                                            {comment?.repliedTo}
                                          </p>
                                          <p className="text-xs">
                                            {" "}
                                            {moment(
                                              comment?.repliedMessageDate
                                            ).format("MM/DD/YY hh:mm a")}
                                          </p>
                                        </div>
                                        <p className="text-sm ">
                                          {comment?.repliedMessage}
                                        </p>
                                      </div>
                                      <p className="text-base">
                                        {comment.message}
                                      </p>
                                    </div>
                                  ) : (
                                    <p className="text-base">
                                      {comment.message}
                                    </p>
                                  )}
                                </div>
                              );
                            }
                          })}
                      </div>
                      {selectedProgram &&
                      (selectedProgram[0]?.isComment ||
                        selectedProgram[0].programReviewerID ==
                          userDetails?.userID) ? (
                        <div className="w-full h-1/4 border-t-2 ">
                          <div className=" my-2">
                            {/* <textarea
                              value={commentInput}
                              onChange={(e) => setCommentInput(e.target.value)}
                              className="h-full md:h-16  px-2 w-full border focus:outline-none focus:shadow-outline border-grey-300 text-gray-900 rounded-l-lg"
                            /> */}
                            {replyComment !== null &&
                              replyComment !== undefined && (
                                <div className=" flex gap-1">
                                  <span className="bg-orange-600 text-white text-[12px]  px-[0.2rem]">
                                    @{replyComment?.author}
                                  </span>
                                  <button
                                    onClick={() => setReplyComment(null)}
                                    className="text-[#C8102E] text-[12px] font-semibold  px-[0.2rem]"
                                  >
                                    x
                                  </button>
                                </div>
                              )}

                            <MentionsInput
                              placeholder="Add Comment. Use '@' for mention"
                              value={commentInput}
                              // onChange={(e) => setCommentInput(e.target.value)}
                              onChange={(
                                e,
                                newValue,
                                newPlainTextValue,
                                mentions
                              ) =>
                                formatCommentInput(
                                  e.target.value,
                                  newValue,
                                  newPlainTextValue,
                                  mentions
                                )
                              }
                              style={style}
                              a11ySuggestionsListLabel={"Suggested mentions"}
                            >
                              <Mention
                                className={""}
                                trigger="@"
                                style={MentionStyle}
                                data={userList?.length > 0 ? userList : users}
                                displayTransform={(id, display) =>
                                  `@${display}`
                                }
                              />
                            </MentionsInput>
                            <button
                              type="button"
                              onClick={() => handlePostComment()}
                              className="bg-[#1D428A] p-2 mt-2 text-white text-md  focus:outline-none focus:shadow-outline rounded transition duration-150 ease-in-out"
                              data-galabel={
                                editItem === null
                                  ? `Add Program Comment ${selectedProgram[0]?.localProgramName}`
                                  : `Edit Program Comment ${selectedProgram[0]?.localProgramName}`
                              }
                            >
                              Post Comment
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </>{" "}
              </div>
            </div>
            {/* comment section end */}

            <div>
              {tabActive === 0 && (
                <div>
                  <div>
                    {cardValue === 0 && (
                      <div>
                        <div className="bg-white border border-gray-200 rounded-lg shadow-md w-full p-4 mt-4 h-full">
                          <div className="text-blue-900 flex gap-3 w-full justify-end">
                            {selectedProgram &&
                              selectedProgram !== null &&
                              userDetails !== undefined &&
                              selectedProgram[0]?.createdBy ==
                                userDetails?.userID &&
                              selectedProgram[0].programState ==
                                "Needs additional Information" && (
                                <div className="mt-2 relative">
                                  <button
                                    data-te-toggle="tooltip"
                                    title="Send to Review"
                                    className="relative inline-flex w-fit text-red-600"
                                    onClick={() => handleClickSubmitReview()}
                                    data-galabel={`Send Program Review ${selectedProgram[0]?.localProgramName}`}
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      className="size-6"
                                    >
                                      <path d="M5.85 3.5a.75.75 0 0 0-1.117-1 9.719 9.719 0 0 0-2.348 4.876.75.75 0 0 0 1.479.248A8.219 8.219 0 0 1 5.85 3.5ZM19.267 2.5a.75.75 0 1 0-1.118 1 8.22 8.22 0 0 1 1.987 4.124.75.75 0 0 0 1.48-.248A9.72 9.72 0 0 0 19.266 2.5Z" />
                                      <path
                                        fillRule="evenodd"
                                        d="M12 2.25A6.75 6.75 0 0 0 5.25 9v.75a8.217 8.217 0 0 1-2.119 5.52.75.75 0 0 0 .298 1.206c1.544.57 3.16.99 4.831 1.243a3.75 3.75 0 1 0 7.48 0 24.583 24.583 0 0 0 4.83-1.244.75.75 0 0 0 .298-1.205 8.217 8.217 0 0 1-2.118-5.52V9A6.75 6.75 0 0 0 12 2.25ZM9.75 18c0-.034 0-.067.002-.1a25.05 25.05 0 0 0 4.496 0l.002.1a2.25 2.25 0 1 1-4.5 0Z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              )}
                            {selectedProgram &&
                              selectedProgram !== null &&
                              userDetails !== undefined &&
                              selectedProgram[0]?.programReviewerID ==
                                userDetails?.userID && (
                                <button
                                  data-te-toggle="tooltip"
                                  title="Add Review comment"
                                  onClick={() => handleOpenReviewPopup()}
                                  data-galabel={`Review Program Comment ${selectedProgram[0]?.localProgramName}`}
                                >
                                  <img
                                    src={ReviewerCommentIcon}
                                    alt="ReviewerComment"
                                    className="w-6 h-6"
                                  />
                                </button>
                              )}
                            {userDetails.roleIDList === "1" ||
                            userDetails.roleIDList === "2" ||
                            userDetails.roleIDList === "3" ||
                            userDetails.roleIDList === "4" ||
                            userDetails.roleIDList === "5" ||
                            userDetails.roleIDList === "6" ? (
                              <div className="flex gap-3 flex-wrap">
                                <button
                                  onClick={() => {
                                    navigate("/create-program", {
                                      state: {
                                        selectedProgram: selectedProgram,
                                        regions: selectedProgram[0].regionName,
                                        programCategory:
                                          selectedProgram[0].programCategoryID,
                                        isClone: true,
                                      },
                                    });
                                  }}
                                  data-te-toggle="tooltip"
                                  title="Clone"
                                  data-galabel="Clone Program Details"
                                >
                                  <img
                                    src={CloneIcon}
                                    className="h-15 w-5"
                                    alt="edit"
                                  />
                                </button>
                                <button
                                  data-te-toggle="tooltip"
                                  title="Edit"
                                  className="text-[#1D428A]"
                                  onClick={() => {
                                    navigate("/create-program", {
                                      state: {
                                        selectedProgram: selectedProgram,
                                        regions: selectedProgram[0].regionName,
                                        programCategory:
                                          selectedProgram[0].programCategoryID,
                                        fromPage: "programDetailsPage",
                                      },
                                    });
                                  }}
                                >
                                  <img
                                    src={EditIcon}
                                    className="h-15 w-5 mr-3"
                                    alt="edit"
                                  />
                                </button>
                              </div>
                            ) : null}
                          </div>
                          <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                            <div>
                              <p>
                                <strong>Program Category</strong>
                              </p>
                              <p className=" text-xl text-[#1D428A]">
                                {selectedProgram
                                  ? selectedProgram[0].programCategory
                                  : "Program Category"}
                              </p>
                            </div>
                            <div>
                              <p>
                                <strong>Program Type</strong>
                              </p>
                              <p className="  text-xl text-[#1D428A]">
                                {selectedProgram
                                  ? selectedProgram[0].programType
                                  : "Program Type"}
                              </p>
                            </div>
                            <div>
                              <p>
                                <strong>Program Duration</strong>
                              </p>
                              <p className="  text-xl text-[#1D428A]">
                                {selectedProgram
                                  ? selectedProgram[0].duration
                                  : "Program Duration"}
                              </p>
                            </div>
                            <div>
                              <p>
                                <strong>Local Program Name</strong>
                              </p>
                              <p className="  text-xl text-[#1D428A]">
                                {selectedProgram
                                  ? selectedProgram[0].localProgramName
                                  : "Program Name"}
                              </p>
                            </div>
                            <div>
                              <p>
                                <strong>Region</strong>
                              </p>
                              <p className="  text-xl text-[#1D428A]">
                                {selectedProgram
                                  ? selectedProgram[0].regionName
                                  : "Program Region"}
                              </p>
                            </div>
                            <div>
                              <p>
                                <strong>City</strong>
                              </p>
                              <p className="  text-xl text-[#1D428A]">
                                {selectedProgram
                                  ? selectedProgram[0].city
                                  : "Program Region"}
                              </p>
                            </div>
                            <div>
                              <p>
                                <strong>Start Date</strong>
                              </p>
                              <p className="  text-xl text-[#1D428A]">
                                {selectedProgram
                                  ? `${selectedProgram[0].overallStartDate.toString()}`
                                  : "Start Dates"}
                              </p>
                            </div>
                            <div>
                              <p>
                                <strong>End Date</strong>
                              </p>
                              <p className="  text-xl text-[#1D428A]">
                                {selectedProgram
                                  ? `${selectedProgram[0].overallEndDate.toString()}`
                                  : "End Dates"}
                              </p>
                            </div>
                            <div>
                              <p>
                                <strong>Country Of Program</strong>
                              </p>
                              <p className="  text-xl text-[#1D428A]">
                                {selectedProgram
                                  ? selectedProgram[0].countryName
                                  : "Country"}
                              </p>
                            </div>
                            {selectedProgram &&
                              selectedProgram[0].stateName && (
                                <div>
                                  <p>
                                    <strong>State Of Program</strong>
                                  </p>
                                  <p className="  text-xl text-[#1D428A]">
                                    {selectedProgram
                                      ? selectedProgram[0].stateName
                                      : "State"}
                                  </p>
                                </div>
                              )}
                            <div>
                              <p>
                                <strong>Agency</strong>
                              </p>
                              <p className="  text-xl text-[#1D428A]">
                                {selectedProgram
                                  ? selectedProgram[0].agencyList
                                  : "Agency"}
                              </p>
                            </div>
                            <div>
                              <p>
                                <strong>Sponsors</strong>
                              </p>
                              <p className="  text-xl text-[#1D428A]">
                                {selectedProgram
                                  ? selectedProgram[0].sponsorsList
                                  : "Sponsors"}
                              </p>
                            </div>
                            <div>
                              <p>
                                <strong>Created By</strong>
                              </p>
                              <p className="  text-xl text-[#1D428A]">
                                {selectedProgram
                                  ? selectedProgram[0].userName
                                  : "Created By"}
                              </p>
                            </div>
                            <div>
                              <p>
                                <strong>Reviewer</strong>
                              </p>
                              <p className="  text-xl text-[#1D428A]">
                                {selectedProgram
                                  ? location?.state?.isFromDashboard !==
                                      undefined &&
                                    location.state?.isFromDashboard !== null &&
                                    location?.state?.isFromDashboard === true
                                    ? selectedProgram[0].programReviewerName
                                    : selectedProgram[0].programReviewer
                                  : ""}
                              </p>
                            </div>
                            <div className="col-span-1 lg:col-span-3">
                              <p>
                                <strong>Program Description</strong>
                              </p>
                              <p className="  text-xl text-[#1D428A]">
                                {selectedProgram
                                  ? selectedProgram[0].programDescription
                                  : "Program Description"}
                              </p>
                            </div>
                          </div>
                          {qAndAJSON && Object.keys(qAndAJSON).length !== 0 ? (
                            <div className="">
                              <p className="pb-2">
                                <strong>Program Category And Type</strong>
                              </p>
                              <ProgramQuestions
                                modalJson={qAndAJSON}
                                toasterMessage={toasterMessage}
                                setToasterMessage={setToasterMessage}
                                programId={
                                  selectedProgram
                                    ? selectedProgram[0].programID
                                    : null
                                }
                                componentId={null}
                                sequence={null}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )}
                    {cardValue === 1 &&
                    questionListForSelectedComponent &&
                    questionListForSelectedComponent.length > 0 ? (
                      <>
                        <h4 className="text-[#1D428A] pt-4">
                          <strong>COMPONENTS</strong>
                        </h4>
                        <div className="bg-white border border-gray-200 rounded-lg shadow-md w-full p-4 mt-4 h-full:h-fit">
                          <div className="col-span-3">
                            <ul
                              className="mb-5 flex list-none flex-col flex-wrap pl-0 md:flex-row w-100 overflow-hidden overflow-x-auto"
                              role="tablist"
                              data-te-nav-ref
                            >
                              {questionListForSelectedComponent.map((item) => {
                                if (count === 0) {
                                  //(item.componentID == selectedProgram[0].componentList.split(',')[0]) {
                                  count++;
                                  return (
                                    <li
                                      role="presentation"
                                      className=" text-center"
                                    >
                                      <a
                                        href={`#${removeSpecialCharFromComponentName(
                                          item.componentName.toString().trim()
                                        )}${item.sequence}`} //"#pills-home01"
                                        className="my-2 block bg-white px-7 pb-3.5 pt-4 text-xs font-medium uppercase leading-tight text-neutral-500 data-[te-nav-active]:!bg-white data-[te-nav-active]:text-[#1D428A] dark:bg-white dark:text-white dark:data-[te-nav-active]:text-[#1D428A] data-[te-nav-active]:border-[#1D428A] data-[te-nav-active]:border-b-2"
                                        id={`pills-${removeSpecialCharFromComponentName(
                                          item.componentName.toString().trim()
                                        )}${item.sequence}`} //{`pills-${item.componentID}`}//"pills-home-tab01"
                                        data-te-toggle="pill"
                                        data-te-target={`#${removeSpecialCharFromComponentName(
                                          item.componentName.toString().trim()
                                        )}${item.sequence}`} //"#pills-home01"
                                        data-te-nav-active
                                        role="tab"
                                        aria-controls={`${removeSpecialCharFromComponentName(
                                          item.componentName.toString().trim()
                                        )}`} //"pills-home01"
                                        aria-selected="true"
                                      >
                                        {item.componentName}-{item.sequence}
                                      </a>
                                    </li>
                                  );
                                } else {
                                  return (
                                    <li
                                      role="presentation"
                                      className=" text-center"
                                    >
                                      <a
                                        href={`#${removeSpecialCharFromComponentName(
                                          item.componentName.toString().trim()
                                        )}${item.sequence}`}
                                        className="my-2 block bg-white px-7 pb-3.5 pt-4 text-xs font-medium uppercase leading-tight text-neutral-500 data-[te-nav-active]:!bg-white data-[te-nav-active]:text-[#1D428A] dark:bg-white dark:text-white dark:data-[te-nav-active]:text-[#1D428A] data-[te-nav-active]:border-[#1D428A] data-[te-nav-active]:border-b-2"
                                        id={`pills-${removeSpecialCharFromComponentName(
                                          item.componentName.toString().trim()
                                        )}${item.sequence}`} //"pills-profile-tab01"
                                        data-te-toggle="pill"
                                        data-te-target={`#${removeSpecialCharFromComponentName(
                                          item.componentName.toString().trim()
                                        )}${item.sequence}`}
                                        role="tab"
                                        aria-controls={`${removeSpecialCharFromComponentName(
                                          item.componentName.toString().trim()
                                        )}${item.sequence}`}
                                        aria-selected="false"
                                      >
                                        {item.componentName}-{item.sequence}
                                      </a>
                                    </li>
                                  );
                                }
                              })}
                            </ul>

                            {
                              <div className="mb-6">
                                {" "}
                                {questionListForSelectedComponent.map(
                                  (item) => {
                                    if (countBody === 0) {
                                      //(item.componentID == selectedProgram[0].componentList.split(',')[0]) {
                                      countBody++;
                                      return (
                                        <div
                                          className="hidden opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                                          id={`${removeSpecialCharFromComponentName(
                                            item.componentName.toString().trim()
                                          )}${item.sequence}`} //"pills-home01"
                                          role="tabpanel"
                                          aria-labelledby={`pills-${removeSpecialCharFromComponentName(
                                            item.componentName.toString().trim()
                                          )}${item.sequence}`} //"pills-home-tab01"
                                          data-te-tab-active
                                        >
                                          <ProgramQuestions
                                            modalJson={getQAndAJSONForComponents(
                                              item.componentID
                                            )}
                                            toasterMessage={toasterMessage}
                                            setToasterMessage={
                                              setToasterMessage
                                            }
                                            programId={
                                              selectedProgram
                                                ? selectedProgram[0].programID
                                                : null
                                            }
                                            componentId={item.componentID}
                                            sequence={item.sequence}
                                          />
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          className="hidden  opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                                          id={`${removeSpecialCharFromComponentName(
                                            item.componentName.toString().trim()
                                          )}${item.sequence}`}
                                          role="tabpanel"
                                          aria-labelledby={`pills-${removeSpecialCharFromComponentName(
                                            item.componentName.toString().trim()
                                          )}${item.sequence}`}
                                        >
                                          <ProgramQuestions
                                            modalJson={getQAndAJSONForComponents(
                                              item.componentID
                                            )}
                                            toasterMessage={toasterMessage}
                                            setToasterMessage={
                                              setToasterMessage
                                            }
                                            programId={
                                              selectedProgram
                                                ? selectedProgram[0].programID
                                                : null
                                            }
                                            componentId={item.componentID}
                                            sequence={item.sequence}
                                          />
                                        </div>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            }
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>

                  {/* buttons */}
                  <div className="flex justify-start p-4">
                    {cardValue === 1 &&
                    questionListForSelectedComponent &&
                    questionListForSelectedComponent.length > 0 ? (
                      <button
                        className=" text-sm border border-[#1D428A] px-4 py-2 rounded-md w-16 text-[#1D428A] mr-4"
                        type="button"
                        onClick={() => {
                          setCardValue(0);
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        Back
                      </button>
                    ) : null}

                    {/* {userDetails !== "" &&
                      userDetails.roleIDList !== "1" &&
                      cardValue === 1 &&
                      questionListForSelectedComponent &&
                      questionListForSelectedComponent.length > 0 && (
                        <button
                          className="bg-[#1D428A] text-sm px-4 py-2 rounded-md text-white mr-4"
                          type="button"
                          onClick={() => {
                            navigate("/modules", {
                              state: { programDetails: selectedProgram[0] },
                            });
                          }}
                        >
                          Next
                        </button>
                      )} */}

                    {cardValue === 0 &&
                      questionListForSelectedComponent &&
                      questionListForSelectedComponent.length > 0 && (
                        <button
                          className="bg-[#1D428A] text-sm px-4 py-2 rounded-md text-white mr-4"
                          type="button"
                          onClick={() => {
                            setCardValue(1);
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          {userDetails?.roleNameList === "Youth Safety Admin" ||
                          userDetails?.roleNameList === "Intl Bbops User" ||
                          userDetails?.roleNameList ===
                            "Regional Program User" ||
                          userDetails?.roleNameList === "US Progam User" ||
                          userDetails?.roleNameList ===
                            "Jr. NBA International Admin" ||
                          userDetails?.roleNameList === "Intl Bbops Admin"
                            ? "Add Component Details"
                            : "View Component Details"}
                        </button>
                      )}
                  </div>
                </div>
              )}
              {tabActive === 1 && (
                <div>
                  <div className="relative overflow-x-auto shadow-md sm:rounded-lg ">
                    <table className=" w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                      <thead className=" text-[#323232] uppercase bg-gray-100">
                        <tr>
                          <th scope="col" className={`px-3 py-3 `}>
                            <div className="flex gap-1">
                              <button onClick={() => handleSortModuleName()}>
                                {" "}
                                MODULE NAME
                              </button>
                              {sortDirection !== undefined &&
                              sortDirection === "ASC" ? (
                                <span>▲</span>
                              ) : sortDirection !== undefined &&
                                sortDirection === "DSC" ? (
                                <span>▼</span>
                              ) : null}
                            </div>
                          </th>
                          <th scope="col" className={`px-3 py-3 `}>
                            <div className="flex gap-1">
                              <button onClick={() => handleSortModuleStatus()}>
                                {" "}
                                STATUS
                              </button>
                              {sortDirection1 !== undefined &&
                              sortDirection1 === "ASC" ? (
                                <span>▲</span>
                              ) : sortDirection1 !== undefined &&
                                sortDirection1 === "DSC" ? (
                                <span>▼</span>
                              ) : null}
                            </div>
                          </th>
                          <th scope="col" className={`px-3 py-3 `}>
                            ACTION
                          </th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {allModules.map((module, index) => {
                          const showDownloadButton =
                            module.programModuleID === 10 ? showDownload : true;
                          let showEdit = false;
                          if (
                            userDetails?.roleNameList === "Medical User" &&
                            module.programModuleID == 7
                          ) {
                            showEdit = true;
                          } else if (
                            userDetails?.roleNameList === "Security User" &&
                            module.programModuleID == 5
                          ) {
                            showEdit = true;
                          } else if (
                            userDetails?.roleNameList === "Youth Events User" &&
                            module.programModuleID == 3
                          ) {
                            showEdit = true;
                          } else if (
                            userDetails?.roleNameList === "Legal User" &&
                            module.programModuleID == 4
                          ) {
                            showEdit = true;
                          } else if (
                            userDetails?.roleNameList ===
                              "Risk Management User" &&
                            module.programModuleID == 6
                          ) {
                            showEdit = true;
                          } else {
                            if (
                              userDetails?.roleIDList === "7" ||
                              userDetails?.roleIDList === "8" ||
                              userDetails?.roleIDList === "9" ||
                              userDetails?.roleIDList === "10"
                            ) {
                              showEdit = false;
                            } else {
                              showEdit = true;
                            }
                          }
                          return (
                            <tr
                              key={index}
                              className="bg-white border-b text-[#394150] dark:bg-gray-800 dark:border-gray-700"
                            >
                              <td className={`px-3 py-3   `}>
                                <button
                                  onClick={() =>
                                    handleModuleClick(module, false)
                                  }
                                  className="flex gap-2 "
                                >
                                  <img
                                    src={(() => {
                                      switch (module.programModuleID) {
                                        case 1:
                                          return ModuleParticipateInfo;
                                        case 2:
                                          return ModuleSatffing;
                                        case 3:
                                          return ModuleYouthSafety;
                                        case 4:
                                          return ModuleLegal;
                                        case 5:
                                          return ModuleSecurity;
                                        case 6:
                                          return ModuleRiskManagement;
                                        case 7:
                                          return ModuleMedical;
                                        case 8:
                                          return ModuleCovid;
                                        case 9:
                                          return ModuleMedia;
                                        case 10:
                                          return ModuleAfterActionReport;
                                        default:
                                          return "";
                                      }
                                    })()}
                                    className="w-15 h-15"
                                    alt={module.programModuleName}
                                  />

                                  <p className="text-lg text-[#1D428A] font-semibold">
                                    {module.programModuleName}
                                  </p>
                                </button>
                              </td>
                              <td
                                className={`px-3 py-3 text-lg ${
                                  module.moduleStatus == null
                                    ? "text-red-600"
                                    : module.moduleStatus == "Completed"
                                    ? "text-green-600"
                                    : module.moduleStatus == "Submitted"
                                    ? "text-orange-600"
                                    : module.moduleStatus == "In Progress"
                                    ? "text-orange-600"
                                    : module.moduleStatus ==
                                      "Requires Follow up"
                                    ? "text-red-600"
                                    : module.moduleStatus == "Approved"
                                    ? "text-green-600"
                                    : "text-red-600"
                                } font-semibold   `}
                              >
                                {module.moduleStatus}
                              </td>
                              <td className={`px-3 py-3   `}>
                                <div className="gap-2 flex">
                                  <button
                                    className="text-blue-900"
                                    onClick={() => {
                                      handleModuleClick(module, true);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 00-1.032-.211 50.89 50.89 0 00-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 002.433 3.984L7.28 21.53A.75.75 0 016 21v-4.03a48.527 48.527 0 01-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979z" />
                                      <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 001.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0015.75 7.5z" />
                                    </svg>
                                  </button>
                                  {showEdit ? (
                                    <button
                                      data-te-toggle="tooltip"
                                      title="Edit"
                                      onClick={() =>
                                        handleModuleClick(module, false)
                                      }
                                      data-galabel="Edit Program Details"
                                    >
                                      <img
                                        src={EditIcon}
                                        className="h-6 w-5 ml-3"
                                        alt="edit"
                                      />
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() =>
                                        handleModuleClick(module, false)
                                      }
                                      data-te-toggle="tooltip"
                                      title="View"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="w-6 h-6 text-blue-900 ml-2"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                        />
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                      </svg>
                                    </button>
                                  )}
                                  {showDownloadButton &&
                                  module.moduleStatus !== null &&
                                  (module.moduleStatus === "Completed" ||
                                    module.moduleStatus === "Submitted" ||
                                    module.moduleStatus === "Approved") ? (
                                    <button
                                      className="text-[#1D428A] mt-1"
                                      onClick={() =>
                                        handleModuleDownload(module)
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        className="w-6 h-6 ml-2"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M12 2.25a.75.75 0 01.75.75v11.69l3.22-3.22a.75.75 0 111.06 1.06l-4.5 4.5a.75.75 0 01-1.06 0l-4.5-4.5a.75.75 0 111.06-1.06l3.22 3.22V3a.75.75 0 01.75-.75zm-9 13.5a.75.75 0 01.75.75v2.25a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V16.5a.75.75 0 011.5 0v2.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V16.5a.75.75 0 01.75-.75z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    </button>
                                  ) : null}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  {/* <div className="bg-white border border-gray-200 rounded-lg shadow-md w-full mt-4">
                    {allModules.map((module) => {
                      const showDownloadButton =
                        module.programModuleID === 10 ? showDownload : true;
                      let showEdit = false;
                      if (
                        userDetails?.roleNameList === "Medical User" &&
                        module.programModuleID == 7
                      ) {
                        showEdit = true;
                      } else if (
                        userDetails?.roleNameList === "Security User" &&
                        module.programModuleID == 5
                      ) {
                        showEdit = true;
                      } else if (
                        userDetails?.roleNameList === "Youth Events User" &&
                        module.programModuleID == 3
                      ) {
                        showEdit = true;
                      } else if (
                        userDetails?.roleNameList === "Legal User" &&
                        module.programModuleID == 4
                      ) {
                        showEdit = true;
                      } else if (
                        userDetails?.roleNameList === "Risk Management User" &&
                        module.programModuleID == 6
                      ) {
                        showEdit = true;
                      } else {
                        if (
                          userDetails?.roleIDList === "7" ||
                          userDetails?.roleIDList === "8" ||
                          userDetails?.roleIDList === "9" ||
                          userDetails?.roleIDList === "10"
                        ) {
                          showEdit = false;
                        } else {
                          showEdit = true;
                        }
                      }

                      return (
                        <div className=" border-b p-2 border-grey-900 ">
                          <div className="flex-none gap-2 md:flex w-full">
                            <div className="w-full md:w-6/12 flex justify-center md:justify-start">
                              <button
                                onClick={() => handleModuleClick(module, false)}
                                className="flex gap-2 "
                              >
                                <img
                                  src={(() => {
                                    switch (module.programModuleID) {
                                      case 1:
                                        return ModuleParticipateInfo;
                                      case 2:
                                        return ModuleSatffing;
                                      case 3:
                                        return ModuleYouthSafety;
                                      case 4:
                                        return ModuleLegal;
                                      case 5:
                                        return ModuleSecurity;
                                      case 6:
                                        return ModuleRiskManagement;
                                      case 7:
                                        return ModuleMedical;
                                      case 8:
                                        return ModuleCovid;
                                      case 9:
                                        return ModuleMedia;
                                      case 10:
                                        return ModuleAfterActionReport;
                                      default:
                                        return "";
                                    }
                                  })()}
                                  className="w-15 h-15"
                                  alt={module.programModuleName}
                                />

                                <p className="text-lg text-[#1D428A] font-semibold">
                                  {module.programModuleName}
                                </p>
                              </button>
                            </div>
                            <div className="w-full md:w-4/12 flex justify-center md:justify-start">
                              <p className="text-[#1D428A] text-lg">
                                Status :
                                <span className="text-lg text-[#1D428A] font-semibold">
                                  {module.moduleStatus !== null
                                    ? module.moduleStatus
                                    : "To Do"}
                                </span>
                              </p>
                            </div>
                            <div className="w-full md:w-2/12 flex justify-center md:justify-end">
                              <div className="gap-2 flex">
                                {showDownloadButton &&
                                module.moduleStatus !== null &&
                                (module.moduleStatus === "Completed" ||
                                  module.moduleStatus === "Submitted" ||
                                  module.moduleStatus === "Approved") ? (
                                  <button
                                    className="text-[#1D428A] mt-1"
                                    onClick={() => handleModuleDownload(module)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M12 2.25a.75.75 0 01.75.75v11.69l3.22-3.22a.75.75 0 111.06 1.06l-4.5 4.5a.75.75 0 01-1.06 0l-4.5-4.5a.75.75 0 111.06-1.06l3.22 3.22V3a.75.75 0 01.75-.75zm-9 13.5a.75.75 0 01.75.75v2.25a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V16.5a.75.75 0 011.5 0v2.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V16.5a.75.75 0 01.75-.75z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </button>
                                ) : null}
                                <button
                                  className="text-blue-900"
                                  onClick={() => {
                                    handleModuleClick(module, true);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="w-6 h-6"
                                  >
                                    <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 00-1.032-.211 50.89 50.89 0 00-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 002.433 3.984L7.28 21.53A.75.75 0 016 21v-4.03a48.527 48.527 0 01-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979z" />
                                    <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 001.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0015.75 7.5z" />
                                  </svg>
                                </button>
                                {showEdit ? (
                                  <button
                                    className="w-full"
                                    data-te-toggle="tooltip"
                                    title="Edit"
                                    onClick={() =>
                                      handleModuleClick(module, false)
                                    }
                                  >
                                    <img
                                      src={EditIcon}
                                      className="h-6 w-5"
                                      alt="edit"
                                    />
                                  </button>
                                ) : (
                                  <button
                                    onClick={() =>
                                      handleModuleClick(module, false)
                                    }
                                    data-te-toggle="tooltip"
                                    title="View"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="1.5"
                                      stroke="currentColor"
                                      className="w-6 h-6 text-blue-900 ml-2"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                      />
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                      />
                                    </svg>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div> */}
                </div>
              )}
              {tabActive === 2 && (
                <div>
                  <RecapReport
                    programDetails={selectedProgram && selectedProgram[0]}
                    setLoading={setLoading}
                    recapReportList={recapReportList}
                    getRecapReportList={getRecapReportList}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal
          showModal={showModal}
          handleClose={() => setShowModal(false)}
          modalMessage={modalMessage}
          handleClick={() => {
            modalMessage.title == "Submit to Review"
              ? handleSubmitReviewConfirm()
              : handleDeletCommentConfirm(selectedCommentForDelete);
          }}
        />
        <Toaster
          toasterMessage={toasterMessage}
          setToasterMessage={setToasterMessage}
        />
        <div className="hidden">
          <div ref={componentRef}>
            <PDFDesign
              modulePDFJSON={modulePDFJSON}
              setModulePDFJSON={setModulePDFJSON}
            />
          </div>
        </div>
      </div>
      {showReviewComment && (
        <div class="fixed backdrop-brightness-75 left-0 top-0 z-[1055]  h-full w-full overflow-y-auto overflow-x-hidden outline-none">
          <div class="pointer-events-none relative  flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center  transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px]">
            <div class="pointer-events-auto shadow-2xl relative flex w-full flex-col rounded-md border-none bg-white  outline-none W">
              <div class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 p-4 dark:border-white/10">
                <h5
                  class="text-xl font-medium leading-normal text-surface dark:text-white"
                  id="exampleModalCenteredScrollableLabel"
                >
                  Add Review Comment
                </h5>

                <button
                  type="button"
                  class="box-content rounded-none border-none text-neutral-500 hover:text-neutral-800 hover:no-underline focus:text-neutral-800 focus:opacity-100 focus:shadow-none focus:outline-none dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
                  onClick={() => setShowRevieweComment(false)}
                >
                  <span class="[&>svg]:h-6 [&>svg]:w-6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </span>
                </button>
              </div>

              <div class="relative p-4">
                <TextareaAutosize
                  type="text"
                  // id="qustion"
                  value={reviewerComment}
                  maxRows={7}
                  minRows={7}
                  onChange={(e) => setReviewerComment(e.target.value)}
                  placeholder="Enter Review comment..."
                  className="h-10 px-2 w-full border focus:outline-none focus:shadow-outline border-grey-300 text-gray-900 rounded "
                />
              </div>

              <div class="flex  flex-wrap gap-2 items-center justify-end rounded-b-md border-t-2 border-neutral-100 p-4 dark:border-white/10">
                <button
                  className="bg-blue-900 p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white"
                  onClick={() => handleReviewerComment("Completed")}
                  data-galabel={`Complete Program Review ${selectedProgram[0]?.localProgramName}`}
                >
                  Completed
                </button>
                <button
                  className="bg-blue-900 p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white"
                  onClick={() =>
                    handleReviewerComment("Needs additional Information")
                  }
                  data-galabel={`Needs to Improvement Program Review ${selectedProgram[0]?.localProgramName}`}
                >
                  Needs an Improvement
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProgramDetails;
