import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import APIHelper from "../../utils/config/apiHelper";
import APIURLConstant from "../../utils/config/apiURLConstant";
import Toaster from "../../sharedComponent/Toaster/Toaster";
import AssignToMeIcon from "../../assets/assign_me1.svg";

const ProgramListForCalendar = (props) => {
  const [programId, setProgramId] = useState(null);
  const [toasterMessage, setToasterMessage] = useState({
    showToaster: false,
    errorType: "",
    message: "",
  });
  const navigate = useNavigate();

  const getStartEndDates = (dateParam) => {
    return moment(dateParam).format("MMM DD, YYYY");
  };

  const handleAssignToMe = (item) => {
    const programData = props?.allData.filter(
      (program) => program.programID === item.programID.value
    );

    const payLoad = {
      programReviewerID: props?.userDetails?.userID,
      // programCategoryID: programData[0]?.programCategoryID,
      // programRegionID: programData[0].regionID,
      // programReviewerEmail: props?.userDetails?.email,
      // reviewerName: props?.userDetails?.userName,
      // reviewerType: "string",
      programID: programData[0]?.programID,
    };

    props.setLoading(true);
    APIHelper.post(APIURLConstant.UPDATE_REVIEWER, payLoad)
      .then((res) => {
        props.setLoading(false);
        props.getProgramList();
        setToasterMessage({
          showToaster: true,
          errorType: "success",
          message: `Program Reviewer assigned successfully`,
        });
      })
      .catch((err) => {
        console.log(err);
        props.setLoading(true);
        props.getProgramList();
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: `Failed to assign Program Reviewer`,
        });
      });
  };

  const handleClickView = (item) => {
    if (item?.programId !== null) {
      navigate(`/program-details/${item.programID.value}`, {
        state: {
          selectedProgram: props?.allData.filter(
            (program) => program.programID === item.programID.value
          ),
          tabTwoActive: false,
          isFromDashboard: true,
        },
      });

      setProgramId(null);
    }
  };
  return (
    <div className="p-3">
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg ">
        <table className=" w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
          <thead className=" text-[#323232] uppercase bg-gray-100">
            <tr>
              <th scope="col" className={`px-3 py-3 `}>
                LOCAL PROGRAM NAME
              </th>
              <th scope="col" className={`px-3 py-3 `}>
                START
              </th>
              <th scope="col" className={`px-3 py-3 `}>
                END
              </th>
              <th scope="col" className={`px-3 py-3 `}>
                CREATED BY
              </th>
              <th scope="col" className={`px-3 py-3 `}>
                REVIEWER
              </th>
            </tr>
          </thead>{" "}
          <tbody className="">
            {props?.data && props?.data.length > 0 ? (
              props?.data.map((tableData, index) => {
                return (
                  <tr
                    key={index}
                    className="bg-white border-b text-[#394150] dark:bg-gray-800 dark:border-gray-700"
                  >
                    <td className={`px-3 py-3   `}>
                      <button
                        className={`text-start  p-0 ${
                          tableData.programStatus.value === "Published"
                            ? "cursor-pointer"
                            : "cursor-default	"
                        }`}
                        onClick={() =>
                          tableData.programStatus.value === "Published" &&
                          handleClickView(tableData)
                        }
                        data-category="view-program"
                      >
                        <span className="text-[#1D428A] font-medium">
                          {" "}
                          {tableData?.localProgramName.value.split("^")[0]}
                        </span>{" "}
                        <br />
                        {getStartEndDates(
                          tableData?.localProgramName.value.split("^")[1]
                        )}{" "}
                        -{" "}
                        {getStartEndDates(
                          tableData?.localProgramName.value.split("^")[2]
                        )}
                      </button>
                    </td>
                    <td className={`px-3 py-3   `}>
                      {tableData?.startDate.value}
                    </td>
                    <td className={`px-3 py-3   `}>
                      {tableData?.endDate.value}
                    </td>
                    <td className={`px-3 py-3   `}>
                      {tableData?.userName.value}
                    </td>
                    <td className={`px-3 py-3   `}>
                      <div className="flex justify-between items-center gap-1">
                        <span className="whitespace-wrap break-words">
                          {tableData?.programReviewerName.value}
                        </span>
                        {props?.userDetails !== undefined &&
                        (props?.userDetails?.roleIDList === "1" &&
                        props?.userDetails?.userID !=
                          tableData?.programReviewerID?.value 
                          || (props?.userDetails?.isProgramReviwer && props?.userDetails?.userID !=
                            tableData?.programReviewerID?.value)) ? (
                          <button
                            className="text-[#1D428A] assign-me-icon"
                            data-te-toggle="tooltip"
                            title="AssignToMe"
                            onClick={() => handleAssignToMe(tableData)}
                          >
                            <img
                              src={AssignToMeIcon}
                              className="h-6 w-6"
                              alt="AssignToMe"
                            />
                          </button>
                        ) : null}
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="text-center" colSpan={5}>
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Toaster
        toasterMessage={toasterMessage}
        setToasterMessage={setToasterMessage}
      />
      {/* <Table
        sourceName="Program-List-Calender"
        data={props?.data ?? []}
        column={column}
        isViewButton={true}
        isDeleteButton={false}
        showCloneButton={false}
        handleClickHistory={handleClickHistory}
        handleClickView={handleClickView}
      /> */}
    </div>
  );
};
export default ProgramListForCalendar;
