import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import EditIcon from "../../assets/edit.svg";
import { Editor } from "@tinymce/tinymce-react";
import ButtonContainer from "../../sharedComponent/ButtonContainer/ButtonContainer";
import Toaster from "../../sharedComponent/Toaster/Toaster";
import APIHelper from "../../utils/config/apiHelper";
import APIURLConstant from "../../utils/config/apiURLConstant";
import { AppContext } from "../../store/context";
import Loader from "../../sharedComponent/Loader/Loader";
import AddUser from "./AddUser";
import { Modal } from "../../sharedComponent";
import ViewNotification from "./ViewNotification";

const NotificationManagement = () => {
  const { userDetails } = useContext(AppContext);
  const [tabActive, setTabActive] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [viewUsers, setViewUsers] = useState(false);
  const [addUsers, setAddUsers] = useState(false);
  const [selectedNotificationForAdd, setSelectedNotificationForAdd] =
    useState(null);
  const [selectedNotificationForEdit, setSelectedNotificationForEdit] =
    useState(null);
  const [selectedNotificationForView, setSelectedNotificationForView] =
    useState(null);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [notificationList, setNotificationList] = useState(null);
  const [allNotificationList, setAllNotificationList] = useState(null);
  const [errorMessage, setErrorMessage] = useState({
    title: false,
    message: false,
  });
  const editorRef = useRef(null);
  const [toasterMessage, setToasterMessage] = useState({
    showToaster: false,
    errorType: "",
    message: "",
  });
  const [modalMessage, setModalMessage] = useState({
    title: "",
    message: "",
  });
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (userDetails?.userID !== "") {
      getCustomeNotification();
    }
  }, [userDetails]);

  const getCustomeNotification = () => {
    setLoading(true);
    APIHelper.post(APIURLConstant.GET_CUSTOME_NOTIFICATION)
      .then((res) => {
        let notificationsData = [];
        if (res && res.length > 0) {
          res.forEach((element) => {
            if (element.isDeleted === false) {
              notificationsData.push({
                selected: false,
                ...element,
              });
            }
          });
        }
        setNotificationList(notificationsData);
        setAllNotificationList(notificationsData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const validateForm = () => {
    let isFormValid = true;
    if (title === "") {
      errorMessage.title = true;
      isFormValid = false;
    }

    if (message === "") {
      errorMessage.message = true;
      isFormValid = false;
    }

    return isFormValid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setLoading(true);

      const payLoad = {
        customNotificationID:
          selectedNotificationForEdit !== null
            ? selectedNotificationForEdit?.customNotificationID
            : 0,
        notificationType: "Admin Custom Notification",
        emailBody: message,
        subject: title,
        userName:
          selectedNotificationForEdit !== null
            ? selectedNotificationForEdit?.userName
            : null,
        status: "Unsent",
        createdBy:
          selectedNotificationForEdit !== null
            ? selectedNotificationForEdit?.createdBy
            : userDetails?.userID,
        createdDate:
          selectedNotificationForEdit !== null
            ? selectedNotificationForEdit?.createdDate
            : new Date(),
        modifiedBy: userDetails?.userID,
        modifiedDate: new Date(),
        isDeleted: false,
        isActive: true,
        isNotificationForEmail:
          selectedNotificationForEdit !== null
            ? selectedNotificationForEdit?.isNotificationForEmail
            : false,
        isNotificationForApp:
          selectedNotificationForEdit !== null
            ? selectedNotificationForEdit?.isNotificationForApp
            : false,
        userRoleIDs:
          selectedNotificationForEdit !== null
            ? selectedNotificationForEdit?.userRoleIDs
            : null,
        userIDs:
          selectedNotificationForEdit !== null
            ? selectedNotificationForEdit?.userIDs
            : null,
        userRoleNames:
          selectedNotificationForEdit !== null
            ? selectedNotificationForEdit?.userRoleNames
            : null,
        userNames:
          selectedNotificationForEdit !== null
            ? selectedNotificationForEdit?.userNames
            : null,
      };
      APIHelper.post(APIURLConstant.CREATE_CUSTOME_NOTIFICATION, payLoad)
        .then((res) => {
          setLoading(false);
          let messageString = "";
          if (selectedNotificationForEdit !== null) {
            messageString = "Notification updated successfully.";
          } else {
            messageString = "Notification created successfully.";
          }
          setToasterMessage({
            errorType: "success",
            showToaster: true,
            message: messageString,
          });
          resetForm();
          setSearchText("");
          getCustomeNotification();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          let messageString = "";
          if (selectedNotificationForEdit !== null) {
            messageString = "Error while updating a notification.";
          } else {
            messageString = "Error while creating a notification.";
          }
          setToasterMessage({
            errorType: "error",
            showToaster: true,
            message: messageString,
          });
        });
    } else {
      setToasterMessage({
        showToaster: true,
        errorType: "error",
        message: "Please enter required fields",
      });
    }
  };
  const handleSelectNotification = (item) => {
    const SelectedData = notificationList?.map((ele) => {
      if (ele.customNotificationID === item.customNotificationID) {
        return { ...ele, ...{ selected: !ele?.selected } };
      } else {
        return ele;
      }
    });

    setNotificationList(SelectedData);
  };

  const resetForm = () => {
    setSelectedNotificationForEdit(null);
    setShowCreateForm(false);
    setTitle("");
    setMessage("");
    setErrorMessage({
      title: false,
      message: false,
    });
  };

  const handleEdit = (item) => {
    setModalMessage({
      title: "Edit",
      message: "Do you want to edit this Notification ?",
    });
    setSelectedNotificationForEdit(item);
    setShowModal(true);
  };

  const handleEditConfirm = () => {
    setShowModal(false);
    setShowCreateForm(true);
    setTitle(selectedNotificationForEdit?.subject);
    setMessage(selectedNotificationForEdit?.emailBody);
  };

  const handleDelete = () => {
    let deleteRequests = [];
    notificationList.forEach((row) => {
      if (row.selected === true) {
        deleteRequests.push(row.customNotificationID);
      }
    });

    let messageString = "";
    if (deleteRequests.length !== notificationList.length) {
      messageString = "Do you want to Delete selected Notifications ?";
    } else {
      messageString = "Do you want to Delete All Notifications ?";
    }
    if (deleteRequests.length > 0) {
      setShowModal(true);
      setModalMessage({
        title: "Delete",
        message: messageString,
      });
    } else {
      setToasterMessage({
        showToaster: true,
        errorType: "error",
        message: "Please select atleast one Notification",
      });
    }
  };

  const handleDeletConfirm = () => {
    let deleteRequests = [];
    notificationList.forEach((row) => {
      if (row.selected === true) {
        deleteRequests.push(row.customNotificationID);
      }
    });
    const payLoad = {
      customNotificationIDs: deleteRequests.join(","),
      modifiedBy: userDetails?.userID,
    };
    setLoading(true);
    APIHelper.post(APIURLConstant.DELETE_CUSTOME_NOTIFICATION, payLoad)
      .then((res) => {
        setLoading(false);
        getCustomeNotification();
        setToasterMessage({
          showToaster: true,
          errorType: "success",
          message: "Notification deleted successfully.",
        });
        setShowModal(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setShowModal(true);
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: "Error while deleting a Notification.",
        });
      });
  };

  const handleCheckForNotification = (item) => {
    let updatedList = [];
    notificationList.map((notification) => {
      if (notification.customNotificationID === item.customNotificationID) {
        updatedList.push({
          ...notification,
          isNotificationForApp: !notification?.isNotificationForApp,
        });
      } else {
        updatedList.push(notification);
      }
    });
    setNotificationList(updatedList);
    console.log(updatedList);
  };
  const handleCheckForEmail = (item) => {
    let updatedList = [];
    notificationList.map((notification) => {
      if (notification.customNotificationID === item.customNotificationID) {
        updatedList.push({
          ...notification,
          isNotificationForEmail: !notification?.isNotificationForEmail,
        });
      } else {
        updatedList.push(notification);
      }
    });
    setNotificationList(updatedList);
    console.log(updatedList);
  };
  const handleSentNotification = (item) => {
    if (
      item.isNotificationForApp === true ||
      item.isNotificationForEmail === true
    ) {
      if (item.userIDs !== null) {
        const notificationObject = {
          customNotificationID: item.customNotificationID,
          isNotificationForEmail: item.isNotificationForEmail,
          isNotificationForApp: item.isNotificationForApp,
        };
        setLoading(true);
        APIHelper.post(
          APIURLConstant.SENT_CUSTOM_NOTIFICATION,
          notificationObject
        )
          .then((data) => {
            console.log(data);
            setLoading(false);
            setToasterMessage({
              showToaster: true,
              errorType: "success",
              message: "Notification sent successfully.",
            });
            getCustomeNotification();
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            setToasterMessage({
              showToaster: true,
              errorType: "error",
              message: "Error while senting Notification.",
            });
          });
      } else {
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: "Please select atleast one recipient.",
        });
      }
    } else {
      setToasterMessage({
        showToaster: true,
        errorType: "error",
        message: "Please select atleast one notification type.",
      });
    }
  };

  const handleSearchUserList = (value) => {
    setSearchText(value);
    if (value.length > 0) {
      const list =
        tabActive === 0
          ? allNotificationList.filter((item) => item.status === "Unsent")
          : allNotificationList.filter((item) => item.status === "Sent");

      const newUserList = list.filter(
        (item) =>
          item.subject.toLowerCase().includes(value.toLowerCase()) ||
          item.userName.toLowerCase().includes(value.toLowerCase())
      );

      setNotificationList(newUserList);
    } else {
      setNotificationList(allNotificationList);
    }
  };

  const hanldeViewNotification = (item) => {
    setSelectedNotificationForView(item);
  };

  return (
    <>
      <div className="mt-16 md:mt-9 ">
        <div className="mx-auto p-2 md:p-7">
          <Loader showLoading={loading} />
          {showCreateForm ? (
            <div>
              {/* form */}
              <p className="text-[#c8102e] font-semibold text-[19px] py-3">
                Create Notification
              </p>
              <div className="bg-white border border-gray-200 rounded-lg shadow-md w-full p-4">
                <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                  <div className="md:w-2/12 lg:2/12 w-full">
                    <p className="text-sm pb-4 lg:p-0 md:p-0 lg:text-end lg:pt-2 text-center required text-[#394150] font-bold">
                      Title :
                    </p>
                  </div>
                  <div className="w-full lg:w-8/12 md:w-7/12">
                    <input
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                        errorMessage.title = false;
                      }}
                      placeholder="Please enter title"
                      className={` border  py-1 w-full border focus:outline-none focus:shadow-outline
                    ${
                      errorMessage && errorMessage.title === true
                        ? "border-red-400"
                        : "border-grey-400"
                    } text-gray-900 rounded pl-2`}
                    />
                    {errorMessage && errorMessage.title === true && (
                      <div className="text-start">
                        <span className="text-red-500 text-sm">
                          Please enter the title
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                  <div className="md:w-2/12 lg:2/12 w-full">
                    <p className="text-sm pb-4 lg:p-0 md:p-0 lg:text-end lg:pt-2 text-center required text-[#394150] font-bold">
                      Message :
                    </p>
                  </div>
                  <div className="w-full lg:w-8/12 md:w-7/12">
                    <Editor
                      apiKey="3gsgwk706d50qes50bns71i979twmm7lk9eni57p2bk46w00"
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      value={message}
                      init={{
                        height: 300,
                        menubar: true,
                        plugins: [
                          "advlist",
                          "autolink",
                          // "lists",
                          "link",
                          "image",
                          "charmap",
                          "preview",
                          "anchor",
                          "searchreplace",
                          "visualblocks",
                          "code",
                          "fullscreen",
                          "insertdatetime",
                          "media",
                          // "table",
                          "code",
                          "help",
                          "wordcount",
                        ],
                        toolbar:
                          "undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | link image code",
                        powerpaste_allow_local_images: true,
                        powerpaste_word_import: "prompt",
                        powerpaste_html_import: "prompt",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                      onEditorChange={(content) => {
                        setMessage(content);

                        errorMessage.message = false;
                      }}
                    />
                    {errorMessage && errorMessage.message === true && (
                      <div className="text-start">
                        <span className="text-red-500 text-sm">
                          Please enter the message
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                  <div className=" md:w-2/12 w-full" />
                  <ButtonContainer
                    handleSubmit={() => handleSubmit()}
                    handleCancel={() => resetForm()}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div>
              {/* list */}
              <p className="text-[#c8102e] font-semibold text-[19px] py-3">
                Notification
              </p>
              <div className="flex flex-wrap w-full">
                <button
                  onClick={() => {
                    setTabActive(0);
                    if (tabActive !== 0) {
                      setSearchText("");
                      setNotificationList(allNotificationList);
                    }
                  }}
                  className={`font-medium hidden md:block px-4 py-2 text-md ${
                    tabActive === 0
                      ? "text-[#1D428A] border-b-2 border-[#C8102E] "
                      : " text-neutral-500"
                  }  `}
                >
                  Notification
                </button>
                <button
                  onClick={() => {
                    setTabActive(1);
                    if (tabActive !== 1) {
                      setSearchText("");
                      setNotificationList(allNotificationList);
                    }
                  }}
                  className={`font-medium hidden md:block px-4 py-2 text-md ${
                    tabActive === 1
                      ? "text-[#1D428A] border-b-2 border-[#C8102E] "
                      : " text-neutral-500"
                  }  `}
                >
                  Sent
                </button>
              </div>

              <div className="w-full flex flex-wrap justify-between py-2 ">
                <div className="w-full pb-2 pr-2 flex md:w-6/12 lg:w-4/12">
                  <input
                    type="text"
                    placeholder="Enter to Search..."
                    className="w-full h-10 focus:outline-none focus:shadow-outline border-y border-l px-2 border-grey-300 rounded-l "
                    onChange={(e) => handleSearchUserList(e.target.value)}
                    value={searchText}
                    onKeyUp={(e) => {
                      if (e.key === "Enter" && searchText?.length > 0) {
                        handleSearchUserList(e.target.value);
                      }
                    }}
                  />
                  <div className="h-10 border-y border-r border-grey-300 rounded-r flex align-center">
                    {searchText !== undefined && searchText.length > 0 && (
                      <button
                        className="  pl-2 "
                        onClick={() => {
                          setSearchText("");
                          setNotificationList(allNotificationList);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18 18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap gap-2">
                  <button
                    className="bg-[#1D428A] p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white"
                    onClick={() => handleDelete()}
                  >
                    Delete
                  </button>
                  {tabActive === 0 && (
                    <button
                      className="bg-[#1D428A] p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white"
                      onClick={() => setShowCreateForm(true)}
                    >
                      Add
                    </button>
                  )}
                </div>
              </div>
              {tabActive === 0 ? (
                <>
                  {/* list */}
                  {notificationList !== null && notificationList?.length > 0 ? (
                    notificationList?.map((item) => {
                      if (item.status !== "Sent") {
                        return (
                          <div className="border-[#ccc] border bg-white py-4 px-2 mb-1 flex-none md:flex  gap-2 rounded w-full  items-end	">
                            <div className="flex flex-wrap gap-2 items-center w-full md:w-4/12">
                              <input
                                type="checkbox"
                                className="w-4 h-4 text-blue-600 bg-gray-100 rounded"
                                checked={item.selected}
                                onChange={(e) => {
                                  handleSelectNotification(item);
                                }}
                              />
                              <div className="flex gap-1 items-center flex-wrap">
                                <div>
                                  <p className="text-md font-bold">
                                    {item?.subject}
                                  </p>
                                  <p className="text-xs text-[#394150]">
                                    created on{" "}
                                    <span className="font-bold">
                                      {moment(item.createdDate).format(
                                        "MM/DD/YYYY"
                                      )}
                                    </span>
                                    , created by{" "}
                                    <span className="font-bold">
                                      {item?.userName}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-wrap items-center  gap-4 w-full md:w-4/12">
                              <div className="flex flex-wrap items-center">
                                <input
                                  type="checkbox"
                                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded"
                                  checked={item?.isNotificationForEmail}
                                  onChange={() => {
                                    handleCheckForEmail(item);
                                  }}
                                />
                                <label className="ml-2">Email</label>
                              </div>

                              <div className="flex flex-wrap items-center">
                                <input
                                  type="checkbox"
                                  checked={item?.isNotificationForApp}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded"
                                  onChange={() => {
                                    handleCheckForNotification(item);
                                  }}
                                />
                                <label className="ml-2">App Notification</label>
                              </div>
                            </div>
                            <div className="w-full flex-none md:flex justify-between md:w-4/12 items-center ">
                              <div>
                                {" "}
                                <p className="text-blue-900 font-bold">
                                  Status
                                </p>
                                <p className="text-[#394150]">{item?.status}</p>
                              </div>
                              <div className="flex gap-3 flex-wrap">
                                <button
                                  data-te-toggle="tooltip"
                                  title="View"
                                  onClick={() => hanldeViewNotification(item)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-6 h-6 text-blue-900"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                    />
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                  </svg>
                                </button>
                                <button
                                  data-te-toggle="tooltip"
                                  title="Add User"
                                  onClick={() => {
                                    setAddUsers(true);
                                    setSelectedNotificationForAdd(item);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="w-6 h-5 text-blue-900"
                                  >
                                    <path d="M5.25 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM2.25 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM18.75 7.5a.75.75 0 0 0-1.5 0v2.25H15a.75.75 0 0 0 0 1.5h2.25v2.25a.75.75 0 0 0 1.5 0v-2.25H21a.75.75 0 0 0 0-1.5h-2.25V7.5Z" />
                                  </svg>
                                </button>
                                <button
                                  data-te-toggle="tooltip"
                                  title="View User"
                                  className="relative inline-flex w-fit"
                                  onClick={() => {
                                    setViewUsers(true);
                                    setSelectedNotificationForAdd(item);
                                  }}
                                >
                                  <div className="absolute bottom-auto left-auto text-[10px] py-1 px-2 right-0 top-0 z-10 inline-block -translate-y-1/2 translate-x-2/4 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 whitespace-nowrap rounded-full bg-blue-900 text-center align-baseline font-bold leading-none text-white">
                                    {item?.userIDs !== null
                                      ? item.userIDs?.split(",").length
                                      : 0}
                                  </div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="w-6 h-5 text-blue-900"
                                  >
                                    <path d="M4.5 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM14.25 8.625a3.375 3.375 0 1 1 6.75 0 3.375 3.375 0 0 1-6.75 0ZM1.5 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM17.25 19.128l-.001.144a2.25 2.25 0 0 1-.233.96 10.088 10.088 0 0 0 5.06-1.01.75.75 0 0 0 .42-.643 4.875 4.875 0 0 0-6.957-4.611 8.586 8.586 0 0 1 1.71 5.157v.003Z" />
                                  </svg>
                                </button>

                                <button
                                  data-te-toggle="tooltip"
                                  title="Edit"
                                  onClick={() => {
                                    handleEdit(item);
                                  }}
                                >
                                  <img
                                    src={EditIcon}
                                    className="h-15 w-5"
                                    alt="edit"
                                  />
                                </button>
                                <button
                                  data-te-toggle="tooltip"
                                  title="Sent"
                                  onClick={() => handleSentNotification(item)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="w-6 h-5 text-blue-900"
                                  >
                                    <path d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z" />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })
                  ) : (
                    <div className="w-full border rounded text-center p-2">
                      No Data
                    </div>
                  )}
                </>
              ) : (
                <>
                  {notificationList !== null && notificationList?.length > 0 ? (
                    notificationList?.map((item) => {
                      if (item.status === "Sent") {
                        return (
                          <div className="border-[#ccc] border bg-white py-4 px-2 mb-1 flex-none md:flex  gap-2 rounded w-full  items-end	">
                            <div className="flex flex-wrap gap-2 items-center w-full md:w-6/12">
                              <input
                                type="checkbox"
                                className="w-4 h-4 text-blue-600 bg-gray-100 rounded"
                                checked={item.selected}
                                onChange={(e) => {
                                  handleSelectNotification(item);
                                }}
                              />
                              <div className="flex gap-1 items-center flex-wrap">
                                <div>
                                  <p className="text-md font-bold">
                                    {item?.subject}
                                  </p>
                                  <p className="text-xs text-[#394150]">
                                    created on{" "}
                                    <span className="font-bold">
                                      {moment(item?.createdDate).format(
                                        "MM/DD/YYYY"
                                      )}
                                    </span>
                                    , created by{" "}
                                    <span className="font-bold">
                                      {item?.userName}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="w-full flex-none md:flex justify-end md:w-6/12 items-center ">
                              <div className="flex gap-3 flex-wrap pr-4">
                                <button
                                  onClick={() => hanldeViewNotification(item)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-6 h-6 text-blue-900"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                    />
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                  </svg>
                                </button>
                                <button
                                  className="relative inline-flex w-fit"
                                  onClick={() => {
                                    setViewUsers(true);
                                    setSelectedNotificationForAdd(item);
                                  }}
                                >
                                  <div className="absolute bottom-auto left-auto text-[10px] py-1 px-2 right-0 top-0 z-10 inline-block -translate-y-1/2 translate-x-2/4 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 whitespace-nowrap rounded-full bg-blue-900 text-center align-baseline font-bold leading-none text-white">
                                    {item?.userIDs !== null
                                      ? item.userIDs?.split(",").length
                                      : 0}
                                  </div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="w-6 h-5 text-blue-900"
                                  >
                                    <path d="M4.5 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM14.25 8.625a3.375 3.375 0 1 1 6.75 0 3.375 3.375 0 0 1-6.75 0ZM1.5 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM17.25 19.128l-.001.144a2.25 2.25 0 0 1-.233.96 10.088 10.088 0 0 0 5.06-1.01.75.75 0 0 0 .42-.643 4.875 4.875 0 0 0-6.957-4.611 8.586 8.586 0 0 1 1.71 5.157v.003Z" />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })
                  ) : (
                    <div className="w-full border rounded flex justify-center items-center p-2">
                      No Data
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>

        {selectedNotificationForView !== null && (
          <ViewNotification
            notification={selectedNotificationForView}
            setSelectedNotificationForView={setSelectedNotificationForView}
          />
        )}

        <Toaster
          toasterMessage={toasterMessage}
          setToasterMessage={setToasterMessage}
        />
      </div>
      <Modal
        showModal={showModal}
        handleClose={() => setShowModal(false)}
        modalMessage={modalMessage}
        handleClick={
          modalMessage.title === "Edit" ? handleEditConfirm : handleDeletConfirm
        }
      />
      {addUsers && (
        <AddUser
          type="Add"
          tabActive={tabActive}
          selectedNotificationForAdd={selectedNotificationForAdd}
          setSelectedNotificationForAdd={setSelectedNotificationForAdd}
          setViewUsers={setViewUsers}
          setAddUsers={setAddUsers}
          setLoading={setLoading}
          setToasterMessage={setToasterMessage}
          getCustomeNotification={getCustomeNotification}
        />
      )}
      {viewUsers && (
        <AddUser
          type="View"
          tabActive={tabActive}
          selectedNotificationForAdd={selectedNotificationForAdd}
          setSelectedNotificationForAdd={setSelectedNotificationForAdd}
          setViewUsers={setViewUsers}
          setAddUsers={setAddUsers}
          setLoading={setLoading}
          setToasterMessage={setToasterMessage}
          getCustomeNotification={getCustomeNotification}
        />
      )}
    </>
  );
};
export default NotificationManagement;
