import { useEffect, useState, useContext } from "react";
import Calender from "../../modules/calender/Calender";
import { AppContext } from "../../store/context";

const Dashboard = () => {
  const { userDetails } = useContext(AppContext);

  let userName = userDetails.firstName ? userDetails.firstName : "";
  userName = userDetails.lastName
    ? userName + " " + userDetails.lastName
    : userName;
  userName = userName ? userName : userDetails.userName;

  const [authenticated, setauthenticated] = useState(null);
  useEffect(() => {
    const loggedInUser = localStorage.getItem("accessToken");
    if (loggedInUser) {
      setauthenticated(loggedInUser);
    }
  }, []);

  if (!authenticated) {
    return (
      <div>
        <p>You are not authenticated</p>
      </div>
    );
    // Redirect
  } else {
    return (
      <div>
        {/* <p>Hi {userName}, Welcome to your Dashboard</p> */}
        <Calender />
      </div>
    );
  }
};

export default Dashboard;
