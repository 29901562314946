import axios from "axios";
import Helper from "../../utils/config/helper";


const instance = axios.create({
  // baseURL: process.env.DOTNET_API_URL
  //we need to change this variable dynamically after assigning .env variable
  withCredentials: true,
  baseURL: "/yppapi/",
});

instance.interceptors.request.use((request) => {
  if (Helper.getStorage('accessToken'))
    request.headers['Authorization'] = `Bearer ${Helper.getStorage('accessToken')}`;
    request.headers['Content-Encoding'] = `gzip`;
  return request;
});

class APIHelper {
  static get(url) {
    return new Promise((resolve, reject) => {
      instance
        .get(url)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static post(url, obj) {
    return new Promise((resolve, reject) => {
      instance
        .post(url, obj)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  static patch(url, obj) {
    return new Promise((resolve, reject) => {
      instance
        .patch(url, obj)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default APIHelper;
