import React, { useEffect } from "react";

const Toaster = (props) => {
  // errorType => success || error
  // showToaster => boolean
  // message => toaster message (string)
  const { errorType, message, showToaster } = props.toasterMessage;

  useEffect(() => {
    if (showToaster === true) {
      const interval = setInterval(() => {
        props.setToasterMessage({
          showToaster: false,
          errorType: "",
          message: "",
        });
      }, 3000);

      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }
  }, [showToaster, props]);

  return (
    <div className="relative">
      <div className="top-4 fixed w-full align-center justify-center items-center flex z-[1046]">
        {showToaster && (
          <div className="">
            {errorType === "success" ? (
              <div
                id="alert-border-3"
                className="flex p-4 bg-green-700 dark:bg-green-200 rounded-lg"
                role="alert"
              >
                <div className="ml-3 text-sm font-medium text-white">
                  {message}
                </div>
                <button
                  type="button"
                  className="ml-1 text-white"
                  data-dismiss-target="#alert-border-3"
                  aria-label="Close"
                  onClick={() =>
                    props.setToasterMessage({
                      showToaster: false,
                      errorType: "",
                      message: "",
                    })
                  }
                >
                  <span className="sr-only">Dismiss</span>
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 ml-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
            ) : errorType === "error" ? (
              <div
                id="alert-border-2"
                className="flex p-4 bg-red-600 dark:bg-green-200 rounded-lg"
                role="alert"
              >
                <div className="ml-3 text-sm font-medium text-white">
                  {message}
                </div>
                <button
                  type="button"
                  className="ml-1 text-white"
                  data-dismiss-target="#alert-border-3"
                  aria-label="Close"
                  onClick={() =>
                    props.setToasterMessage({
                      showToaster: false,
                      errorType: "",
                      message: "",
                    })
                  }
                >
                  <span className="sr-only">Dismiss</span>
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 ml-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};
export default Toaster;
