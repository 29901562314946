/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, Table } from "../../sharedComponent";
import Loader from "../../sharedComponent/Loader/Loader";
import Pagination from "../../sharedComponent/Pagination/Pagination";
import Toaster from "../../sharedComponent/Toaster/Toaster";
import * as utils from "../../utils";
import APIHelper from "../../utils/config/apiHelper";
import APIURLConstant from "../../utils/config/apiURLConstant";

import { AppContext } from "../../store/context";
import { Multiselect } from "multiselect-react-dropdown";
import Select from "react-select";
import Helper from "../../utils/config/helper";
import DatePicker from "react-datepicker";
import "../../utils/Common.css";
import { ProgramCategoryAndTypeSidebar } from "./ProgramCategoryAndTypeSidebar";
import ProgramHistoryModel from "./ProgramHistoryModal";

const ProgramList = (props) => {
  const [programList, setProgramList] = useState([]);
  const [allprogramList, setAllProgramList] = useState([]);
  const [detailedProgramList, setDetailedProgramList] = useState([]);
  const [pageSize, setPageSize] = useState(Helper.getStorage("pageSize"));
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [canPreviousPage, setCanPreviousPage] = useState(false);
  const [canNextPage, setCanNextPage] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({
    title: "",
    message: "",
  });
  const [toasterMessage, setToasterMessage] = useState({
    showToaster: false,
    errorType: "",
    message: "",
  });
  const [showProgramHistory, setShowProgramHistory] = useState(false);
  const [programId, setProgramId] = useState(null);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [statusValue, setStatusValue] = useState(null);
  const [showChangeStatus, setShowChangeStatus] = useState(false);
  const navigate = useNavigate();
  const {
    userDetails,
    userRegions,
    dispatchRegions,
    userCategories,
    dispatchCategories,
  } = useContext(AppContext);
  const [programCategory, setProgramCategory] = useState([]);
  const [regions, setRegions] = useState([]);
  const [programCategoryFilters, setProgramCategoryFilters] = useState("");
  const [regionFilters, setRegionFilters] = useState([]);
  const [programTypes, setProgramTypes] = useState([]);
  const [programStageFilter, setProgramStageFilter] = useState(null);

  const [programTypesFilters, setProgramTypesFilters] = useState([]);
  const [showCreateProgram, setShowCreateProgram] = useState(false);
  const [sortStatus, setSortStatus] = useState("normal");
  const [sortStatusText, setSortStatusText] = useState("");
  const [overallDateStart, setOverallDateStart] = useState(null);
  const [overallDateEnd, setOverallDateEnd] = useState(null);

  let column = [
    { heading: "SR.NO", value: "checkbox" },
    { heading: "LOCAL PROGRAM NAME", value: "localProgramName" },
    { heading: "PROGRAM TYPE", value: "programType" },
    { heading: "PROGRAM CATEGORY", value: "tagList" },
    { heading: "START DATE", value: "startDate" },
    { heading: "END DATE", value: "endDate" },
    { heading: "REGION", value: "region" },
    { heading: "STATUS", value: "programStatus" },
    { heading: "STAGE", value: "programStage" },
    { heading: "CREATED BY", value: "userName" },
    { heading: "REVIEWER", value: "programReviewerName", noSorting: true },
    { heading: "ACTION", value: "action" },
  ];
  const location = useLocation();

  useEffect(() => {
    const stateData = location.state;
    if (stateData !== undefined && stateData !== null) {
      if (stateData.stage === "Operating") {
        // setProgramStageFilter({
        //   value: "Operating",
        //   label: "Operating",
        // });
      }
      if (stateData.stage === "Completed") {
        // setProgramStageFilter({
        //   value: "Completed",
        //   label: "Completed",
        // });
      }
      if (stateData.stage === "Planning") {
        // setProgramStageFilter({
        //   value: "Planning",
        //   label: "Planning",
        // });
      }
    }
  }, [location?.state]);

  useEffect(() => {
    let region = regionFilters
      .map((item) => {
        return item.value;
      })
      .join(",");
    let types = programTypesFilters
      .map((item) => {
        return item.value;
      })
      .join(",");
    if (userDetails.email !== "") {
      if (
        userDetails.roleIDList === "7" ||
        userDetails.roleIDList === "8" ||
        userDetails.roleIDList === "9" ||
        userDetails.roleIDList === "10"
      ) {
        setShowCreateProgram(false);
      } else {
        setShowCreateProgram(true);
      }
      getProgramList(
        null,
        pageSize,
        statusValue !== null ? statusValue.value : null,
        userDetails.email,
        programCategoryFilters?.value ?? null,
        region?.length
          ? region
          : userDetails.regionIDs
          ? userDetails.regionIDs
          : null,
        types?.length > 0 ? types : null,
        programStageFilter !== null ? programStageFilter?.value : null,
        searchText,
        overallDateStart !== null ? overallDateStart : null,
        overallDateEnd !== null ? overallDateEnd : null,
        getSortByColumn(),
        sortStatus === "ascending" ? "ASC" : "DESC"
      );
      if (location.state?.regions) {
        setRegions(location.state.regions);
      } else {
        getProgramRegions();
      }

      if (!location.state?.programCategory) {
        getProgramCategory();
      } else {
        setProgramCategory(location.state?.programCategory);
      }
    }
  }, [userDetails.email, currentPage, pageSize]);

  useEffect(() => {
    if (programList.length > 0) {
      setTotalCount(programList[0].totalCount.value);
    } else {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
      setTotalCount(0);
    }
  }, [programList]);

  const getProgramList = (
    recordCount,
    rowCount = pageSize,
    statusValue,
    userEmail = userDetails.email,
    categoryId,
    regionId,
    typeId,
    programStage,
    searchTextString,
    startDate,
    endDate,
    sortBy,
    sortDirection
  ) => {
    setLoading(true);
    const payload = {
      RecordCount: (recordCount =
        currentPage === 0 ? 0 : currentPage * pageSize),
      RowCount: rowCount,
      Status: statusValue === null ? "null" : statusValue,
      UserEmail: userEmail,
      CategoryIDs: categoryId === null ? "null" : categoryId.toString(),
      RegionIDs: regionId
        ? regionId
        : userDetails.regionIDs
        ? userDetails.regionIDs
        : "null",
      TypeIDs: typeId === null ? "null" : typeId.toString(),
      programStage: programStage === null ? "null" : programStage,
      StartDate: startDate,
      EndDate: endDate,
      ProgramId: 0,
      CreatedBy: 0,
      searchLocalProgramName: searchTextString,
      sortBy: sortBy === null ? "null" : sortBy,
      sortDirection: sortDirection === null ? "null" : sortDirection,
    };

    APIHelper.post(APIURLConstant.GET_PROGRAM_LIST, payload)
      .then((res) => {
        let arrayList = [];
        if (res && res.length > 0) {
          //save detailed list
          setDetailedProgramList(res);
          res.forEach((item) => {
            arrayList.push({
              programID: {
                value: item.programID,
              },
              localProgramName: {
                value: `${item.localProgramName} ^ ${item.overallStartDate} ^ ${item.overallEndDate}`,
              },

              isDeleted: {
                value: item.IsDeleted,
              },
              programStatus: {
                value: item.programStatus,
              },
              tagList: {
                value: item.programCategory,
              },
              programCategoryID: {
                value: item.programCategoryID,
              },
              programDescription: {
                value: item.programDescription,
              },
              region: {
                value: item.regionName,
              },
              isSelected: {
                value: false,
              },
              startDate: {
                value: item.overallStartDate,
              },
              endDate: {
                value: item.overallEndDate,
              },
              programType: {
                value: item.programType,
              },
              programTypeID: {
                value: item.programTypeID,
              },
              totalCount: {
                value: item.totalCount,
              },
              programStage: {
                value: item.programStage,
              },
              isEditable: {
                value: item.isEditable,
              },
              isComment: {
                value: item.isComment,
              },
              userName: {
                value: item.userName,
              },
              programReviewerName: {
                value: item.programReviewer,
              },
              programReviewerID: {
                value: item.programReviewerID,
              },
            });
          });
        }
        setProgramList(arrayList);
        setAllProgramList(arrayList);
        //setSortStatus("normal");
        //setSortStatusText("");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: "Failed to get program list",
        });
      });
  };

  const getSortByColumn = () => {
    switch (sortStatusText) {
      case "LOCAL PROGRAM NAME":
        return "LocalProgramName";
      case "STAGE":
        return "Stage";
      case "PROGRAM TYPE":
        return "ProgramType";
      case "PROGRAM CATEGORY":
        return "ProgramCategory";
      case "REGION":
        return "Region";
      case "STATUS":
        return "Status";
      case "CREATED BY":
        return "CreatedBy";
      case "START DATE":
        return "StartDate";
      case "END DATE":
        return "EndDate";
      default:
        return "";
    }
  };

  const onSelectTableRow = (e, object) => {
    e.stopPropagation();
    const SelectedData = programList?.map((ele) => {
      if (ele.programID === object.programID) {
        return { ...ele, ...{ isSelected: { value: !ele?.isSelected.value } } };
      } else {
        return ele;
      }
    });
    setProgramList(SelectedData);
    setAllProgramList(SelectedData);
  };

  const onClickSelectAll = (e, isAllSelected) => {
    e.stopPropagation();
    const rowData = programList?.map((ele) => {
      if (isAllSelected) {
        return { ...ele, ...{ isSelected: { value: false } } };
      } else {
        return { ...ele, ...{ isSelected: { value: true } } };
      }
    });
    setProgramList(rowData);
    setAllProgramList(rowData);
  };

  const gotoPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // const handleOpenModule = (item) => {
  //   navigate("/modules", {
  //     state: { programDetails: item },
  //   });
  // };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleCloneButton = (item) => {
    setShowModal(true);
    setProgramId(item.programID);
    setSelectedProgram(
      detailedProgramList.filter(
        (program) => program.programID === item.programID.value
      )
    );
    setModalMessage({
      title: "Clone",
      message: "Do you want to clone this program ?",
    });
  };

  const handleClickEdit = (item) => {
    setShowModal(true);
    setProgramId(item.programID);
    setSelectedProgram(
      detailedProgramList.filter(
        (program) => program.programID === item.programID.value
      )
    );
    setModalMessage({
      title: "Edit",
      message: "Do you want to edit this program ?",
    });
  };

  const handleDeleteProgram = (item) => {
    setShowModal(true);
    setProgramId(item.programID);
    setModalMessage({
      title: "Delete",
      message: "Do you want to delete this program ?",
    });
  };

  //Delete selected user
  const handleDelete = () => {
    let deleteRequests = [];
    programList.forEach((row) => {
      if (row.isSelected.value === true) {
        deleteRequests.push(row.programID.value);
      }
    });

    let messageString = "";
    if (deleteRequests.length !== programList.length) {
      messageString = "Do you want to Delete selected Programs ?";
    } else {
      messageString = "Do you want to Delete All Programs ?";
    }
    if (deleteRequests.length > 0) {
      setShowModal(true);
      setModalMessage({
        title: "Multiple Delete",
        message: messageString,
      });
    } else {
      setToasterMessage({
        showToaster: true,
        errorType: "error",
        message: "Please select atleast one Program",
      });
    }
  };

  // multi delete confirm
  const handleMultiDeleteConfirm = () => {
    setShowModal(false);
    let deleteRequests = [];
    // eslint-disable-next-line array-callback-return
    programList.map((row) => {
      if (row.isSelected.value === true) {
        deleteRequests.push(row.programID.value);
      }
    });
    if (deleteRequests.length > 0) {
      setLoading(true);

      updateNotification(
        deleteRequests[0],
        "Program Delete Notification",
        false,
        true,
        deleteRequests.length
      );

      const payload = {
        ProgramIds: deleteRequests.join(","),
        IsDeleted: true,
      };
      let region = regionFilters
        .map((item) => {
          return item.value;
        })
        .join(",");
      let types = programTypesFilters
        .map((item) => {
          return item.value;
        })
        .join(",");
      APIHelper.post(APIURLConstant.DELETE_PROGRAM_LIST, payload)
        .then((res) => {
          setToasterMessage({
            showToaster: true,
            errorType: "success",
            message: "Program Deleted successfully",
          });
          getProgramList(
            null,
            pageSize,
            statusValue !== null ? statusValue.value : null,
            userDetails.email,
            programCategoryFilters?.value ?? null,
            region?.length > 0 ? region : null,
            types?.length > 0 ? types : null,
            programStageFilter !== null ? programStageFilter?.value : null,
            searchText,
            null,
            null,
            null,
            null
          );
        })
        .catch((err) => {
          console.log(err);
          setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: "Error While Deleting a Program!",
          });
          setLoading(false);
        });
    }
  };

  //clone confirmation
  const handleCloneConfirm = () => {
    setShowModal(false);
    setModalMessage({
      title: "",
      message: "",
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (programId !== null) {
      navigate("/create-program", {
        state: {
          selectedProgram: selectedProgram,
          regions: regions,
          programCategory: programCategory,
          fromPage: "programList",
          isClone: true,
        },
      });
    }
  };

  //edit confirm function
  const handleEditConfirm = () => {
    setShowModal(false);
    setModalMessage({
      title: "",
      message: "",
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (programId !== null) {
      navigate("/create-program", {
        state: {
          selectedProgram: selectedProgram,
          regions: regions,
          programCategory: programCategory,
          fromPage: "programList",
          isClone: false,
        },
      });

      if (userDetails.roleNameList === "Regular User") {
        navigate(`/program-details/${selectedProgram[0]?.programID.value}`, {
          state: { selectedProgram: selectedProgram },
        });
      } else {
        navigate("/create-program", {
          state: {
            selectedProgram: selectedProgram,
            regions: regions,
            programCategory: programCategory,
            fromPage: "programList",
          },
        });
      }

      setProgramId(null);
    }
  };

  const handleClickView = (item) => {
    if (item?.programId !== null) {
      navigate(`/program-details/${item.programID.value}`, {
        state: {
          selectedProgram: detailedProgramList.filter(
            (program) => program.programID === item.programID.value
          ),
          tabTwoActive: false,
        },
      });

      setProgramId(null);
    }
  };

  const handleClickHistory = (item) => {
    setShowProgramHistory(true);

    setProgramId(item?.programID);
  };

  //delete confirm
  const handleDeletConfirm = () => {
    setShowModal(false);
    setModalMessage({
      title: "",
      message: "",
    });
    if (programId !== null) {
      const id = programId.value;
      updateNotification(id, "Program Delete Notification", false, true, 1);
      setLoading(true);
      const payload = {
        ProgramIds: JSON.stringify(id),
        IsDeleted: true,
        modifiedBy: userDetails.userID ? userDetails.userID : 0,
      };
      let region = regionFilters
        .map((item) => {
          return item.value;
        })
        .join(",");
      let types = programTypesFilters
        .map((item) => {
          return item.value;
        })
        .join(",");
      APIHelper.post(APIURLConstant.DELETE_PROGRAM_LIST, payload)
        .then((res) => {
          setToasterMessage({
            showToaster: true,
            errorType: "success",
            message: "Program deleted successfully",
          });
          setLoading(false);
          getProgramList(
            null,
            pageSize,
            statusValue !== null ? statusValue.value : null,
            userDetails.email,
            programCategoryFilters?.value ?? null,
            region?.length > 0 ? region : null,
            types?.length > 0 ? types : null,
            programStageFilter !== null ? programStageFilter?.value : null,
            searchText,
            null,
            null,
            null,
            null
          );
        })
        .catch((err) => {
          setLoading(false);
          setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: "Error while deleting a program ",
          });
        });
    }
  };

  // filter reset
  const resetFilter = () => {
    setSearchText("");
    setStatusValue(null);
    setProgramCategoryFilters("");
    setProgramTypesFilters([]);
    setProgramTypes([]);
    setRegionFilters([]);
    setProgramStageFilter(null);
    setOverallDateStart(null);
    setOverallDateEnd(null);
    setCurrentPage(0);
    setCanPreviousPage(false);
    setCanNextPage(true);
    setSortStatus("");
    setSortStatusText("");
    getProgramList(
      null,
      pageSize,
      null,
      userDetails.email,
      null,
      null,
      null,
      null,
      "",
      null,
      null
    );
  };
  const updateNotification = (
    programID,
    notificationType,
    isNotificationDelete,
    redirect,
    DeletedProgramCount
  ) => {
    setLoading(true);
    const notificationObject = {
      NotificationID: 0,
      ProgramID: programID,
      CreatedBy: userDetails.userID,
      IsDelete: false,
      IsRead: false,
      IsNotificationDelete: isNotificationDelete ? isNotificationDelete : false,
      ToUserID: 0,
      NotificationIDs: null,
      NotificationType: notificationType,
      SendEmail: !isNotificationDelete,
      DeletedProgramCount: DeletedProgramCount,
    };
    APIHelper.post(APIURLConstant.ADD_UPDATE_NOTIFICATIONS, notificationObject)
      .then((data) => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleStatusChange = (status) => {
    setStatusValue(status);
  };

  const getProgramCategory = () => {
    if (userCategories === undefined || userCategories?.length === 0) {
      setLoading(true);
      const payload = {
        RoleId: userDetails.roleIDList,
        UserID: 0,
      };
      APIHelper.post(APIURLConstant.GET_PROGRAMCATEGORY_BY_USER_ROLE, payload)
        .then((res) => {
          if (res) {
            const programCategory = res.map((item) => {
              return {
                value: item.programCategoryID,
                label: item.category,
              };
            });
            if (programCategory && programCategory.length > 0) {
              setProgramCategory([...programCategory]);
              const category = [...programCategory];
              dispatchCategories("ADD_CATEGORY", category);
            }
          }
          if (programList.length > 0) {
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      const categoriesUpdated = userCategories ?? [];
      setProgramCategory(categoriesUpdated);
    }
  };

  const getProgramRegions = () => {
    if (userRegions === undefined || userRegions?.length === 0) {
      setLoading(true);
      APIHelper.post(APIURLConstant.GET_PROGRAMREGIONS, {
        RegionIDs: userDetails?.regionIDs ? userDetails?.regionIDs : "null",
      })
        .then((res) => {
          if (res) {
            const programRegions = res.map((item) => {
              return {
                value: item.regionID,
                label: item.regionName,
              };
            });
            if (programRegions && programRegions.length > 0) {
              setRegions([...programRegions]);
              const region = [...programRegions];
              dispatchRegions("ADD_REGIONS", region);
            }
          }
          if (programList.length > 0) {
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      const regionsUpdated = userRegions ?? [];
      setRegions([...regionsUpdated]);
    }
  };

  const getProgramTypes = (categoryIds) => {
    setLoading(true);
    APIHelper.post(APIURLConstant.GET_PROGRAMTYPE, {
      ProgramCategoryID: categoryIds,
    })
      .then((res) => {
        if (res) {
          const programTypes = res.map((item) => {
            return {
              value: item.programTypeID,
              label: item.type,
            };
          });
          if (programTypes && programTypes.length > 0) {
            setProgramTypes([...programTypes]);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleChangeProgramCategory = (value) => {
    setProgramCategoryFilters(value);
    setProgramTypesFilters([]);
    getProgramTypes(value?.value);
  };

  const handleChangeRegion = (value) => {
    setRegionFilters(value);
  };

  const handleChangeProgramTypes = (value) => {
    setProgramTypesFilters(value);
  };

  const handleSearch = () => {
    setCurrentPage(0);
    setCanPreviousPage(false);
    setCanNextPage(true);
    setSortStatus("");
    setSortStatusText("");
    let region = regionFilters
      .map((item) => {
        return item.value;
      })
      .join(",");
    let types = programTypesFilters
      .map((item) => {
        return item.value;
      })
      .join(",");

    getProgramList(
      null,
      pageSize,
      statusValue !== null ? statusValue.value : null,
      userDetails.email,
      programCategoryFilters?.value ?? null,
      region?.length > 0 ? region : null,
      types?.length > 0 ? types : null,
      programStageFilter !== null ? programStageFilter?.value : null,
      searchText,
      overallDateStart !== null ? overallDateStart : null,
      overallDateEnd !== null ? overallDateEnd : null,
      null, //getSortByColumn(),
      null //sortStatus === "ascending" ? "ASC" : "DESC"
    );
  };

  const handleSearchText = () => {
    setCurrentPage(0);
    setCanPreviousPage(false);
    setCanNextPage(true);
    let region = regionFilters
      .map((item) => {
        return item.value;
      })
      .join(",");
    let types = programTypesFilters
      .map((item) => {
        return item.value;
      })
      .join(",");
    getProgramList(
      null,
      pageSize,
      statusValue !== null ? statusValue.value : null,
      userDetails.email,
      programCategoryFilters?.value ?? null,
      region?.length > 0 ? region : null,
      types?.length > 0 ? types : null,
      programStageFilter !== null ? programStageFilter?.value : null,
      searchText,
      overallDateStart !== null ? overallDateStart : null,
      overallDateEnd !== null ? overallDateEnd : null,
      getSortByColumn(),
      sortStatus === "ascending" ? "ASC" : "DESC"
    );
  };

  useEffect(() => {
    if (sortStatus && sortStatusText) {
      setSortConfig();
    }
  }, [sortStatus, sortStatusText]);

  const setSortConfig = () => {
    setCurrentPage(0);
    setCanPreviousPage(false);
    setCanNextPage(true);
    let region = regionFilters
      .map((item) => {
        return item.value;
      })
      .join(",");
    let types = programTypesFilters
      .map((item) => {
        return item.value;
      })
      .join(",");
    getProgramList(
      null,
      pageSize,
      statusValue !== null ? statusValue.value : null,
      userDetails.email,
      programCategoryFilters?.value ?? null,
      region?.length > 0 ? region : null,
      types?.length > 0 ? types : null,
      programStageFilter !== null ? programStageFilter?.value : null,
      searchText,
      overallDateStart !== null ? overallDateStart : null,
      overallDateEnd !== null ? overallDateEnd : null,
      getSortByColumn(),
      sortStatus === "ascending" ? "ASC" : "DESC"
    );
    //let sortableItems = [...programList];

    // if (sortStatus === "ascending" || sortStatus === "descending") {
    //   if (sortStatusText === "LOCAL PROGRAM NAME") {
    //     const dataSorted = sortableItems.sort((a, b) => {
    //       if (
    //         a.localProgramName.value.toLowerCase() <
    //         b.localProgramName.value.toLowerCase()
    //       ) {
    //         return sortStatus === "ascending" ? -1 : 1;
    //       }
    //       if (
    //         a.localProgramName.value.toLowerCase() >
    //         b.localProgramName.value.toLowerCase()
    //       ) {
    //         return sortStatus === "ascending" ? 1 : -1;
    //       }
    //       return 0;
    //     });
    //     setProgramList(dataSorted);
    //   }
    //   if (sortStatusText === "STAGE") {
    //     const dataSorted = sortableItems.sort((a, b) => {
    //       if (
    //         a.programStage.value.toLowerCase() <
    //         b.programStage.value.toLowerCase()
    //       ) {
    //         return sortStatus === "ascending" ? -1 : 1;
    //       }
    //       if (
    //         a.programStage.value.toLowerCase() >
    //         b.programStage.value.toLowerCase()
    //       ) {
    //         return sortStatus === "ascending" ? 1 : -1;
    //       }
    //       return 0;
    //     });
    //     setProgramList(dataSorted);
    //   }
    //   if (sortStatusText === "PROGRAM TYPE") {
    //     const dataSorted = sortableItems.sort((a, b) => {
    //       if (
    //         a.programType.value.toLowerCase() <
    //         b.programType.value.toLowerCase()
    //       ) {
    //         return sortStatus === "ascending" ? -1 : 1;
    //       }
    //       if (
    //         a.programType.value.toLowerCase() >
    //         b.programType.value.toLowerCase()
    //       ) {
    //         return sortStatus === "ascending" ? 1 : -1;
    //       }
    //       return 0;
    //     });
    //     setProgramList(dataSorted);
    //   }
    //   if (sortStatusText === "PROGRAM CATEGORY") {
    //     const dataSorted = sortableItems.sort((a, b) => {
    //       if (a.tagList.value.toLowerCase() < b.tagList.value.toLowerCase()) {
    //         return sortStatus === "ascending" ? -1 : 1;
    //       }
    //       if (a.tagList.value.toLowerCase() > b.tagList.value.toLowerCase()) {
    //         return sortStatus === "ascending" ? 1 : -1;
    //       }
    //       return 0;
    //     });
    //     setProgramList(dataSorted);
    //   }
    //   if (sortStatusText === "REGION") {
    //     const dataSorted = sortableItems.sort((a, b) => {
    //       if (a.region.value.toLowerCase() < b.region.value.toLowerCase()) {
    //         return sortStatus === "ascending" ? -1 : 1;
    //       }
    //       if (a.region.value.toLowerCase() > b.region.value.toLowerCase()) {
    //         return sortStatus === "ascending" ? 1 : -1;
    //       }
    //       return 0;
    //     });
    //     setProgramList(dataSorted);
    //   }
    //   if (sortStatusText === "STATUS") {
    //     const dataSorted = sortableItems.sort((a, b) => {
    //       if (
    //         a.programStatus.value.toLowerCase() <
    //         b.programStatus.value.toLowerCase()
    //       ) {
    //         return sortStatus === "ascending" ? -1 : 1;
    //       }
    //       if (
    //         a.programStatus.value.toLowerCase() >
    //         b.programStatus.value.toLowerCase()
    //       ) {
    //         return sortStatus === "ascending" ? 1 : -1;
    //       }
    //       return 0;
    //     });
    //     setProgramList(dataSorted);
    //   }
    // }
  };
  const handleSort = (name) => {
    if (sortStatus === "normal" || sortStatusText !== name) {
      setSortStatus("ascending");
      setSortStatusText(name);
    } else if (sortStatus === "ascending") {
      setSortStatus("descending");
      setSortStatusText(name);
    } else if (sortStatus === "descending") {
      setSortStatus("ascending");
      setSortStatusText(name);
    }
  };

  const handleDateChange = (value, dateType) => {
    if (dateType === "overallDateStart") {
      if (overallDateEnd && overallDateEnd < value) {
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: `Over all start date should be less than end date`,
        });
      } else {
        setOverallDateStart(value);
        //setStateData(value, "overallDateStart");
      }
    } else {
      if (overallDateStart && overallDateStart > value) {
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: `Over all end date should be greater than start date`,
        });
      } else {
        setOverallDateEnd(value);
        //setStateData(value, "overallDateEnd");
      }
    }
  };

  return (
    <div className="mt-9">
      <div className="mx-auto md:p-7 p-2 overflow-x-hidded">
        <div className="block lg:hidden">
          <button
            className="mt-1 inline-block shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out"
            data-te-offcanvas-toggle
            href="#categoryListView"
            type="button"
            aria-controls="categoryListView"
            data-te-ripple-init
            data-te-ripple-color="light"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6 "
            >
              <path
                fillRule="evenodd"
                d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <div
            className="invisible fixed bottom-0 top-0 left-0 z-[1045] flex w-96 max-w-full -translate-x-full flex-col border-none bg-white bg-clip-padding text-neutral-700 shadow-sm outline-none transition duration-300 ease-in-out dark:bg-neutral-800 dark:text-neutral-200 [&[data-te-offcanvas-show]]:transform-none"
            tabIndex="-1"
            id="categoryListView"
            aria-labelledby="categoryListViewLabel"
            data-te-offcanvas-init
          >
            <div className="flex w-full justify-end p-2">
              <button
                type="button"
                className="box-content rounded-none border-none"
                data-te-offcanvas-dismiss
              >
                <span className="w-[1em] disabled:pointer-events-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6 text-[#C8102E]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </span>
              </button>
            </div>
            <ProgramCategoryAndTypeSidebar
              setToasterMessage={setToasterMessage}
            />
          </div>
        </div>
        <Loader showLoading={loading} />
        <div>
          <p className="text-[#c8102e] font-semibold text-[19px] py-3">
            All Programs
          </p>
        </div>
        <div className="flex-none md:flex w-full mb-3">
          <div className="flex flex-wrap w-full md:6/12 lg:w-5/12">
            <input
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className="focus:outline-none h-10 rounded-l-lg border border-grey-300 w-10/12 px-1"
              placeholder="Search by Local program name..."
            />
            <button
              disabled={searchText !== "" ? false : true}
              onClick={handleSearchText}
              className={`text-white text-sm h-10 rounded-r-lg w-2/12 text-center 
              ${searchText !== "" ? "bg-[#1D428A]" : "bg-gray-400"}`}
              data-galabel="Search program Program List"
            >
              Search
            </button>
          </div>
          <div className="flex justify-end gap-3 flex-wrap w-full md:6/12 lg:7/12 md:mt-0 mt-2">
            {showCreateProgram === true ? (
              <>
                <button
                  className="bg-[#1D428A] p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white text-sm"
                  onClick={() => handleDelete()}
                  data-galabel="All Programs Delete-Program(s)"
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="bg-[#1D428A] p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white text-sm"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                    navigate("/create-program", {
                      state: {
                        regions: regions,
                        programCategory: programCategory,
                        fromPage: "programList",
                      },
                    });
                  }}
                  data-galabel="All Programs Create Program"
                >
                  Create a Program
                </button>
              </>
            ) : null}

            {/* )} */}
          </div>
        </div>
        <div className="w-full flex-wrap md:flex gap-2 md:flex pb-4">
          <div className="w-full md:w-5/12 lg:w-1/6 md:mt-0 mt-2">
            <Select
              value={programCategoryFilters}
              options={programCategory}
              className=" w-full  focus:outline-none focus:shadow-outline text-gray-900 rounded"
              placeholder="Program Category"
              onChange={(e) => handleChangeProgramCategory(e)}
            />
          </div>

          <div className="w-full md:w-5/12 lg:w-1/6 md:mt-0 mt-2">
            <Multiselect
              displayValue="label"
              showCheckbox={true}
              onSelect={handleChangeProgramTypes}
              options={programTypes}
              placeholder={"Program Type"}
              selectedValues={programTypesFilters}
              className=""
              onRemove={(event) => {
                setProgramTypesFilters(event);
              }}
            />
          </div>

          <div className="w-full md:w-5/12 lg:w-1/6 md:mt-0 mt-2">
            <Select
              value={statusValue}
              placeholder="Status"
              options={[
                {
                  value: "Draft",
                  label: "Draft",
                },
                // {
                //   value: "Complete",
                //   label: "Complete",
                // },
                {
                  value: "Published",
                  label: "Published",
                },
                {
                  value: "Unpublished",
                  label: "Unpublished",
                },
              ]}
              onChange={handleStatusChange}
            />
          </div>
          <div className="w-full md:w-5/12 lg:w-1/6 md:mt-0 mt-2">
            <Multiselect
              displayValue="label"
              showCheckbox={true}
              onSelect={handleChangeRegion}
              options={regions}
              selectedValues={regionFilters}
              placeholder={"Region"}
              className=""
              onRemove={(event) => {
                setCurrentPage(0);
                setCanPreviousPage(false);
                setCanNextPage(true);
                setSortStatus("");
                setSortStatusText("");
                if (event && event.length > 0) {
                  setRegionFilters(event);
                } else {
                  setRegionFilters(event);

                  let types = programTypesFilters
                    .map((item) => {
                      return item.value;
                    })
                    .join(",");

                  getProgramList(
                    null,
                    pageSize,
                    statusValue !== null ? statusValue.value : null,
                    userDetails.email,
                    programCategoryFilters?.value ?? null,
                    null,
                    types?.length > 0 ? types : null,
                    programStageFilter !== null
                      ? programStageFilter?.value
                      : null,
                    searchText,
                    overallDateStart !== null ? overallDateStart : null,
                    overallDateEnd !== null ? overallDateEnd : null,
                    null, //getSortByColumn(),
                    null //sortStatus === "ascending" ? "ASC" : "DESC"
                  );
                }
              }}
            />
          </div>
          <div className="w-full md:w-5/12 lg:w-1/6 md:mt-0 mt-2">
            <Select
              value={programStageFilter}
              options={[
                {
                  value: "Planning",
                  label: "Planning",
                },

                {
                  value: "Operating",
                  label: "Operating",
                },
                {
                  value: "Completed",
                  label: "Completed",
                },
              ]}
              className=" w-full  focus:outline-none focus:shadow-outline text-gray-900 rounded"
              placeholder="Stage"
              onChange={(e) => setProgramStageFilter(e)}
            />
          </div>
        </div>
        <div className="w-full flex-none md:flex justify-between gap-2">
          <div className="flex-none lg:flex md:flex lg:space-x-4">
            <div className="gap-2 lg:w-4/12 w-full">
              <DatePicker
                className="h-10 w-full pl-1 border focus:outline-none border border-grey-300 focus:shadow-outline text-gray-900 rounded"
                selected={overallDateStart}
                placeholderText={"Start Date"}
                onChange={(date) => handleDateChange(date, "overallDateStart")}
                //ref={startDateRef}
                wrapperClassName="w-full"
                //minDate={new Date()}
                //dateFormat="MM/dd/yyyy"
                dateFormat="dd MMMM yyyy"
              />
            </div>
            <span className="px-2">-</span>
            <div className="lg:w-4/12 w-full">
              <div className="flex gap-2">
                <DatePicker
                  className="h-10 mb-2 md:mb-0 w-full pl-1 border focus:outline-none focus:shadow-outline border-grey-300 text-gray-900 rounded"
                  selected={overallDateEnd}
                  placeholderText={"End Date"}
                  onChange={(date) => handleDateChange(date, "overallDateEnd")}
                  wrapperClassName="w-full"
                  // minDate={
                  //   programData.overallDateStart.value
                  //     ? programData.overallDateStart.value
                  //     : new Date()
                  // }
                  dateFormat="dd MMMM yyyy"
                  //ref={endDateRef}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap gap-2">
            <button
              disabled={
                programCategoryFilters !== "" ||
                programTypesFilters.length > 0 ||
                regionFilters.length > 0 ||
                statusValue !== null ||
                programStageFilter !== null ||
                (overallDateStart !== null && overallDateEnd !== null)
                  ? false
                  : true
              }
              className={
                programCategoryFilters !== "" ||
                programTypesFilters.length > 0 ||
                regionFilters.length > 0 ||
                statusValue !== null ||
                programStageFilter !== null ||
                (overallDateStart !== null && overallDateEnd !== null)
                  ? "bg-[#1D428A] p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white"
                  : "bg-gray-400 p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white"
              }
              data-action="unpublish"
              onClick={() => {
                handleSearch();
              }}
              data-galabel="Filter Program List"
            >
              Filter
            </button>
            <button
              className={
                "bg-[#1D428A] p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white"
              }
              onClick={() => {
                resetFilter();
              }}
              data-te-toggle="tooltip"
              title="Reset filter"
            >
              Reset
            </button>
          </div>
        </div>

        <div className="mt-3">
          <Table
            data={programList}
            column={column}
            isDeleteButton={true}
            isViewButton={true}
            handleSort={handleSort}
            onSelectTableRow={onSelectTableRow}
            handleClickEdit={handleClickEdit}
            handleClickView={handleClickView}
            handleDeleteUser={handleDeleteProgram}
            onClickSelectAll={onClickSelectAll}
            handleClickHistory={handleClickHistory}
            sortStatus={sortStatus}
            showCloneButton={true}
            showHistoryButton={true}
            sortStatusText={sortStatusText}
            showCheckboxInHeader={showCreateProgram}
            handleCloneButton={handleCloneButton}
            sourceName="Program-List"
            // handleOpenModule={handleOpenModule}
          />
          <Pagination
            gotoPage={gotoPage}
            previousPage={previousPage}
            nextPage={nextPage}
            totalCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            setPageSize={setPageSize}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            setCanNextPage={setCanNextPage}
            setCanPreviousPage={setCanPreviousPage}
            setCurrentPage={setCurrentPage}
          />
        </div>

        <Modal
          showModal={showModal}
          handleClose={() => setShowModal(false)}
          modalMessage={modalMessage}
          handleClick={
            modalMessage.title === "Edit"
              ? handleEditConfirm
              : modalMessage.title === "Delete"
              ? handleDeletConfirm
              : modalMessage.title === "Multiple Delete"
              ? handleMultiDeleteConfirm
              : modalMessage.title === "Clone"
              ? handleCloneConfirm
              : null
          }
        />
        <Toaster
          toasterMessage={toasterMessage}
          setToasterMessage={setToasterMessage}
        />

        {showProgramHistory === true && (
          <ProgramHistoryModel
            programID={programId?.value ?? null}
            handleCloseHistoryModal={() => {
              setShowProgramHistory(false);
              setProgramId(null);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ProgramList;
